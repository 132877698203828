import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NoResult from 'common/components/NoResult/NoResult';
import ReadMore from 'common/components/ReadMore/ReadMore';
import InfoYellow from 'common/Icons/InfoYellow';

import { ManualSearchOptions } from 'common/components/SearchComponent/data';

import AdvanceFilter from './AdvanceFilter';
import SearchWizard from './components/search-wizard/SearchWizard';
import SearchComponent from './SearchComponent';
import SearchResult from './SearchResult';

import { search as searchCrawlers } from 'store/slices/search/crawlers';
import {
  SEARCH_TYPE,
  search as searchDataLeaks,
} from 'store/slices/search/manual/dataLeaks';
import { search as searchCreditCard } from 'store/slices/search/manual/creditCard';

const ManualExpertSearch = () => {
  const dispatch = useDispatch();

  const [autoSearch, setAutoSearch] = useState(false);
  
  const [wizardStep, setWizardStep] = useState(0);
  const [searchType, setSearchType] = useState(ManualSearchOptions[0]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [openAdvanceFilter, setOpenAdvanceFilter] = useState(false);

  // useEffect(() => {
  //   const fun = async() => {
  //     await dispatch(
  //       searchCreditCard({ query: '', searchCondition: [] }),
  //     );
  //   }
  //   fun();
  // }, []);

  const handleSearchTypeChange = (searchType) => {
    setSearchType(searchType);
  };

  const handleSearch = async ({ searchType, searchTerm, searchCondition }) => {
    setSearching(true);
    setSearchTerm(searchTerm);

    if (searchType.id === 1) {
      await dispatch(
        searchDataLeaks({ type: SEARCH_TYPE.ALL, query: searchTerm }),
      );
    } else if (searchType.id === 2) {
      await dispatch(searchCreditCard({ query: searchTerm, searchCondition }));
      dispatch(searchCrawlers());
    }

    setSearching(false);
    setWizardStep(0);
    setShowResult(true);
  };

  const steps = [
    {
      number: 1,
      text: 'Select Search Type',
    },
    {
      number: 2,
      text: 'Enter Search Term',
    },
    {
      number: 3,
      text: 'Adjust Search Results',
    },
    {
      number: 4,
      text: 'Additional Search Filters',
    },
  ];


  return (
    <div className="tw-flex tw-flex-col tw-gap-6 tw-p-2">
      <AdvanceFilter open={openAdvanceFilter} close={setOpenAdvanceFilter} />

      <div className="tw-flex tw-gap-x-6">
        <h1 className="tw-text-xl tw-font-bold tw-leading-8 tw-text-left">
          Manual Expert Search
        </h1>

        {!showResult && <SearchWizard steps={steps} selectedIndex={wizardStep} />}
      </div>

      {showResult ? (
        <SearchResult
          setSearch={setShowResult}
          selectedOptionLabel={searchType?.label}
          searchTerm={searchTerm}
        />
      ) : (
        <>
          <SearchComponent
            searchTerm={searchTerm}
            onSearch={handleSearch}
            wizardStep={wizardStep}
            onWizardStepChange={(step) => setWizardStep(step)}
            onSearchTypeChange={handleSearchTypeChange}
            defaultOption={searchType}
            loading={searching}
            setOpenAdvanceFilter={setOpenAdvanceFilter}
          />

          <div className="tw-flex tw-gap-3 tw-p-4 tw-bg-white tw-rounded-lg">
            <div>
              <InfoYellow />
            </div>
            <ReadMore
              size={350}
              text="Our team of analysts conducts daily visits to forums, Telegram channels, and darknet sites to locate data sets from breaches that are freely available. These data sets are then cataloged in our database. Criminals typically attempt to sell this information rather than distribute it freely, which may affect the accuracy or timeliness of the data. To access data that is currently for sale, we recommend performing a live search on hacker forums."
              textStyling="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]"
              readStyling="tw-text-sm tw-font-bold tw-leading-6 tw-text-[#00A4E6]"
            />
          </div>
          <div className="tw-relative">
            <NoResult />
            {searching && (
              <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ManualExpertSearch;
