import React, { useState } from 'react';
import ProgressBar from '../../../common/components/ProgressBar/ProgressBar';
import CustomButton from '../../../common/components/Button/Button';
import EyeYellow from '../../../common/Icons/EyeYellow';
import ArrowBack from '../../../common/Icons/ArrowBack';
import Table from '../../common/table/table.component';
import FilterIcon from '../../../common/Icons/FilterIcon';

const DomainSpoofingDetection = ({ setSearch, setIsDomainSpoofingView }) => {
  const [isDetailView, setIsDetailView] = useState(false);
  const results = [
    {
      progress: 100,
    },
    {
      progress: 100,
    },
    {
      progress: 100,
    },
    {
      progress: 100,
    },
    {
      progress: 100,
    },
  ];

  const activity = [
    { title: 'Generating Type-Squtted Domains' },
    { title: 'Find Domains that contain your search term' },
    { title: 'Find domains with 2 typos' },
    { title: 'Find domains with 1 typo' },
    { title: 'Find domain in SSK transperancy logs' },
  ];

  const spoofingDomainColumns = [
    {
      Header: 'Created',
      accessor: 'created',
      render: (_, record) => {
        return <span className=" tw-text-[#44464A]">{record.created}</span>;
      },
    },
    {
      Header: 'Brand',
      accessor: 'brand',
    },
    {
      Header: 'date',
      accessor: 'date',
    },
    {
      Header: 'MX',
      accessor: 'mx',
    },
    {
      Header: 'NX',
      accessor: 'nx',
    },
    {
      Header: 'WHOIS',
      accessor: 'whois',
    },
    {
      Header: 'WEB',
      accessor: 'web',
    },
    {
      Header: 'Category',
      accessor: 'category',
    },
    {
      Header: 'Spoofing',
      accessor: 'spoofing',
    },
    {
      Header: 'Risk',
      accessor: 'risk',
    },

    {
      Header: 'Screenshot',
      accessor: 'screenshot',
      render: (_, item) => {
        return (
          <button>
            <EyeYellow />
          </button>
        );
      },
    },
  ];
  const spoofingDomainData = [
    {
      created: 'www.example.com',
      brand: 'true',
      date: '4/21/12',
      mx: 'true',
      nx: 'true',
      whois: 'anon',
      web: 'true',
      category: 'parked',
      spoofing: 'lorem',
      risk: '34',
      searchKeywords: 'example.com',
    },
    {
      created: 'www.example.com',
      brand: 'true',
      date: '4/21/12',
      mx: 'true',
      nx: 'true',
      whois: 'anon',
      web: 'true',
      category: 'parked',
      spoofing: 'lorem',
      risk: '34',
      searchKeywords: 'example.com',
    },
    {
      created: 'www.example.com',
      brand: 'true',
      date: '4/21/12',
      mx: 'true',
      nx: 'true',
      whois: 'anon',
      web: 'true',
      category: 'parked',
      spoofing: 'lorem',
      risk: '34',
      searchKeywords: 'example.com',
    },
    {
      created: 'www.example.com',
      brand: 'true',
      date: '4/21/12',
      mx: 'true',
      nx: 'true',
      whois: 'anon',
      web: 'true',
      category: 'parked',
      spoofing: 'lorem',
      risk: '34',
      searchKeywords: 'example.com',
    },
    {
      created: 'www.example.com',
      brand: 'true',
      date: '4/21/12',
      mx: 'true',
      nx: 'true',
      whois: 'anon',
      web: 'true',
      category: 'parked',
      spoofing: 'lorem',
      risk: '34',
      searchKeywords: 'example.com',
    },
  ];

  return (
    <>
      {isDetailView ? (
        <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
          <div className=" tw-flex tw-justify-between">
            <div className="tw-flex tw-gap-[11px]">
              <div
                onClick={() => setIsDetailView(false)}
                className="tw-cursor-pointer"
              >
                <ArrowBack />
              </div>
              <span className="tw-font-bold tw-text-[#263238]">
                Spoofing domain
              </span>
            </div>
            <div className="tw-flex tw-gap-3">
              <CustomButton
                icon={<FilterIcon />}
                text="Filters"
                className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border tw-border-[#DFE0E3] tw-rounded-md tw-bg-[#ffffff] tw-text-[#0B0B0B] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
              />
              <CustomButton
                text="Monitor Typosquatted Domains"
                className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border tw-border-[#DFE0E3] tw-rounded-md tw-bg-[#ffffff] tw-text-[#0B0B0B] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
              />
              <CustomButton
                text="Run a new Search"
                onClick={() => setSearch(false)}
                className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
              />
            </div>
          </div>
          <Table
            data={spoofingDomainData}
            columns={spoofingDomainColumns}
            selectable={true}
          />
        </div>
      ) : (
        <div className="tw-flex tw-flex-col tw-bg-[#ffffff] tw-rounded-lg tw-p-6 tw-gap-4">
          <div className="tw-flex tw-items-center tw-gap-2">
            <div
              onClick={() => {
                if (setSearch) {
                  setSearch(false);
                } else {
                  setIsDomainSpoofingView(false);
                }
              }}
              className="tw-cursor-pointer"
            >
              <ArrowBack />
            </div>
            <div className="tw-text-lg tw-font-bold tw-leading-[30px] tw-text-[#263238]">
              Domain Spoofing Detection
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-4 tw-p-6 tw-border tw-border-solid tw-border-[#EFF0F2]">
            <div className="tw-flex tw-flex-col tw-gap-1">
              <div className="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]">
                Selected Domain
              </div>
              <div className="tw-text-sm tw-font-medium tw-leading-[16.41px] tw-text-[#0B0B0B]">
                Example.com
              </div>
            </div>
            <div className="tw-w-full tw-flex tw-justify-between">
              <div className="tw-w-full tw-flex tw-gap-8">
                {' '}
                <div className="tw-w-full tw-flex tw-flex-col tw-gap-3">
                  <div className="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]">
                    Result
                  </div>
                  <div>
                    {results.map((item, index) => (
                      <div className="tw-flex tw-flex-col tw-mb-2" key={index}>
                        <ProgressBar
                          height="tw-h-[6px]"
                          inlineProgress={true}
                          current={item.progress}
                          backgroundColor="#ECC551"
                          trackColor="tw-bg-[#EFF0F2]"
                          className="tw-text-sm tw-font-medium tw-leading-[16.41px] tw-text-[#0B0B0B]"
                          subClassName="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]"
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="tw-w-full">
                  <div className="tw-mb-3 tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]">
                    Activity
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-2">
                    {activity?.map((item, index) => (
                      <div
                        key={index}
                        className="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#0B0B0B]"
                      >
                        {item.title}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="tw-flex tw-gap-8">
                <div className="w-flex tw-flex-col">
                  <div className="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]">
                    Alert
                  </div>
                  <div className="tw-mt-3 tw-text-xs tw-whitespace-nowrap tw-border tw-border-solid tw-border-[#B42318] tw-px-2 tw-py-[2px] tw-font-medium tw-leading-[18px] tw-text-center tw-bg-[#FECDCA] tw-rounded-full">
                    {'Warning: > {Nr} Results'}
                  </div>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-3">
                  <div className="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]">
                    Exclude
                  </div>
                  <div onClick={() => setIsDetailView(true)}>
                    <CustomButton
                      text="view details"
                      icon={<EyeYellow />}
                      className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-[#ECC551] tw-whitespace-nowrap"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-gap-4">
            <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-bg-[#FCF6E5]">
              <div className="tw-text-base tw-font-bold tw-leading-[26px] tw-text-[#0B0B0B]">
                What is Typosquatting?
              </div>
              <div className="tw-text-sm tw-font-normal tw-leading-5 tw-tracking-[0.02em] tw-text-[#455A64]">
                Typosquatting involves buying domain names that closely mimic
                legitimate websites by adding hyphens, using different
                spellings, or substituting characters (e.g., zeros for "O" or
                ones for "I").
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-bg-[#FCF6E5]">
              <div className="tw-text-base tw-font-bold tw-leading-[26px] tw-text-[#0B0B0B]">
                What is Typosquatting?
              </div>
              <div className="tw-text-sm tw-font-normal tw-leading-5 tw-tracking-[0.02em] tw-text-[#455A64]">
                Typosquatting involves buying domain names that closely mimic
                legitimate websites by adding hyphens, using different
                spellings, or substituting characters (e.g., zeros for "O" or
                ones for "I").
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DomainSpoofingDetection;
