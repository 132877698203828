import React from "react";

const DropdownList = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5 7.50016H9.58333M17.5 3.3335H7.91667M17.5 11.6668H11.25M17.5 15.8335H15.4167H13.3333M5 16.6668L5 3.3335M5 16.6668L2.5 14.1668M5 16.6668L7.5 14.1668"
          stroke="#44464A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default DropdownList;
