import * as React from "react";

function BugIcon(props) {
  return (
    <svg
      width="44"
      height="37"
      viewBox="0 0 44 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0612 16.2L18.887 12.8971C19.0104 12.4038 19.4538 12.0576 19.9622 12.0576H23.7739C24.2824 12.0576 24.7258 12.4038 24.8492 12.8971L25.675 16.2"
        stroke="#FFBE00"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M30.7357 12.6113L29.073 13.7198V16.491L26.856 17.5995M26.856 22.5877L28.5188 24.2504V27.5759M21.8679 17.0453L18.0488 16.1966C17.4488 16.0633 16.8797 16.5198 16.8797 17.1344V22.0334C16.8797 24.7884 19.1129 27.0216 21.8679 27.0216C24.6228 27.0216 26.856 24.7884 26.856 22.0334V17.1344C26.856 16.5198 26.2869 16.0633 25.6869 16.1966L21.8679 17.0453Z"
        stroke="#FFBE00"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M30.7358 25.9134V22.0337L29.073 19.8167H26.8561M13 12.6116L14.6627 13.7201V16.4913L16.8797 17.5998M16.8797 22.588L15.217 24.2507V27.5761M13 25.9134V22.0337L14.6627 19.8167H16.8797M25.7476 9.28613H24.0848L23.5306 12.0573M17.9882 9.28613H19.6509L20.2052 12.0573M21.8679 17.0455V27.0219"
        stroke="#FFBE00"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default BugIcon;
