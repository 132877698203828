import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ArrowDown from '../../../common/Icons/ArrowDown';
import ArrowUp from '../../../common/Icons/ArrowUp';
import Alert from '../../assets/svgs/Alert';
import Dashboard from '../../assets/svgs/Dashboard';
import DirectionSidebarIcon from '../../assets/svgs/DirectionSidebarIcon';
import Document from '../../assets/svgs/Document';
import OpenBook from '../../assets/svgs/OpenBook';
import Search from '../../assets/svgs/Search';
import Setting from '../../assets/svgs/Setting';
import Support from '../../assets/svgs/Support';
import CornerRadius from '../CornerRadius';

const Sidebar = () => {
  const [activeRoute, setActiveRoute] = useState('');
  const location = useLocation();

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location.pathname]);
  const isSmallScreen = useIsSmallScreen();

  const getColor = (path) => {
    if (isSmallScreen) {
      return '#FFFFFF';
    }
    return activeRoute === path ? '#000000' : '#FFFFFF';
  };

  const [isOpen, setIsOpen] = useState(null);

  useEffect(() => {
    if (
      activeRoute.startsWith('/search-all-databases') ||
      activeRoute.startsWith('/darknet-search') ||
      activeRoute.startsWith('/manual-expert-search')
    ) {
      setIsOpen('first');
    } else if (activeRoute.startsWith('/ransomware-feed')) {
      setIsOpen('second');
    } else if (
      activeRoute.startsWith('/ActiveAlerts') ||
      activeRoute.startsWith('/AlertingRules') ||
      activeRoute.startsWith('/basic-alerting-settings')
    ) {
      setIsOpen('third');
    } else {
      setIsOpen(null);
    }
  }, [activeRoute]);

  const toggleDropdown = (dropdownId) => {
    setIsOpen((prev) => (prev === dropdownId ? null : dropdownId));
  };

  const shouldShowArrowDown = (path) => {
    return (activeRoute === path && !isOpen) || activeRoute !== path;
  };

  return (
    <>
      <div className="md:tw-flex tw-flex-col tw-text-[#F5F5F6] tw-py-3 md:tw-py-[50px]">
        <NavLink
          className={
            activeRoute === '/' ? 'sidebarActiveLink' : 'sidebarInactiveLink'
          }
          to={'/'}
          onClick={() => setIsOpen(null)}
        >
          <Dashboard color={getColor('/')} />
          <p className="md:tw-block">Dashboard</p>
          {activeRoute === '/' && <CornerRadius />}
        </NavLink>

        <div>
          <NavLink
            className={
              activeRoute === '/search-all-databases'
                ? 'tw-flex tw-items-center tw-gap-4  md:tw-justify-between tw-px-4 tw-py-5 hover:tw-cursor-pointer tw-rounded-tl-[30px] tw-rounded-bl-[30px] tw-font-semibold'
                : 'tw-flex tw-items-center tw-gap-4  md:tw-justify-between tw-px-4 tw-py-5 hover:tw-cursor-pointer tw-rounded-tl-[30px] tw-rounded-bl-[30px] tw-font-semibold'
            }
            to={'/search-all-databases'}
            onClick={() => toggleDropdown('first')}
          >
            <div className="tw-flex tw-items-center md:tw-justify-between tw-gap-3 ">
              <Search color={'#FFFFFF'} />
              <p className="md:tw-block tw-whitespace-nowrap tw-text-[14px] md:tw-text-[16px]">
                Search
              </p>
            </div>
            {shouldShowArrowDown('/search-all-databases') &&
              shouldShowArrowDown('/darknet-search') &&
              shouldShowArrowDown('/manual-expert-search') && (
                <ArrowDown stroke="#FFFFFF" />
              )}
            {(activeRoute === '/search-all-databases' ||
              activeRoute === '/darknet-search' ||
              activeRoute === '/manual-expert-search') &&
              isOpen === 'first' && (
                <ArrowUp stroke={getColor('/search-all-databases')} />
              )}
          </NavLink>
          {isOpen === 'first' && (
            <div className="tw-flex tw-items-center tw-gap-1 tw-px-4">
              <DirectionSidebarIcon />
              <div className="tw-flex tw-flex-col">
                <NavLink
                  className={(navClass) =>
                    navClass.isActive
                      ? 'sidebarDropdownActiveLink'
                      : 'sidebarDropdownInctiveLink'
                  }
                  to={'/search-all-databases'}
                >
                  Search All Databases
                </NavLink>
                <NavLink
                  className={(navClass) =>
                    navClass.isActive
                      ? 'sidebarDropdownActiveLink'
                      : 'sidebarDropdownInctiveLink'
                  }
                  to={'/darknet-search'}
                >
                  Live search
                </NavLink>
                <NavLink
                  className={(navClass) =>
                    navClass.isActive
                      ? 'sidebarDropdownActiveLink'
                      : 'sidebarDropdownInctiveLink'
                  }
                  to={'/manual-expert-search'}
                >
                  Expert search
                </NavLink>
              </div>
            </div>
          )}
        </div>

        <div>
          <NavLink
            className={
              activeRoute === '/ransomware-feed'
                ? 'tw-flex tw-items-center tw-gap-4 md:tw-justify-between tw-px-4 tw-py-5 hover:tw-cursor-pointer tw-rounded-tl-[30px] tw-rounded-bl-[30px] tw-font-semibold'
                : 'tw-flex tw-items-center tw-gap-4  md:tw-justify-between tw-px-4 tw-py-5 hover:tw-cursor-pointer tw-rounded-tl-[30px] tw-rounded-bl-[30px] tw-font-semibold'
            }
            onClick={() => toggleDropdown('second')}
            to={'/ransomware-feed'}
          >
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
              <OpenBook color={'#FFFFFF'} />
              <p className="md:tw-block tw-text-[14px] md:tw-text-[16px]">
                CyberIntel
              </p>
            </div>
            {/* {activeRoute === "/intel" && <CornerRadius />} */}

            {shouldShowArrowDown('/ransomware-feed') &&
              shouldShowArrowDown('/ransomware-feed-spoofing') &&
              shouldShowArrowDown('/ransomware-feed-surface') && (
                <ArrowDown stroke="#FFFFFF" />
              )}
            {(activeRoute === '/ransomware-feed' ||
              activeRoute === '/ransomware-feed-spoofing' ||
              activeRoute === '/ransomware-feed-surface') &&
              isOpen === 'second' && (
                <ArrowUp stroke={getColor('/ransomware-feed')} />
              )}
          </NavLink>

          {isOpen === 'second' && (
            <div className="tw-flex tw-items-center tw-gap-1 tw-px-4">
              <DirectionSidebarIcon />
              <div className="tw-flex tw-flex-col">
                <NavLink
                  className={(navClass) =>
                    navClass.isActive
                      ? 'sidebarDropdownActiveLink'
                      : 'sidebarDropdownInctiveLink'
                  }
                  to={'/ransomware-feed'}
                >
                  Ransomware Feed
                </NavLink>
                <NavLink
                  className={(navClass) =>
                    navClass.isActive
                      ? 'sidebarDropdownActiveLink'
                      : 'sidebarDropdownInctiveLink'
                  }
                  to={'/ransomware-feed-spoofing'}
                >
                  Spoofing Detection
                </NavLink>
                <NavLink
                  className={(navClass) =>
                    navClass.isActive
                      ? 'sidebarDropdownActiveLink'
                      : 'sidebarDropdownInctiveLink'
                  }
                  to={'/ransomware-feed-surface'}
                >
                  Attack Surface detection
                </NavLink>
              </div>
            </div>
          )}
        </div>

        <div>
          <NavLink
            className={
              activeRoute === '/ActiveAlerts'
                ? 'tw-flex tw-items-center tw-gap-4 md:tw-justify-between tw-px-4 tw-py-5 hover:tw-cursor-pointer tw-rounded-tl-[30px] tw-rounded-bl-[30px] tw-font-semibold'
                : 'tw-flex tw-items-center tw-gap-4  md:tw-justify-between tw-px-4 tw-py-5 hover:tw-cursor-pointer tw-rounded-tl-[30px] tw-rounded-bl-[30px] tw-font-semibold'
            }
            to={'/ActiveAlerts'}
            onClick={() => toggleDropdown('third')}
          >
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
              <Alert color={'#FFFFFF'} />
              <p className="md:tw-block tw-whitespace-nowrap tw-text-[14px] md:tw-text-[16px]">
                Alerts
              </p>
            </div>

            {shouldShowArrowDown('/ActiveAlerts') &&
              shouldShowArrowDown('/AlertingRules') &&
              shouldShowArrowDown('/basic-alerting-settings') && (
                <ArrowDown stroke="#FFFFFF" />
              )}
            {(activeRoute === '/ActiveAlerts' ||
              activeRoute === '/AlertingRules' ||
              activeRoute === '/basic-alerting-settings') &&
              isOpen === 'third' && (
                <ArrowUp stroke={getColor('/ActiveAlerts')} />
              )}
          </NavLink>

          {isOpen === 'third' && (
            <div className="tw-flex tw-items-center tw-gap-1 tw-px-4">
              <DirectionSidebarIcon />
              <div className="tw-flex tw-flex-col">
                <NavLink
                  className={(navClass) =>
                    navClass.isActive
                      ? 'sidebarDropdownActiveLink'
                      : 'sidebarDropdownInctiveLink'
                  }
                  to={'/ActiveAlerts'}
                >
                  Active Alerts
                </NavLink>
                <NavLink
                  className={(navClass) =>
                    navClass.isActive
                      ? 'sidebarDropdownActiveLink'
                      : 'sidebarDropdownInctiveLink'
                  }
                  to={'/AlertingRules'}
                >
                  Alerting Rules
                </NavLink>
                <NavLink
                  className={(navClass) =>
                    navClass.isActive
                      ? 'sidebarDropdownActiveLink'
                      : 'sidebarDropdownInctiveLink'
                  }
                  to={'/basic-alerting-settings'}
                >
                  Basic Alerting Settings
                </NavLink>
              </div>
            </div>
          )}
        </div>

        <NavLink
          className={
            activeRoute === '/reports'
              ? 'sidebarActiveLink'
              : 'sidebarInactiveLink'
          }
          to={'/reports'}
          onClick={() => setIsOpen(null)}
        >
          <Document color={getColor('/reports')} />
          <p className="md:tw-block">Reports & Exports</p>
          {activeRoute === '/reports' && <CornerRadius />}
        </NavLink>

        <NavLink
          className={
            activeRoute === '/settings'
              ? 'sidebarActiveLink'
              : 'sidebarInactiveLink'
          }
          to={'/settings'}
          onClick={() => setIsOpen(null)}
        >
          <Setting color={getColor('/settings')} />
          <p className="md:tw-block">Settings</p>
          {activeRoute === '/settings' && <CornerRadius />}
        </NavLink>

        <NavLink
          className={
            activeRoute === '/support'
              ? 'sidebarActiveLink'
              : 'sidebarInactiveLink'
          }
          to={'/support'}
          onClick={() => setIsOpen(null)}
        >
          <Support color={getColor('/support')} />
          <p className="md:tw-block">Support</p>
          {activeRoute === '/support' && <CornerRadius />}
        </NavLink>
      </div>
    </>
  );
};

export default Sidebar;

const useIsSmallScreen = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isSmallScreen;
};
