import React, { useRef, useState } from "react";
import Image from "../../../assets/svgs/Image";
import InputGroup from "../../../../common/components/InputGroup/InputGroup";
import { useForm } from "react-hook-form";
import Upload from "../../../assets/svgs/Upload";
const WhiteLabeling = () => {
  const fileInputRef = useRef(null);
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <>
      <div className="tw-w-[100%] tw-flex tw-flex-col tw-gap-6">
        <div className="tw-flex tw-flex-col tw-gap-3">
          <p className="tw-text-[18px] tw-font-bold">Company Details</p>
          <p className="tw-text-[18px] tw-font-bold">License Info</p>
        </div>
        <div>
          <div className="tw-flex tw-items-center tw-gap-3 tw-flex-col md:tw-flex-row">
            {uploadedImage ? (
              <img
                src={uploadedImage}
                alt=""
                className="tw-rounded-[100%] tw-w-[100px] tw-h-[100px]"
              />
            ) : (
              <div
                className="tw-flex tw-items-center tw-justify-center tw-rounded-[100%] tw-w-[100px] tw-h-[100px] tw-bg-[#F5F5F6] tw-border-4 tw-border-[#979BA5] tw-border-dashed tw-p-4 tw-cursor-pointer"
                onClick={handleFileClick}
              >
                <Upload />
              </div>
            )}

            <div className="flex items-center space-x-2">
              <input
                type="file"
                ref={fileInputRef}
                className="tw-hidden"
                onChange={handleFileChange}
              />
              <button
                onClick={handleFileClick}
                className="tw-flex tw-items-center tww-px-4 tw-p-2  tw-rounded-md tw-cursor-pointer tw-gap-2 tw-bg-secondary"
              >
                <Image />
                <span className="tw-text-[#FFFFFF] tw-font-normal">
                  Upload an Image
                </span>
              </button>
            </div>

            {/*  */}
          </div>
        </div>

        <div className="tw-flex tw-flex-col tw-gap-3">
          <p className="tw-text-[18px] tw-font-bold tw-mb-2">
            Change Application Name{" "}
          </p>
          <div className="lg:tw-w-[397px]">
            {" "}
            <InputGroup
              labelText="Application Name"
              inputType="text"
              inputName="ApplicationName"
              value="Darknet Engine by MSSP"
              readOnly
              register={register}
              errors={errors}
              focusOnType={true}
              className="tw-text-[#0B0B0B] tw-text-base"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WhiteLabeling;
