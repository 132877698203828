import React from "react";

const Feedback = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2735_8368)">
        <path
          d="M5.07847 9.35766C5.02668 9.02385 4.99981 8.68187 4.99981 8.33366C4.99981 4.65176 8.00421 1.66699 11.7103 1.66699C15.4165 1.66699 18.4209 4.65176 18.4209 8.33366C18.4209 9.16539 18.2675 9.96154 17.9875 10.6957C17.9293 10.8482 17.9002 10.9245 17.887 10.984C17.8739 11.043 17.8689 11.0845 17.8674 11.1449C17.866 11.2058 17.8743 11.273 17.8908 11.4073L18.2263 14.1324C18.2626 14.4274 18.2808 14.5749 18.2317 14.6822C18.1887 14.7761 18.1123 14.8508 18.0174 14.8916C17.9091 14.9382 17.762 14.9166 17.4679 14.8735L14.8135 14.4844C14.675 14.4641 14.6056 14.4539 14.5425 14.4543C14.4801 14.4547 14.4369 14.4593 14.3758 14.4721C14.314 14.4851 14.2351 14.5147 14.0773 14.5738C13.3412 14.8495 12.5435 15.0003 11.7103 15.0003C11.3618 15.0003 11.0196 14.9739 10.6854 14.9231M6.35949 18.3337C8.83023 18.3337 10.8332 16.2816 10.8332 13.7503C10.8332 11.219 8.83023 9.16699 6.35949 9.16699C3.88874 9.16699 1.8858 11.219 1.8858 13.7503C1.8858 14.2592 1.96673 14.7486 2.11613 15.2059C2.17928 15.3992 2.21085 15.4959 2.22122 15.5619C2.23204 15.6309 2.23393 15.6696 2.2299 15.7393C2.22604 15.806 2.20935 15.8814 2.17596 16.0323L1.6665 18.3337L4.16217 17.9928C4.29839 17.9742 4.3665 17.9649 4.42598 17.9653C4.4886 17.9657 4.52184 17.9691 4.58326 17.9814C4.64159 17.993 4.7283 18.0236 4.90173 18.0848C5.35866 18.2461 5.84909 18.3337 6.35949 18.3337Z"
          stroke="#2B2F38"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2735_8368">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Feedback;
