import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgress = ({
  percentage,
  title,
  count,
  strokeColor,
  percentageColor,
}) => {
  return (
    <div className="tw-flex tw-flex-col tw-items-center">
      <div className="tw-w-20 tw-h-20 tw-mb-2">
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          styles={buildStyles({
            textSize: "16px",
            pathColor: strokeColor,
            textColor: percentageColor,
            trailColor: "#F5F5F6",
            backgroundColor: "#3e98c7",
          })}
        />
      </div>
      <div className="tw-text-center">
        <div className="tw-text-xs tw-font-normal tw-leading-[18px] tw-text-[#000000]">
          {title}
        </div>
        <div className="tw-text-sm tw-font-bold tw-leading-[16.41px] tw-text-[#000000]">
          {count}
        </div>
      </div>
    </div>
  );
};

export default CircularProgress;
