import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import QrCode from "../../../assets/images/Qr.png";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputGroup from "../../../../common/components/InputGroup/InputGroup";
import RadioButton from "../../../../common/components/RadioButton/RadioButton";

const Security = () => {
  const schema = yup.object().shape({
    currentPassword: yup.string().required("Current Password is required"),
    newPassword: yup.string().required("New Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      securityRadio: "passwordAuth",
    },
  });

  const selectedSecurityOption = watch("securityRadio");

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <>
      <div className="tw-w-[100%] tw-flex tw-flex-col tw-gap-6">
        <div className="">
          <p className="tw-text-[18px] tw-font-bold">Security Settings</p>
        </div>
        <div className="tw-flex tw-justify-between md:tw-w-[386px]">
          <div className="tw-flex tw-flex-row ">
            <RadioButton
              htmlFor="passwordAuth"
              inputType="radio"
              inputName="securityRadio"
              value="passwordAuth"
              register={register}
              errors={errors}
            />
            <p className="tw-ml-2">Password Authentication</p>
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <RadioButton
              htmlFor="2fAuth"
              inputType="radio"
              inputName="securityRadio"
              value="2fAuth"
              register={register}
              errors={errors}
            />
            <p>2FA Setup</p>
          </div>
        </div>
        {selectedSecurityOption === "passwordAuth" ? (
          <div className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-flex tw-flex-col tw-gap-1">
              <p className="tw-text-[18px] tw-font-bold">
                Password Authentication
              </p>
              <p>Use a password to log in to the system.</p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-4">
              <div className="tw-grid tw-grid-cols-1">
                {" "}
                <InputGroup
                  labelText="Current Password"
                  inputType="password"
                  placeholder="********"
                  inputName="currentPassword"
                  register={register}
                  errors={errors}
                  focusOnType={true}
                />
              </div>
              <div className="tw-grid lg:tw-grid-cols-2 tw-gap-4">
                <div className="">
                  {" "}
                  <InputGroup
                    labelText="New Password"
                    inputType="password"
                    placeholder="********"
                    inputName="newPassword"
                    register={register}
                    errors={errors}
                    focusOnType={true}
                  />
                </div>{" "}
                <div className="">
                  {" "}
                  <InputGroup
                    labelText="Confirm Password"
                    inputType="password"
                    placeholder="********"
                    inputName="confirmPassword"
                    register={register}
                    errors={errors}
                    focusOnType={true}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-flex tw-flex-col tw-gap-1">
              <p className="tw-text-[18px] tw-font-bold">
                Two-Factor Authentication (2FA)
              </p>
              <p>
                Enhance your account security by enabling two-factor
                authentication using the Authy app.
              </p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1">
              <p className="tw-text-[18px] tw-font-bold">How Its work</p>
              <p>
                Visit the App Store or Google Play Store on your mobile device
                and search for 'Authy'.{" "}
                <span>
                  <a className="tw-text-[#00A4E6]" href="#">
                    Download for iOS
                  </a>
                </span>{" "}
                or{" "}
                <span>
                  <a className="tw-text-[#00A4E6]" href="#">
                    Download for Android
                  </a>
                </span>{" "}
                Once installed, open the Authy app and select 'Add Account'.
              </p>
              <p>
                Use the Authy app to scan the QR code below to link your
                account.
              </p>
            </div>
            <div className="tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-border md:tw-w-[220px] md:tw-h-[220px]">
              <img src={QrCode} alt="" className=" tw-w-[210px] tw-h-[210px]" />
            </div>
          </div>
        )}

        <div className="tw-flex tw-gap-4">
          <button
            className="tw-border tw-border-[#DFE0E3] tw-p-3 tw-rounded-lg"
            // onClick={}
          >
            Cancel
          </button>
          <button
            className="tw-bg-primary tw-text-white tw-p-3 tw-rounded-lg"
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
};

export default Security;
