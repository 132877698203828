import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { logout } from '../../actions';

export const getUserInfo = createAsyncThunk(
  'user/getUserInfo',
  async (_, { rejectWithValue }) => {
    try {
      const profileResponse = await api.get('settings/user_profile_info/');
      const licenseResponse = await api.get('settings/license_info/');

      return { profile: profileResponse.data, license: licenseResponse.data };
    } catch (error) {
      return rejectWithValue('Get user data failed');
    }
  },
);

const initialState = {
  profile: {
    id: 0,
    first_name: null,
    last_name: null,
    city: null,
    country: null,
    email: null,
    zip_code: null,
    phone: null,
    organization_name: null,
    profile_img: null,
  },
  license: {
    license_type: null,
    expiry_date: null,
    active_features: [],
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state, action) => {
        state.profile = initialState.profile;
        state.license = initialState.license;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.profile = action.payload.profile;
        state.license = action.payload.license;
      })
      .addCase(logout, (state) => {
        state.profile = initialState.profile;
        state.license = initialState.license;
      });
  },
});

export default userSlice.reducer;
