import React from "react";
import CountriesChart from "./countries_chart/countries_chart";
import RegionsChart from "./region_chart/region_chart";
import GroupAttacksChart from "./group_attacks/group_attacks";
import GroupAttacksList from "./list_attack/list_attack";
import WorldMapChart from "./world_map/world_map";
import GroupPercentChart from "./attack_percent/attack_percent";
import RiskChart from "./risk_chart/risk_chart";
import BubbleChartComponent from "./bubble_chart/bubble_chart";
import BenchmarkChart from "./bench_marks/bench_marks";
import IndustriesChart from "./target_industries/target_industries";

function ChartsSection() {
  return (
    <div className="tw-bg-gray-100 tw-p-4 tw-min-h-screen">
      <p className="tw-bg-white tw-text-black tw-p-4 tw-mb-4">
        Cyber Threat Intelligence News
      </p>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-6">
        <div>
          <CountriesChart />
        </div>
        <div>
          <RegionsChart />
        </div>
        <div>
          <GroupAttacksChart />
        </div>
      </div>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-7 tw-my-4">
        <div>
          <GroupAttacksList />
        </div>
        <div>
          <IndustriesChart />
        </div>
      </div>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-7 tw-my-4">
        <div className="md:tw-col-span-2">
          <WorldMapChart />
        </div>
        <div className="md:tw-col-span-2">
          <GroupPercentChart />
        </div>
      </div>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-7 tw-my-4">
        <div className="md:tw-col-span-2">
          <RiskChart />
        </div>
        <div className="md:tw-col-span-2">
          <BubbleChartComponent />
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-7 tw-my-4">
        <div className="md:tw-col-span-2">
          <BenchmarkChart />
        </div>
        <div className="md:tw-col-span-2">
          <GroupPercentChart />
        </div>
      </div>
    </div>
  );
}

export default ChartsSection;
