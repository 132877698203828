import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import Heading from '../../common/components/heading/Heading';
import CustomComponent from '../../common/components/SearchComponent/CustomComponent';
import {
  DarknetSearchOptions,
  DatabaseSearchOptions,
} from '../../common/components/SearchComponent/data';
import GenericSearch from '../../common/components/SearchComponent/GenericSearch';
import LiveSearchComponent from '../search/DarknetLiveSearch/SearchComponent';
import { AlertIcon } from '../../common/Icons';
import CustomTabs from '../../pages/tabs';
import DomainSpoofingDetection from '../search/ManualExpertSearch/DomainSpoofingDetection';
import ExposureInfrastructureCheck from '../search/ManualExpertSearch/ExposureInfrastructureCheck';
import Alerts from './Alerts/alerts.component';
import BookMark from './BookMark/bookmark.component';
import Credientials from './Credientials/credientials.component';
import CyberSheet from './Cyber/cyber.component';
import CreateReport from './Downloads/Create/create.component';
import Report from './Downloads/Report/report.component';
import Infrastructure from './Infrastructure/infrastructure.component';
import Leak from './Leaks/leak.component';
import PasswordStrengthTable from './Password/password-strength.component';
import Risk from './Risks/risk.component';
import RiskyPassword from './Risky-password/RiskyPassword';
import Risky from './Risky/risky.component';
import CyberThreat from './Threats/threats.component';
import TooltipComponent from './Tooltip/Tooltip';
import TopRisky from './TopRisky/top-risky.component';
import WebSheet from './Web/web.component';

function AdminDashboard() {
  const [isInfrastructureView, setIsInfrastructureView] = useState(false);
  const [isDomainSpoofingView, setIsDomainSpoofingView] = useState(false);

  const navigate = useNavigate();

  const handleLiveSearch = ({ option, searchTerm, selectedForums }) => {
    const redirectUrl = 'darknet-search';

    const queryParams = new URLSearchParams({
      type: option.id,
      query: searchTerm,
      forums: selectedForums.map((f) => f.id),
    });

    navigate(`${redirectUrl}?${queryParams.toString()}`);
  };

  // Function to handle manual expert search
  const handleSearchTermTwo = (term) => {
    navigate('/manual-expert-search');
  };
  const [openAdvanceFilter, setOpenAdvanceFilter] = useState(false);

  return (
    <>
      {isInfrastructureView ? (
        <ExposureInfrastructureCheck
          setIsInfrastructureView={setIsInfrastructureView}
        />
      ) : isDomainSpoofingView ? (
        <DomainSpoofingDetection
          setIsDomainSpoofingView={setIsDomainSpoofingView}
        />
      ) : (
        <div className="tw-p-2">
          <p className="tw-flex tw-justify-center tw-items-center tw-gap-2 tw-text-white tw-bg-red-800 tw-rounded-lg tw-p-2 tw-text-base">
            <AlertIcon color="#ffffff" />
            Warning: Your license is about to expire in 14 days.{' '}
            <u>
              <b
                onClick={() => navigate('/settings')}
                className="
              tw-cursor-pointer"
              >
                Click here
              </b>
            </u>{' '}
            to request a renewal offer
          </p>

          <TooltipComponent />
          <p className=" tw-pb-4 tw-flex tw-justify-start tw-text-base tw-font-normal tw-text-[#5B5D63]">
            Here you can choose and start between Database, Live, or Expert
            search.
          </p>

          <div className="tabs-sec">
            <CustomTabs>
              <div label="Search across all database sources">
                <div className="tw-pb-4">
                  <GenericSearch
                    options={DatabaseSearchOptions}
                    redirectUrl="/search-all-databases"
                  />
                </div>
              </div>
              <div label="Live Search in the deep and dark web">
                <div className="tw-pb-4">
                  <LiveSearchComponent
                    darknet={true}
                    onSearch={handleLiveSearch}
                    defaultOption={DarknetSearchOptions[0]}
                  />
                </div>
              </div>
              <div label="Manual Expert Search">
                <CustomComponent
                  // search={setSearch}
                  manualExpert={true}
                  setOpenAdvanceFilter={setOpenAdvanceFilter}
                  // handleOptionLabelChange={handleOptionLabelChange}
                  handleSearchTerm={handleSearchTermTwo}
                />
              </div>
            </CustomTabs>
          </div>

          <div className="tw-py-4">
            <BookMark />
          </div>
          <div className="tw-bg-white tw-p-4 tw-rounded ">
            <Heading size="h2" text="Latest Alerts" />
            <p className=" tw-flex tw-justify-start tw-text-base tw-font-normal ">
              Change your Alert Settings here.<b>Alert Settings</b>{' '}
            </p>
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-12 tw-gap-3 tw-my-4">
              <div className="tw-col-span-1 md:tw-col-span-8 tw-border tw-rounded-lg">
                <Alerts />
              </div>
              <div className="tw-col-span-1 md:tw-col-span-4 tw-rounded-lg tw-border">
                <Leak />
              </div>
            </div>
          </div>

          <div className="tw-py-4">
            <Risk />
          </div>
          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-3 tw-my-4">
            <div className="md:tw-col-span-2">
              <Infrastructure />
            </div>
            <div className="md:tw-col-span-2">
              <Credientials />
            </div>
          </div>
          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-3 tw-my-4">
            <div className="md:tw-col-span-2">
              <Risky />
            </div>
            <div className="md:tw-col-span-2">
              <TopRisky />
            </div>
          </div>
          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-3 tw-my-4">
            <div className="md:tw-col-span-2">
              <PasswordStrengthTable />
            </div>
            <div className="md:tw-col-span-2">
              <RiskyPassword />
            </div>
          </div>
          <div className="tw-bg-white tw-p-4 tw-rounded">
            <p className=" tw-flex tw-justify-start tw-text-lg tw-font-semibold">
              Download Recent Darknet Reports
            </p>
            <p className=" tw-flex tw-justify-start tw-text-base tw-font-normal ">
              Create a darknet report within minutes by providing us domain and
              date range
            </p>
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-5 tw-my-4">
              <div className="md:tw-col-span-2 graph-card">
                <CreateReport />
              </div>
              <div className="md:tw-col-span-2 graph-card">
                <Report />
              </div>
            </div>
          </div>
          <div className="tw-bg-white tw-p-4 tw-border tw-border-gray-200 tw-my-4 tw-rounded-lg">
            <p className=" tw-flex tw-justify-start tw-text-lg tw-font-semibold ">
              Quick Links
            </p>
            <p className=" tw-flex tw-justify-start tw-text-base tw-font-normal ">
              Quick links can be accessed here{' '}
            </p>
            <div className="tw-grid md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-5 tw-my-4">
              <div className="tw-border tw-rounded-xl">
                <WebSheet />
              </div>
              <div className="tw-border tw-rounded-xl">
                <CyberSheet
                  setIsInfrastructureView={setIsInfrastructureView}
                  setIsDomainSpoofingView={setIsDomainSpoofingView}
                />
              </div>
            </div>
          </div>

          <div className="tw-bg-white  tw-rounded ">
            <CyberThreat />
          </div>
        </div>
      )}
    </>
  );
}

export default AdminDashboard;
