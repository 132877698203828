import React from "react";

const EmailSetting = ({ props }) => {
  return (
    <div>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.82936 16.1419L7.3164 17.2373C7.46118 17.5634 7.69747 17.8404 7.99659 18.0349C8.29571 18.2293 8.64483 18.3327 9.00159 18.3327C9.35835 18.3327 9.70746 18.2293 10.0066 18.0349C10.3057 17.8404 10.542 17.5634 10.6868 17.2373L11.1738 16.1419C11.3472 15.7533 11.6388 15.4292 12.0071 15.216C12.3778 15.0022 12.8066 14.9111 13.2321 14.9558L14.4238 15.0827C14.7785 15.1202 15.1365 15.054 15.4544 14.8921C15.7722 14.7302 16.0363 14.4796 16.2145 14.1706C16.393 13.8619 16.4781 13.5079 16.4593 13.1518C16.4406 12.7956 16.3189 12.4525 16.109 12.1642L15.4034 11.1947C15.1522 10.847 15.018 10.4284 15.0201 9.99935C15.02 9.57151 15.1555 9.15464 15.4071 8.80861L16.1127 7.83916C16.3226 7.55081 16.4443 7.20774 16.463 6.85158C16.4818 6.49541 16.3967 6.14147 16.2183 5.83268C16.04 5.52371 15.7759 5.27309 15.4581 5.11121C15.1402 4.94932 14.7822 4.88312 14.4275 4.92065L13.2358 5.0475C12.8103 5.09219 12.3815 5.00112 12.0108 4.78731C11.6418 4.57289 11.3501 4.24715 11.1775 3.85676L10.6868 2.76139C10.542 2.43532 10.3057 2.15828 10.0066 1.96385C9.70746 1.76942 9.35835 1.66596 9.00159 1.66602C8.64483 1.66596 8.29571 1.76942 7.99659 1.96385C7.69747 2.15828 7.46118 2.43532 7.3164 2.76139L6.82936 3.85676C6.6568 4.24715 6.36509 4.57289 5.99603 4.78731C5.62538 5.00112 5.19659 5.09219 4.77103 5.0475L3.57566 4.92065C3.22094 4.88312 2.86294 4.94932 2.54509 5.11121C2.22724 5.27309 1.96317 5.52371 1.78492 5.83268C1.60644 6.14147 1.52141 6.49541 1.54014 6.85158C1.55888 7.20774 1.68058 7.55081 1.89048 7.83916L2.59603 8.80861C2.84765 9.15464 2.98315 9.57151 2.98307 9.99935C2.98315 10.4272 2.84765 10.8441 2.59603 11.1901L1.89048 12.1595C1.68058 12.4479 1.55888 12.791 1.54014 13.1471C1.52141 13.5033 1.60644 13.8572 1.78492 14.166C1.96335 14.4748 2.22744 14.7253 2.54525 14.8872C2.86306 15.049 3.22096 15.1153 3.57566 15.0781L4.76733 14.9512C5.19288 14.9065 5.62167 14.9976 5.99233 15.2114C6.36277 15.4252 6.65583 15.751 6.82936 16.1419Z"
          stroke="#44464A"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.0001 12.4993C10.3808 12.4993 11.5001 11.3801 11.5001 9.99935C11.5001 8.61864 10.3808 7.49935 9.0001 7.49935C7.61939 7.49935 6.5001 8.61864 6.5001 9.99935C6.5001 11.3801 7.61939 12.4993 9.0001 12.4993Z"
          stroke="#44464A"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default EmailSetting;
