import _ from 'lodash';
import moment from 'moment';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';

export const search = createAsyncThunk(
  'search/manual/creditCard/search',
  async ({ query, searchCondition }, { dispatch, rejectWithValue }) => {
    let resultList = [];

    try {
      // control.leak.center
      let page = 0,
        totalPages = 1;
      while (page < totalPages) {
        let clResponse = await api.get('/service/cc_leaks_search/', {
          params: {
            page: page++,
            size: 100,
            query: query,
          },
        });

        totalPages = clResponse.data.totalPages;
        resultList = resultList.concat(
          clResponse.data.content.map((item) => ({
            id: item.id,
            createdAt: item.createdAt
              ? moment(item.createdAt).format('YYYY/MM/DD')
              : '',
            number: item.number,
            expire: item.expireDate
              ? moment(item.expireDate).format('MM/YY')
              : '',
            cvv: item.cvv,
            owner: item.owner,
            bank: item.bank,
            leakName: item.leakName,
            leakDate: item.leakDiscoverDate,
            price: 'Free',
          })),
        );
      }

      const [dwQuery, dwSearchTerm] = makeDeepwebQuery(searchCondition);
      /*
      // deepweb.leak.center
      await api.post('service/cc_search_request/', dwQuery);

      let taskId = null,
        currentTask;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const statusResponse = await api.get(`service/cc_search_results/`);

        if (!taskId) {
          currentTask = statusResponse.data.tasks.find(
            (task) => task.search_term === dwSearchTerm,
          );
          taskId = currentTask.id;
        } else {
          currentTask = statusResponse.data.tasks.find(
            (task) => task.id === taskId,
          );
        }

        if (currentTask.status === 'Completed') break;
      }

      const resultResponse = await api.post('service/download_results_file/', {
        output_format: 'json',
        served_link: currentTask.served_link,
      });

      resultList = resultList.concat(
        resultResponse.data.map((item) => ({
          id: item.id,
          createdAt: item.scrapped_date,
          number: item.bin,
          expire: item.expires,
          cvv: item.card_code,
          owner: item.firstname,
          bank: item.bank_fullname,
          leakName: item.source,
          leakDate: item.scrapped_date,
          price: item.offer_final_price_usd
            ? '$' + item.offer_final_price_usd
            : 'Free',
        })),
      );
      */

      return resultList;
    } catch (error) {
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

const makeDeepwebQuery = (searchCondition) => {
  let dwQuery, dwSearchTerm;

  const cardnum = searchCondition.find((cond) => _.isNil(cond.field.name));
  const match = cardnum.term.match(/^[^*X]*/);
  const binString = match ? match[0] : cardnum.term;
  dwQuery = { bins: binString };
  dwSearchTerm = `bins=['${binString}']`;

  const owner = searchCondition.find((cond) => cond.field.name == 'owner');
  if (owner) {
    const names = owner.term.toLowerCase().split(' ');
    dwQuery.first_name = names[0];
    dwSearchTerm += `,first_name=${names[0]}`;
    if (names.length > 1) {
      dwQuery.last_name = names[1];
      dwSearchTerm += `,last_name=${names[1]}`;
    }
  }

  const createdAt = searchCondition.find(
    (cond) => cond.field.name == 'createdAt',
  );
  if (createdAt) {
    const dates = createdAt.term.split(' TO ');
    if (dates[0] !== '*') {
      dwQuery.from_date = dates[0];
      dwSearchTerm += `,from_date=${dates[0]}`;
    }
    if (dates[1] !== '*') {
      dwQuery.to_date = dates[1];
      dwSearchTerm += `,to_date=${dates[1]}`;
    }
  }

  return [dwQuery, dwSearchTerm];
};

const creditCardSlice = createSlice({
  name: 'creditCard',
  initialState: {
    list: [],
    totalElements: 0,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload;
        state.totalElements = state.list.length;

        state.loading = false;
      })
      .addCase(search.rejected, (state, action) => {
        state.list = [];
        state.totalElements = 0;
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default creditCardSlice.reducer;
