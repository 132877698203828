import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';

export const search = createAsyncThunk(
  'search/crawlers/search',
  async (_, { rejectWithValue }) => {
    try {
      const resultResponse = await api.get(
        `service/get_active_forum_crawlers/`,
      );

      return resultResponse.data.crawlers.sort((a, b) =>
        a.name < b.name ? -1 : 1,
      );
    } catch (error) {
      return rejectWithValue(error.response.data || 'Fetch crawlers failed');
    }
  },
);

const crawlerSlice = createSlice({
  name: 'crawlers',
  initialState: {
    loading: false,
    error: null,
    crawlers: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.crawlers = action.payload;
        state.loading = false;
      })
      .addCase(search.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default crawlerSlice.reducer;
