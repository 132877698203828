import React, { useState } from "react";
import Heading from "../../common/components/heading/Heading.js";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import EmailRecipient from "./components/emailRecipient/EmailRecipient.js";
import EmailSender from "./components/emailSender/EmailSender.js";
import EmailSettings from "./components/emailSettings/EmailSettings.js";
import EmailTemplate from "./components/emailTemplate/EmailTemplate.js";
import TestEmail from "./components/testEmail/TestEmail.js";
import Email from "../../common/Icons/Email.js";
import Message from "../../common/Icons/Message.js";
import Template from "../../common/Icons/Template.js";
import Test from "../../common/Icons/Test.js";
import { EmailSetting } from "../../common/Icons/index.js";

export default function BasicAlertingSettings() {
  const [activeItem, setActiveItem] = useState("emailRecipient");

  // If not using these in the form, remove or use them accordingly
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  // Defining EmailNavBarData locally with icons
  const EmailNavBarData = [
    {
      label: "Email Recipient",
      link: "emailRecipient",
      icon: <Email />,
    },
    {
      label: "Email Sender",
      link: "emailSender",
      icon: <Message />,
    },
    {
      label: "Email Settings",
      link: "emailSettings",
      icon: <EmailSetting />,
    },
    {
      label: "Email Template",
      link: "emailTemplate",
      icon: <Template />,
    },
    {
      label: "Test Email",
      link: "testEmail",
      icon: <Test />,
    },
  ];

  // Function to render the appropriate tab content
  const renderTabContent = (tab) => {
    switch (tab) {
      case "emailRecipient":
        return <EmailRecipient />;
      case "emailSender":
        return <EmailSender />;
      case "emailSettings":
        return <EmailSettings />;
      case "emailTemplate":
        return <EmailTemplate />;
      case "testEmail":
        return <TestEmail />;
      default:
        return <div>Select a tab</div>;
    }
  };

  return (
    <>
      <div className="tw-mt-4 md:tw-px-6">
        <Heading size="h2" text="Basic Alerting Settings" />
      </div>

      <div className="tw-p-4 md:tw-p-6 tw-mt-4 tw-bg-white tw-rounded-lg">
        <div className="tw-rounded-lg ">
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-w-full md:tw-w-[82vw] tw-overflow-x-auto tw-bg-[#FFFFFF] tw-gap-3 tw-p-3 tw-rounded-lg">
            {EmailNavBarData?.map((item, index) => (
              <div
                key={index}
                onClick={() => setActiveItem(item.link)}
                className={
                  activeItem?.includes(item.link)
                    ? "tw-flex tw-p-4 tw-justify-between md:tw-w-[255px] md:tw-justify-center tw-items-center tw-rounded-lg tw-gap-2 hover:tw-cursor-pointer tw-font-semibold tw-h-[42px] tw-border-2 tw-border-[#0B0B0B]"
                    : "tw-flex tw-p-4 tw-justify-between md:tw-w-[255px] md:tw-justify-center tw-items-center tw-rounded-lg tw-gap-2 tw-bg-[#FFFFFF] hover:tw-cursor-pointer tw-h-[42px] tw-border-2 tw-border-[#DFE0E3]"
                }
              >
                <div className="tw-flex tw-items-center tw-gap-2">
                  <p className="tw-text-[16px] tw-w-max">{item.label}</p>
                  {item.icon}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="tw-bg-white tw-p-6 tw-rounded-lg">
          {renderTabContent(activeItem)}
        </div>
      </div>
    </>
  );
}
