import React, { useState } from 'react';
import Modal from '../../../../../common/components/Modal/Modal';
import ReadMore from '../../../../../common/components/ReadMore/ReadMore';
import BellYellow from '../../../../../common/Icons/BellYellow';
import CustomButton from '../../../../../common/components/Button/Button';
import Feedback from '../../../../../common/Icons/Feedback';
import Heading from '../../../../../common/components/heading/Heading';
import DbUpload from '../../../../../common/Icons/DbUpload';
import DropdownList from '../../../../../common/Icons/DropdownList';
import Table from '../../../../common/table/table.component';
import EyeIcon from '../../../../../common/Icons/EyeIcon';
import Delete from '../../../../../components/assets/svgs/Delete';
import DocFile from '../../../../../components/assets/svgs/DocFile';
import DownloadIcon from '../../../../assets/svgs/DownloadIcon';
import ArrowBack from '../../../../../common/Icons/ArrowBack';
import ExportButton from '../../../../../common/components/Export/ExportButton';
const HackerMarketDetail = ({
  setSearch,
  selectedOptionLabel,
  searchTerm,
  setIsDetailView,
}) => {
  console.log({ setSearch });
  const [isExpanded, setIsExpanded] = useState(false);
  // const [isDetailView, setIsDetailView] = useState(false);
  // const [isDetailViewModal, setIsDetailViewModal] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openSearchTips, setOpenSearchTips] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  const columns = [
    {
      Header: 'Forum',
      accessor: 'forum',
      render: (_, record) => {
        return <span>{record.forum}</span>;
      },
    },
    {
      Header: 'State',
      accessor: 'state',
      render: (_, record) => {
        return (
          <span
            className={
              record.state === 'Pending'
                ? 'tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]'
                : 'tw-whitespace-nowrap tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]'
            }
          >
            {record.state}
          </span>
        );
      },
    },
    {
      Header: 'Results',
      accessor: 'doc',
      render: (_, record) => {
        return (
          <span className="tw-font-semibold tw-text-[#000000]">
            <DocFile />
          </span>
        );
      },
    },
  ];
  const data = [
    {
      forum: 'Antichat',
      state: 'Pending',
    },
  ];

  const sideData = [
    {
      site: 'Example.com',
      status: 'Offline',
    },
    {
      site: 'Example.com',
      status: 'Online',
    },
    {
      site: 'Example.com',
      status: 'Offline',
    },
    {
      site: 'Example.com',
      status: 'Online',
    },
    {
      site: 'Example.com',
      status: 'Offline',
    },
    {
      site: 'Example.com',
      status: 'Online',
    },
    {
      site: 'Example.com',
      status: 'Offline',
    },
    {
      site: 'Example.com',
      status: 'Online',
    },
    {
      site: 'Example.com',
      status: 'Offline',
    },
  ];

  return (
    <>
      {selectedOptionLabel !== 'Search Everywhere' && (
        <div className="tw-flex tw-justify-between lg:tw-items-center tw-items-start lg:tw-flex-row tw-flex-col tw-bg-[#ffffff] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px]">
          <div className="tw-flex tw-items-center tw-gap-[9.45px]">
            <BellYellow />
            <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
              If you didn't find what you were looking for, try refining your
              search or click 'New search' to start again.
            </div>
          </div>

          <CustomButton
            text="New Search"
            onClick={() => setSearch(false)}
            className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
          />
        </div>
      )}

      {/*DETAIL VIEW TABLE */}
      <div className="tw-grid tw-grid-cols-4 tw-gap-3 tw-mt-6">
        <div
          className={
            'tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4  lg:tw-col-span-3 tw-col-span-4'
          }
        >
          <div className="tw-flex tw-items-center tw-justify-between">
            <div className="tw-flex tw-gap-2">
              <div
                onClick={() => setIsDetailView(false)}
                className="tw-cursor-pointer"
              >
                <ArrowBack />
              </div>
              <span className="tw-font-semibold">
                Hacker Forum Search Details
              </span>
            </div>

            <ExportButton />
          </div>
          <Table data={data} columns={columns} />
        </div>

        <div className="lg:tw-col-span-1 tw-col-span-4 tw-bg-white tw-rounded-lg tw-p-4 tw-overflow-auto">
          <Heading size="h3" text="Site Status" />

          <div>
            <div className="tw-bg-[#F4F7FC] tw-text-sm tw-w-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-font-medium tw-justify-between">
              <span>Site</span>
              <span className="tw-pr-[14px]">Status</span>
            </div>
            <div className="tw-overflow-y-auto tw-h-[80vh]">
              {sideData.map((data, index) => {
                return (
                  <div
                    className="tw-w-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-justify-between"
                    key={index}
                  >
                    <span className="tw-text-[#5B5D63] tw-text-sm">
                      {data.site}
                    </span>
                    <span
                      className={
                        data.status === 'Offline'
                          ? 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]'
                          : 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]'
                      }
                    >
                      {data.status}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HackerMarketDetail;
