import React from "react";
import Chart from "react-apexcharts";

const IndustriesChart = () => {
  const data = {
    series: [
      {
        data: [450, 300, 200, 350, 250, 150, 200, 400, 300, 450],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '90%', // Adjust the height of the bars for better visibility
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          "Banking",
          "Insurance",
          "Energy",
          "HealthCare",
          "Wapda",
          "Healthy",
          "Real Estate",
          "Work Load",
          "Parking",
          "Hospitals",
        ],
        labels: {
          show: false, // Hides the x-axis labels (data)
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "16px",  // Make y-axis labels larger
            fontWeight: "semibold",  // Make y-axis labels bold
          },
          align: 'left',
          offsetX: -10,  // Adjust y-axis label position closer to the bars
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: "horizontal",
          shadeIntensity: 1,
          gradientToColors: ['#ECC551'],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
          colorStops: [
            {
              offset: 0,
              color: '#F9EDC9',
              opacity: 1
            },
            {
              offset: 100,
              color: '#ECC551',
              opacity: 1
            }
          ]
        }
      },
    },
  };

  return (
    <div className="tw-bg-white tw-rounded-lg tw-px-2 tw-pt-4">
      <p className="tw-font-medium tw-text-lg tw-px-5">Targeted Industries</p>

      <Chart
        options={data.options}
        series={data.series}
        type="bar"
        height={420}
      />
    </div>
  );
};

export default IndustriesChart;
