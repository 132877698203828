import React from "react";

const ExpressionLessFace = ({ color }) => {
  return (
    <div>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5 18C12.6716 18 12 18.6716 12 19.5C12 20.3284 12.6716 21 13.5 21H19.5C20.3284 21 21 20.3284 21 19.5C21 18.6716 20.3284 18 19.5 18H13.5Z"
          fill="#212121"
        />
        <path
          d="M27 19.5C27 18.6716 27.6716 18 28.5 18H34.5C35.3284 18 36 18.6716 36 19.5C36 20.3284 35.3284 21 34.5 21H28.5C27.6716 21 27 20.3284 27 19.5Z"
          fill="#212121"
        />
        <path
          d="M15 30C15 29.1716 15.6716 28.5 16.5 28.5H31.5C32.3284 28.5 33 29.1716 33 30C33 30.8284 32.3284 31.5 31.5 31.5H16.5C15.6716 31.5 15 30.8284 15 30Z"
          fill="#212121"
        />
        <path
          d="M23.9983 1.5C16.6428 1.5 10.9695 3.98129 7.14513 8.13414C3.3394 12.2668 1.5 17.9067 1.5 23.9983C1.5 30.09 3.3394 35.7299 7.14513 39.8625C10.9695 44.0154 16.6428 46.4967 23.9983 46.4967C31.3538 46.4967 37.0272 44.0154 40.8515 39.8625C44.6573 35.7299 46.4967 30.09 46.4967 23.9983C46.4967 17.9067 44.6573 12.2668 40.8515 8.13414C37.0272 3.98129 31.3538 1.5 23.9983 1.5ZM4.5 23.9983C4.5 18.4929 6.15893 13.6337 9.35194 10.1664C12.5263 6.71935 17.3522 4.5 23.9983 4.5C30.6445 4.5 35.4703 6.71935 38.6447 10.1664C41.8377 13.6337 43.4967 18.4929 43.4967 23.9983C43.4967 29.5037 41.8377 34.363 38.6447 37.8303C35.4703 41.2773 30.6445 43.4967 23.9983 43.4967C17.3522 43.4967 12.5263 41.2773 9.35194 37.8303C6.15893 34.363 4.5 29.5037 4.5 23.9983Z"
          fill="#212121"
        />
      </svg>
    </div>
  );
};

export default ExpressionLessFace;
