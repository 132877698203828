import * as React from "react";

function AlertIcon(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3852_26211)">
        <path
          d="M10.5001 7.49999V10.8333M10.5001 14.1667H10.5085M9.34622 3.24309L2.49215 15.0819C2.11198 15.7386 1.92189 16.0669 1.94999 16.3364C1.97449 16.5714 2.09763 16.785 2.28876 16.924C2.5079 17.0833 2.88728 17.0833 3.64605 17.0833H17.3542C18.113 17.0833 18.4923 17.0833 18.7115 16.924C18.9026 16.785 19.0258 16.5714 19.0503 16.3364C19.0783 16.0669 18.8883 15.7386 18.5081 15.0819L11.654 3.24309C11.2752 2.58879 11.0858 2.26164 10.8387 2.15176C10.6232 2.05591 10.3771 2.05591 10.1615 2.15176C9.91443 2.26164 9.72503 2.58879 9.34622 3.24309Z"
          stroke={props ? props.color : "#ECC551"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3852_26211">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AlertIcon;
