import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Heading from 'common/components/heading/Heading';
import CustomButton from 'common/components/Button/Button';
import FunnelFilter from 'common/Icons/FunnelFilter';
import ExportButton from 'common/components/Export/ExportButton';
import GenericDropdown from 'common/components/GenericDropdown/GenericDropdown';

import Loading from 'common/components/Loading/Loading';
import Table from 'components/common/table/table.component';

const CreditCards = ({ selectedOptionLabel, searchTerm }) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  // Function to open modal
  const openFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  // Function to close modal
  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const FilterFormModal = ({ showModal, closeModal }) => {
    if (!showModal) return null; // If the modal is not open, return nothing

    return (
      <div className="tw-fixed tw-top-0 tw-right-0 tw-p-4 tw-mt-24 tw-mr-4 tw-bg-white tw-w-80 tw-shadow-lg tw-rounded-lg tw-z-50">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <h2 className="tw-text-lg tw-font-semibold">Filter</h2>
          <button onClick={closeModal} className="tw-text-gray-500">
            &times;
          </button>
        </div>

        {/* Form Starts Here */}
        <form>
          {/* Start Date */}
          <div className="tw-mb-4">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
              From Date
            </label>
            <input
              type="text"
              className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm"
              placeholder="mm/dd/yyyy"
            />
          </div>

          {/* End Date */}
          <div className="tw-mb-4">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
              End Date
            </label>
            <input
              type="text"
              className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm"
              placeholder="mm/dd/yyyy"
            />
          </div>

          {/* Enumeration Method */}
          <div className="tw-mb-4">
            <GenericDropdown
              htmlfor="Enumeration Method"
              labelText="Enumeration Method"
              inputName="country"
              options={[
                { value: '', label: 'Select an option' },
                {
                  value: 'Method 1',
                  label: 'Method 1',
                },
                {
                  value: 'Method 2',
                  label: 'Method 2',
                },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
            />
          </div>

          {/* Domain Condition */}
          <div className="tw-mb-4">
            <GenericDropdown
              htmlfor=" Domain has a"
              labelText="Domain has a"
              inputName="country"
              options={[
                { value: '', label: 'Select an option' },
                {
                  value: 'Has',
                  label: 'Has',
                },
                {
                  value: 'Does not have',
                  label: 'Does not have',
                },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
            />
          </div>

          {/* Categories */}
          <div className="tw-mb-4">
            <GenericDropdown
              htmlfor="Categories"
              labelText="Categories"
              inputName="country"
              options={[
                { value: '', label: 'Select an option' },
                {
                  value: 'Category 1',
                  label: 'Category 1',
                },
                {
                  value: 'Category 2',
                  label: 'Category 2',
                },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
            />
          </div>

          {/* Risk */}
          <div className="tw-mb-4">
            <GenericDropdown
              htmlfor="Risk"
              labelText="Risk"
              inputName="country"
              options={[
                { value: '', label: 'Select an option' },
                {
                  value: 'Low',
                  label: 'Low',
                },
                {
                  value: 'Medium',
                  label: 'Medium',
                },
                {
                  value: 'High',
                  label: 'High',
                },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
            />
          </div>

          {/* Buttons */}
          <div className="tw-flex tw-justify-around tw-mt-4">
            <button
              type="button"
              className="tw-bg-yellow-500 tw-text-white tw-px-6 tw-py-2 tw-rounded-md hover:tw-bg-yellow-600"
            >
              Apply Filter
            </button>
            <button
              type="button"
              className="tw-border tw-text-gray-700 tw-px-6 tw-py-2 tw-rounded-md hover:tw-bg-gray-400"
              onClick={closeModal}
            >
              Clear Filter
            </button>
          </div>
        </form>
      </div>
    );
  };

  const { list, totalElements } = useSelector(
    (state) => state.search.manual.creditCard,
  );

  const { crawlers, crawlerLoading } = useSelector(
    (state) => state.search.crawlers,
  );

  const size = 10;
  const defaultRenderer = (value) => (
    <span className=" tw-text-[#44464A]">{value ?? '—'}</span>
  );
  const columns = [
    {
      Header: 'Created',
      accessor: 'createdAt',
      render: defaultRenderer,
    },
    {
      Header: 'Number',
      accessor: 'number',
      render: (value) => {
        return <span className="tw-text-[#00B6FF]">{value ?? '—'}</span>;
      },
    },
    {
      Header: 'Expire',
      accessor: 'expire',
      render: defaultRenderer,
    },
    {
      Header: 'CVV',
      accessor: 'cvv',
      render: defaultRenderer,
    },
    {
      Header: 'Owner',
      accessor: 'owner',
      render: defaultRenderer,
    },
    {
      Header: 'Bank',
      accessor: 'bank',
      render: (value) => {
        return (
          <span className="tw-text-[#44464A] tw-uppercase">{value ?? '—'}</span>
        );
      },
    },
    {
      Header: 'Leak',
      accessor: 'leak',
      render: (_, record) => {
        return (
          <div>
            <p className="tw-text-[#00B6FF]">{record.leakName}</p>
            <p className="tw-text-[#44464A]">{record.leakDate}</p>
          </div>
        );
      },
    },
    {
      Header: 'Price',
      accessor: 'price',
      render: defaultRenderer,
    },
  ];

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);

  // Function to match the heights of child divs
  const matchHeights = () => {
    const section1 = section1Ref.current;
    const section2 = section2Ref.current;

    const height = section1.offsetHeight;
    section2.setAttribute('style', `height:${height}px !important`);
  };

  useEffect(() => {
    matchHeights();
    window.addEventListener('resize', matchHeights);

    return () => window.removeEventListener('resize', matchHeights);
  }, []);

  return (
    <div className="tw-grid tw-grid-rows-1 tw-grid-cols-5 tw-gap-4">
      <div className="tw-col-span-5 lg:tw-col-span-4 tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4" ref={section1Ref}>
        <span className="tw-font-bold tw-text-lg tw-text-[#263238]">
          {selectedOptionLabel} Search Results
        </span>
        <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search results</span>
            <span className="tw-text-sm tw-font-medium">{totalElements}</span>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-sm tw-text-[#344054]">Search term</span>
            <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
          </div>
        </div>
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
          <div></div>
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 md:tw-gap-4">
            <CustomButton
              bgColor="tw-bg-white"
              textColor="tw-text-primary"
              hoverBgColor="[#EFF0F2]"
              hoverTextColor="primary"
              icon={<FunnelFilter />}
              text="Filters"
              onClick={openFilterModal}
              className="!tw-invisible tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border !tw-border-solid tw-border-[#DFE0E3] tw-rounded-md tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
            />

            <ExportButton />
          </div>
        </div>

        <Table
          data={list}
          columns={columns}
          selectable={true}
          paginatorLabel="credit cards"
          rowsPerPage={size}
        />
      </div>
      <div className="tw-col-span-5 lg:tw-col-span-1 tw-bg-white tw-p-4 tw-rounded-lg tw-overflow-auto !tw-h-0" ref={section2Ref}>
        <Heading size="h3" text="Site Status" />

        <div className="tw-mt-4 tw-relative">
          <div className="tw-bg-[#F4F7FC] tw-text-sm tw-w-full tw-h-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-font-medium tw-justify-between">
            <span>Site</span>
            <span className="tw-pr-[14px]">Status</span>
          </div>
          <div className="tw-overflow-y-auto">
            {crawlers.map((crawler, index) => {
              return (
                <div
                  className="tw-w-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-justify-between"
                  key={index}
                >
                  <span className="tw-text-[#5B5D63] tw-text-sm">
                    {crawler.front_name}
                  </span>
                  <span
                    className={
                      crawler.has_error
                        ? 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]'
                        : 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]'
                    }
                  >
                    {crawler.has_error ? 'Offline' : 'Online'}
                  </span>
                </div>
              );
            })}
          </div>
          {crawlerLoading && (
            <Loading label="Checking Site status..." />
          )}
          {/* <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div> */}
        </div>
      </div>

      <FilterFormModal
        showModal={isFilterModalOpen}
        closeModal={closeFilterModal}
      />
    </div>
  );
};

export default CreditCards;
