import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import InfoYellow from 'common/Icons/InfoYellow';
import ExportButton from 'common/components/Export/ExportButton';
import Table from 'components/common/table/table.component';
import Loading from 'common/components/Loading/Loading';

import { truncateString } from 'utils/global';

const URLShorteners = ({ selectedOptionLabel, searchTerm }) => {
  const { query, list, totalElements, loading } = useSelector(
    (state) => state.search.database.urlShortener,
  );

  const size = 10;

  const defaultRenderer = (value) => (
    <span className=" tw-text-[#44464A]">{value}</span>
  );

  const columns = [
    {
      Header: 'Domain',
      accessor: 'domain',
      render: defaultRenderer,
    },
    {
      Header: 'Shortener',
      accessor: 'shortener',
      render: defaultRenderer,
    },
    {
      Header: 'Extension',
      accessor: 'extension',
      render: defaultRenderer,
    },
    {
      Header: 'URL',
      accessor: 'link',
      render: (value, _, rowIndex) => {
        const short = truncateString(value, 255);

        return (
          <>
            <span
              className="tw-text-[#44464A] tw-break-all"
              id={`row-${rowIndex}`}
            >
              {short}
            </span>
            {short.length < value.length && (
              <Tooltip
                anchorSelect={`#row-${rowIndex}`}
                place="top-end"
                border="1px solid #eee"
                opacity={1}
                style={{
                  backgroundColor: '#ECC551',
                  color: 'black',
                  borderRadius: 10,
                  padding: 20,
                  width: 450,
                  boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
                }}
              >
                <span className="tw-block tw-break-all">{value}</span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      Header: 'Date',
      accessor: 'discovered_date',
      render: (value) => {
        return (
          <span className="tw-text-[#44464A]">
            {value ? moment(value).format('YYYY-MM-DD') : 'N/A'}
          </span>
        );
      },
    },
  ];

  return (
    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
      {!loading ? (
        <>
          <span className="tw-font-bold tw-text-lg tw-text-[#263238]">
            Search Result for {selectedOptionLabel} Search
          </span>
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
            <div className="tw-flex tw-shrink-0 tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">
                Search results
              </span>
              <span className="tw-text-sm tw-font-medium">{totalElements}</span>
            </div>
            <div className="tw-flex tw-shrink-0 tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">Search term</span>
              <span className="tw-text-sm tw-font-medium">{query}</span>
            </div>
            {totalElements === 5000 && (
              <div className="tw-flex tw-grow tw-items-center tw-justify-center tw-gap-x-4">
                <div>
                  <InfoYellow />
                </div>
                <div className="tw-text-sm tw-font-normal tw-text-[#344054] tw-text-pretty">
                  Your search returned more than 5&apos;000 entries. We show
                  only the first 5&apos;000 entries.
                  <br /> Please adjust your search to get more accurate results.
                </div>
              </div>
            )}
          </div>
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-end tw-gap-1 md:tw-gap-4">
            <ExportButton />
          </div>
          <Table
            data={list}
            columns={columns}
            paginatorLabel="URL Shorteners"
            rowsPerPage={size}
          />
        </>
      ) : (
        <Loading label="The search is still running – Please wait a moment..." />
      )}
    </div>
  );
};

export default URLShorteners;
