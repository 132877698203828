import React, {useRef, useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';

import CustomTable from "../../common/table-no-paginator/table.component";
import Paginator from "../../common/table-no-paginator/paginator.component";
import Heading from "../../../common/components/heading/Heading";
import { getList as getAffectedSystemList } from 'store/slices/report/affectedSystem';

function Infrastructure() {
  const columns = [
    { Header: "Affected Systems from Stealer Logs", accessor: "sub_domain" },
    { Header: "Results", accessor: "leaks_number" },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAffectedSystemList({ }));
  }, [dispatch]);

  const { list, size, currentPage, totalElements, totalPages, loading } =
    useSelector((state) => state.report.affectedSystem);

  const setCurrentPage = (page) => {
    dispatch(
      getAffectedSystemList({
        page: page - 1, 
      }),
    );
  };

  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow tw-h-full">
      <Heading size="h2" text="Leaks from your own Infrastructure" />
      <CustomTable data={list} columns={columns} selectable={false} />
    </div>
  );
}

export default Infrastructure;
