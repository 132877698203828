import React, { useState } from "react";
import { Successful } from "../../../../common/Icons";
import Cross from "../../../../common/Icons/Cross";
import CrossIcon from "../../../../common/Icons/CrossIcon";

const LicenseInfo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="tw-w-full tw-flex tw-flex-col tw-gap-6">
        <div className="">
          <p className="tw-text-[18px] tw-font-bold">License Info</p>
        </div>
        <div className="tw-h-[40px] tw-bg-red-800 tw-flex tw-justify-center tw-items-center tw-font-medium tw-text-sm tw-text-[#FFFFFF]">
          <p>14 Days Left in Next Renewal</p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-4">
          <p className=" tw-font-medium ">License Overview</p>
          <div className=" tw-grid md:tw-grid-cols-2">
            <div>
              <p className="tw-text-gray-500">License Name</p>
              <p className="tw-font-medium">Babar Sandhu</p>
            </div>
            <div>
              <p className="tw-text-gray-500">Expire at</p>
              <p className="tw-font-medium">2024-05-16</p>
            </div>
          </div>
          <div className=" tw-grid md:tw-grid-cols-2">
            <div>
              <p className="tw-text-gray-500">Renew License</p>
              <p className="tw-font-medium">Request a renewal offer</p>
            </div>
            <div>
              <button
                className="tw-bg-primary tw-text-white tw-px-3 tw-py-2 tw-rounded-lg tw-font-normal lg:tw-h-[40px] md:tw-text-base tw-text-xs tw-w-full md:tw-w-max"
                onClick={handleModalOpen}
              >
                Click here to request renewal offer
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75 tw-flex tw-items-center tw-justify-center tw-p-4">
          <div className="tw-bg-white tw-rounded-lg tw-w-[90%] md:tw-w-[500px] tw-p-4 tw-relative">
            <button
              className="tw-absolute tw-top-2 tw-right-2 tw-text-gray-600 tw-mr-2 tw-mt-2"
              onClick={handleModalClose}
            >
              <Cross />
            </button>
            <div className="tw-flex tw-flex-col tw-items-start">
              <div className="tw-flex tw-items-center tw-mb-4">
                <Successful />
              </div>
              <h2 className="tw-text-xl tw-font-bold">
                Subscription Renewed Successfully!
              </h2>
              <p className="tw-mb-4 tw-text-base">
                Your subscription has been renewed successfully. Thank you for
                continuing to be a valued member of our service!
              </p>
              <button
                className="tw-bg-black tw-text-white tw-px-4 tw-py-2 tw-rounded-lg"
                onClick={handleModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LicenseInfo;
