import React from "react";

const PasswordIcon = () => {
  return (
    <div>
      <svg
        width="15"
        height="13"
        viewBox="0 0 15 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1663 5.33337V3.46671C14.1663 2.71997 14.1663 2.3466 14.021 2.06139C13.8932 1.8105 13.6892 1.60653 13.4383 1.4787C13.1531 1.33337 12.7797 1.33337 12.033 1.33337H2.96634C2.2196 1.33337 1.84624 1.33337 1.56102 1.4787C1.31014 1.60653 1.10616 1.8105 0.978332 2.06139C0.833008 2.3466 0.833008 2.71997 0.833008 3.46671V5.86671C0.833008 6.61344 0.833008 6.98681 0.978332 7.27203C1.10616 7.52291 1.31014 7.72689 1.56102 7.85472C1.84624 8.00004 2.2196 8.00004 2.96634 8.00004H6.83301M7.49967 4.66671H7.50301M10.833 4.66671H10.8363M4.16634 4.66671H4.16967M12.333 9.33337V8.16671C12.333 7.52238 11.8107 7.00004 11.1663 7.00004C10.522 7.00004 9.99967 7.52238 9.99967 8.16671V9.33337M7.66634 4.66671C7.66634 4.75875 7.59172 4.83337 7.49967 4.83337C7.40763 4.83337 7.33301 4.75875 7.33301 4.66671C7.33301 4.57466 7.40763 4.50004 7.49967 4.50004C7.59172 4.50004 7.66634 4.57466 7.66634 4.66671ZM10.9997 4.66671C10.9997 4.75875 10.9251 4.83337 10.833 4.83337C10.741 4.83337 10.6663 4.75875 10.6663 4.66671C10.6663 4.57466 10.741 4.50004 10.833 4.50004C10.9251 4.50004 10.9997 4.57466 10.9997 4.66671ZM4.33301 4.66671C4.33301 4.75875 4.25839 4.83337 4.16634 4.83337C4.07429 4.83337 3.99967 4.75875 3.99967 4.66671C3.99967 4.57466 4.07429 4.50004 4.16634 4.50004C4.25839 4.50004 4.33301 4.57466 4.33301 4.66671ZM9.89968 12H12.433C12.8064 12 12.9931 12 13.1357 11.9274C13.2611 11.8635 13.3631 11.7615 13.427 11.636C13.4997 11.4934 13.4997 11.3067 13.4997 10.9334V10.4C13.4997 10.0267 13.4997 9.83999 13.427 9.69738C13.3631 9.57194 13.2611 9.46995 13.1357 9.40604C12.9931 9.33337 12.8064 9.33337 12.433 9.33337H9.89968C9.52631 9.33337 9.33962 9.33337 9.19701 9.40604C9.07157 9.46995 8.96959 9.57194 8.90567 9.69738C8.83301 9.83999 8.83301 10.0267 8.83301 10.4V10.9334C8.83301 11.3067 8.83301 11.4934 8.90567 11.636C8.96959 11.7615 9.07157 11.8635 9.19701 11.9274C9.33962 12 9.52631 12 9.89968 12Z"
          stroke="#71747C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default PasswordIcon;
