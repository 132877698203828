import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import RansomNewsTable from "./ransoms.component";
import CyberNewsTable from "./cyber.component";
function CyberThreat() {
  const navigate = useNavigate();
  return (
    <div className="tw-bg-[#FCF6E5] tw-p-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <h2 className="tw-text-lg tw-font-bold">
          Cyber Threat Intelligence Feed
        </h2>
        <a
          href="#"
          className="tw-text-sm tw-text-gray-500"
          onClick={() => navigate("/ransomware-feed")}
        >
          Show All
        </a>
      </div>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-3 tw-my-4">
        <div className="md:tw-col-span-2">
          <RansomNewsTable />
        </div>
        <div className="md:tw-col-span-2">
          <CyberNewsTable />
        </div>
      </div>
    </div>
  );
}

export default CyberThreat;
