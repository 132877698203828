import React, { useState } from 'react';
import Modal from '../../../../../common/components/Modal/Modal';
import ReadMore from '../../../../../common/components/ReadMore/ReadMore';
import BellYellow from '../../../../../common/Icons/BellYellow';
import CustomButton from '../../../../../common/components/Button/Button';
import Feedback from '../../../../../common/Icons/Feedback';
import Heading from '../../../../../common/components/heading/Heading';
import DbUpload from '../../../../../common/Icons/DbUpload';
import DropdownList from '../../../../../common/Icons/DropdownList';
import Table from '../../../../common/table/table.component';
import EyeIcon from '../../../../../common/Icons/EyeIcon';
import Delete from '../../../../../components/assets/svgs/Delete';
import HackerMarketDetail from './HackerMarketDetail';
const HackerMarket = ({ setSearch, selectedOptionLabel, searchTerm }) => {
  console.log({ setSearch });
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDetailView, setIsDetailView] = useState(false);
  const [isDetailViewModal, setIsDetailViewModal] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openSearchTips, setOpenSearchTips] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  // Hacker Marketplaces
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      render: (_, record) => {
        return (
          <div className="tw-flex tw-items-center tw-gap-[10px]">
            <span>{record.id}</span>
          </div>
        );
      },
    },
    {
      Header: 'Search keyword',
      accessor: 'searchKeyword',
    },
    {
      Header: 'Forum',
      accessor: 'forum',
    },
    {
      Header: 'State',
      accessor: 'state',
      render: (_, record) => {
        return (
          <span
            className={
              record.state === 'Pending'
                ? 'tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]'
                : 'tw-whitespace-nowrap tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]'
            }
          >
            {record.state}
          </span>
        );
      },
    },
    {
      Header: 'Actions',
      accessor: 'action',
      render: (_, item) => {
        return (
          <div className="tw-flex tw-gap-2">
            <button onClick={() => setIsDetailView(true)}>
              <EyeIcon color="#71747C" />
            </button>
            <button>
              <Delete color="#71747C" />
            </button>
          </div>
        );
      },
    },
  ];

  const data = [
    {
      id: 'QEEsdf1234km5462asdwe',
      searchKeyword: 'bank',
      forum: 'Antichat',
      state: 'Pending',
      action: 'Link',
    },
    {
      id: 'Asdf1234km5462rxcsderf',
      searchKeyword: 'bank',
      forum: 'Antichat',
      state: 'Completed',
      action: 'Link',
    },
    {
      id: 'QEEsdf1234km5462asdwe',
      searchKeyword: 'bank',
      forum: 'Antichat',
      state: 'Pending',
      action: 'Link',
    },
    {
      id: 'Asdf1234km5462rxcsderf',
      searchKeyword: 'bank',
      forum: 'Antichat',
      state: 'Completed',
      action: 'Link',
    },
    {
      id: 'QEEsdf1234km5462asdwe',
      searchKeyword: 'bank',
      forum: 'Antichat',
      state: 'Pending',
      action: 'Link',
    },
    {
      id: 'Asdf1234km5462rxcsderf',
      searchKeyword: 'bank',
      forum: 'Antichat',
      state: 'Completed',
      action: 'Link',
    },
  ];

  const sideData = [
    {
      site: 'Example.com',
      status: 'Offline',
    },
    {
      site: 'Example.com',
      status: 'Online',
    },
    {
      site: 'Example.com',
      status: 'Offline',
    },
    {
      site: 'Example.com',
      status: 'Online',
    },
    {
      site: 'Example.com',
      status: 'Offline',
    },
    {
      site: 'Example.com',
      status: 'Online',
    },
    {
      site: 'Example.com',
      status: 'Offline',
    },
    {
      site: 'Example.com',
      status: 'Online',
    },
    {
      site: 'Example.com',
      status: 'Offline',
    },
  ];

  return (
    <>
      {!isDetailView ? (
        <>
          {' '}
          {selectedOptionLabel !== 'Search Everywhere' && (
            <div className="tw-flex tw-justify-between lg:tw-items-center tw-items-start lg:tw-flex-row tw-flex-col tw-bg-[#ffffff] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px]">
              <div className="tw-flex tw-items-center tw-gap-[9.45px]">
                <BellYellow />
                <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
                  If you didn't find what you were looking for, try refining
                  your search or click 'New search' to start again.
                </div>
              </div>

              <CustomButton
                text="New Search"
                onClick={() => setSearch(false)}
                className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
              />
            </div>
          )}
          {/* NORMAL Table */}
          <div className="tw-grid tw-grid-cols-4 tw-gap-3 tw-mt-6">
            <div
              className={
                'tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4 lg:tw-col-span-3 tw-col-span-4'
              }
            >
              <Heading
                size="h2"
                text={`${selectedOptionLabel} Search History`}
              />

              <div>
                <div className="tw-bg-[#F4F7FC] tw-flex tw-justify-between tw-w-full tw-py-3 tw-px-3">
                  <span className="tw-font-medium tw-text-[#0B0B0B] tw-text-sm">
                    Status
                  </span>
                  <span className="tw-font-medium tw-text-[#0B0B0B] tw-text-sm">
                    Search Keyword
                  </span>
                  <span className="tw-font-medium tw-text-[#0B0B0B] tw-text-sm">
                    Action
                  </span>
                </div>
                <div className="tw-flex tw-justify-between tw-w-full tw-py-3 tw-px-3 tw-border-b tw-border-[#F2F4F7] tw-text-xs">
                  <span
                    className={
                      'Pending' === 'Pending'
                        ? 'tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]'
                        : 'tw-whitespace-nowrap tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]'
                    }
                  >
                    Pending
                  </span>
                  <span className="tw-font-medium tw-text-[#44464A]">
                    {searchTerm ? searchTerm : 'NO KEYWORD'}
                  </span>
                  <span
                    className={
                      'Stop' === 'Stop'
                        ? 'tw-whitespace-nowrap tw-text-[#E6AB00]'
                        : 'tw-whitespace-nowrap tw-text-[#B93815]'
                    }
                  >
                    Stop
                  </span>
                </div>
              </div>

              <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
                <div>
                  <p>Showing paste: 1 — 5 of 349</p>
                </div>
                <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4">
                  <CustomButton
                    icon={<DbUpload />}
                    text="Upload"
                    onClick={() => setSearch(false)}
                    className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border tw-border-[#DFE0E3] tw-rounded-md tw-bg-[#ffffff] tw-text-[#0B0B0B] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
                  />

                  <CustomButton
                    icon={<DropdownList />}
                    text="Creation time"
                    onClick={() => setSearch(false)}
                    className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border tw-border-[#DFE0E3] tw-rounded-md tw-bg-[#ffffff] tw-text-[#0B0B0B] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
                  />
                </div>
              </div>

              <Table data={data} columns={columns} selectable={true} />
            </div>

            <div className="lg:tw-col-span-1 tw-col-span-4 tw-bg-white tw-rounded-lg tw-p-4 tw-overflow-auto">
              <Heading size="h3" text="Site Status" />

              <div>
                <div className="tw-bg-[#F4F7FC] tw-text-sm tw-w-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-font-medium tw-justify-between">
                  <span>Site</span>
                  <span className="tw-pr-[14px]">Status</span>
                </div>
                <div className="tw-overflow-y-auto tw-h-[80vh]">
                  {sideData.map((data, index) => {
                    return (
                      <div
                        className="tw-w-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-justify-between"
                        key={index}
                      >
                        <span className="tw-text-[#5B5D63] tw-text-sm">
                          {data.site}
                        </span>
                        <span
                          className={
                            data.status === 'Offline'
                              ? 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]'
                              : 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]'
                          }
                        >
                          {data.status}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <HackerMarketDetail
          setSearch={setSearch}
          selectedOptionLabel={selectedOptionLabel}
          searchTerm={searchTerm}
          setIsDetailView={setIsDetailView}
        />
      )}
    </>
  );
};

export default HackerMarket;
