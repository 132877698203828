import React from "react";
import ReactApexChart from "react-apexcharts";

function CustomBubbleChart(chartData) {
  const chartOptions = {
    chart: {
      height: 350,
      type: "bubble",
      toolbar: {
        show: false,
      },
      background: "#FFFFFF",
    },
    legend: {
      show: false,
      // markers: {
      //   width: 12,
      //   height: 12,
      //   radius: 12,
      // },
      // itemMargin: {
      //   horizontal: 15,
      // },
      // labels: {
      //   colors: "#000",
      // },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 0.5,
    },
    xaxis: {
      tickAmount: 8,
      type: "category",
      categories: [
        "2017-1-1",
        "2018-1-1",
        "2019-1-1",
        "2020-1-1",
        "2021-1-1",
        "2022-1-1",
        "2023-1-1",
        "2024-1-1",
      ],
    },
    yaxis: {
      max: 100,
      tickAmount: 5,
    },
    colors: ["#FCE0B6", "#925C0C", "#BD9E41"],
    grid: {
      show: true,
      borderColor: "#f1f1f1",
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const data = series[seriesIndex][dataPointIndex];
        return `
          <div class="custom-tooltip tw-p-2 tw-bg-[#F9F9F9] tw-rounded-lg">
            <div class="tw-text-gray-600 tw-font-medium tw-px-2">Stealer log exposed</div>
            <div class="tw-text-gray-600 tw-font-medium tw-px-2">Stealer log exposed</div>
            <div class="tw-text-gray-600 tw-font-medium tw-px-2">Stealer log exposed</div>
            <div class="tw-text-gray-600 tw-font-medium tw-px-2">Stealer log exposed</div>
            <div class="tw-text-gray-600 tw-font-medium tw-px-2">Stealer log exposed</div>
            <div class="tw-text-gray-600 tw-font-medium tw-px-2">Stealer log exposed</div>
            <div class="tw-text-gray-600 tw-font-medium tw-px-2">Credientials</div>
          </div>
        `;
      },
    },
    plotOptions: {
      bubble: {
        dataLabels: {
          enabled: false,
        },
      },
    },
  };

  const series = [
    {
      name: "Mean",
      data: [
        { x: "2017-1-1", y: 40, z: 900 },
        { x: "2018-1-1", y: 80, z: 980 },
        { x: "2019-1-1", y: 90, z: 865 },
        { x: "2021-1-1", y: 90, z: 450 },
      ],
    },
    {
      name: "Median",
      data: [
        { x: "2017-1-1", y: 15, z: 910 },
        { x: "2018-1-1", y: 0, z: 780 },
        { x: "2019-1-1", y: 55, z: 965 },
        { x: "2021-1-1", y: 35, z: 450 },
      ],
    },
    {
      name: "Your Company",
      data: [
        { x: "2017-1-1", y: 100, z: 930 },
        { x: "2018-1-1", y: 45, z: 990 },
        { x: "2021-1-1", y: 25, z: 960 },
      ],
    },
  ];

  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg">
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="bubble"
        height={320}
      />
    </div>
  );
}

export default CustomBubbleChart;
