import React from "react";
import RansomwareComponents from "../../components/ransomware-feed-component/ransomware.component";
import Heading from "../../common/components/heading/Heading";
import CustomTabs from "../tabs";
import DomainSpoofing from "../../components/domain-spoofing-detection/domainSpofing.component";
import ExposeAttackComponent from "../../components/expose-attack-surface/expose-attack.component";
import { useLocation } from "react-router-dom";

export default function RansomwareFeedPage() {
  const location = useLocation();
  const {spoofDomain, infraDomain, activeTab} = location.state || { spoofDomain: '', infraDomain: '', activeTab: 0 };
  return (
    <>
      <Heading size="h3" text="Cyber Threat Detection" />

      <div className="tabs-sec mt-2">
        <CustomTabs  initialActiveIndex={activeTab}>
          <div label="Ransomware Feed">
            <RansomwareComponents />
          </div>
          <div label="Domain Spoofing Detection">
            <DomainSpoofing searchDomainTerm = {spoofDomain}/>
          </div>
          <div label="Exposed Attack Surface Detection">
            <ExposeAttackComponent searchInfraTerm = {infraDomain}/>
          </div>
        </CustomTabs>
      </div>
    </>
  );
}
