import * as React from "react";

function ArrowUpSide(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83301 14.1667L14.1663 5.83337M14.1663 5.83337H5.83301M14.1663 5.83337V14.1667"
        stroke="#0B0B0B"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowUpSide;
