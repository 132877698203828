import { enqueueSnackbar } from 'notistack';

import { snackbarConfig } from '../config/snackbar';

export const useSnackbar = (title, variant = 'success') => {
  if (typeof title === 'object') {
    title = Object.values(title)[0];
  }

  enqueueSnackbar(title, {
    variant,
    ...snackbarConfig,
  });
};

export const useErrorbar = (title) => {
  useSnackbar(title, 'error');
};
