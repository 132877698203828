import React, { useState, useEffect } from "react";
import InputLabel from "../InputLabel/InputLabel";
import _ from "lodash";
import ArrowDown from "../../Icons/ArrowDown"; // Your custom dropdown icon

const GenericDropdown = ({
  htmlfor = "",
  labelText = "",
  inputName = "",
  options = [],
  errors = {},
  value,
  onChange,
  className,
  defaultValue,
  bgColor,
  disabled = false,
  onDropDown,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (onDropDown) onDropDown(isDropdownOpen);
  }, [isDropdownOpen])

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectChange = (option) => {
    setIsDropdownOpen(false);
    onChange(option);
  };



  return (
    <div className={`tw-relative tw-space-y-1 ${className}`}>
      <InputLabel htmlFor={htmlfor} text={labelText} />
      <div className="tw-relative tw-w-full">
        {/* Custom dropdown button */}
        <div
          onClick={handleDropdownToggle}
          className="tw-block tw-w-full tw-px-4 tw-py-2 tw-text-primary tw-bg-white tw-border tw-border-blue-500 tw-rounded-lg tw-flex tw-justify-between tw-items-center cursor-pointer"
        >
          {value
            ? options.find((opt) => opt.value === value)?.label || "Select"
            : "Select"}
          <ArrowDown />
        </div>

        {/* Dropdown menu */}
        {isDropdownOpen && (
          <div className="tw-absolute tw-mt-2 tw-bg-white tw-w-[575px] tw-shadow-lg tw-rounded-lg  tw-z-10 tw-max-h-60 tw-overflow-y-auto">
            <ul className="tw-list-none tw-py-2">
              {options.length > 0 ? (
                options.map((option) => (
                  <li
                    key={option.value}
                    onClick={() => handleSelectChange(option)}
                    className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-primary hover:tw-bg-[#ECC551] tw-cursor-pointer"
                  >
                    {option.label}
                  </li>
                ))
              ) : (
                <li className="tw-px-4 tw-py-2 tw-text-gray-500">
                  No options found
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      {_.get(errors, inputName) && (
        <p className="tw-text-red-500 tw-text-sm">
          {_.get(errors, inputName).message}
        </p>
      )}
    </div>
  );
};

export default GenericDropdown;
