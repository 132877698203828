import React from "react";

const Characters = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 10.8333L12.5 10.8333M5.83333 14.1667L9.39309 6.3352C9.5859 5.91102 9.6823 5.69893 9.81589 5.63313C9.93197 5.57596 10.068 5.57596 10.1841 5.63313C10.3177 5.69893 10.4141 5.91102 10.6069 6.3352L14.1667 14.1667M17.5 2.5V17.5M2.5 2.5L2.5 17.5"
          stroke="#44464A"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Characters;
