import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import DownloadFileIcon from 'common/Icons/DownloadFileIcon';
import ArrowBack from 'common/Icons/ArrowBack';
import Feedback from 'common/Icons/Feedback';
import CustomButton from 'common/components/Button/Button';
import FilterIcon from 'common/Icons/FilterIcon';
import { InfoIcon } from 'common/Icons';
import Loader from 'common/components/Loader/Loader';
import Loading from 'common/components/Loading/Loading';

import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';

import api from 'store/api';
import { delay, formatFileSize } from 'utils/global';

const LeakFileView = ({ leakId, setIsLeakFileView }) => {
  const size = 10;
  const [leakFiles, setLeakFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(null);

  const handleChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const fetchLeakFiles = async (leakId) => {
      try {
        setLoading(true);

        const response = await api.get(`service/leak_files/${leakId}`, {
          params: {
            page: currentPage - 1,
          },
        });

        setLeakFiles(response.data.content);
        setTotalElements(response.data.totalElements);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (!_.isNil(leakId)) {
      fetchLeakFiles(leakId);
    }
  }, [currentPage]);

  const defaultRenderer = (value) => (
    <span className=" tw-text-[#44464A]">{value ?? ''}</span>
  );

  const columns = [
    {
      Header: 'Created',
      accessor: 'createdAt',
      render: defaultRenderer,
    },
    {
      Header: 'Name',
      accessor: 'name',
      render: defaultRenderer,
    },
    {
      Header: 'Size',
      accessor: 'size',
      render: (value) => {
        return (
          <span className="tw-whitespace-nowrap tw-text-[#44464A]">
            {formatFileSize(value)}
          </span>
        );
      },
    },
    {
      Header: 'Download File',
      accessor: 'download',
      render: (_, record) => {
        const disabled = record.size >= 10 * 1024 * 1024;

        return (
          <button disabled={disabled} onClick={() => downloadFile(record)}>
            <div className="tw-flex tw-gap-2 tw-cursor-pointer">
              {downloading === record.id ? (
                <Loader className="tw-text-[#22C55E] !tw-w-5 !tw-h-5 tw-ms-2" />
              ) : (
                <DownloadFileIcon color={disabled ? '#979BA5' : '#22C55E'} />
              )}
              <span className="tw-text-[#979BA5]">Download</span>
            </div>
          </button>
        );
      },
    },
  ];

  const downloadFile = async (file) => {
    try {
      setDownloading(file.id);

      const requestResponse = await api.post(
        `service/request_to_download_leak_file/`,
        {
          leakId,
          digest: file.id,
        },
      );

      const downloadId = requestResponse.data.id;
      let statusOK = false;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const statusResponse = await api.get(`service/leaks_downloads/`);

        for (const request of statusResponse.data.content) {
          if (request.id === downloadId && request.status === 'FINISHED') {
            statusOK = true;
            break;
          }
        }

        if (statusOK) break;
        await delay(250);
      }

      const downloadResponse = await api.get(
        `service/download_leak_file/${downloadId}`,
      );

      const fileBlob = new Blob([downloadResponse.data]);
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(fileBlob);
      downloadLink.download = file.name;

      document.body.appendChild(downloadLink);
      downloadLink.click();

      URL.revokeObjectURL(downloadLink.href);
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.response);
    } finally {
      setDownloading(null);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <div className="tw-flex tw-gap-2">
        <div
          onClick={() => setIsLeakFileView(false)}
          className="tw-cursor-pointer"
        >
          <ArrowBack />
        </div>
        <span className="tw-font-semibold">Leaks Files</span>
      </div>

      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
        <div className="tw-w-full">
          <div className="tw-flex tw-items-center tw-gap-[9.45px]">
            <Feedback />
            <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-[#0B0B0B]">
              Download Restrictions
            </div>
          </div>
          <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
            Please note that you can only download files smaller than 10 MB. To
            Investigate larger files, we recommend searching using the leak ID.
          </div>
        </div>
      </div>

      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
        <div className="tw-relative">
          <Table data={leakFiles} columns={columns} selectable={true} />

          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between tw-items-center">
            <div className={loading ? 'tw-invisible' : 'tw-visible'}>
              <p className="tw-my-4">
                Showing leak files:{' '}
                {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
                {currentPage * size > totalElements
                  ? totalElements
                  : currentPage * size}{' '}
                of {totalElements}
              </p>
            </div>
            {totalPages > 1 && (
              <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handleChangeCurrentPage}
              />
            )}
          </div>

          {loading && (
            <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
          )}
        </div>
      </div>
    </div>
  );
};

const LeakDetails = ({ leakId, setIsLeakDetails }) => {
  const [leakDetail, setLeakDetail] = useState(null);

  useEffect(() => {
    const fetchDetail = async (leakId) => {
      try {
        const response = await api.get(`service/leak_detail/${leakId}`);
        setLeakDetail(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (!_.isNil(leakId)) {
      fetchDetail(leakId);
    }
  }, [leakId]);

  const [isLeakFileView, setIsLeakFileView] = useState(false);

  return (
    <>
      {isLeakFileView ? (
        <LeakFileView leakId={leakId} setIsLeakFileView={setIsLeakFileView} />
      ) : (
        <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-gap-2">
            <div
              onClick={() => setIsLeakDetails(false)}
              className="tw-cursor-pointer"
            >
              <ArrowBack />
            </div>
            <span className="tw-font-semibold">Leak Detail View</span>
          </div>

          {leakDetail ? (
            <div className="tw-border tw-border-[#DFE0E3] tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-3">
              <div className="tw-flex tw-justify-between tw-items-center tw-border-b tw-border-[#DFE0E3] tw-pb-4">
                <div className="tw-flex tw-gap-1 tw-font-medium tw-text-lg">
                  <span className="tw-text-[#35363A]">Leak name: </span>
                  <span>{leakDetail.name}</span>
                </div>
                <div
                  onClick={() => {
                    setIsLeakFileView(true);
                  }}
                >
                  <CustomButton
                    icon={<FilterIcon />}
                    text="View Raw Leak Files"
                    className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border tw-border-[#DFE0E3] tw-rounded-md tw-bg-[#ffffff] tw-text-[#0B0B0B] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
                  />
                </div>
              </div>

              <div>
                <div className="tw-flex tw-gap-1 tw-items-center">
                  <span className="tw-text-sm tw-text-[#44464A]">ID</span>
                  <div className="my-anchor-element23">
                    <InfoIcon />
                  </div>
                  <Tooltip
                    anchorSelect=".my-anchor-element23"
                    place="top-end"
                    border="1px solid #eee"
                    opacity={1}
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      borderRadius: 10,
                      padding: 20,
                      width: 450,
                      boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <div>
                      You can use the leak ID to find data within a specific
                      leak. For exame, the query
                      <br />
                      <br />
                      &#x201C;<strong>
                        test AND leakId:{leakDetail.id}
                      </strong>{' '}
                      &#x201D;
                      <br />
                      <br />
                      serches for the word &ldquo;test&rdquo; within the leak
                      with ID {leakDetail.id}. You can also see all the data
                      inside the leak by queryig only the leak ID, like
                      <br />
                      <br />
                      &#x201C;<strong>leakId:{leakDetail.id}</strong> &#x201D;
                    </div>
                  </Tooltip>
                </div>
                <span className="tw-text-sm tw-text-[#00B6FF]">
                  {leakDetail.id}
                </span>
              </div>

              <div className="tw-grid tw-grid-cols-4 tw-gap-4 tw-text-sm tw-font-semibold tw-text-[#44464A]">
                <div>
                  <p>Size</p>
                  <p>{formatFileSize(leakDetail.size)}</p>
                </div>
                <div>
                  <p>Files</p>
                  <p>{leakDetail.numberOfFiles}</p>
                </div>
                <div>
                  <p>Records</p>
                  <p>{leakDetail.numberOfRecords}</p>
                </div>
                <div>
                  <p>Accounts</p>
                  <p>{leakDetail.numberOfAccounts}</p>
                </div>
                <div>
                  <div className="tw-flex tw-gap-1">
                    <span>Publish Date</span>
                    <InfoIcon />
                  </div>
                  <p>{leakDetail.publishDate}</p>
                </div>
                <div>
                  <p>Discover Date</p>
                  <p>{leakDetail.discoverDate}</p>
                </div>
                <div>
                  <p>Creation Time</p>
                  <p>{leakDetail.createdAt}</p>
                </div>
              </div>

              <div>
                <p className="tw-text-sm tw-font-semibold tw-text-[#44464A] tw-mb-1">
                  Tags
                </p>
                <div className="tw-flex tw-flex-wrap tw-w-full tw-gap-1">
                  {leakDetail.tags.map((tag, index) => {
                    return (
                      <span
                        className="tw-text-xs tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815] "
                        key={index}
                      >
                        {tag}
                      </span>
                    );
                  })}
                </div>
              </div>

              <div>
                <p className="tw-text-sm tw-font-semibold tw-text-[#44464A] tw-mb-1">
                  Description
                </p>
                <div className="tw-text-sm tw-text-[#0B0B0B]">
                  {leakDetail.description}
                </div>
              </div>

              <div>
                <p className="tw-text-sm tw-font-semibold tw-text-[#44464A] tw-mb-1">
                  Affected websites in this leak
                </p>
                <div className="tw-flex tw-flex-wrap tw-gap-2">
                  {Object.entries(leakDetail.websites).length > 0
                    ? Object.entries(leakDetail.websites).map(
                        ([site, count], index) => (
                          <div
                            key={index}
                            className="tw-flex tw-items-center tw-gap-1 tw-border-r tw-border-[#DFE0E3] tw-pr-2"
                          >
                            <span className="tw-text-sm">{site}</span>
                            <span className="tw-bg-[#F9FAFB] tw-text-center tw-border tw-border-[#EAECF0] tw-py-[2px] tw-px-[8px] tw-rounded-full tw-text-xs tw-text-[#344054]">
                              {count}
                            </span>
                          </div>
                        ),
                      )
                    : '—'}
                </div>
              </div>

              <div>
                <p className="tw-text-sm tw-font-semibold tw-text-[#44464A] tw-mb-1">
                  Similarity: Defines, in percentage, how much of the exact same
                  leak data may appear in other leaks.
                </p>
                <span className="tw-text-sm">
                  {leakDetail.similarity.toFixed(2)}%
                </span>
              </div>

              <div>
                <p className="tw-text-sm tw-font-semibold tw-text-[#44464A] tw-mb-1">
                  Similar Leaks
                </p>
                <div className="tw-flex tw-flex-wrap tw-gap-2">
                  {leakDetail.similarLeaks.length > 0
                    ? leakDetail.similarLeaks.map((leak, index) => (
                        <div
                          key={index}
                          className="tw-flex tw-items-center tw-gap-1 tw-border-r tw-border-[#DFE0E3] tw-pr-2"
                        >
                          <span className="tw-text-sm  tw-text-[#00B6FF]">
                            {leak.name}
                          </span>
                          <span className="tw-bg-[#F9FAFB] tw-text-center tw-border tw-border-[#EAECF0] tw-py-[2px] tw-px-[8px] tw-rounded-full tw-text-xs tw-text-[#344054]">
                            {leak.similarity.toFixed(2)}%
                          </span>
                        </div>
                      ))
                    : '—'}
                </div>
              </div>
            </div>
          ) : (
            <Loading label="Loading Leak Details..." />
          )}
        </div>
      )}
    </>
  );
};

export default LeakDetails;
