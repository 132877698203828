import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import { Tooltip } from 'react-tooltip';

import CustomButton from 'common/components/Button/Button';
import InputGroup from 'common/components/InputGroup/InputGroup';
import { InfoIcon } from 'common/Icons';

import { useIsMount } from 'hooks/useMount';

import { FilterMode } from 'common/components/SearchComponent/data';

const Step3 = ({ searchType, onApply }) => {
  const isMount = useIsMount();

  const [inputValue1, setInputValue1] = useState('');
  const [inputValue2, setInputValue2] = useState('');

  const [errorMessage1, setErrorMessage1] = useState('');
  const [errorMessage2, setErrorMessage2] = useState('');

  const [optionalFilters, setOptionalFilters] = useState([]);

  useEffect(() => {
    if (isMount) {
      onApply && onApply(optionalFilters);
    }
  }, [optionalFilters]);

  const handleApply = () => {
    setOptionalFilters((prevFilters) => {
      let newFilters = prevFilters;

      if (!_.isEmpty(inputValue1)) {
        const andFilters = inputValue1.split(',').map((value) => {
          return {
            filterMode: FilterMode[0].operator,
            searchType,
            field: searchType.main,
            term: value.trim(),
          };
        });
        newFilters = [...newFilters, ...andFilters];
      }

      if (!_.isEmpty(inputValue2)) {
        const notFilters = inputValue2.split(',').map((value) => {
          return {
            filterMode: FilterMode[1].operator,
            searchType,
            field: searchType.main,
            term: value.trim(),
          };
        });
        newFilters = [...newFilters, ...notFilters];
      }

      return newFilters;
    });
  };

  const handleSkip = () => {
    onApply && onApply([]);
  };

  return (
    <div className="tw-gap-1 tw-px-4 tw-py-2 tw-rounded-xl tw-bg-[#ffffff]">
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 md:tw-gap-x-6 tw-items-center">
        <h3 className="tw-text-base tw-font-bold tw-text-nowrap">Step 3</h3>
        <div className="tw-text-sm tw-text-nowrap">Adjust Search Results</div>

        <div className="tw-relative tw-w-48 tw-flex-shrink-0">
          <div className="tw-w-full tw-h-10 tw-flex tw-items-center tw-justify-end tw-text-sm">
            {FilterMode[0].label}:
          </div>
        </div>

        <div className="tw-w-full tw-flex tw-items-center tw-relative">
          <InputGroup
            placeholder={searchType.main.placeholder2}
            value={inputValue1}
            onChange={(e) => setInputValue1(e.target.value)}
            className="tw-w-full tw-h-full !tw-p-3 tw-text-left tw-leading-[16.41px] tw-tracking-[0.02em]"
          />
          {errorMessage1 && (
            <div className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-red-500 tw-text-white tw-text-xs tw-rounded tw-px-2 tw-py-1 tw-shadow-md">
              {errorMessage1}
            </div>
          )}
        </div>

        {searchType.tip2 && (
          <div className="tw-ms-[0px]">
            <div id="tip-element">
              <InfoIcon />
            </div>
            <Tooltip
              anchorSelect="#tip-element"
              effect="solid"
              style={{
                maxWidth: 350,
                border: '1px solid black',
                backgroundColor: 'white',
                borderRadius: 10,
                padding: 20,
                boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
                color: 'black',
                zIndex: 99,
              }}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: searchType.tip2,
                }}
              ></p>
            </Tooltip>
          </div>
        )}

        <CustomButton text="Apply" className="!tw-w-60" onClick={handleApply} />
      </div>

      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 md:tw-gap-x-6 tw-items-center">
        <h3 className="tw-text-base tw-font-bold tw-text-nowrap tw-invisible">
          Step 3
        </h3>
        <div className="tw-text-sm tw-text-nowrap tw-invisible">
          Adjust Search Results
        </div>

        <div className="tw-relative tw-w-48 tw-flex-shrink-0">
          <div className="tw-w-full tw-h-10 tw-flex tw-items-center tw-justify-end tw-text-sm">
            {FilterMode[1].label}:
          </div>
        </div>

        <div className="tw-w-full tw-flex tw-items-center tw-relative">
          <InputGroup
            placeholder={searchType.main.placeholder2}
            value={inputValue2}
            onChange={(e) => setInputValue2(e.target.value)}
            className="tw-w-full tw-h-full !tw-p-3 tw-text-left tw-leading-[16.41px] tw-tracking-[0.02em]"
          />
          {errorMessage2 && (
            <div className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-red-500 tw-text-white tw-text-xs tw-rounded tw-px-2 tw-py-1 tw-shadow-md">
              {errorMessage2}
            </div>
          )}
        </div>

        {searchType.tip2 && (
          <div className="tw-ms-[0px] tw-invisible">
            <div id="tip-element">
              <InfoIcon />
            </div>
            <Tooltip
              anchorSelect="#tip-element"
              effect="solid"
              style={{
                border: '1px solid black',
                backgroundColor: 'white',
                borderRadius: 10,
                padding: 20,
                boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
                color: 'black',
                zIndex: '999',
              }}
            >
              <p>{searchType.tip2}</p>
            </Tooltip>
          </div>
        )}

        <CustomButton
          text="Skip this step"
          bgColor="tw-bg-secondary"
          textColor="tw-text-primary"
          hoverTextColor="white"
          className="!tw-w-60"
          onClick={handleSkip}
        />
      </div>
    </div>
  );
};

export default Step3;
