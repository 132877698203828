import _ from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const searchRequest = createAsyncThunk(
  'search/live/externalSource/searchRequest',
  async ({ query }, { getState, dispatch, rejectWithValue }) => {
    let progressValue = 0,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (!isError) {
        if (step === 0 && progressValue < 10) {
          progressValue++;
          dispatch(setProgress(progressValue));

          await delay(50);
          requestAnimationFrame(mockProgress);
        } else if (step === 1 && progressValue < 90) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(600);
          requestAnimationFrame(mockProgress);
        } else if (step === 2 && progressValue < 95) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(500);
          requestAnimationFrame(mockProgress);
        }
      }
    };

    try {
      requestAnimationFrame(mockProgress);
      const requestResponse = await api.post(
        'service/external_sources_search/',
        {
          query: query,
          // validate: query.trim().split(/\s+/).length == 1,
        },
      );

      progressValue = 10;
      step = 1;
      requestAnimationFrame(mockProgress);
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const statusResponse = await api.get(
          `service/external_sources_search_status/${requestResponse.data.id}`,
        );

        if (statusResponse.data.status === 'FINISHED') break;
        await delay(2500);
      }

      progressValue = 90;
      step = 2;
      requestAnimationFrame(mockProgress);

      const state = getState();

      const resultResponse = await api.get(
        `service/external_sources_search_results/${requestResponse.data.id}`,
        {
          params: {
            page: 0,
            size: state.search.live.externalSource.size,
            sort: state.search.live.externalSource.sort,
          },
        },
      );
      step = 3;

      return { searchId: requestResponse.data.id, data: resultResponse.data };
    } catch (error) {
      isError = true;
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

export const searchRequestSimple = createAsyncThunk(
  'search/live/externalSource/searchRequestSimple',
  async ({ query }, { getState, dispatch, rejectWithValue }) => {
    const state = getState();

    let progressValue = 0,
      step = 0,
      isDisplayed = false,
      isError = false;
    const mockProgress = async () => {
      if (!isError) {
        if (step === 0 && progressValue < 95) {
          progressValue++;
          dispatch(setProgress(progressValue));

          await delay(20);
          requestAnimationFrame(mockProgress);
        }
      }
    };

    try {
      requestAnimationFrame(mockProgress);
      const requestResponse = await api.post(
        'service/external_sources_search/',
        {
          query: query,
          // validate: query.trim().split(/\s+/).length == 1,
        },
      );
      const states = parseStates(requestResponse.data.states);
      dispatch(setStates({ count: requestResponse.data.resultCount, states }));

      step = 1;
      dispatch(setLoading(false));

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const statusResponse = await api.get(
          `service/external_sources_search_status/${requestResponse.data.id}`,
        );

        const states = parseStates(statusResponse.data.states);
        dispatch(setStates({ count: statusResponse.data.resultCount, states }));

        if (!isDisplayed) {
          if (
            statusResponse.data.resultCount >=
            state.search.live.externalSource.size
          ) {
            const resultResponse = await api.get(
              `service/external_sources_search_results/${requestResponse.data.id}`,
              {
                params: {
                  page: 0,
                  size: state.search.live.externalSource.size,
                  sort: state.search.live.externalSource.sort,
                },
              },
            );

            isDisplayed = true;
            dispatch(
              setSearchData({
                searchId: requestResponse.data.id,
                data: resultResponse.data,
              }),
            );
          }
        }

        // console.log(statusResponse.data.status);
        if (statusResponse.data.status === 'FINISHED') break;
        await delay(1500);
      }

      return;
    } catch (error) {
      isError = true;
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

export const search = createAsyncThunk(
  'search/live/externalSource/search',
  async ({ page, size, sort }, { getState, dispatch, rejectWithValue }) => {
    const state = getState();

    if (_.isNil(page)) page = state.search.live.externalSource.currentPage - 1;
    if (_.isNil(size)) size = state.search.live.externalSource.size;
    if (_.isNil(sort)) sort = state.search.live.externalSource.sort;

    try {
      dispatch(setParams({ size, sort }));

      const resultResponse = await api.get(
        `service/external_sources_search_results/${state.search.live.externalSource.searchId}`,
        {
          params: {
            page,
            size,
            sort,
          },
        },
      );

      return resultResponse.data;
    } catch (error) {
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

const externalSourceSlice = createSlice({
  name: 'externalSource',
  initialState: {
    searchId: null,
    list: [],
    sort: 'createdAt,desc',
    size: 10,
    currentPage: 1,
    totalElements: 0,
    totalPages: 0,
    loading: false,
    progress: 0,
    error: null,
    done: false,
    states: [],
  },
  reducers: {
    setParams: (state, action) => {
      state.size = action.payload.size;
      state.sort = action.payload.sort;
    },
    setLoading: (state, action) => {
      state.progress = 100;
      state.loading = action.payload;
    },
    setStates: (state, action) => {
      state.states = action.payload.states;
      state.totalElements = action.payload.count;
      state.totalPages = Math.ceil(state.totalElements / state.size);
    },
    setSearchData: (state, action) => {
      state.searchId = action.payload.searchId;

      const { hasContent, totalElements, totalPages } = action.payload.data;
      state.list = hasContent ? action.payload.data.content : [];
      state.totalElements = totalElements;
      state.totalPages = totalPages;
      state.currentPage = action.payload.data.number + 1;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchRequest.pending, (state) => {
        state.loading = true;
        state.error = null;

        state.searchId = null;
      })
      .addCase(searchRequest.fulfilled, (state, action) => {
        state.searchId = action.payload.searchId;

        const { hasContent, totalElements, totalPages } = action.payload.data;
        state.list = hasContent ? action.payload.data.content : [];
        state.totalElements = totalElements;
        state.totalPages = totalPages;
        state.currentPage = action.payload.data.number + 1;

        state.loading = false;
        state.progress = 100;
      })
      .addCase(searchRequest.rejected, (state, action) => {
        state.totalElements = state.totalPages = 0;
        state.list = [];
        state.loading = false;
        // state.progress = 0;
        state.error = action.payload;
      })
      .addCase(searchRequestSimple.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.done = false;
        state.searchId = null;

        state.totalElements = state.totalPages = 0;
        state.list = [];
      })
      .addCase(searchRequestSimple.fulfilled, (state, action) => {
        state.done = true;
      })
      .addCase(searchRequestSimple.rejected, (state, action) => {
        state.totalElements = state.totalPages = 0;
        state.list = [];
        state.loading = false;
        // state.progress = 0;
        state.error = action.payload;
      })
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        const { hasContent, totalElements, totalPages } = action.payload;
        state.list = hasContent ? action.payload.content : [];
        state.totalElements = totalElements;
        state.totalPages = totalPages;
        state.currentPage = action.payload.number + 1;

        state.loading = false;
      })
      .addCase(search.rejected, (state, action) => {
        state.totalElements = state.totalPages = 0;
        state.list = [];
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(searchActions.resetProgress, (state) => {
        state.progress = 0;
      });
  },
});

const { setParams, setLoading, setStates, setSearchData, setProgress } =
  externalSourceSlice.actions;
export default externalSourceSlice.reducer;

const parseStates = (states) => {
  return Object.keys(states).map((key, idx) => {
    return {
      id: idx + 1,
      title: key,
      ...states[key],
    };
  });
};
