import React from 'react';

function Notifications() {
  return (
    <div>
      <div className="tw-font-semibold tw-bg-gray-200 tw-p-2 ">
        Notifications
      </div>
      <p className="tw-font-medium tw-mt-2 tw-px-2">Here is new update</p>
      <p className="tw-font-normal tw-px-2">
        The Darknet Risk Score quantifies the security threat .<br></br>
        Organization with a score between 1 and 100, where 100 the risk.
      </p>
      <hr className="tw-my-1" />
      <p className="tw-font-medium tw-mt-2 tw-px-2">Here is new update</p>
      <p className="tw-font-normal tw-px-2">
        The Darknet Risk Score quantifies the security threat .<br></br>
        Organization with a score between 1 and 100, where 100 the risk.
      </p>
      <hr className="tw-my-1" />
      <p className="tw-font-medium tw-mt-2 tw-px-2">Here is new update</p>
      <p className="tw-font-normal tw-px-2">
        The Darknet Risk Score quantifies the security threat .<br></br>
        Organization with a score between 1 and 100, where 100 the risk.
      </p>
      <hr className="tw-my-1" />
      <p className="tw-font-medium tw-mt-2 tw-px-2">Here is new update</p>
      <p className="tw-font-normal tw-px-2 tw-mb-2">
        The Darknet Risk Score quantifies the security threat .<br></br>
        Organization with a score between 1 and 100, where 100 the risk.
      </p>
    </div>
  );
}

export default Notifications;
