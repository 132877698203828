import cart from "../assets/svgs/case-1.svg";
import cart2 from "../assets/svgs/case-2.svg";
import cart3 from "../assets/svgs/case-3.svg";

export const EXPOSE_ATTACK = [
  {
    id: 1,
    title: "Servers With Unusual Ports",
    result: "38",
    icon: cart,
  },
  {
    id: 2,
    title: "Servers With Reported Vulns",
    result: "13",
    icon: cart2,
  },
  {
    id: 3,
    title: "Servers With Unusual Names",
    result: "17",
    icon: cart3,
  },
];
