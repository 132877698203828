import React from "react";

const Bell = () => {
  return (
    <>
      <svg
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.35395 20C8.05908 20.6224 8.98533 21 9.99977 21C11.0142 21 11.9405 20.6224 12.6456 20M15.9998 7C15.9998 5.4087 15.3676 3.88258 14.2424 2.75736C13.1172 1.63214 11.5911 1 9.99977 1C8.40848 1 6.88235 1.63214 5.75713 2.75736C4.63192 3.88258 3.99977 5.4087 3.99977 7C3.99977 10.0902 3.22024 12.206 2.34944 13.6054C1.6149 14.7859 1.24763 15.3761 1.2611 15.5408C1.27601 15.7231 1.31463 15.7926 1.46155 15.9016C1.59423 16 2.19237 16 3.38863 16H16.6109C17.8072 16 18.4053 16 18.538 15.9016C18.6849 15.7926 18.7235 15.7231 18.7384 15.5408C18.7519 15.3761 18.3846 14.7859 17.6501 13.6054C16.7793 12.206 15.9998 10.0902 15.9998 7Z"
          stroke="#455A64"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Bell;
