import _ from 'lodash';
import React, { useState } from 'react';

import CustomButton from 'common/components/Button/Button';
import CustomDropdown from 'common/components/DropDown/CustomDropdown';
import { ArrowDownLight } from 'common/Icons';

import { ManualSearchOptions } from 'common/components/SearchComponent/data';

const Step1 = ({ onApply }) => {
  const [searchType, setSearchType] = useState(null);

  return (
    <div className="tw-px-4 tw-py-2 tw-rounded-xl tw-bg-[#ffffff]">
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 md:tw-gap-x-6 tw-items-center">
        <h3 className="tw-text-base tw-font-bold tw-text-nowrap">Step 1</h3>
        <div className="tw-text-sm tw-text-nowrap">Select Search Type</div>
        <div className="tw-w-60">
          <CustomDropdown
            labelOfList="Search Type"
            placeholder="Select"
            options={ManualSearchOptions}
            name="search_type"
            background={true}
            border={true}
            dropdownArrow={<ArrowDownLight />}
            className="tw-w-full tw-h-10 tw-border tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm tw-rounded-lg"
            onChange={(option) => {
              setSearchType(option);
            }}
          />
        </div>
        <CustomButton
          text="Apply"
          onClick={() => onApply && onApply(searchType)}
          disabled={_.isEmpty(searchType)}
        />
        {searchType && searchType.tip && (
          <div className="tw-px-4 tw-py-2 tw-text-xs tw-border tw-border-solid tw-border-secondary tw-shadow-sm tw-rounded-lg">
            {searchType.tip}
          </div>
        )}
      </div>
    </div>
  );
};

export default Step1;
