// NotFound.js
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-screen">
      <h1 className="tw-text-4xl tw-font-semibold tw-text-gray-800">
        404 - Page Not Found
      </h1>
      <p className="tw-mt-4 tw-text-lg tw-text-gray-600">
        The page you are looking for does not exist.
      </p>
      <Link to="/" className="tw-mt-4 tw-text-blue-600 hover:tw-underline">
        Go back
      </Link>
    </div>
  );
};

export default NotFound;
