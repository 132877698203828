import React from "react";

const Mail = () => {
  return (
    <>
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4536 2.19382L17.727 6.27149C17.9486 6.41558 18.0595 6.48762 18.1398 6.58372C18.2109 6.66879 18.2643 6.76718 18.2969 6.87314C18.3337 6.99284 18.3337 7.12503 18.3337 7.38942V13.4992C18.3337 14.8993 18.3337 15.5994 18.0612 16.1341C17.8215 16.6046 17.439 16.987 16.9686 17.2267C16.4339 17.4992 15.7338 17.4992 14.3337 17.4992H5.66699C4.26686 17.4992 3.5668 17.4992 3.03202 17.2267C2.56161 16.987 2.17916 16.6046 1.93948 16.1341C1.66699 15.5994 1.66699 14.8993 1.66699 13.4992V7.38942C1.66699 7.12503 1.66699 6.99284 1.7038 6.87314C1.73638 6.76718 1.78978 6.66879 1.86087 6.58372C1.94117 6.48762 2.052 6.41558 2.27367 6.27149L8.54702 2.19382M11.4536 2.19382C10.9276 1.85189 10.6646 1.68093 10.3812 1.61441C10.1307 1.55561 9.86997 1.55561 9.61947 1.61441C9.33608 1.68093 9.07306 1.85189 8.54702 2.19382M11.4536 2.19382L16.6138 5.54791C17.187 5.92049 17.4736 6.10679 17.5728 6.34303C17.6596 6.54949 17.6596 6.78218 17.5728 6.98864C17.4736 7.22488 17.187 7.41118 16.6138 7.78376L11.4536 11.1379C10.9276 11.4798 10.6646 11.6507 10.3812 11.7173C10.1307 11.7761 9.86997 11.7761 9.61947 11.7173C9.33608 11.6507 9.07306 11.4798 8.54702 11.1379L3.38688 7.78376C2.81367 7.41118 2.52707 7.22488 2.4278 6.98864C2.34106 6.78218 2.34106 6.54949 2.4278 6.34303C2.52707 6.10679 2.81367 5.9205 3.38688 5.54791L8.54702 2.19382M17.917 15.8325L12.3813 10.8325M7.61934 10.8325L2.08366 15.8325"
          stroke="#2B2F38"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Mail;
