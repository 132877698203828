import React, {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';

import CustomTable from "../../common/table-no-paginator/table.component";
import Heading from "../../../common/components/heading/Heading";
import { getList as getLeakedCredentialList } from 'store/slices/report/leakedCredential';

function Credientials() {
  const columns = [
    { Header: "Leaked Credentials", accessor: "created" },
    { Header: "Results", accessor: "results" },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLeakedCredentialList({ }));
  }, [dispatch]);

  const { list, size, currentPage, totalElements, totalPages, loading } =
    useSelector((state) => state.report.leakedCredential);
  const setCurrentPage = (page) => {
    dispatch(
      getLeakedCredentialList({
        page: page - 1, 
      }),
    );
  };

  const data = [
    {
      created: "Total Number of Leaked Credentials",
      results: `${list.total_number}`,
    },
    {
      created: "Credentials originating from Stealer Logs*",
      results: `${list.from_stealer_logs}`,
    },
    {
      created: "Credentials found in Combo Lists",
      results: `${list.from_combo}`,
    },
  ];

  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow tw-h-full">
      <Heading size="h2" text="Leaked Credentials" />
      <CustomTable data={data} columns={columns} selectable={false} />
    </div>
  );
}

export default Credientials;
