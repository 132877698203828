import React from "react";

const CustomTextArea = ({
  label,
  placeholder,
  value,
  onChange,
  rows = 4,
  cols = 50,
  maxLength,
  className = "",
  labelClassName = "",
  textAreaClassName = "",
}) => {
  return (
    <div className={`flex flex-col ${className}`}>
      {label && (
        <label className={`mb-2 text-gray-700 ${labelClassName}`}>
          {label}
        </label>
      )}
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows={rows}
        cols={cols}
        maxLength={maxLength}
        className={`tw-w-full p-2 border border-gray-300 rounded resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 ${textAreaClassName}`}
      />
    </div>
  );
};

export default CustomTextArea;
