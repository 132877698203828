import React from "react";

const Feedback = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.07896 9.35863C5.02717 9.02482 5.00029 8.68285 5.00029 8.33464C5.00029 4.65274 8.0047 1.66797 11.7108 1.66797C15.4169 1.66797 18.4213 4.65274 18.4213 8.33464C18.4213 9.16636 18.268 9.96251 17.988 10.6967C17.9298 10.8492 17.9007 10.9255 17.8875 10.985C17.8744 11.044 17.8694 11.0854 17.8679 11.1458C17.8665 11.2068 17.8748 11.2739 17.8913 11.4082L18.2268 14.1334C18.2631 14.4284 18.2813 14.5759 18.2322 14.6832C18.1892 14.7771 18.1128 14.8517 18.0179 14.8926C17.9096 14.9391 17.7625 14.9176 17.4684 14.8745L14.814 14.4854C14.6755 14.4651 14.6061 14.4549 14.543 14.4553C14.4806 14.4556 14.4374 14.4602 14.3763 14.4731C14.3145 14.4861 14.2356 14.5156 14.0778 14.5747C13.3417 14.8505 12.544 15.0013 11.7108 15.0013C11.3623 15.0013 11.02 14.9749 10.6859 14.924M6.35997 18.3346C8.83072 18.3346 10.8337 16.2826 10.8337 13.7513C10.8337 11.22 8.83072 9.16797 6.35997 9.16797C3.88923 9.16797 1.88629 11.22 1.88629 13.7513C1.88629 14.2601 1.96722 14.7496 2.11662 15.2069C2.17977 15.4002 2.21134 15.4969 2.22171 15.5629C2.23252 15.6319 2.23442 15.6706 2.23039 15.7402C2.22653 15.807 2.20984 15.8824 2.17645 16.0332L1.66699 18.3346L4.16266 17.9938C4.29888 17.9752 4.36699 17.9659 4.42647 17.9663C4.48909 17.9667 4.52233 17.9701 4.58375 17.9824C4.64208 17.994 4.72879 18.0246 4.90222 18.0858C5.35915 18.2471 5.84958 18.3346 6.35997 18.3346Z"
          stroke="#2B2F38"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Feedback;
