import React, { useRef, useState, useEffect } from "react";
import {
  ArrowUpSide,
  InfoIcon,
  Setting,
  ArrowDown,
  ArrowBack,
} from "../../../common/Icons";
import ReactApexChart from "react-apexcharts";
import DomainsIncluded from "./DomainsIncluded";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import DomainDropdown from "./DomainDropdown";
import CustomTable from "../../common/table/table.component";
import { Tooltip } from "react-tooltip";
import api from 'store/api';
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";

function DarkNet() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedYearScore, setSelectedYearScore] = useState([]);
  const [chartScore, setChartScore] = useState([]);
  const [open, setOpen] = useState(false);
  const settingsDropdownRef = useRef(null);
  const [isDropdownOpenSetting, setDropdownOpenSetting] = useState(false);
  const settingsDropdownRefSetting = useRef(null);
  const [detailDarknetScore, setDetailDarknetScore] = useState([]);

  const [selectedDomain, setSelectedDomain] = useState('');
  const [selectedDomains, setSelectedDomains] = useState([]);

  useOutsideClick(settingsDropdownRef, () => setDropdownOpen(false));
  useOutsideClick(settingsDropdownRefSetting, () =>
    setDropdownOpenSetting(false)
  );

  const [view, setView] = useState("main"); 

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setDropdownOpen((prev) => !prev);
  };

  const toggleDropdownSetting = (event) => {
    event.stopPropagation();
    setDropdownOpenSetting((prev) => !prev);
  };

  const chartOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    stroke: {
      curve: "smooth",
      width: 4,
    },
    fill: {
      type: "solid",
      colors: ["#ECC551"],
    },
    colors: ["#ECC551"],
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
    },
  };

  const chartSeries = [
    {
      name: "Risk Score",
      data: chartScore.map(item => Math.round(item.averageScore)),
    },
  ];

  useEffect(() => {
    const fetchDetailDarknetScore = async () => {
      try {
        const response = await api.get('/service/get_risk_score');
        setDetailDarknetScore(response.data);
      } catch (error) {
        console.error('Error fetching domain data:', error);
      }
    };
    fetchDetailDarknetScore();
    const fetchScoreForYear = async () => {
      try {
        const response = await api.get('/service/get_company_score_history', {
          params: {
            year: selectedYear
          },
        },
      );
        setSelectedYearScore(response.data);
      } catch (error) {
        console.error('Error fetching year score data:', error);
      }
    };
    fetchScoreForYear();
  }, [selectedYear]);

  useEffect(() => {
    const groupDataByMonth = (data) => {
      const allMonths = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
      const groupedData = data.reduce((acc, item) => {
        const month = item.day.split('-')[0]; // Extract month from 'day'
        if (!acc[month]) {
          acc[month] = { totalScore: 0, count: 0 };
        }
        acc[month].totalScore += item.score;
        acc[month].count += 1;
        return acc;
      }, {});

      const averageScoresByMonth = allMonths.map((month) => ({
        month,
        averageScore: groupedData[month] ? Math.round(groupedData[month].totalScore / groupedData[month].count) : 0
      }));

      return averageScoresByMonth;
    };

    if (selectedYearScore.length > 0) {
      const averageScores = groupDataByMonth(selectedYearScore);
      console.log("selectedYearScore-->", averageScores);
      setChartScore(averageScores);
    }
  }, [selectedYearScore]);

  const detailDarknetScoreEdited = {
    "score_a": detailDarknetScore.score_a,
    "score_b": detailDarknetScore.score_b,
    "score_c": detailDarknetScore.score_c,
    "score_d": detailDarknetScore.score_d,
    "score_e": detailDarknetScore.score_e,
    "total_score": detailDarknetScore.total_score
  };

  const scoreTitles = [
    "Number of leaks vs Organization size",
    "Recent versus historical leak comparison",
    "Sale of botnet logs on darknet markets",
    "Number of leaks vs Organization size",
    "Recent versus historical leak comparison"
  ];
  
  const scores = Object.keys(detailDarknetScoreEdited).filter(key => key !== "total_score");
  
  return (
    <div className="tw-p-4 tw-bg-white tw-border tw-rounded-lg tw-shadow tw-h-full">
      {view === "main" && (
        <>
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
            <div className="tw-flex tw-items-center tw-gap-1">
              <h2 className="tw-text-xl tw-font-bold">DARKNET</h2>
              <div className="my-anchor-elementsa">
                <InfoIcon />
              </div>
              <Tooltip
                anchorSelect=".my-anchor-elementsa"
                place="right"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  width: "600px",
                }}
              >
                The Darknet Risk Score quantifies the security threat to your
                organization with a score<br></br> between 1 and 100, where 100
                represents the highest risk.<br></br>This score is derived from
                several key factors: the number of data leaks associated{" "}
                <br></br>with your company's domain, the types and severity of
                these leaks—ranging from <br></br> generic collections to those
                specifically targeting your infrastructure,and the presence{" "}
                <br></br> of your organization's data in hacker forums<br></br>
                .Additionally, we conduct an infrastructure risk analysis that
                includes checks for open <br></br>ports and known
                vulnerabilities (CVEs), and assess the security of passwords
                that <br></br>have been exposed in data leaks
              </Tooltip>
            </div>
            <div className="tw-flex tw-items-center tw-gap-2 tw-relative">
              <div
                onClick={toggleDropdownSetting}
                className="tw-cursor-pointer"
              >
                <Setting />
              </div>
              {isDropdownOpenSetting && (
                <div
                  ref={settingsDropdownRefSetting}
                  className="tw-absolute tw-top-12 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
                >
                  <DomainsIncluded selectedDomains={selectedDomains} setSelectedDomains={setSelectedDomains} />
                </div>
              )}
              <div>
                <div onClick={toggleDropdown} className="tw-w-full tw-cursor-pointer tw-py-1.5 tw-gap-2 tw-px-2  tw-mr-1   tw-bg-[#F9F9F9] tw-text-gray-700 tw-rounded-md tw-flex tw-items-center tw-justify-center hover:tw-bg-yellow-200]">
                  Domain <ArrowDown />
                </div>
                {isDropdownOpen && (
                  <div
                    ref={settingsDropdownRef}
                    className="tw-absolute tw-top-12 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
                  >
                    <DomainDropdown selectedDomain={selectedDomain} setSelectedDomain={setSelectedDomain} selectedDomains={selectedDomains}/>
                  </div>
                )}
              </div>
            </div>
          </div>
          <p>Risk Score</p>
          <div className="tw-flex tw-justify-between">
            <div>
              <p className="tw-text-base tw-font-medium tw-text-[#00B6FF]">
                atlanta-health-insurance.com
              </p>
              <p className="tw-text-sm tw-font-normal">
                Min. Risk: <b>0</b> Max. Risk: <b>100</b>
              </p>
              <div
                className="tw-flex tw-items-center tw-gap-2"
                onClick={() => setView("details")}
              >
                <p className="tw-text-base tw-font-medium tw-cursor-pointer">
                  How it works
                </p>
                <ArrowUpSide />
              </div>
            </div>
            <p className="tw-text-2xl md:tw-text-5xl lg:tw-text-7xl tw-font-bold">
              65
            </p>
          </div>
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="line"
            height={230}
          />
          <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
            <div className="tw-flex tw-items-center tw-gap-1 mt-2">
              <span className="tw-text-[#AFABAB] tw-text-sm">Year:</span>
              <span className="tw-font-semibold tw-text-black tw-text-sm tw-flex tw-items-center">
                {selectedYear}{" "}
                <button
                  className="tw-relative tw-ml-2"
                  onClick={() => setOpen((prev) => !prev)}
                >
                  <ArrowDown />
                  {open && (
                    <div className="tw-absolute tw-w-28 tw-bg-white card-shadow tw-rounded-lg tw-z-50 py-1 tw-right-0 tw-top-4">
                      <ul className="">
                        <li
                          className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
                          onClick={() => setSelectedYear("2022")}
                        >
                          2022
                        </li>
                        <li
                          className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
                          onClick={() => setSelectedYear("2023")}
                        >
                          2023
                        </li>
                        <li
                          className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
                          onClick={() => setSelectedYear("2024")}
                        >
                          2024
                        </li>
                      </ul>
                    </div>
                  )}
                </button>
              </span>
            </div>
          </div>
        </>
      )}
      {view === "details" && (
        <>
          <div
            className="tw-flex tw-gap-[11px]"
            onClick={() => setView("main")}
          >
            <div className="tw-cursor-pointer">
              <ArrowBack />
            </div>
            <span className="tw-font-bold tw-text-[#263238]">
              Details Darknet Risk Score
            </span>
          </div>
          <p className="tw-pl-8">atlanta-health-insurance.com</p>
          <div className="tw-overflow-x-auto tw-shadow-lg tw-max-w-4xl tw-mx-auto ">
            <table className="tw-min-w-full tw-leading-normal">
            <thead>
              <tr>
                <th className="tw-px-5 tw-font-semibold tw-py-3 tw-border-b-2 tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-uppercase tracking-wider">
                  Title
                </th>
                <th className="tw-px-5 tw-py-3 tw-font-semibold tw-border-b-2 tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-uppercase tracking-wider">
                  Your Value
                </th>
              </tr>
            </thead>
            <tbody>
              {scores.map((key, index) => (
                <tr key={index}>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    {scoreTitles[index]}
                  </td>
                  <td className="tw-px-5 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                    {detailDarknetScore[key]}
                  </td>
                </tr>
              ))}
              <tr>
                <td className="tw-px-5 tw-py-2.5 tw-font-semibold tw-border-b tw-border-gray-200 tw-bg-white tw-text-sm">
                  Total Risk Score
                </td>
                <td className="tw-px-5 tw-py-2.5 tw-border-b tw-font-semibold tw-border-gray-200 tw-bg-white tw-text-sm">
                  {detailDarknetScore.total_score}
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default DarkNet;
