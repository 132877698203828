import _ from 'lodash';
import React, { useState } from 'react';
import * as Yup from 'yup';

import CustomButton from 'common/components/Button/Button';
import InputGroup from 'common/components/InputGroup/InputGroup';

const Step2 = ({ searchType, onApply }) => {
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleApply = () => {
    Yup.object()
      .shape({ term: searchType.main.validation })
      .validate({ term: inputValue })
      .then(() => {
        setErrorMessage('');

        onApply && onApply(inputValue);
      })
      .catch((err) => {
        setErrorMessage(err.errors);
      });
  };

  return (
    <div className="tw-px-4 tw-py-2 tw-rounded-xl tw-bg-[#ffffff]">
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 md:tw-gap-x-6 tw-items-center">
        <h3 className="tw-text-base tw-font-bold tw-text-nowrap">Step 2</h3>

        <div className="tw-text-sm tw-text-nowrap">Select Search Term</div>

        <div className="tw-w-full tw-flex tw-items-center tw-relative">
          <InputGroup
            placeholder={searchType.main.placeholder}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="tw-w-full tw-h-full !tw-p-3 tw-text-left tw-leading-[16.41px] tw-tracking-[0.02em]"
          />
          {errorMessage && (
            <div className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-red-500 tw-text-white tw-text-xs tw-rounded tw-px-2 tw-py-1 tw-shadow-md">
              {errorMessage}
            </div>
          )}
        </div>

        <CustomButton text="Apply" onClick={handleApply} />
        {searchType && searchType.tip && (
          <div className="tw-px-4 tw-py-2 tw-text-xs tw-border tw-border-solid tw-border-secondary tw-shadow-sm tw-rounded-lg">
            {searchType.tip}
          </div>
        )}
      </div>
    </div>
  );
};

export default Step2;
