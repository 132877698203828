import React from "react";
import { BugIcon } from "../../../common/Icons/index";

const GroupAttacksList = () => {
  const data = [
    { name: "Lockbit 3", value: 52648 },
    { name: "Lockbit2", value: 1254 },
    { name: "Alphv", value: 3658 },
    { name: "Play", value: 12560 },
    { name: "Bianlian", value: 1524 },
    { name: "Clop", value: 3256 },
    { name: "Blackbasta", value: 1250 },
  ];

  return (
    <div className="tw-bg-white  tw-rounded-lg tw-p-4 ">
      <p className="tw-font-medium tw-text-lg tw-px-6 tw-mb-6 ">Group Attacks</p>
      <ul>
        {data.map((item, index) => (
          <li
            key={index}
            className={`tw-flex tw-items-center tw-justify-between tw-py-2 tw-px-3 ${
              index % 2 === 0 ? "tw-bg-gray-50 tw-rounded-lg tw-px-3" : ""
            }`}
          >
            <div className="tw-flex tw-items-center">
              <BugIcon className="tw-text-yellow-500 tw-mr-3 tw-w-4 tw-h-4" />
              <span className="tw-text-gray-800 tw-font-medium tw-text-sm">
                {item.name}
              </span>
            </div>
            <span className="tw-text-blue tw-font-semibold tw-pl-2 tw-text-sm">
              {item.value.toLocaleString()}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GroupAttacksList;
