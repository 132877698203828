import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InfoYellow from 'common/Icons/InfoYellow';
import FunnelFilter from 'common/Icons/FunnelFilter';
import CustomButton from 'common/components/Button/Button';
import InputGroup from 'common/components/InputGroup/InputGroup';
import ExportButton from 'common/components/Export/ExportButton';
import CreationDropdown from 'common/components/DropDown/CreationDropdown';
import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import Loading from 'common/components/Loading/Loading';

import useExport from 'hooks/use-export';

import { search as searchDataLeaks } from 'store/slices/search/database/dataLeaks';
import { kaduuHighlightTags } from 'utils/global';

const initialFilters = {
  filter1: false,
  filter2: false,
};

const Leaks = ({ selectedOptionLabel, searchTerm, onLeakDetail }) => {
  const dispatch = useDispatch();

  const {
    query,
    list: dataLeaks,
    sort,
    size,
    length,
    currentPage,
    totalElements,
    totalPages,
    loading,
  } = useSelector((state) => state.search.database.dataLeaks);

  const [isPageSearch, setIsPageSearch] = useState(false);

  const setCurrentPage = (page) => {
    setIsPageSearch(true);

    dispatch(
      searchDataLeaks({
        page: page - 1,
        progress: false,
      }),
    );
  };

  const columns = [
    {
      Header: 'Leak Name',
      accessor: 'leakName',
      render: (_, record) => {
        return (
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span
              className="tw-whitespace-nowrap tw-text-[#00B6FF] tw-cursor-pointer"
              onClick={() => {
                if (onLeakDetail) onLeakDetail(record.leakId);
              }}
            >
              {record.leakName}
            </span>
            <span>{record.createdAt}</span>
          </div>
        );
      },
    },
    {
      Header: 'Leak Content Extract',
      accessor: 'content',
      render: (_, record) => {
        return (
          <>
            <div className="tw-flex tw-flex-wrap tw-gap-1">
              {record.leakTags.split(',').map((keyword, index) => {
                return (
                  <span
                    className="tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]"
                    key={index}
                  >
                    {keyword}
                  </span>
                );
              })}
            </div>
            <div
              className="tw-mt-[10px] tw-whitespace-pre-wrap"
              dangerouslySetInnerHTML={{
                __html: kaduuHighlightTags(record.content),
              }}
            />
            <p className="tw-mt-[10px] tw-text-[#00B6FF]">{record.fileName}</p>
          </>
        );
      },
    },
    {
      Header: 'Leak Date',
      accessor: 'leakDiscoverDate',
      render: (value) => <span className="tw-whitespace-nowrap tw-text-[#44464A]">{value}</span>,
    },
  ];

  const handleChange = (_length) => {
    if (length !== _length) {      
      setIsPageSearch(true);

      dispatch(
        searchDataLeaks({
          length: _length,
          progress: false,
        }),
      );
    }
  };

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const openFilterModal = () => {
    setIsFilterModalOpen(true);
  };
  const closeFilterModal = (filters) => {
    setFilters(filters);

    setIsFilterModalOpen(false);
  };

  const { canExport, handleRowSelection, exporting, handleExportOptionChange } =
    useExport({ source: 'leak', sort, size, length, searchTerm });

  return (
    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
      {!(loading && !isPageSearch) ? (
        <>
          <span className="tw-font-bold tw-text-lg tw-text-[#263238]">
            Search Result for {selectedOptionLabel} Search
          </span>
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
            <div className="tw-flex tw-shrink-0 tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">
                Search results
              </span>
              <span className="tw-text-sm tw-font-medium">{totalElements}</span>
            </div>
            <div className="tw-flex tw-shrink-0 tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">Search term</span>
              <span className="tw-text-sm tw-font-medium">{query}</span>
            </div>
            {totalElements === 10000 && (
              <div className="tw-flex tw-grow tw-items-center tw-justify-center tw-gap-x-4">
                <div>
                  <InfoYellow />
                </div>
                <div className="tw-text-sm tw-font-normal tw-text-[#344054] tw-text-pretty">
                  Your search returned more than 10&apos;000 entries. We show
                  only the first 10&apos;000 entries.
                  <br /> Please adjust your search to get more accurate results.
                </div>
              </div>
            )}
          </div>
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
            <div className={loading ? 'tw-invisible' : 'tw-visible'}>
              <p>
                Showing leaks:{' '}
                {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
                {currentPage * size > totalElements
                  ? totalElements
                  : currentPage * size}{' '}
                of {totalElements}
              </p>
            </div>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 md:tw-gap-4">
              <CreationDropdown defaultValue={length} onChange={handleChange} />

              <CustomButton
                bgColor="tw-bg-white"
                textColor="tw-text-primary"
                icon={<FunnelFilter />}
                text="Filters"
                onClick={openFilterModal}
                className="tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border !tw-border-solid tw-border-[#DFE0E3] tw-rounded-md tw-text-sm tw-font-semibold tw-leading-6 tw-text-center hover:tw-bg-[#F5F6F8]"
              />

              <ExportButton
                onChange={handleExportOptionChange}
                isLoading={exporting}
                disabled={!canExport}
              />
            </div>
          </div>
          <div className="tw-relative">
            <Table
              data={dataLeaks}
              columns={columns}
              selectable={true}
              onRowSelection={handleRowSelection}
            />
            {totalPages > 1 && (
              <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
            {loading && (
              <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
            )}
          </div>
        </>
      ) : (
        <Loading label="The search is still running – Please wait a moment..." />
      )}

      <FilterFormModal
        filters={filters}
        showModal={isFilterModalOpen}
        closeModal={closeFilterModal}
      />
    </div>
  );
};

export default Leaks;

const FilterFormModal = ({ filters, showModal, closeModal }) => {
  const [values, setValues] = useState(initialFilters);

  useEffect(() => {
    setValues(filters);
  }, [filters]);

  const handleChange = (e) => {
    const checked = e.currentTarget.checked;

    if (e.currentTarget.name == 'filter1') {
      setValues((prev) => {
        return { ...prev, filter1: checked };
      });
    } else if (e.currentTarget.name == 'filter2') {
      setValues((prev) => {
        return { ...prev, filter2: checked };
      });
    }
  };

  if (!showModal) return null; // If the modal is not open, return nothing

  return (
    <div
      className={`tw-fixed tw-right-0 tw-bottom-4 tw-p-4 tw-mt-24 tw-mr-4 tw-bg-white tw-shadow-lg tw-rounded-lg tw-z-50`}
    >
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <h2 className="tw-text-lg tw-font-semibold">Filter</h2>
        <button onClick={closeModal} className="tw-text-3xl tw-text-gray-500">
          &times;
        </button>
      </div>

      {/* Form Starts Here */}
      <form>
        {/* Filter 1 */}
        <div className="tw-flex tw-justify-self-start tw-gap-2 tw-mb-4">
          <InputGroup
            inputType="checkbox"
            inputName="filter1"
            focusOnType={true}
            checked={values.filter1}
            onChange={handleChange}
          />
          <span className="tw-text-sm tw-text-[#44464A] tw-whitespace-nowrap">
            Exclude duplicate Username/Password in Combo Lists
          </span>
        </div>

        {/* Filter 2 */}
        <div className="tw-flex tw-justify-self-start tw-gap-2">
          <InputGroup
            inputType="checkbox"
            inputName="filter2"
            focusOnType={true}
            checked={values.filter2}
            onChange={handleChange}
          />
          <span className="tw-text-sm tw-text-[#44464A] tw-whitespace-nowrap">
            Show only unique Username/Password Combos
          </span>
        </div>

        {/* Buttons */}
        <div className="tw-flex tw-justify-around tw-mt-4">
          <button
            type="button"
            className="tw-bg-yellow-500 tw-text-white tw-px-6 tw-py-2 tw-rounded-md hover:tw-bg-yellow-600"
            onClick={() => closeModal(values)}
          >
            Apply Filter
          </button>
          <button
            type="button"
            className="tw-border tw-text-gray-700 tw-px-6 tw-py-2 tw-rounded-md hover:tw-bg-gray-400"
            onClick={() => {
              closeModal(initialFilters);
            }}
          >
            Clear Filter
          </button>
        </div>
      </form>
    </div>
  );
};
