import React, { useRef, useState, useEffect } from 'react';
import CustomButton from '../Button/Button';
import { Export } from '../../Icons';
import ExportOptions from './ExportOptions';

const ExportButton = ({ onChange, isLoading = false, disabled = false }) => {
  const [exportDropdown, setExportDropdown] = useState(false);
  const exportDropdownRef = useRef(null);

  // Toggle dropdown visibility
  const toggleExport = (event) => {
    setExportDropdown((prev) => !prev);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        exportDropdownRef.current &&
        !exportDropdownRef.current.contains(event.target)
      ) {
        setExportDropdown(false);
      }
    };

    if (exportDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [exportDropdown]);

  // Handle option selection and close dropdown
  const handleOptionSelect = (option) => {
    if (onChange) onChange(option);
    setExportDropdown(false);
  };

  return (
    <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-4 tw-relative">
      <div onClick={toggleExport}>
        <CustomButton
          type="button"
          text="Export"
          className="tw-border-[#DFE0E3] tw-ease-in-out hover:tw-bg-secondary tw-border tw-flex tw-items-center tw-px-[14px]"
          textColor="tw-text-white"
          borderRadius="tw-rounded-lg"
          fontSize="tw-text-sm"
          fontWeight="tw-font-medium"
          isLoading={isLoading}
          icon={<Export />}
          disabled={disabled}
        />
      </div>
      {exportDropdown && (
        <div
          className="tw-absolute tw-py-1 tw-top-10 tw-bg-white tw-w-28 tw-z-10 tw-shadow-lg"
          ref={exportDropdownRef}
        >
          <ExportOptions onSelect={handleOptionSelect} />
        </div>
      )}
    </div>
  );
};

export default ExportButton;
