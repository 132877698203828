import React, { useRef, useState } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import Heading from "../../common/components/heading/Heading";
import Results from "./components/results/results.component";
import CountriesChart from "../charts/countries_chart/countries_chart";
import RegionsChart from "../charts/region_chart/region_chart";
import GroupAttacksChart from "../charts/group_attacks/group_attacks";
import GroupAttacksList from "../charts/list_attack/list_attack";
import WorldMapChart from "../charts/world_map/world_map";
import GroupPercentChart from "../charts/attack_percent/attack_percent";
import CustomTable from "../common/table/table.component";
import { RANSOM_WARE } from "./ransomware.constant";
import InputGroup from "../../common/components/InputGroup/InputGroup";
import CustomButton from "../../common/components/Button/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiCalendarDate } from "react-icons/ci";
import CustomSearch from "../../common/components/CustomSearch/CustomSearch";
import { IoIosArrowDown } from "react-icons/io";
import { CompanySearch } from "./components/company-search";
import { useFeed } from "./use-feed.hook";
import BellYellow from "../../common/Icons/BellYellow";
import DomainDropdown from "../dashboard/DarkNet/DomainDropdown";
import { ArrowDown } from "../../common/Icons";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import IndustriesChart from "../charts/target_industries/target_industries";

export default function RansomwareComponents() {
  const {
    selectedOption,
    toggleDropdown,
    handleSearchChange,
    handleOptionClick,
    filteredOptions,
    isOpen,
    searchTerm,
  } = useFeed();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const columns = [
    {
      Header: "Date",
      accessor: "date",
      render: (_, date) => {
        return <span className="tw-whitespace-nowrap">{date.date}</span>;
      },
    },
    {
      Header: "Company",
      accessor: "company",
      render: (_, record) => {
        return <span className="tw-text-blue">{record.company}</span>;
      },
    },
    {
      Header: "Group Name",
      accessor: "groupName",
    },
    { Header: "Industry", accessor: "industry" },
    {
      Header: "Country",
      accessor: "country",
      render: (_, item) => {
        return <span className="tw-whitespace-nowrap">{item.country}</span>;
      },
    },
    { Header: "Region", accessor: "region" },
    { Header: "Description", accessor: "description" },
  ];

  const data = [
    {
      date: "2024-05-21",
      company: "SIAED.it - HOSTER/DEV FOR ITALY BIGGEST BANKS",
      groupName: "LORENZ",
      industry: "Electrical and Data Communication",
      country: "United States",
      region: "Midwest",
      description:
        "Windemuller, a family-owned electrical, data communications, and automation company that provides services to various industries",
    },
    {
      date: "2024-05-21",
      company: "SIAED.it - HOSTER/DEV FOR ITALY BIGGEST BANKS",
      groupName: "LORENZ",
      industry: "Electrical and Data Communication",
      country: "United States",
      region: "Midwest",
      description:
        "Windemuller, a family-owned electrical, data communications, and automation company that provides services to various industries",
    },
    {
      date: "2024-05-21",
      company: "SIAED.it - HOSTER/DEV FOR ITALY BIGGEST BANKS",
      groupName: "LORENZ",
      industry: "Electrical and Data Communication",
      country: "United States",
      region: "Midwest",
      description:
        "Windemuller, a family-owned electrical, data communications, and automation company that provides services to various industries",
    },
    {
      date: "2024-05-21",
      company: "agranibank.org",
      groupName: "RAGNARLOCKER",
      industry: "Telecommunication",
      country: "United States",
      region: "Midwest",
      description:
        "Windemuller, a family-owned electrical, data communications, and automation company that provides services to various industries",
    },
    {
      date: "2024-05-21",
      company: "Bank Pembangunan Daerah Banten Tbk PT",
      groupName: "RAGNARLOCKER",
      industry: "Technology",
      country: "United States",
      region: "Midwest",
      description:
        "Windemuller, a family-owned electrical, data communications, and automation company that provides services to various industries",
    },
  ];

  const [isSaved, setIsSaved] = useState(false);
  const navigate = useNavigate();
  const settingsDropdownRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  useOutsideClick(settingsDropdownRef, () => setDropdownOpen(false));
  const toggleDropdownCountry = (event) => {
    event.stopPropagation();
    setDropdownOpen((prev) => !prev);
  };

  return (
    <>
      <Results data={RANSOM_WARE} />
      <div className="tw-mt-4">
        <Heading size="h3" text="Alerts" />
        <>
          {!isSaved ? (
            <div className="tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 tw-mt-4 tw-flex-col sm:tw-flex-row tw-flex tw-items-center tw-gap-3">
              <p className="tw-text-sm tw-text-[#344054] md:tw-whitespace-nowrap">
                Notify me, when
              </p>
              <InputGroup
                className="tw-border tw-border-[#DFE0E3] tw-text-base tw-px-2 tw-py-2 tw-rounded-lg input"
                placeholder="Keyword"
              />
              <p className="tw-text-sm tw-text-[#344054] md:tw-whitespace-nowrap">
                appears in a ransomware case and send the notification to
              </p>
              <InputGroup
                className="tw-border tw-border-[#DFE0E3] tw-text-base tw-px-2 tw-py-2 tw-rounded-lg input"
                placeholder="Email"
              />
              <CustomButton
                type="button"
                text="Save"
                textColor="tw-text-white"
                bgColor="tw-bg-dark"
                fontSize="tw-text-sm"
                fontWeight="tw-font-medium"
                className="tw-border-dark tw-border tw-w-full md:tw-w-max tw-py-2 tw-px-[14px]"
                borderRadius="tw-rounded-lg"
                onClick={() => setIsSaved(true)} // Set to true on save
              />
            </div>
          ) : (
            <div className="tw-flex tw-justify-between lg:tw-items-center tw-items-start lg:tw-flex-row tw-flex-col tw-bg-[#ffffff] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px]">
              <div className="tw-flex tw-items-center tw-gap-[9.45px]">
                <BellYellow />
                <div className="tw-text-normal tw-font-normal tw-leading-6 tw-text-[#344054]">
                  We added an Alert for {`keyword`}. Please go to{" "}
                  <strong className="tw-font-semibold">
                    <u>Alerts/Alerting Rules</u>
                  </strong>{" "}
                  to edit this rule.
                </div>
              </div>
              <CustomButton
                text="Alerting Rules"
                onClick={() => navigate("/AlertingRules")}
                className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
              />
            </div>
          )}
        </>
      </div>

      <div className="tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 tw-mt-4">
        <Heading size="h2" text="Ransomware Threat Intelligence Statistics" />
        <div className="tw-flex tw-justify-between tw-flex-col sm:tw-flex-row">
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg md:tw-w-[32%] tw-w-[100%]">
            <CountriesChart />
          </div>
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg md:tw-w-[32%] tw-w-[100%]">
            <RegionsChart />
          </div>
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg md:tw-w-[32%] tw-w-[100%]">
            <GroupAttacksChart />
          </div>
        </div>

        <div className="tw-flex tw-justify-between tw-mt-5">
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg md:tw-w-[49%] tw-w-[100%]">
            <GroupAttacksList />
          </div>
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg md:tw-w-[49%] tw-w-[100%]">
            <IndustriesChart />
          </div>
        </div>

        <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-5">
          <div className="md:tw-w-[68%] ">
            <WorldMapChart />
          </div>
          <div className=" md:tw-w-[32%] tw-mt-12">
            <GroupPercentChart />
          </div>
        </div>

        <Heading
          size="h2"
          fontSize="18px"
          color="#263238"
          text="Latest Ransomware Attacks"
        />
        <div className="tw-flex tw-mb-4 tw-gap-3">
          <div
            onClick={toggleDropdownCountry}
            className="tw-cursor-pointer tw-w-[54%]"
          >
            <div className=" tw-py-2.5 tw-gap-2 tw-px-4  tw-mr-1 tw-border tw-text-gray-700 tw-rounded-md tw-flex tw-items-center tw-justify-between hover:tw-bg-yellow-200">
              <span>Country</span>
              <ArrowDown />
            </div>

            {isDropdownOpen && (
              <div
                ref={settingsDropdownRef}
                className="tw-absolute   tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-80 tw-z-10"
              >
                <DomainDropdown />
              </div>
            )}
          </div>
          <div className="tw-flex tw-gap-3 tw-w-[54%]">
            <div className="tw-w-2/4 tw-border tw-border-[#DFE0E3] tw-text-base tw-rounded-lg tw-py-1 tw-pr-4 tw-relative custom__picker">
              <DatePicker
                showIcon
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
                icon={
                  <CiCalendarDate
                    style={{ fontSize: "19px", color: "#71747C" }}
                  />
                }
              />
            </div>
            <div className="tw-w-2/4 tw-border tw-border-[#DFE0E3] tw-text-base tw-rounded-lg tw-py-1 tw-pr-4 tw-relative custom__picker">
              <DatePicker
                showIcon
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="End Date"
                icon={
                  <CiCalendarDate
                    style={{ fontSize: "19px", color: "#71747C" }}
                  />
                }
              />
            </div>
          </div>
        </div>

        <CustomTable data={data} columns={columns} selectable={true} />
      </div>
    </>
  );
}
