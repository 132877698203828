import React from 'react';

const Premium = ({ disabled }) => {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1L12.3333 6L16.5 2.66667L14.8333 11H3.16667L1.5 2.66667L5.66667 6L9 1Z"
        stroke={`url(#paint0_linear_${disabled ? '4078_2348' : '4070_2340'})`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4070_2340"
          x1="9"
          y1="1"
          x2="9"
          y2="11"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECC551" />
          <stop offset="1" stopColor="#D4B149" />
        </linearGradient>

        <linearGradient
          id="paint0_linear_4078_2348"
          x1="9"
          y1="1"
          x2="9"
          y2="11"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1D5DB" />
          <stop offset="1" stopColor="#9CA3AF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Premium;
