import React from "react";

export default function Heading(props) {
  const { size, fontWeight } = props;
  switch (size) {
    case "h1":
      return <h1 className="heading_lg">{props.text}</h1>;
    case "h2":
      return (
        <h2 className="tw-text-textDark md:tw-text-2xl tw-text-lg tw-font-bold tw-mb-4">
          {props.text}
        </h2>
      );
    case "h3":
      return (
        <h3 className="tw-text-dark tw-text-base tw-font-bold">{props.text}</h3>
      );
    case "h4":
      return (
        <h4 className="tw-text-textDark tw-text-lg tw-font-bold">
          {props.text}
        </h4>
      );
    case "h5":
      return (
        <h5
          className={`tw-font-${fontWeight} sm:tw-text-sm tw-text-lg tw-text-dark`}
        >
          {props.text}
        </h5>
      );
    case "h6":
      return (
        <h6
          className={`sm:tw-text-sm tw-text-lg tw-text-dark tw-font-${fontWeight} leading-[unset]`}
        >
          {props.text}
        </h6>
      );
    case "p":
      return <p className="heading_xs font-semibold">{props.text}</p>;

    default:
      return <h1>{props.text}</h1>;
  }
}
