import React, { useState } from 'react';

const CloudDropdown = ({ onClose }) => {
  const [domains, setDomains] = useState([
    { name: 'Archives (zip, gz etc)', checked: false },
    { name: 'Images (png, jpeg etc)', checked: false },
    { name: 'Documents (txt, pdf, word etc)', checked: false },
    { name: 'Databases (SQL etc)', checked: false },
    { name: 'Code (python, perl etc)', checked: false },
    { name: 'Security (ssh, pub etc)', checked: false },
    { name: 'Media (mp3, wav etc)', checked: false },
    { name: 'General formats (json, xml etc)', checked: false },
    { name: 'Unknown', checked: false },
  ]);

  const handleCheckboxChange = (index) => {
    const newDomains = [...domains];
    newDomains[index].checked = !newDomains[index].checked;
    setDomains(newDomains);
  };

  return (
    <div className="tw-max-w-xs tw-bg-white tw-rounded-lg tw-shadow-md tw-p-4">
      <h4 className="tw-text-lg tw-font-medium tw-mb-2">File Types</h4>
      <div className="tw-h-70 tw-overflow-y-auto tw-flex tw-flex-col tw-gap-2">
        {domains.map((domain, index) => (
          <label key={index} className="tw-flex tw-items-center tw-mb-2">
            <input
              type="checkbox"
              checked={domain.checked}
              onChange={() => handleCheckboxChange(index)}
              className="tw-mr-2 tw-h-5 tw-w-5 tw-text-black tw-accent-black"
            />
            <span className="tw-text-sm tw-text-gray-700">{domain.name}</span>
          </label>
        ))}
      </div>

      {/* Action buttons */}
      <div className="tw-flex tw-gap-4 tw-mt-4">
        <button
          className="tw-bg-black tw-text-white tw-px-4 tw-py-2 tw-rounded-md"
          onClick={onClose}
        >
          Export
        </button>
        <button
          className="tw-border tw-text-gray-800 tw-px-4 tw-py-2 tw-rounded-md"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CloudDropdown;
