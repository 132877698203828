import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'common/components/Modal/Modal';
import ReadMore from 'common/components/ReadMore/ReadMore';
import BellYellow from 'common/Icons/BellYellow';
import UserIcon from 'common/Icons/UserIcon';
import CustomButton from 'common/components/Button/Button';
import Heading from 'common/components/heading/Heading';
import ExportButton from 'common/components/Export/ExportButton';

import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';

import { search as searchSocialNetwork } from 'store/slices/search/live/socialNetwork';

const SocialNetwroks = ({ setSearch, selectedOptionLabel, searchTerm }) => {
  const dispatch = useDispatch();

  const { list, size, currentPage, totalElements, totalPages, loading } =
    useSelector((state) => state.search.live.socialNetwork);

  const setCurrentPage = (page) => {
    dispatch(
      searchSocialNetwork({
        page: page - 1,
      }),
    );
  };

  
  const columns = [
    {
      Header: 'User',
      accessor: 'userName',
      render: (_, record) => {
        return (
          <div className="tw-flex tw-flex-col tw-gap-1">
            <a href={record.userProfileLink} target="_blank">
              <span className="tw-whitespace-nowrap tw-text-[#00B6FF]">
                {record.userName || record.userAlias}
              </span>
            </a>
            <span>{record.createdAt}</span>
            {record.userImageLink === 'self' || !record.userImageLink ? (
              <UserIcon />
            ) : (
              <div>
                <img
                  src={record.userImageLink}
                  className="tw-rounded-[49px] tw-w-[40px] tw-h-[40px]"
                  alt=""
                />
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: 'Details',
      accessor: 'post',
      render: (_, record) => {
        return (
          <>
            <div className="tw-flex tw-gap-1 tw-mb-[10px]">
              {record.type
                .toLowerCase()
                .split(',')
                .map((keyword, index) => {
                  return (
                    <span
                      className="tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]"
                      key={index}
                    >
                      {keyword}
                    </span>
                  );
                })}
            </div>
            <ReadMore text={record.post} size={200} />
            <p className="tw-mt-[10px] tw-text-[#00B6FF]">{record.link}</p>
          </>
        );
      },
    },
    {
      Header: 'Sources',
      accessor: 'type',
      render: (value) => (
        <span className=" tw-text-[#44464A]">{value.toLowerCase()}</span>
      ),
    },
    {
      Header: 'Languages',
      accessor: 'cvssScore',
      render: (value) => {
        if (value <= 2.5) {
          return (
            <div className="tw-flex tw-gap-1 tw-items-center">
              <p className="tw-text-[16px]">&#x1F642;</p> {/* &#x1F60A; */}
              <span className="tw-whitespace-nowrap tw-bg-green-50 tw-border tw-border-green-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-green-700">
                {value.toFixed(1)}
              </span>
            </div>
          );
        } else if (value <= 5.0) {
          return (
            <div className="tw-flex tw-gap-1 tw-items-center">
              <p className="tw-text-[16px]">&#x1F610;</p>
              <span className="tw-whitespace-nowrap tw-bg-amber-50 tw-border tw-border-amber-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-amber-700">
                {value.toFixed(1)}
              </span>
            </div>
          );
        } else if (value <= 7.5) {
          return (
            <div className="tw-flex tw-gap-1 tw-items-center">
              <p className="tw-text-[16px]">&#x1F641;</p>
              <span className="tw-whitespace-nowrap tw-bg-red-50 tw-border tw-border-red-400 tw-rounded-full tw-py-[2px] tw-px-2 tw-text-red-700">
                {value.toFixed(1)}
              </span>
            </div>
          );
        }
      },
    },
  ];

  return (
    <>
      {selectedOptionLabel !== 'Search Everywhere' && (
        <div className="tw-flex tw-justify-between lg:tw-items-center tw-items-start lg:tw-flex-row tw-flex-col tw-bg-[#ffffff] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px]">
          <div className="tw-flex tw-items-center tw-gap-[9.45px]">
            <BellYellow />
            <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
              If you didn't find what you were looking for, try refining your
              search or click 'New search' to start again.
            </div>
          </div>

          <CustomButton
            text="New Search"
            onClick={() => setSearch(false)}
            className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
          />
        </div>
      )}

      {/* NORMAL Table */}
      <div className="tw-grid tw-grid-cols-4 tw-gap-3 tw-mt-6">
        <div
          className={
            'tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4 tw-col-span-4'
          }
        >
          <Heading size="h2" text={`${selectedOptionLabel} Search Result`} />

          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
            <div className="tw-flex tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">
                Search results
              </span>
              <span className="tw-text-sm tw-font-medium">{totalElements}</span>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">Search term</span>
              <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
            </div>
          </div>

          <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
            <div>
              <p>
                Showing leaks:{' '}
                {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
                {currentPage * size > totalElements
                  ? totalElements
                  : currentPage * size}{' '}
                of {totalElements}
              </p>
            </div>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4">
              <ExportButton />
            </div>
          </div>

          <div className="tw-relative">
            <Table
              data={list}
              columns={columns}
              selectable={true}
              // onRowSelection={handleRowSelection}
            />
            {totalPages > 1 && (
              <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
            {loading && (
              <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialNetwroks;
