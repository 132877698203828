import _ from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';

export const getList = createAsyncThunk(
  'report/export/getList',
  async ({ page, size, sort }, { getState, dispatch, rejectWithValue }) => {
    const state = getState();

    if (_.isNil(page)) page = state.report.export.currentPage - 1;
    if (_.isNil(size)) size = state.report.export.size;
    if (_.isNil(sort)) sort = state.report.export.sort;

    try {
      dispatch(setParams({ size, sort }));
      const response = await api.get('service/exports_list/', {
        params: {
          page,
          size,
          sort,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || 'Fetch export list failed');
    }
  },
);

const exportSlice = createSlice({
  name: 'export',
  initialState: {
    list: [],
    sort: 'createdAt,desc',
    size: 10,
    currentPage: 1,
    totalElements: 0,
    totalPages: 0,
    loading: false,
    error: null,
  },
  reducers: {
    setParams: (state, action) => {
      state.size = action.payload.size;
      state.sort = action.payload.sort;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getList.fulfilled, (state, action) => {
        const { hasContent, totalElements, totalPages } = action.payload;

        state.list = hasContent ? action.payload.content : [];
        state.totalElements = totalElements;
        state.totalPages = totalPages;
        state.currentPage = action.payload.number + 1;

        state.loading = false;
      })
      .addCase(getList.rejected, (state, action) => {
        state.totalElements = state.totalPages = 0;
        state.list = [];
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const { setParams } = exportSlice.actions;

export default exportSlice.reducer;
