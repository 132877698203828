import React from "react";

const Message = ({ props }) => {
  return (
    <div>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.54957 11.2501L18.2996 2.50014M9.65589 11.5235L11.846 17.1552C12.0389 17.6513 12.1354 17.8994 12.2744 17.9718C12.3949 18.0346 12.5384 18.0347 12.659 17.972C12.7981 17.8998 12.8948 17.6518 13.0884 17.1559L18.5803 3.08281C18.755 2.63516 18.8424 2.41133 18.7946 2.26831C18.7531 2.1441 18.6556 2.04663 18.5314 2.00514C18.3884 1.95736 18.1646 2.0447 17.7169 2.21939L3.64378 7.71134C3.14789 7.90486 2.89994 8.00163 2.82768 8.14071C2.76504 8.26129 2.76513 8.40483 2.82791 8.52533C2.90033 8.66433 3.14839 8.7608 3.64451 8.95373L9.27618 11.1438C9.37689 11.183 9.42724 11.2026 9.46964 11.2328C9.50722 11.2596 9.54009 11.2925 9.5669 11.3301C9.59714 11.3725 9.61672 11.4228 9.65589 11.5235Z"
          stroke="#44464A"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Message;
