import React, { useRef, useState } from "react";
import userIcon from "../../components/assets/images/ico.png";
import Mail from "../assets/svgs/Mail";
import Phone from "../assets/svgs/Phone";
import Map from "../assets/svgs/Map";
import Image from "../assets/svgs/Image";
import Upload from "../assets/svgs/Upload";

const SideCard = () => {
  const fileInputRef = useRef(null);
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-p-6 tw-gap-6 tw-bg-[#FFFFFF] tw-rounded-lg tw-w-full lg:tw-w-[270px]  lg:tw-h-[378px]">
        <div className="tw-flex tw-flex-col tw-gap-3">
          {uploadedImage ? (
            <img
              src={uploadedImage}
              alt=""
              className="tw-rounded-[100%] tw-w-[100px] tw-h-[100px]"
            />
          ) : (
            <div
              className="tw-flex tw-items-center tw-justify-center tw-rounded-[100%] tw-w-[100px] tw-h-[100px] tw-bg-[#F5F5F6] tw-border-4 tw-border-[#979BA5] tw-border-dashed tw-p-4 tw-cursor-pointer"
              onClick={handleFileClick}
            >
              <Upload />
            </div>
          )}

          <div className="tw-mb-2"> 
            <p className="tw-text-[18px] tw-font-bold">Oliver Muenchow</p>
            <p className="tw-text-[16px]">ZAPTA Technology</p>
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="file"
              ref={fileInputRef}
              className="tw-hidden"
              onChange={handleFileChange}
            />
            <button
              onClick={handleFileClick}
              className="tw-flex tw-items-center tww-px-4 tw-p-2  tw-rounded-md tw-cursor-pointer tw-gap-2 tw-bg-secondary"
            >
              <Image />
              <span className="tw-text-[#FFFFFF] tw-font-semibold">
                Change image
              </span>
            </button>
          </div>

          {/*  */}
        </div>
        <div className="tw-flex tw-flex-col tw-gap-2">
          <div className="tw-flex tw-gap-3 tw-items-center">
            <Mail />
            <p>babar.13@outlook.com</p>
          </div>
          <div className="tw-flex tw-gap-3 tw-items-center">
            <Phone />
            <p>+923070460959</p>
          </div>
          <div className="tw-flex tw-gap-3 tw-items-center">
            <Map />
            <p>45 Street Lahore, PK</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideCard;
