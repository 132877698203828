import React, { useState } from "react";

const ExportOptions = ({ onSelect }) => {
  const [selected, setSelected] = useState("CSV");
  const options = ["CSV", "JSON", "XML", "DOCX"];

  const handleOptionClick = (option) => {
    setSelected(option);
    onSelect(option);
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-1">
      {options.map((option) => (
        <button
          key={option}
          onClick={() => handleOptionClick(option)}
          className={`tw-py-2 tw-text-left tw-px-4 tw-text-sm tw-text-[#44464A] ${
            selected === option ? "tw-bg-gray-100" : ""
          } hover:tw-bg-primary_danger`}
        >
          {option}
        </button>
      ))}
    </div>
  );
};

export default ExportOptions;
