import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import InputGroup from '../../common/components/InputGroup/InputGroup';
import CustomButton from '../../common/components/Button/Button';
import MailIcon from '../../common/Icons/MailIcon';
import PasswordIcon from '../../common/Icons/PasswordIcon';
import AuthEyeIcon from '../../common/Icons/AuthEyeIcon';

import { loginUser } from '../../store/slices/auth';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { accessToken } = useSelector((state) => state.auth);

  useEffect(() => {
    if (accessToken) {
      navigate('/');
    }
  }, [accessToken, navigate]);

  const loginSchema = yup.object().shape({
    userEmail: yup
      .string()
      .email('Invalid email')
      .required('Email is required'),
    userPassword: yup.string().required('Password is required'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = (data) => {
    setLoading(true);

    const { userEmail, userPassword, rememberMe } = data;

    dispatch(
      loginUser({ email: userEmail, password: userPassword, rememberMe }),
    )
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="tw-bg-hero-pattern tw-bg-cover tw-bg-center tw-flex tw-justify-center tw-items-center tw-h-[100vh]">
      <div className="tw-bg-white tw-border tw-border-[#EDEEF2] md:tw-w-[487px] tw-rounded-lg tw-p-9 tw-flex tw-flex-col tw-gap-9 tw-m-2 md:tw-m-1">
        <div className="tw-bg-white tw-flex tw-gap-0">
          <span className="tw-text-primary tw-text-[20px] md:tw-text-[30px]">
            KAD
          </span>
          <span className="tw-text-secondary tw-text-[20px] md:tw-text-[30px]">
            UU
          </span>
        </div>
        <div>
          <div className="tw-mb-8">
            {/* <Heading size="h2" text="Login to your account!" /> */}
            <span className="tw-text-textDark tw-text-lg md:tw-leading-8 md:tw-text-[20px] tw-font-bold">
              Login to your account!
            </span>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-flex tw-flex-col tw-gap-6">
              <InputGroup
                labelText="Email Address"
                inputType="email"
                placeholder="Enter Email Address"
                inputName="userEmail"
                register={register}
                errors={errors}
                focusOnType={true}
                prefixIcon={<MailIcon />}
              />
              <InputGroup
                labelText="Password"
                inputType="password"
                placeholder="Enter Password"
                inputName="userPassword"
                register={register}
                errors={errors}
                focusOnType={true}
                prefixIcon={<PasswordIcon />}
                suffixIcon={<AuthEyeIcon />}
              />
            </div>

            <div className="tw-flex tw-items-center tw-flex-col md:tw-flex-row tw-justify-between">
              <label className="tw-flex tw-gap-2">
                <InputGroup
                  inputType="checkbox"
                  inputName="rememberMe"
                  register={register}
                  errors={errors}
                  focusOnType={true}
                  className="custom-checkbox"
                />
                <span className="tw-text-[#979BA5] tw-whitespace-nowrap">
                  Remember me
                </span>
              </label>
              <div>
                <Link
                  to={'/forgot-password'}
                  className="tw-text-[#35363A] tw-text-sm tw-underline tw-whitespace-nowrap"
                >
                  Forgot Password
                </Link>
              </div>
            </div>
          </div>
        </div>

        <CustomButton
          className="tw-text-sm tw-w-full tw-font-semibold tw-leading-6 tw-h-max tw-py-[10px] tw-px-[14px] tw-mt-2 tw-bg-secondary tw-text-white tw-rounded-lg"
          isLoading={loading}
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          Login
        </CustomButton>
      </div>
    </div>
  );
};

export default Login;
