import React, { useState } from "react";
import InputLabel from "../InputLabel/InputLabel";
import _ from "lodash";

const RadioButton = ({
  htmlFor = "",
  labelText = "",
  inputType = "text",
  placeholder = "",
  inputName = "",
  register = () => {},
  errors = {},
  value,
  onChange,
  className,
  children,
  defaultValue = "",
  checked,
  readOnly,
  bgColor,
  disabled = false,
  extraLabel = "",
  focusOnType = false,
  prefixIcon,
  suffixIcon,
}) => {
  const customBgColor = (inputType === "checkbox" || inputType === "radio") 
    ? "tw-bg-[#ECC551]" 
    : "";

  return (
    <div
      className={`tw-relative tw-m-auto tw-space-y-1 tw-inputClass`}
    >
      {labelText && (
        <InputLabel
          htmlFor={htmlFor}
          text={labelText}
          extraLabel={extraLabel}
        />
      )}
      <div className="tw-relative tw-flex tw-items-center">
        {prefixIcon && (
          <span className="tw-absolute tw-left-4">{prefixIcon}</span>
        )}
        {inputType !== "textarea" ? (
          <input
            type={inputType}
            name={inputName}
            placeholder={placeholder}
            checked={checked}
            defaultValue={defaultValue}
            value={value}
            readOnly={readOnly}
            className={`tw-outline-none ${
              inputType === "checkbox" || inputType === "radio"
                ? `${customBgColor} tw-text-text-link tw-border-gray-300 focus:tw-border-[#ECC551] focus:tw-ring focus:tw-ring-[#ECC551] focus:tw-ring-opacity-50`
                : "tw-block tw-w-full tw-px-4 tw-py-2 tw-text-text-primary tw-bg-white tw-border tw-border-border-primary tw-rounded-lg focus:tw-border-border-primary focus:tw-ring-0 focus:tw-outline-none"
            } ${prefixIcon ? "tw-pl-10" : ""} ${
              suffixIcon ? "tw-pr-10" : ""
            } ${bgColor} ${inputType === "checkbox" && "tw-rounded"} ${
              _.get(errors, inputName) ? "!tw-border-red-500" : ""
            } ${
              focusOnType ? "focus:tw-ring-2 focus:tw-bg-[#F5F5F6]" : ""
            } ${className}`}
            onChange={onChange}
            autoComplete={inputName}
            {...register(inputName)}
            disabled={disabled}
          />
        ) : (
          <textarea
            rows="6"
            name={inputName}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            className={`tw-block tw-w-full tw-px-4 py-2 tw-appearance-none tw-text-text-primary tw-bg-white tw-border tw-rounded-lg focus:tw-border-border-primary focus:tw-ring-0 focus:tw-outline-none tw-h-28 tw-resize-none ${
              _.get(errors, inputName)
                ? "tw-border-red-500"
                : "tw-border-border-primary"
            } ${focusOnType ? "focus:tw-ring-2 focus:tw-bg-[#F5F5F6]" : ""}`}
            onChange={onChange}
            autoComplete={inputName}
            {...register(inputName)}
          />
        )}
      </div>
      {children}
      {_.get(errors, inputName) && (
        <p className="tw-text-red-500 tw-text-sm">
          {_.get(errors, inputName).message}
        </p>
      )}
    </div>
  );
};

export default RadioButton;
