import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BellYellow from 'common/Icons/BellYellow';
import CustomButton from 'common/components/Button/Button';
import Heading from 'common/components/heading/Heading';
import CircularProgress from 'common/components/CircularProgressBar/CircularProgressBar';
import ExpandIcon from 'common/Icons/ExpandIcon';
import ExportButton from 'common/components/Export/ExportButton';

import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';

import { search as searchExternalSource } from 'store/slices/search/live/externalSource';

const ExternalSources = ({ setSearch, selectedOptionLabel, searchTerm }) => {
  const dispatch = useDispatch();

  const {
    list,
    size,
    currentPage,
    totalElements,
    totalPages,
    loading,
    done,
    states,
  } = useSelector((state) => state.search.live.externalSource);

  const setCurrentPage = (page) => {
    dispatch(
      searchExternalSource({
        page: page - 1,
      }),
    );
  };

  const columns = [
    {
      Header: 'Created',
      accessor: 'createdAt',
      render: (value) => (
        <span className=" tw-text-[#44464A]">{value ?? 'N/A'}</span>
      ),
    },
    {
      Header: 'Engine',
      accessor: 'engine',
      render: (value) => {
        return (
          <span className="tw-bg-[#F9FAFB] tw-border tw-border-[#EAECF0] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#344054]">
            {value}
          </span>
        );
      },
    },
    {
      Header: 'URL',
      accessor: 'url',
      render: (value) => {
        return (
          <a href={value} target="_blank">
            <span className="tw-text-[#00B6FF] tw-block tw-break-all">
              {value}
            </span>
          </a>
        );
      },
    },

    {
      Header: 'Description',
      accessor: 'description',
      render: (value) => <Description description={value} />,
    },
  ];

  return (
    <>
      {selectedOptionLabel !== 'Search Everywhere' && (
        <div className="tw-flex tw-justify-between lg:tw-items-center tw-items-start lg:tw-flex-row tw-flex-col tw-bg-[#ffffff] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px]">
          <div className="tw-flex tw-items-center tw-gap-[9.45px]">
            <BellYellow />
            <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
              If you didn't find what you were looking for, try refining your
              search or click 'New search' to start again.
            </div>
          </div>

          <CustomButton
            text="New Search"
            onClick={() => setSearch(false)}
            className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
          />
        </div>
      )}

      {/* NORMAL Table */}
      <div className="tw-grid tw-grid-cols-4 tw-gap-3 tw-mt-6">
        <div
          className={
            'tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4 tw-col-span-4'
          }
        >
          <Heading size="h2" text={`${selectedOptionLabel} Search Results`} />

          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
            <div className="tw-flex tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">
                Search results
              </span>
              <span className="tw-text-sm tw-font-medium">{totalElements}</span>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">Search term</span>
              <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
            </div>
          </div>
          {!done && (
            <div className="tw-p-6 tw-flex tw-flex-col tw-gap-6">
              <div className="tw-flex tw-justify-between tw-items-center">
                <h2 className="tw-text-base tw-font-semibold tw-text-[#263238]">
                  Progress
                </h2>
                <div className="tw-flex tw-gap-1 tw-items-center">
                  <p className="tw-text-sm tw-text-[#5B5D63] tw-font-normal">
                    All Results:
                  </p>
                  <p className="tw-text-base tw-text-[#00B6FF] tw-font-bold">
                    {totalElements}
                  </p>
                </div>
              </div>

              <div className="tw-flex tw-items-center tw-justify-center">
                <div className="tw-grid tw-grid-cols-2 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-9 tw-gap-9">
                  {states?.map((state) => (
                    <CircularProgress
                      percentage={state.progress}
                      title={state.title}
                      count={state.resultCount}
                      strokeColor={
                        state.progress == 100 ? '#22C55E' : '#ECC551'
                      }
                      percentageColor="#111111"
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
            <div>
              <p>
                Showing leaks:{' '}
                {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
                {currentPage * size > totalElements
                  ? totalElements
                  : currentPage * size}{' '}
                of {totalElements}
              </p>
            </div>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4">
              <ExportButton />
            </div>
          </div>

          <div className="tw-relative">
            <Table
              data={list}
              columns={columns}
              selectable={true}
              // onRowSelection={handleRowSelection}
            />
            {totalPages > 1 && (
              <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
            {loading && (
              <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalSources;

const Description = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className="tw-relative">
      <div className={`tw-overflow-hidden ${isExpanded ? '' : 'tw-max-h-10'}`}>
        <p
          className={`tw-line-clamp-2 tw-block tw-break-all ${
            isExpanded ? 'tw-line-clamp-none' : ''
          }`}
        >
          {description}
        </p>
      </div>
      {(description.length > 150) && !isExpanded && (
        <button
          onClick={toggleExpand}
          className="tw-absolute  tw-right-[50%] tw-flex tw-justify-center tw-items-center tw-bg-white"
        >
          <ExpandIcon />
        </button>
      )}
      {isExpanded && (
        <button
          onClick={toggleExpand}
          className="tw-absolute  tw-right-[50%] tw-flex tw-justify-center tw-items-center tw-text-secondary tw-bg-white"
        >
          Hide
        </button>
      )}
    </div>
  );
};
