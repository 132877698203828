import React from "react";

const Setting = ({ color }) => {
  return (
    <div>
      <svg
        width="19"
        height="21"
        viewBox="0 0 19 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_5041_9131"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="-1"
          y="0"
          width="21"
          height="21"
        >
          <path
            d="M0 1H19V20H0V1Z"
            fill="white"
            stroke="white"
            strokeWidth="1.6"
          />
        </mask>
        <g mask="url(#mask0_5041_9131)">
          <path
            d="M9.49949 7.58072C7.88723 7.58072 6.5802 8.88771 6.5802 10.5C6.5802 12.1123 7.88723 13.4192 9.49949 13.4192C11.1117 13.4192 12.4188 12.1123 12.4188 10.5C12.4188 8.88771 11.1117 7.58072 9.49949 7.58072ZM17.6933 8.41679L16.9029 9.06747C16.0013 9.80969 16.0013 11.1903 16.9029 11.9325L17.6933 12.5832C17.9734 12.8138 18.0457 13.2131 17.8643 13.5273L16.3036 16.2305C16.1222 16.5447 15.7402 16.6817 15.4005 16.5544L14.4418 16.1953C13.3482 15.7856 12.1526 16.4759 11.9606 17.6278L11.7923 18.6376C11.7327 18.9955 11.423 19.2578 11.0602 19.2578H7.93874C7.57596 19.2578 7.26632 18.9955 7.20668 18.6376L7.03836 17.6278C6.84639 16.4759 5.65076 15.7856 4.55719 16.1953L3.5985 16.5544C3.25873 16.6817 2.87676 16.5447 2.69533 16.2305L1.13463 13.5273C0.953235 13.2131 1.02556 12.8138 1.30566 12.5832L2.09609 11.9325C2.99766 11.1903 2.99766 9.80969 2.09609 9.06747L1.30566 8.41679C1.02556 8.18619 0.953235 7.7869 1.13463 7.47269L2.69533 4.76946C2.87676 4.45525 3.25873 4.31825 3.5985 4.44553L4.55719 4.80468C5.65076 5.21436 6.84639 4.52409 7.03836 3.37218L7.20668 2.36232C7.26632 2.00448 7.57596 1.74219 7.93874 1.74219H11.0602C11.423 1.74219 11.7327 2.00448 11.7923 2.36232L11.9606 3.37218C12.1526 4.52409 13.3482 5.21436 14.4418 4.80468L15.4005 4.44553C15.7402 4.31825 16.1222 4.45525 16.3036 4.76946L17.8643 7.47269C18.0457 7.7869 17.9734 8.18619 17.6933 8.41679Z"
            stroke={color}
            strokeWidth="1.6"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};

export default Setting;
