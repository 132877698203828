import React, { useState } from "react";
import InputLabel from "../InputLabel/InputLabel";
import _ from "lodash";
import ArrowDown from "../../Icons/ArrowDown";  // Your custom dropdown icon
import { FaSearch } from "react-icons/fa";      // Example using react-icons

const SelectGroup = ({
  htmlfor = "",
  labelText = "",
  inputName = "",
  options = [],
  register = () => {},
  errors = {},
  value,
  onChange,
  className,
  defaultValue,
  bgColor,
  disabled = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || defaultValue); // Manage selected value

  // Filter options based on search term
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectChange = (option) => {
    setSelectedValue(option.value);  // Update selected value
    onChange({ target: { name: inputName, value: option.value } });
    setIsDropdownOpen(false); // Close dropdown after selecting
  };

  return (
    <div className={`tw-relative tw-space-y-1 ${className}`}>
      <InputLabel htmlFor={htmlfor} text={labelText} />
      <div className="tw-relative tw-w-full">
        {/* Custom dropdown button */}
        <div
          onClick={handleDropdownToggle}
          className="tw-block tw-w-full tw-px-4 tw-py-2 tw-text-primary tw-bg-white tw-border tw-border-blue-500 tw-rounded-lg tw-flex tw-justify-between tw-items-center cursor-pointer"
        >
          {selectedValue ? options.find((opt) => opt.value === selectedValue)?.label : "Select Country"}
          <ArrowDown />
        </div>

        {/* Dropdown menu */}
        {isDropdownOpen && (
          <div className="tw-absolute tw-mt-2 tw-bg-white tw-shadow-lg tw-rounded-lg tw-w-full tw-z-10 tw-max-h-60 tw-overflow-y-auto">
            {/* Search input with icon */}
            <div className="tw-relative tw-px-4 tw-py-2">
              <FaSearch className="tw-absolute tw-left-6 tw-top-1/2 tw-transform -tw-translate-y-2 tw-text-gray-400" />
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className="tw-block tw-w-full tw-pl-10 tw-px-4 tw-py-2 tw-text-primary tw-border tw-rounded-md focus:tw-outline-none"
              />
            </div>

            {/* Dropdown options with flags conditionally */}
            <ul className="tw-list-none tw-py-2">
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <li
                    key={option.value}
                    onClick={() => handleSelectChange(option)}  // Updated to pass option object directly
                    className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-primary hover:tw-bg-gray-100 tw-cursor-pointer"
                  >
                    {/* Conditionally render flag image */}
                    {option.flag && (
                      <img src={option.flag} alt={`${option.label} flag`} className="tw-w-6 tw-h-6 tw-mr-2" />
                    )}
                    {option.label}
                  </li>
                ))
              ) : (
                <li className="tw-px-4 tw-py-2 tw-text-gray-500">No options found</li>
              )}
            </ul>
          </div>
        )}
      </div>

      {_.get(errors, inputName) && (
        <p className="tw-text-red-500 tw-text-sm">
          {_.get(errors, inputName).message}
        </p>
      )}
    </div>
  );
};

export default SelectGroup;
