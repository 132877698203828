import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import InputGroup from "../../../../common/components/InputGroup/InputGroup";
import DeleteIcon from "../../../../common/Icons/DeleteIcon";
import PlusIcon from "../../../../common/Icons/PlusIcon";

const TestEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({});

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <div className="">
        <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
          <InputGroup
            labelText="Test email"
            extraLabel="(enter the email recipient)"
            inputType="email"
            placeholder="email@email.com"
            inputName="testEmailRecipient"
            register={register}
            errors={errors}
            focusOnType={true}
          />
        </div>
      </div>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-items-center">
        <button
          className="tw-bg-primary tw-w-full md:tw-w-max tw-text-white tw-py-2 tw-px-4  tw-rounded-lg"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </button>
        <button
          className="tw-text-primary tw-w-full md:tw-w-max tw-bg-white tw-border tw-py-2 tw-px-4  tw-rounded-lg"
          onClick={() => setIsNewExpertRuleOpen(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default TestEmail;
