import React, { useEffect, useState, useRef } from 'react';
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi';
import usePagination from './use-pagination.hook';
import useWidth from '../../../hooks/use-width';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

function CustomTable({
  columns,
  data,
  onPageChange,
  rowsPerPage = 10,
  paginatorLabel,
  selectable = false,
  onRowSelection,
}) {
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [includeRows, setIncludeRows] = useState([]);
  const [excludeRows, setExcludeRows] = useState([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const dropdownRef = useRef(null);
  const { windowWidth } = useWidth();

  const {
    currentPage,
    totalPages,
    paginatedData,
    handlePageChange,
    getPageNumbers,
  } = usePagination(data, rowsPerPage);

  const totalElements = data.length;

  useOutsideClick(dropdownRef, () => setOpenDropdownIndex(null));

  useEffect(() => {
    if (isSelectAll) {
      setIncludeRows((prev) => [
        ...new Set([
          ...prev,
          ...data
            .map((item) => item.id)
            .filter((i) => !excludeRows.includes(i)),
        ]),
      ]);
    } else {
      setExcludeRows((prev) => [
        ...new Set([
          ...prev,
          ...data
            .map((item) => item.id)
            .filter((i) => !includeRows.includes(i)),
        ]),
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (includeRows.length === data.length && data.length > 0)
      setIsSelectAll(true);

    if (onRowSelection) onRowSelection(isSelectAll, includeRows, excludeRows);
  }, [isSelectAll, includeRows, excludeRows]);

  const handleSelectRow = (e, id) => {
    if (e.target.checked) {
      setIncludeRows((prev) => {
        return [...prev, id];
      });

      setExcludeRows((prev) => {
        return prev.filter((i) => i !== id);
      });
    } else {
      setIncludeRows((prev) => {
        return prev.filter((i) => i !== id);
      });

      setExcludeRows((prev) => {
        return [...prev, id];
      });
    }
  };

  const handleSelectAll = (e) => {
    setIsSelectAll(e.target.checked);

    setIncludeRows(e.target.checked ? data.map((item) => item.id) : []);
    setExcludeRows(e.target.checked ? [] : data.map((item) => item.id));
  };

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <div className="tw-overflow-auto">
        <table className="tw-w-full">
          <thead className="tw-bg-grey">
            <tr>
              {selectable && (
                <th className="tw-text-left tw-px-4 form-group">
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={isSelectAll}
                    className="tw-w-4 tw-h-4"
                  />
                </th>
              )}
              {columns.map((column, index) => (
                <th
                  key={column.accessor ?? index}
                  className="tw-text-dark tw-text-sm tw-text-left tw-py-4 tw-px-4"
                >
                  {column.Header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedData.length === 0 ? (
              <tr>
                <td colSpan={columns.length + (selectable ? 1 : 0)}>
                  <div className="tw-text-center tw-p-10">No data found</div>
                </td>
              </tr>
            ) : (
              paginatedData.map((row, rowIndex) => (
                <tr key={rowIndex + currentPage * rowsPerPage}>
                  {selectable && (
                    <td className="tw-py-4 tw-px-4 tw-border-b tw-border-grey form-group tw-align-baseline">
                      <input
                        type="checkbox"
                        checked={includeRows.includes(row.id)}
                        onChange={(e) => handleSelectRow(e, row.id)}
                        className="tw-w-4 tw-h-4"
                      />
                    </td>
                  )}
                  {columns.map((column, index) => (
                    <td
                      key={column.accessor ?? index}
                      className="tw-py-4 tw-px-4 tw-border-b tw-border-grey tw-text-sm tw-align-baseline tw-min-w-[120px]"
                    >
                      {column.render ? (
                        column.accessor === 'state' ? (
                          <div className="tw-relative">
                            <span
                              onClick={() => toggleDropdown(rowIndex)}
                              className={`tw-cursor-pointer tw-whitespace-nowrap tw-rounded-full tw-py-[2px] tw-px-2 ${
                                row[column.accessor] === 'Closed'
                                  ? 'tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-text-[#B93815]'
                                  : 'tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-text-[#067647]'
                              }`}
                            >
                              {row[column.accessor]}
                            </span>
                            {openDropdownIndex === rowIndex && (
                              <div
                                ref={dropdownRef}
                                className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-white tw-rounded-lg tw-p-4 tw-divide-y tw-shadow-xl tw-z-10"
                              >
                                <h3 className="tw-text-black tw-font-semibold">
                                  Change Status
                                </h3>
                                <ul className="tw-flex tw-flex-col tw-pt-4 tw-mt-3 tw-space-y-3">
                                  <li
                                    className="tw-cursor-pointer tw-py-1"
                                    onClick={() => {
                                      column
                                        .render(null, row, rowIndex)
                                        .onStatusChange('Open');
                                      setOpenDropdownIndex(null);
                                    }}
                                  >
                                    <span className="tw-whitespace-nowrap tw-bg-[#EFF8FF] tw-border tw-border-[#B2DDFF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#175CD3]">
                                      Open
                                    </span>
                                  </li>
                                  <li
                                    className="tw-cursor-pointer tw-py-1"
                                    onClick={() => {
                                      column
                                        .render(null, row, rowIndex)
                                        .onStatusChange('In Progress');
                                      setOpenDropdownIndex(null);
                                    }}
                                  >
                                    <span className="tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]">
                                      In Progress
                                    </span>
                                  </li>
                                  <li
                                    className="tw-cursor-pointer tw-py-1"
                                    onClick={() => {
                                      column
                                        .render(null, row, rowIndex)
                                        .onStatusChange('Closed');
                                      setOpenDropdownIndex(null);
                                    }}
                                  >
                                    <span className="tw-whitespace-nowrap tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#ABEFC6]">
                                      Closed
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        ) : (
                          column.render(row[column.accessor], row, rowIndex)
                        )
                      ) : (
                        row[column.accessor]
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination code remains the same */}
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
        <div>
          <p className="tw-my-4">
            Showing {paginatorLabel}:{' '}
            {totalElements === 0 ? 0 : currentPage * rowsPerPage + 1} —{' '}
            {(currentPage + 1) * rowsPerPage > totalElements
              ? totalElements
              : (currentPage + 1) * rowsPerPage}{' '}
            of {totalElements}
          </p>
        </div>

        {totalElements > rowsPerPage && (
          <div className="tw-flex tw-justify-center md:tw-justify-end tw-py-2">
            <button
              onClick={() => {
                handlePageChange(currentPage - 1);
                if (onPageChange) onPageChange(currentPage - 1);
              }}
              disabled={currentPage === 0}
              className="tw-font-semibold tw-rounded-l tw-flex tw-gap-3 tw-items-center tw-px-5 tw-py-2 tw-border tw-text-black tw-border-[#EFF0F2]  tw-bg-white tw-disabled:opacity-50"
            >
              <HiOutlineArrowLeft />
              {windowWidth < 767 ? '' : 'Previous'}
            </button>
            {getPageNumbers().map((page, index) => (
              <button
                key={index}
                onClick={() => {
                  if (typeof page === 'number') {
                    handlePageChange(page - 1);
                    if (onPageChange) onPageChange(page - 1);
                  }
                }}
                className={`tw-text-black tw-font-semibold tw-px-5 tw-py-2 tw-border tw-border-l-0 tw-border-grey tw-bg-white ${
                  currentPage + 1 === page ? 'activePaginate tw-text-white' : ''
                }`}
                disabled={page === '...'}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => {
                handlePageChange(currentPage + 1);
                if (onPageChange) onPageChange(currentPage + 1);
              }}
              disabled={currentPage === totalPages - 1}
              className="tw-flex tw-px-5 tw-py-2 tw-border-l-0 tw-rounded-r tw-font-semibold tw-items-center tw-gap-4 tw-border tw-text-black tw-border-[#EFF0F2] tw-bg-white tw-disabled:opacity-50"
            >
              {windowWidth < 767 ? '' : 'Next'}

              <HiOutlineArrowRight />
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default CustomTable;
