import React from 'react';

import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi';
import useWidth from '../../../hooks/use-width';

const Paginator = ({ currentPage, totalPages, onPageChange }) => {
  const { windowWidth } = useWidth();

  const handlePageChange = (newPage) => {
    if (onPageChange) onPageChange(newPage);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pageNumbers.push(1, 2, 3, 4, '...', totalPages);
      } else if (currentPage >= totalPages - 2) {
        pageNumbers.push(
          1,
          '...',
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        );
      } else {
        pageNumbers.push(
          1,
          '...',
          currentPage - 1,
          currentPage,
          currentPage + 1,
          '...',
          totalPages,
        );
      }
    }
    return pageNumbers;
  };

  return (
    <div className="tw-flex tw-justify-center md:tw-justify-end tw-py-2">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="tw-font-semibold tw-rounded-l tw-flex tw-gap-3 tw-items-center tw-px-5 tw-py-2 tw-border tw-text-black tw-border-[#EFF0F2]  tw-bg-white tw-disabled:opacity-50"
      >
        <HiOutlineArrowLeft />
        {windowWidth < 767 ? '' : 'Previous'}
      </button>
      {getPageNumbers().map((page, index) => (
        <button
          key={index}
          onClick={() => typeof page === 'number' && handlePageChange(page)}
          className={`tw-text-black tw-font-semibold tw-px-5 tw-py-2 tw-border tw-border-l-0 tw-border-grey tw-bg-white ${
            currentPage === page ? 'activePaginate tw-text-white' : ''
          }`}
          disabled={page === '...'}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="tw-flex tw-px-5 tw-py-2 tw-border-l-0 tw-rounded-r tw-font-semibold tw-items-center tw-gap-4 tw-border tw-text-black tw-border-[#EFF0F2] tw-bg-white tw-disabled:opacity-50"
      >
        {windowWidth < 767 ? '' : 'Next'}

        <HiOutlineArrowRight />
      </button>
    </div>
  );
};

export default Paginator;
