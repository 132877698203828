import React from "react";
import ReactDOM from "react-dom";
import Cross from "../../Icons/Cross";
import CrossIcon from "../../Icons/CrossIcon";
import CustomButton from "../Button/Button";
import CrossIconLight from "../../Icons/CrossIconLight";

const Modal = ({ isOpen, title, onClose, children, className }) => {
  const handleClose = () => {
    onClose && onClose();
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      {/* Semi-transparent black overlay */}
      <div
        className="tw-fixed tw-inset-0 tw-z-10 tw-bg-[#01020C] tw-opacity-[70%]"
        onClick={handleClose}
      ></div>
      {/* Modal */}
      <div
        className={`tw-fixed tw-inset-0 tw-z-10 tw-flex tw-items-center tw-justify-center`}
      >
        <div
          className={`tw-flex tw-flex-col tw-primary-scroll tw-bg-white tw-round-shadow tw-py-4 tw-max-h-[80vh] tw-overflow-y-auto tw-rounded-xl ${className}`}
        >
          <div className="tw-flex tw-justify-between tw-items-center tw-px-4 tw-h-[62px] tw-border-b tw-border-solid tw-border-[#EFF0F2]">
            <div className="tw-text-xl tw-font-medium tw-leading-[29.97px] tw-mb-[13px]">
              {title}
            </div>
            {onClose && (
              <div onClick={onClose} className="tw-cursor-pointer tw-mb-3 ">
                <CrossIconLight />
              </div>
            )}
          </div>
          <div>{children}</div>
        </div>
      </div>
    </>,
    document.getElementById("modal-root")
  );
};

export default Modal;
