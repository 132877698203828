import React from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import Heading from "../../../common/components/heading/Heading";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";
import CustomSelect from "../../../common/components/Select/select";
import GenericDropdown from "../../../common/components/DropDown/GenericDrop";

const AlertsHistoryChart = () => {
 
  const chartOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    stroke: {
      curve: "smooth",
      width: 4,
    },
    fill: {
      type: "solid",
      colors: ["#ECC551"],
    },
    colors: ["#ECC551"],
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
    },
    
  };

  const chartSeries = [
    {
      name: "Risk Score",
      data: [40, 60, 75, 60, 80, 95, 85],
    },
  ];

  const handleSelect = (option) => {
    console.log("", option);
  };

  return (
    <>
      <div className="tw-bg-white tw-rounded-lg  tw-px-4 tw-pt-4">
        <div className="tw-flex tw-justify-between tw-items-center">
          <Heading size="h3" text="Alerts History" />
          <GenericDropdown
            options={options}
            // onSelect={handleSelect}
            defaultText="Last 30 Days"
            staticFirstOption={true}
          />
        </div>
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={350}
        />
      </div>
    </>
  );
};

export default AlertsHistoryChart;
const options = ["Date Range", "Last Year", "Last 6 Month", "All Time"];
