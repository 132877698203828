import React, { useEffect, useRef, useState } from 'react';
import DropdownArrow from '../../Icons/dropdownArrow';
import CustomButton from '../Button/Button';

const CustomDropdownMulti = ({
  label,
  options,
  placeholder = '',
  name = '',
  setValue,
  value,
  onChange,
  disabled = false,
  className = 'tw-w-full',
  dropdownArrow = <DropdownArrow />,
  defaultOptions = [],
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [appliedOptions, setAppliedOptions] = useState([]);
  const [dropdownPosition, setDropdownPosition] = useState('bottom');

  useEffect(() => {
    if (defaultOptions.length > 0) setAppliedOptions(defaultOptions);
  }, [defaultOptions]);

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown);
    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, []);

  const toggleSelectAll = () => {
    if (selectedOptions.length === options.length) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(options);
    }
  };

  const toggleSelectionSingle = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option],
    );
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const dropdownElement = dropdownRef.current;
    if (!dropdownElement) return;

    const { bottom } = dropdownElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // Check if there is enough space below the dropdown, otherwise set position to "top"
    if (bottom + 200 > windowHeight) {
      setDropdownPosition('top');
    } else {
      setDropdownPosition('bottom');
    }
  }, [isOpen]);

  const handleToggleDropdown = (e) => {
    e.stopPropagation();

    setSelectedOptions(appliedOptions);

    !disabled && setIsOpen(!isOpen);
  };

  const handleApplyClick = () => {
    setIsOpen(false);

    setAppliedOptions(selectedOptions);
    onChange && onChange(selectedOptions);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  return (
    <div
      className={`tw-text-left tw-w-full  ${
        dropdownPosition === 'top' ? 'tw-bottom-0' : ''
      }`}
      ref={dropdownRef}
    >
      <div className="tw-relative tw-w-full tw-flex tw-items-start tw-gap-2 tw-flex-col">
        {label && (
          <div className="tw-whitespace-nowrap tw-text-sm tw-text-black tw-font-normal tw-leading-[17.5px]">
            {label}
          </div>
        )}
        <div
          className={`tw-flex tw-justify-between tw-px-4 tw-items-center tw-w-fit tw-transition tw-ease-in-out tw-duration-150 ${
            disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'
          } ${className}`}
          onClick={handleToggleDropdown}
        >
          <span className="tw-max-w-40 tw-truncate tw-me-2">
            {value ||
              (appliedOptions.length > 0
                ? appliedOptions.map((o) => o.front_name).join(', ')
                : null) ||
              placeholder}
          </span>
          <span>{dropdownArrow}</span>
        </div>
        {isOpen && (
          <div
            className={`tw-flex tw-flex-col tw-absolute tw-z-10 tw-py-2 tw-gap-2 ${
              dropdownPosition === 'top' ? 'tw-bottom-12' : 'tw-top-[100%]'
            } tw-left-0 tw-mt-2 tw-min-w-56 tw-max-w-auto tw-shadow-lg tw-bg-[#F8FAFB] tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-y-auto tw-max-h-100`}
          >
            <div className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-[#374151] tw-text-left tw-px-4">
              Choose Forum
            </div>
            <div className="tw-flex tw-items-center tw-border tw-border-gray-300 tw-px-4">
              <input
                type="checkbox"
                checked={selectedOptions.length === options.length}
                onChange={toggleSelectAll}
                className="tw-mr-2 custom-checkbox"
              />
              <span className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#44464A] tw-py-2">
                Select All
              </span>
            </div>
            <div className="tw-flex tw-flex-col tw-max-h-48 tw-overflow-y-auto tw-gap-3 tw-px-4">
              {options.map((option) => (
                <div key={option.id} className="tw-flex tw-items-center">
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option)}
                    onChange={() => toggleSelectionSingle(option)}
                    className="tw-mr-2 custom-checkbox"
                  />
                  <span className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#44464A]">
                    {option.front_name}
                  </span>
                </div>
              ))}
            </div>
            <div className="tw-flex tw-px-4 tw-gap-2 tw-mt-2">
              <CustomButton
                onClick={handleApplyClick}
                text="Apply"
                className="tw-text-sm tw-font-semibold tw-leading-6 tw-rounded-xl tw-bg-[#0B0B0B] tw-text-white tw-px-[14px] tw-py-[6px]"
              />
              <CustomButton
                onClick={() => setIsOpen(false)}
                text="Cancel"
                className="tw-text-sm tw-font-semibold tw-leading-6 tw-rounded-xl tw-bg-[#ffffff] tw-text-black tw-px-[14px] tw-py-[6px] tw-border tw-border-solid tw-border-[#DFE0E3]"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropdownMulti;
