import ServerPorts from '../../assets/svgs/ServerPorts.svg';
import ReportedServers from '../../assets/svgs/ReportedServers.svg';
import UnusualServers from '../../assets/svgs/UnusualServers.svg';

export const EXPOSE_ATTACK = [
  {
    id: 1,
    title: 'Servers With Unusual Ports',
    result: '38',
    icon: ServerPorts,
  },
  {
    id: 2,
    title: 'Servers With Reported Vulns',
    result: '13',
    icon: ReportedServers,
  },
  {
    id: 3,
    title: 'Servers With Unusual Names',
    result: '17',
    icon: UnusualServers,
  },
];
