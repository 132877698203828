import React from "react";

const Chip = ({
  children,
  className = "",
  shadow = "sm",
}) => {
  return (
    <div
      className={`tw-text-xs shadow-${shadow} tw-rounded-full tw-px-4 tw-py-[5px] ${className}`}
    >
      {children}
    </div>
  );
};

export default Chip;
