import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import env from 'config';
import { useErrorbar } from 'utils/snackbar';
import { logout } from '../../actions';

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password, rememberMe }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${env.API_BASE_URL}/settings/login/`, {
        email,
        password,
      });

      return {
        accessToken: response.data.access,
        refreshToken: rememberMe ? response.data.refresh : null,
      };
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        return rejectWithValue('Network connnection failed');
      } else {
        return rejectWithValue(error.response.data || 'Login failed');
      }
    }
  },
);

export const refreshAccessToken = createAsyncThunk(
  'auth/refreshToken',
  async (refreshToken, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${env.API_BASE_URL}/token/refresh/`, {
        refresh: refreshToken,
      });
      return response.data.access;
    } catch (error) {
      return rejectWithValue(error.response.data || 'Token refresh failed');
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {})
      .addCase(loginUser.fulfilled, (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.isAuthenticated = true;
      })
      .addCase(refreshAccessToken.fulfilled, (state, action) => {
        state.accessToken = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        useErrorbar(action.payload);
      })
      .addCase(refreshAccessToken.rejected, (state, action) => {})
      .addCase(logout, (state) => {
        state.accessToken = null;
        state.refreshToken = null;
        state.isAuthenticated = false;
      });
  },
});

export default authSlice.reducer;
