import React from 'react';

export default function CustomTabs({ children, active, onTabActived }) {
  return (
    <>
      <div className="tabs tw-overflow-auto md:tw-overflow-hidden">
        <div className="tab-list tw-w-fit tw-flex tw-gap-4 tw-flex-nowrap md:tw-flex-wrap">
          {children.map((child, index) => (
            <Tab
              key={index.toString()}
              label={child.props.label}
              onClick={() => {
                if (onTabActived) onTabActived(child.props.id);
              }}
              isActive={child.props.id === active}
              disabled={child.props.disabled}
            />
          ))}
        </div>
      </div>

      {children?.length && (
        <div className="tab-content">
          {children.map((child) => (child.props.id === active ? child : null))}
        </div>
      )}
    </>
  );
}

const Tab = ({ label, onClick, isActive, disabled }) => {
  return (
    <button
      className={`tab ${
        disabled
          ? 'tw-text-[#CBCBCB] md:tw-text-base tw-pb-3 defaultTab !tw-border-gray-300 tw-whitespace-nowrap tw-text-sm'
          : isActive
            ? 'tw-font-bold tw-pb-3 tabActive tw-whitespace-nowrap tw-text-sm md:tw-text-base'
            : 'tw-text-[#0B0B0B] md:tw-text-base tw-pb-3 defaultTab tw-whitespace-nowrap tw-text-sm'
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
