import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import CustomButton from '../../common/components/Button/Button';
import InputGroup from '../../common/components/InputGroup/InputGroup';
import MailIcon from '../../common/Icons/MailIcon';
import Heading from '../../common/components/heading/Heading';
import PasswordIcon from '../../common/Icons/PasswordIcon';
import AuthEyeIcon from '../../common/Icons/AuthEyeIcon';

const ForgotPassword = () => {
  const [code, setCode] = useState(Array(6).fill(''));
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  // Define the validation schema using Yup
  const emailSchema = yup.object().shape({
    userEmail: yup
      .string()
      .email('Invalid email')
      .required('Email is required')
      .max(255, 'Email must be at most 255 characters long')
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format'),
  });

  const codeSchema = yup.object().shape({
    code: yup
      .string()
      .matches(/^\d{6}$/, 'Code must be exactly 6 digits')
      .required('Code is required'),
  });

  const passwordSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must contain at least 8 characters')
      .max(20, 'Password can have a maximum of 20 characters')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'Password must contain at least one numeric character')
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        'Password must contain at least one special character',
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
      .required('Please confirm your password'),
  });

  // Select the appropriate schema based on the step
  const validationSchema =
    step === 1 ? emailSchema : step === 2 ? codeSchema : passwordSchema;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      userEmail: '',
      code: '',
      newPassword: '',
      confirmPassword: '',
    },
  });
  const inputRefs = useRef([]);

  const handleEmailSubmit = (data) => {
    console.log(data);
    setStep(2);
  };
  const handlePasswordSubmit = (data) => {
    console.log(data);
    // Handle password reset
    navigate('/login');
  };

  const handleCodeSubmit = (data) => {
    // Now handle form submission logic
    console.log(data.code);
    alert('Code verified!');
    setStep(3);
  };

  const handleCodeChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === '') {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      } else if (!value && index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };
  // Function to check if all elements in the array are non-empty
  const isCodeValid = (code) => {
    return code.length === 6 && code.every((digit) => digit.trim() !== '');
  };

  if (isCodeValid(code)) {
    const jointCode = code.join('');
    setValue('code', jointCode);
  }

  const password = watch('newPassword');

  const passwordRequirements = [
    {
      text: 'Password must contain min 8 characters',
      valid: password.length >= 8,
    },
    {
      text: 'Password can have max 20 characters',
      valid: password.length <= 20,
    },
    {
      text: 'Password must contain a special character',
      valid: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    },
    {
      text: 'Password must have at least 1 numeric character',
      valid: /[0-9]/.test(password),
    },
    {
      text: 'Password must have at least 1 lower-case and 1 upper-case alphabet',
      valid: /[a-z]/.test(password) && /[A-Z]/.test(password),
    },
  ];

  return (
    <div className="tw-bg-hero-pattern tw-bg-cover tw-bg-center tw-flex tw-justify-center tw-items-center md:tw-min-h-[100vh]">
      <div className="tw-bg-white tw-border tw-border-[#EDEEF2] md:tw-w-[487px] tw-rounded-lg tw-p-9 tw-flex tw-max-h-[550px] tw-overflow-y-auto tw-flex-col tw-gap-9 tw-m-2 md:tw-m-1">
        <div className="tw-bg-white tw-flex tw-gap-0">
          <span className="tw-text-primary tw-text-[20px] md:tw-text-[30px]">
            KAD
          </span>
          <span className="tw-text-secondary tw-text-[20px] md:tw-text-[30px]">
            UU
          </span>
        </div>
        <div>
          <div className="tw-mb-8 tw-flex tw-flex-col tw-gap-1">
            <span className="tw-text-textDark tw-text-lg md:tw-leading-8 md:tw-text-[20px] tw-font-bold">
              Forgot Password
            </span>
            <p className="tw-text-sm tw-text-[#44464A]">
              Account Recovery: Reset your password to regain access to your
              Venue account. Enter your email for further instructions.
            </p>
          </div>
          {step === 1 ? (
            <div className="tw-flex tw-flex-col tw-gap-4">
              <div className="tw-flex tw-flex-col tw-gap-6">
                <InputGroup
                  labelText="Email Address"
                  inputType="email"
                  placeholder="Enter Email Address"
                  inputName="userEmail"
                  register={register}
                  errors={errors}
                  focusOnType={true}
                  prefixIcon={<MailIcon />}
                />
              </div>
              <CustomButton
                className="tw-text-sm tw-w-full tw-font-semibold tw-leading-6 tw-h-max tw-py-[10px] tw-px-[14px] tw-mt-2 tw-bg-secondary tw-text-white tw-rounded-lg"
                onClick={handleSubmit(handleEmailSubmit)}
              >
                Confirm
              </CustomButton>
            </div>
          ) : step === 2 ? (
            <>
              <div className="tw-mb-8">
                <Heading level={3} className="tw-text-center">
                  Enter the 6-digit code sent to your email
                </Heading>
                <div className="tw-flex tw-gap-2 tw-justify-center tw-items-center">
                  {code.map((digit, index) => (
                    <React.Fragment key={index}>
                      <input
                        type="text"
                        maxLength="1"
                        value={digit}
                        placeholder="0"
                        ref={(el) => (inputRefs.current[index] = el)}
                        onChange={(e) => handleCodeChange(e, index)}
                        className="tw-w-[33px] tw-h-[44px] md:tw-w-[53px] md:tw-h-[64px] tw-text-2xl tw-text-center tw-border tw-border-gray-300 tw-rounded"
                      />
                      {index === 2 && (
                        <span className="tw-text-6xl tw-px-2 tw-text-[#DFE0E3] tw-text-center">
                          -
                        </span>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <CustomButton
                className="tw-text-sm tw-w-full tw-font-semibold tw-leading-6 tw-h-max tw-py-[10px] tw-px-[14px] tw-mt-2 tw-bg-secondary tw-text-white tw-rounded-lg"
                onClick={handleSubmit(handleCodeSubmit)}
              >
                Confirm
              </CustomButton>
            </>
          ) : (
            <div className="">
              <div className="tw-flex tw-flex-col tw-gap-8">
                <InputGroup
                  labelText="New Password"
                  inputType="password"
                  placeholder="Enter New Password"
                  inputName="newPassword"
                  register={register}
                  errors={errors}
                  focusOnType={true}
                  prefixIcon={<PasswordIcon />}
                  suffixIcon={<AuthEyeIcon />}
                />

                <InputGroup
                  labelText="Confirm Password"
                  inputType="password"
                  placeholder="Confirm New Password"
                  inputName="confirmPassword"
                  register={register}
                  errors={errors}
                  focusOnType={true}
                  prefixIcon={<PasswordIcon />}
                  suffixIcon={<AuthEyeIcon />}
                />
              </div>

              <div className="tw-my-4">
                <ul className="tw-text-xs tw-list-disc tw-ml-4">
                  {passwordRequirements.map((requirement, index) => (
                    <li
                      key={index}
                      className={`${
                        requirement.valid
                          ? 'tw-text-[#22C55E]'
                          : 'tw-text-red-800'
                      }`}
                    >
                      {requirement.text}
                    </li>
                  ))}
                </ul>
              </div>

              <CustomButton
                className="tw-text-sm tw-w-full tw-font-semibold tw-leading-6 tw-h-max tw-py-[10px] tw-px-[14px] tw-mt-2 tw-bg-secondary tw-text-white tw-rounded-lg"
                onClick={handleSubmit(handlePasswordSubmit)}
              >
                Confirm
              </CustomButton>
            </div>
          )}
        </div>

        <div className="tw-flex tw-items-center tw-justify-center">
          <div>
            <span className="tw-text-sm tw-text-[#44464A]">
              Remember Password?
            </span>
            <Link
              to="/login"
              className="tw-text-sm tw-font-bold tw-text-[#44464A] tw-ml-2 tw-underline"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
