import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BellYellow from '../../../common/Icons/BellYellow';
import CustomButton from '../../../common/components/Button/Button';

import CustomTabs from '../../../pages/tabs';

import HackerMarket from './components/hacker/HackerMarket';
import SocialNetworks from './components/social/SocialNetworks';
import ExternalSources from './components/external/ExternalSources';
import Telegram from './components/telegram/Telegram';
import Discord from './components/discord/Discord';
import DataPastes from './components/data-pastes/DataPastes';

const SearchResult = ({ selectedOption, setSearch, searchTerm }) => {
  const navigate = useNavigate();
  const currentTab = (selectedOptionLabel) => {
    switch (selectedOptionLabel) {
      case 'Hacker Marketplaces':
        return (
          <HackerMarket
            selectedOptionLabel="Hacker Forum"
            setSearch={setSearch}
            searchTerm={searchTerm}
          />
        );
      case 'Social Networks':
        return (
          <SocialNetworks
            selectedOptionLabel="Social Media"
            setSearch={setSearch}
            searchTerm={searchTerm}
          />
        );
      case 'External Sources':
        return (
          <ExternalSources
            selectedOptionLabel="Tor and I2P Network"
            setSearch={setSearch}
            searchTerm={searchTerm}
          />
        );
      case 'Telegram':
        return (
          <Telegram
            selectedOptionLabel="Telegram"
            setSearch={setSearch}
            searchTerm={searchTerm}
          />
        );
      case 'Discord':
        return (
          <Discord
            selectedOptionLabel="Discord"
            setSearch={setSearch}
            searchTerm={searchTerm}
          />
        );
      case 'Paste Live Search':
        return (
          <DataPastes
            selectedOptionLabel="Paste"
            setSearch={setSearch}
            searchTerm={searchTerm}
          />
        );

      default:
        return 'ABC';
    }
  };

  return (
    <>
      {selectedOption.label !== 'Search Everywhere' ? (
        currentTab(selectedOption.label)
      ) : (
        <>
          <div className="tw-flex tw-justify-between lg:tw-items-center tw-items-start lg:tw-flex-row tw-flex-col tw-bg-[#ffffff] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px]">
            <div className="tw-flex tw-items-center tw-gap-[9.45px]">
              <BellYellow />
              <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
                If you didn&apos;t find what you were looking for, try refining
                your search or click &lsquo;New search&rsquo; to start again.
              </div>
            </div>
            <CustomButton
              text="New Search"
              onClick={() => {
                setSearch(false);
                navigate('/darknet-search');
              }}
              className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
            />
          </div>

          <div className="tabs-sec ">
            <CustomTabs>
              <div label="Hacker forum live search">
                <HackerMarket
                  selectedOptionLabel="Hacker Forum"
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Social media">
                <SocialNetworks
                  selectedOptionLabel="Social Media"
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Tor and I2P network">
                <ExternalSources
                  selectedOptionLabel="Tor and I2P network"
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Paste Live Search">
                <DataPastes
                  selectedOptionLabel="Paste"
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Telegram Live Search">
                <Telegram
                  selectedOptionLabel="Telegram"
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Discord Live Search">
                <Discord
                  selectedOptionLabel="Discord"
                  searchTerm={searchTerm}
                />
              </div>
            </CustomTabs>
          </div>
        </>
      )}
    </>
  );
};

export default SearchResult;
