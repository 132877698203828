import React, { useState, useEffect } from 'react';

import CustomButton from 'common/components/Button/Button';

import Step1 from './components/search-wizard/Step1';
import Step2 from './components/search-wizard/Step2';
import Step3 from './components/search-wizard/Step3';
import Step4 from './components/search-wizard/Step4';

import { CrossBlackIcon, SearchIcon } from 'common/Icons';

const SearchComponent = ({
  wizardStep,
  onWizardStepChange,
  onSearchTypeChange,
  onSearch,
  defaultOption,
  loading,
}) => {
  const [searchType, setSearchType] = useState(null);
  const [conditions, setConditions] = useState([]);
  const [subquery, setSubquery] = useState([]);
  const [additionalFilters, setAdditionalFilters] = useState([]);
  const [visibleSearchButton, showSearchButton] = useState(false);

  const addSearchType = (searchType) => {
    setConditions([{ searchType }]);
  };

  const addSearchTerm = (searchTerm) => {
    setConditions([
      {
        ...conditions[0],
        field: searchType.main,
        term: searchTerm,
      },
    ]);
  };

  useEffect(() => {
    if (conditions.length > 0) {
      setConditions([conditions[0], ...subquery, ...additionalFilters]);
    }
  }, [subquery, additionalFilters]);

  const buildQuery = () => {
    return conditions
      .filter((cond) => cond.field && cond.term)
      .map(
        (cond, idx) =>
          `${cond.filterMode ?? ''} ${cond.field.name ? cond.field.name + ':' : ''}${cond.field.type === 'daterange' && cond.term.indexOf(' TO ') > -1 ? '[' + cond.term + ']' : cond.field.type === 'text' && cond.term.indexOf(' ') > -1 ? '\"' + cond.term.toLowerCase() + '\"': cond.term.toLowerCase()}`,
      )
      .join(' ');
  };

  const handleSearch = async () => {
    if (conditions.length > 0) {
      const query = buildQuery();
      onSearch &&
        onSearch({
          searchType,
          searchTerm: query.trim(),
          searchCondition: conditions,
        });
    }
  };

  return (
    <>
      {wizardStep === 0 ? (
        <Step1
          onApply={(searchType) => {
            setSearchType(searchType);
            addSearchType(searchType);

            onSearchTypeChange && onSearchTypeChange(searchType);
            onWizardStepChange(wizardStep + 1);
          }}
        />
      ) : wizardStep === 1 ? (
        <Step2
          searchType={searchType}
          onApply={(searchTerm) => {
            addSearchTerm(searchTerm);

            onWizardStepChange(wizardStep + 1);
          }}
        />
      ) : wizardStep === 2 ? (
        <Step3
          searchType={searchType}
          onApply={(subquery) => {
            setSubquery(subquery);

            onWizardStepChange(wizardStep + 1);
          }}
        />
      ) : wizardStep === 3 ? (
        <Step4
          searchType={searchType}
          onApply={(filters) => {
            setAdditionalFilters(filters);
          }}
          setSearchButton={showSearchButton}
        />
      ) : null}

      {conditions.length > 0 && (
        <div className="tw-p-4 tw-rounded-xl tw-bg-[#ffffff] tw-w-full">
          <div className="tw-flex tw-gap-4 tw-justify-between tw-w-full">
            <div className="tw-flex tw-gap-4">
              <div
                className={
                  'tw-text-[#0B0B0B] tw-text-base tw-font-semibold tw-whitespace-nowrap tw-leading-[26px]' +
                  (wizardStep > 1 ? ' tw-pt-2' : '')
                }
              >
                Your search
              </div>
              <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-3">
                {conditions.map((condition, index) => {
                  return (
                    <div className="tw-flex tw-gap-x-3" key={index}>
                      {condition.filterMode && (
                        <div
                          className={`tw-flex tw-gap-3 tw-px-2 tw-py-[5px] tw-items-center tw-bg-dark tw-rounded-full`}
                        >
                          <div className="tw-text-xs tw-text-white tw-font-medium tw-leading-[18px]">
                            {condition.filterMode}
                          </div>
                        </div>
                      )}
                      <div className="tw-flex tw-rounded-full tw-bg-[#ECC551] tw-h-[28px]">
                        <div className="tw-flex tw-px-2 tw-py-[5px] tw-items-center">
                          <div className="tw-text-xs tw-font-medium tw-leading-[18px]">
                            {condition.searchType?.label}
                          </div>
                          {/* {index > 0 && (
                            <div
                              onClick={() =>
                                step3Ref.current.removeFilter(index - 1)
                              }
                            >
                              <CrossBlackIcon />
                            </div>
                          )} */}
                        </div>
                        {condition.field ? (
                          condition.field.label ? (
                            <>
                              <div
                                className={
                                  `tw-flex tw-px-2 tw-py-[5px] tw-items-center ` +
                                  (condition.field
                                    ? 'tw-bg-dark'
                                    : 'tw-bg-white') +
                                  (condition.term
                                    ? ' tw-rounded-[100px_0px_0px_100px]'
                                    : ' tw-rounded-full')
                                }
                              >
                                <div className="tw-text-xs tw-text-white tw-font-medium tw-leading-[18px] tw-min-w-2">
                                  {condition.field?.label}
                                </div>
                              </div>
                              <div
                                className={`tw-flex tw-px-2 tw-py-[5px] tw-items-center tw-bg-white tw-rounded-[0px_100px_100px_0px] tw-border tw-border-primary`}
                              >
                                <div className="tw-text-xs tw-font-medium tw-leading-[18px]">
                                  {condition.term}
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              className={`tw-flex tw-px-2 tw-py-[5px] tw-items-center tw-bg-white tw-rounded-full tw-border tw-border-primary`}
                            >
                              <div className="tw-text-xs tw-font-medium tw-leading-[18px]">
                                {condition.term}
                              </div>
                            </div>
                          )
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {visibleSearchButton && (
              <CustomButton
                text="Search"
                onClick={handleSearch}
                isLoading={loading}
                disabled={loading}
                icon={<SearchIcon fill="#ffffff" />}
                className="tw-h-[40px]"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SearchComponent;
