import React, { useRef, useState } from 'react';
import ArrowBack from '../../../common/Icons/ArrowBack';
import { AlertIcon, Export } from '../../../common/Icons';
import CustomButton from '../../../common/components/Button/Button';
import InputGroup from '../../../common/components/InputGroup/InputGroup';
import { useForm } from 'react-hook-form';
import SelectGroup from '../../../common/components/SelectDropdown/SelectDropdown';
import DownloadIcon from '../../assets/svgs/DownloadIcon';
import ServerPorts from '../../../common/Icons/ServerPorts';
import ServerReported from '../../../common/Icons/ServerReported';
import ServerNames from '../../../common/Icons/ServerNames';
import Table from '../../common/table/table.component';
import ScanIcon from '../../../common/Icons/ScanIcon';
import { EXPOSE_ATTACK } from './infrastructureConstants';
import Results from '../../ransomware-feed-component/components/results/results.component';
import ExportOptions from '../../../common/components/Export/ExportOptions';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import ExportButton from '../../../common/components/Export/ExportButton';

const InfrastructureExposureResults = ({ setInfrastructureResultView }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const [exportDropdown, setExportDropdown] = useState(false);

  const exportDropdownRef = useRef(null);
  useOutsideClick(exportDropdownRef, () => setExportDropdown(false));
  const toggleExport = (event) => {
    event.stopPropagation(); // Prevent the click from triggering the outside click handler
    setExportDropdown((prev) => !prev); // Toggle the dropdown open state
  };
  const onSubmit = (data) => {
    console.log(data);
    onClose();
  };

  const columns = [
    { Header: 'Domain', accessor: 'domain' },
    { Header: 'IP Address', accessor: 'ipAddress' },
    {
      Header: 'Matadata',
      accessor: 'matadata',
      render: (_, record) => {
        return (
          <>
            <p className="tw-text-[#44464A] tw-text-xs">
              <span className="tw-font-bold">Reverse DNS</span>:{' '}
              {record.matadata.ReverseDNS}
            </p>
            <p className="tw-text-[#44464A] tw-text-xs">
              <span className="tw-font-bold">AS</span>: {record.matadata.AS}
            </p>
            <p className="tw-text-[#44464A] tw-text-xs">
              <span className="tw-font-bold">Provicer</span>:{' '}
              {record.matadata.Provicer}
            </p>
            <p className="tw-text-[#44464A] tw-text-xs">
              <span className="tw-font-bold">Country</span>:{' '}
              {record.matadata.Country}
            </p>
          </>
        );
      },
    },
    {
      Header: 'Shodan/Zoomeye',
      accessor: 'zoomeye',
      render: (_, record) => {
        return (
          <div>
            <span className="tw-font-bold tw-text-[#44464A] tw-text-xs">
              HotsNames :
            </span>
            {record.zoomeye.Hostnames.map((item, i) => {
              return <p className="tw-text-[#44464A] tw-text-xs">{item}</p>;
            })}
            <p className="tw-text-[#44464A] tw-text-xs">
              <span className="tw-font-bold">IP</span>: {record.zoomeye.ip}
            </p>
            <p className="tw-text-[#44464A] tw-text-xs">
              <span className="tw-font-bold">Port</span>: {record.zoomeye.port}
            </p>
          </div>
        );
      },
    },
  ];

  const data = [
    {
      domain: 'Examplewebsite.com',
      ipAddress: '188.92.53.225',
      matadata: {
        ReverseDNS: ' xyz',
        AS: ' WISSCOM',
        Provicer: 'Swisscom Ltd',
        Country: 'Switzerlan',
      },
      zoomeye: {
        Hostnames: [
          'tb-server-ebics.blkb.ch',
          'tb-server-ebics.blkb.ch',
          'tb-server-ebics.blkb.ch',
        ],
        ip: '188.92.53.225',
        port: '443',
      },
    },
    {
      domain: 'azeR.com',
      ipAddress: '188.92.53.225',
      matadata: {
        ReverseDNS: ' xyz',
        AS: ' WISSCOM',
        Provicer: 'Swisscom Ltd',
        Country: 'Switzerlan',
      },
      zoomeye: {
        Hostnames: ['tb-server-ebics.blkb.ch'],
        ip: '188.92.53.225',
        port: '443',
      },
    },
    {
      domain: 'WeWillRockYou.rock',
      ipAddress: '188.92.53.225',
      matadata: {
        ReverseDNS: ' xyz',
        AS: ' WISSCOM',
        Provicer: 'Swisscom Ltd',
        Country: 'Switzerlan',
      },
      zoomeye: {
        Hostnames: ['tb-server-ebics.blkb.ch'],
        ip: '188.92.53.225',
        port: '443',
      },
    },
    {
      domain: 'MyWayOrTheHighway.com',
      ipAddress: '188.92.53.225',
      matadata: {
        ReverseDNS: ' xyz',
        AS: ' WISSCOM',
        Provicer: 'Swisscom Ltd',
        Country: 'Switzerlan',
      },
      zoomeye: {
        Hostnames: ['tb-server-ebics.blkb.ch'],
        ip: '188.92.53.225',
        port: '443',
      },
    },
  ];
  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <div className="tw-flex tw-flex-col tw-gap-3">
        <div className="tw-flex tw-gap-2">
          <div
            onClick={() => setInfrastructureResultView(false)}
            className="tw-cursor-pointer"
          >
            <ArrowBack />
          </div>
          <span className="tw-font-semibold">Infrastructure Exposure</span>
        </div>

        <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
          <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
            <div className="tw-flex tw-items-center tw-gap-[9.45px]">
              <ScanIcon />
              <span className="tw-text-sm tw-font-semibold  tw-text-[#0B0B0B]">
                New Scan
              </span>
              <p>Start a new infrastructure exposure scan</p>
            </div>
            <div
              onClick={() => {
                // setInfrastructureResultView(true);
              }}
              className="tw-flex tw-gap-6"
            >
              <div>
                <InputGroup
                  inputType="text"
                  placeholder="Domain.com"
                  inputName="domain"
                  register={register}
                  errors={errors}
                  focusOnType={true}
                />
              </div>
              <CustomButton
                text="Scan"
                className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] md:tw-min-w-[106px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border  tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
              />
            </div>
          </div>
        </div>

        <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
          <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
            <div className="tw-flex tw-items-center tw-gap-[9.45px]">
              <AlertIcon />
              <span className="tw-text-sm tw-font-semibold  tw-text-[#0B0B0B]">
                Setup Alerts
              </span>
              <p>Receive alerts when a new server appears</p>
            </div>
            <div
              onClick={() => {
                // setInfrastructureResultView(true);
              }}
            >
              <CustomButton
                text="Setup Alerts"
                className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border  tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-6">
        <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
          <div className="tw-flex tw-items-center tw-gap-[9.45px]">
            <span className="tw-text-lg  tw-font-bold  tw-text-[#0B0B0B]">
              Infrastructure Exposure
            </span>
            <div className="">
              <SelectGroup
                htmlfor="Subject"
                inputName="subject"
                register={register}
                errors={errors}
                options={[
                  { value: '', label: 'Kaddu.com' },
                  {
                    value: 'Hard_to find_sound_settings',
                    label: 'Hard to find sound settings',
                  },
                  { value: 'RESTAURANT', label: 'Device does not power up' },
                  {
                    value: 'RETAIL_SHOP',
                    label: 'Sound is all fuzzy using bluetooth',
                  },
                ]}
                onChange={() => {}}
                placeholder="Select Subject"
                className="tw-w-2/4 tw-border-none"
                isLoading={false}
              />
            </div>
          </div>
          <div
            onClick={() => {
              // setInfrastructureResultView(true);
            }}
            className="tw-flex tw-gap-6"
          >
            <div>
              <InputGroup
                inputType="text"
                placeholder="Search"
                inputName="search"
                register={register}
                errors={errors}
                focusOnType={true}
              />
            </div>

            <ExportButton />
          </div>
        </div>

        <Results data={EXPOSE_ATTACK} />
        <Table data={data} columns={columns} selectable={true} />
      </div>
    </div>
  );
};

export default InfrastructureExposureResults;
