import { useState } from "react";
import { OPTIONS } from "./components/company-search/company.constant";

export const useFeed = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const filteredOptions = OPTIONS.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  return {
    selectedOption,
    toggleDropdown,
    handleSearchChange,
    handleOptionClick,
    filteredOptions,
    isOpen,
    searchTerm,
  };
};
