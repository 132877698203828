import * as Yup from 'yup';

export const Assets = [
  {
    id: 1,
    label: 'Domain',
    value: 'Domain',
  },
  { id: 2, label: 'Ip Address', value: 'Ip Address' },
  { id: 3, label: 'E-Mail', value: 'E-Mail' },
  { id: 4, label: 'Name', value: 'Name' },
];

export const Operators = [
  { id: 1, label: 'AND', unary: false },
  { id: 2, label: 'OR', unary: false },
  { id: 3, label: 'NOT', unary: true },
  { id: 4, label: 'AND NOT', unary: false },
  { id: 5, label: 'OR NOT', unary: false },
];

export const FilterMode = [
  { id: 1, label: 'Ignore results containing', operator: 'AND NOT' },
  { id: 2, label: 'Results must contain', operator: 'AND' },
];

export const DatabaseSearchOptions = [
  {
    id: 1,
    label: 'Domain',
    placeholder: 'example.com',
    validation: Yup.string()
      .required('Domain cannot be empty')
      .test(
        'no-protocol',
        'Domain must not contain protocols like http:// or https://',
        (value) => {
          if (!value) return true; // Skip empty case (handled by .required).
          return !/^https?:\/\//i.test(value);
        },
      )
      .test(
        'no-subdomain',
        'Domain must not contain subdomains (e.g., example.com, example.com.uk)',
        (value) => {
          if (!value) return true;
          const parts = value.split('.');
          if (parts.length <= 3) {
            const ccTLDs = ['.af', '.al', '.dz', '.ad', '.ao', '.ar', '.am', '.au', '.at', '.az', '.bs', '.bh', '.bd', '.by', '.be', '.bz', '.bj', '.bt', '.bo', '.ba', '.bw', '.br', '.bn', '.bg', '.bf', '.bi', '.kh', '.cm', '.ca', '.cv', '.cf', '.td', '.cl', '.cn', '.co', '.km', '.cg', '.cd', '.cr', '.hr', '.cu', '.cy', '.cz', '.dk', '.dj', '.dm', '.do', '.ec', '.eg', '.sv', '.ee', '.sz', '.et', '.fj', '.fi', '.fr', '.ga', '.gm', '.ge', '.de', '.gh', '.gr', '.gd', '.gt', '.gn', '.gy', '.ht', '.hn', '.hk', '.hu', '.is', '.in', '.id', '.ir', '.iq', '.ie', '.il', '.it', '.jm', '.jp', '.jo', '.kz', '.ke', '.kp', '.kr', '.kw', '.kg', '.la', '.lv', '.lb', '.ls', '.lr', '.ly', '.li', '.lt', '.lu', '.mg', '.mw', '.my', '.mv', '.ml', '.mt', '.mx', '.md', '.mc', '.mn', '.me', '.ma', '.mz', '.mm', '.na', '.np', '.nl', '.nz', '.ni', '.ne', '.ng', '.no', '.om', '.pk', '.pa', '.pg', '.py', '.pe', '.ph', '.pl', '.pt', '.qa', '.ro', '.ru', '.rw', '.sa', '.sn', '.rs', '.sc', '.sg', '.sk', '.si', '.za', '.es', '.lk', '.sd', '.se', '.ch', '.sy', '.tw', '.tz', '.th', '.tr', '.ug', '.ua', '.ae', '.uk', '.us', '.uy', '.uz', '.ve', '.vn', '.ye', '.zm', '.zw'];

            if (parts.length == 3) {
              const ccTLD = parts[2];
              return ccTLDs.includes(`.${ccTLD}`);
            }
            return true;
          }
          return false;
        },
      )
      .test('valid-structure', 'Invalid Domain format', (value) => {
        if (!value) return true;
        const parts = value.split('.');
        return parts.length === 2 || parts.length === 3;
      })
      .test(
        'valid-domain-name',
        'Domain name must only contain letters, numbers, or hyphens and must not start or end with a hyphen',
        (value) => {
          if (!value) return true;
          const domainName = value.split('.')[0];
          return (
            /^[a-zA-Z0-9-]+$/.test(domainName) &&
            !domainName.startsWith('-') &&
            !domainName.endsWith('-')
          );
        },
      )
      .test(
        'valid-tld',
        'TLD must only contain letters and be at least two characters long',
        (value) => {
          if (!value) return true;
          const parts = value.split('.');
          const tld = parts[parts.length - 1];
          return /^[a-zA-Z]{2,}$/.test(tld);
        },
      )
      .test(
        'valid-sld',
        'SLD must only contain letters and be at least two characters long (if present)',
        (value) => {
          if (!value) return true;
          const parts = value.split('.');
          if (parts.length === 3) {
            const sld = parts[1];
            return /^[a-zA-Z]{2,}$/.test(sld);
          }
          return true; // No SLD is valid
        },
      ),
    // validation: Yup.string()
    //   .required('Domain cannot be empty')
    //   .max(253)
    //   .matches(
    //     /^(?!https?:\/\/)[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2})?$/, // Domain validation
    //     'Invalid domain format',
    //   ),
  },
  {
    id: 2,
    label: 'Email',
    placeholder: 'john@example.com',
    validation: Yup.string()
      .required('Email address cannot be empty')
      .email('Please enter a valid email address'),
  },
  {
    id: 3,
    label: 'Name',
    placeholder: 'John Doe',
    validation: Yup.string()
      .required('Name cannot be empty')
      .max(253)
      .matches(
        /^[A-Za-z]+(?:\s[A-Za-z]+)?$/, // First name only, Last name only, Full name (first and last)
        // /^[A-Za-z]{3,}\s[A-Za-z]{3,}$/, // Full name validation
        'Please enter a valid name (first name only, last name only or full name)',
      ),
  },
  {
    id: 4,
    label: 'IP Address & Range',
    placeholder: '212.10.22.13',
    validation: Yup.string()
      .required('IP address cannot be empty')
      .matches(
        /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3}|\*)(\/\d{1,2})?$/, // IP address & range validation
        'Invalid IP address & range format',
      ),
  },
  {
    id: 5,
    label: 'Credit Card',
    placeholder: '543210',
    validation: Yup.string()
      .required('Credit card number cannot be empty')
      .matches(
        /^\d{4,}$/, // Credit card number(partial)
        'Invalid credit card number. It must be part of the card number.',
      ),
  },
  {
    id: 6,
    label: 'Brand',
    placeholder: 'louis vuitton',
    validation: Yup.string()
      .required('Brand cannot be empty')
      .matches(
        /^[A-Za-z0-9]+(?:\s[A-Za-z0-9]+)?$/, // Brand is 1 or 2 words
        'Brand can be one or two words.',
      ),
  },
  {
    id: 7,
    label: 'Password',
    placeholder: 'Hds63*sj_2 or a hash value',
    validation: Yup.string().required('Password cannot be empty'),
  },
  {
    id: 8,
    label: 'File Name',
    placeholder: 'accounts.xls',
    validation: Yup.string().required('File name cannot be empty'),
  },
  {
    id: 9,
    label: 'Host Name',
    placeholder: ' int.portal.example.io',
    validation: Yup.string()
      .required('Host name cannot be empty')
      .test(
        'no-protocol',
        'Host name must not contain protocols like http:// or https://',
        (value) => {
          if (!value) return true;
          return !/^https?:\/\//i.test(value);
        },
      )
      .matches(
        /^(?:[a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}/,
        'Host name must be a valid domain format, including subdomains.',
      ),
  },
];

export const DarknetSearchOptions = [
  {
    id: 1,
    label: 'Search Everywhere',
    value: 'Enter your keywords',
    validation: Yup.string().required('Search input cannot be empty'),
  },
  {
    id: 2,
    label: 'Hacker Marketplaces',
    value: 'Hacker Marketplaces',
    validation: Yup.string().required('Search input cannot be empty'),
  },
  {
    id: 3,
    label: 'Social Networks',
    value: 'Social Networks',
    validation: Yup.string().required('Search input cannot be empty'),
  },
  {
    id: 4,
    label: 'External Sources',
    value: 'External Sources',
    validation: Yup.string().required('Search input cannot be empty'),
  },
  {
    id: 5,
    label: 'Paste Live Search',
    value: 'Paste Live Search',
    validation: Yup.string().required('Search input cannot be empty'),
  },
  {
    id: 6,
    label: 'Telegram',
    value: 'Telegram',
    validation: Yup.string().required('Search input cannot be empty'),
  },
  {
    id: 7,
    label: 'Discord',
    value: 'Discord',
    validation: Yup.string().required('Search input cannot be empty'),
  },
];

export const forums = [
  { id: 1, title: 'Antichat' },
  { id: 2, title: 'Antimigalki' },
  { id: 3, title: 'Arbitrag' },
  { id: 4, title: 'Bhcforums' },
  { id: 5, title: 'Blackbones' },
  { id: 6, title: 'Crackingorg' },
  { id: 7, title: 'Crackingx' },
  { id: 8, title: 'Crdcrew' },
  { id: 9, title: 'Crdforum' },
  { id: 10, title: 'Dark2web' },
  { id: 11, title: 'Darkwebmafias' },
  { id: 12, title: 'DemonForums' },
  { id: 13, title: 'Exploit' },
  { id: 14, title: 'ForumTeam' },
  { id: 15, title: 'Hardtm' },
  { id: 16, title: 'Leakeddatabases' },
  { id: 17, title: 'Niflheim' },
  { id: 18, title: 'Nohide' },
  { id: 19, title: 'Plumdatabases' },
  { id: 20, title: 'Russain Market' },
  { id: 21, title: 'Rstforums' },
  { id: 22, title: 'Spyhackerz' },
  { id: 23, title: 'TheJavaSea' },
  { id: 24, title: 'Vlmi' },
  { id: 25, title: 'Xss' },
  { id: 26, title: 'Zelenka' },
];

export const ManualSearchOptions = [
  {
    id: 1,
    label: 'Leaks',
    main: {
      validation: Yup.string().required('Leak content cannot be empty'),
      placeholder: 'e.g. example.com or test',
      placeholder2: 'e.g. domain1, domain2',
    },
    optionals: [
      {
        id: 1,
        type: 'dropdown',
        validation: Yup.string().required(
          'Leak file extension cannot be empty',
        ),
        label: 'Leak File Extension',
        name: 'fileExtension',
        list: [
          { id: 1, label: 'TEXT', value: 'txt' },
          { id: 2, label: 'CSV', value: 'csv' },
          { id: 3, label: 'SQL', value: 'sql' },
          { id: 4, label: 'JSON', value: 'json' },
          { id: 5, label: 'XLS', value: 'xls' },
          { id: 6, label: 'XLSX', value: 'xlsx' },
          { id: 7, label: 'DOC', value: 'doc' },
          { id: 8, label: 'DOCX', value: 'docx' },
          { id: 9, label: 'PPTX', value: 'pptx' },
          { id: 10, label: 'RTF', value: 'rtf' },
          { id: 11, label: 'PDF', value: 'pdf' },
          { id: 12, label: 'ODT', value: 'odt' },
          { id: 13, label: 'LOG', value: 'log' },
        ],
        placeholder: 'e.g. SQL',
      },
      {
        id: 2,
        type: 'daterange',
        label: 'Date Range',
        name: 'createdAt',
        placeholder: 'e.g. 2024-10-20',
      },
    ],
    tip: 'A data leak refers to the unauthorized release or exposure of sentitive, confidential, or private information to an unintended or unauthorized audience',
    tip2: 'A user can input multiple items separated by comma like domain1, domain2',
    validation: Yup.string().required('Search term cannot be empty'),
  },
  {
    id: 2,
    label: 'Credit Cards',
    main: {
      validation: Yup.string()
        .required('Card number cannot be empty')
        .matches(
          /^\d{5,}[0-9*X]+$/, // Card number validation
          'Invalid Card number format. It can contain only the digits 0 through 9, character * and X. Also there must be at least 5 digits at the beginning',
        ),
      placeholder: 'e.g. 543210* or 543210*1234 or 543210XXXXXX1234',
      placeholder2: 'e.g. number1, number2',
    },
    optionals: [
      {
        id: 1,
        type: 'daterange',
        label: 'Expire Date',
        name: 'expireDate',
        placeholder: 'e.g. 2024-10-20',
      },
      {
        id: 2,
        type: 'text',
        label: 'Card Verification Value',
        name: 'cvv',
        placeholder: 'e.g. 378',
        validation: Yup.string()
          .required('CVV cannot be empty')
          .matches(
            /^[0-9]{3,4}$/, // CVV validation
            'Invalid CVV format',
          ),
      },
      {
        id: 3,
        type: 'text',
        label: 'Owner Name',
        name: 'owner',
        placeholder: 'e.g. Elon Musk',
        validation: Yup.string().required('Owner name cannot be empty'),
      },
      {
        id: 4,
        type: 'text',
        label: 'Issuer Bank Name',
        name: 'bank',
        placeholder: 'e.g. Citibank',
        validation: Yup.string().required('Bank name cannot be empty'),
      },
      {
        id: 5,
        type: 'daterange',
        label: 'Date Range',
        name: 'createdAt',
        placeholder: 'e.g. 2024-10-20',
      },
    ],
    tip: null,
    tip2: 'A user can input multiple items separated by comma like number1, number2.<br />Each item has the same input format as the previous step.',
    validation: Yup.string().required('Search term cannot be empty'),
  },
  { id: 3, label: 'URL Shorteners', value: 'URL Shorteners' },
  { id: 4, label: 'Open Cloud Storage', value: 'Open Cloud Storage' },
  {
    id: 5,
    label: 'Data Pastes',
    main: {
      validation: Yup.string().required('Paste text cannot be empty'),
      placeholder: 'e.g. test or example.com or john@domain.com',
      placeholder2: 'e.g. text1, text2',
    },
    optionals: [
      {
        id: 1,
        type: 'text',
        label: 'Paste Title',
        name: 'title',
        placeholder: 'e.g. Untitled',
        validation: Yup.string().required('Paste title cannot be empty'),
      },
      {
        id: 2,
        type: 'text',
        label: 'Paste document URL',
        name: 'url',
        placeholder: 'e.g. https://ideone.com/nFXGCN',
        validation: Yup.string()
          .required('URL cannot be empty')
          .matches(
            /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/, // URL validation
            'Invalid URL format',
          ),
      },
      {
        id: 3,
        type: 'text',
        label: 'Source ID',
        name: 'sourceId',
        placeholder: 'e.g. intergen_paste',
        validation: Yup.string().required('Source Id cannot be empty'),
      },
      {
        id: 4,
        type: 'daterange',
        label: 'Date Range',
        name: 'createdAt',
        placeholder: 'e.g. 2024-10-20',
      },
    ],
    tip: null,
    tip2: 'A user can input multiple items separated by comma like text1, text2.',
    validation: Yup.string().required('Search term cannot be empty'),
  },
  { id: 6, label: 'Fake Apps', value: 'Fake Apps' },
  { id: 7, label: 'E-Mail References', value: 'E-Mail References' },
  { id: 8, label: 'Phishing References', value: 'Phishing References' },
  {
    id: 9,
    label: 'Stealer Logs',
    main: {
      placeholder: 'e.g. test or 37903 or 1.2.3.0/24',
      placeholder2: 'e.g. text1, text2',
    },
    optionals: [
      {
        id: 1,
        type: 'text',
        label: 'Software Name',
        name: 'name',
        placeholder: 'e.g. azorult',
        validation: Yup.string().required('Software name cannot be empty'),
      },
      {
        id: 2,
        type: 'text',
        label: 'Version Number',
        name: 'version',
        placeholder: 'e.g. v3*',
        validation: Yup.string().required('Version number cannot be empty'),
      },
      {
        id: 3,
        type: 'text',
        label: 'IP Address',
        name: 'ip',
        placeholder: 'e.g. 1.2.3.4 or 1.2.3.0/24',
        validation: Yup.string()
          .required('IP address cannot be empty')
          .matches(
            /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\/([0-9]|[1-2][0-9]|3[0-2]))?$/, // IP validation
            'Invalid IP address format',
          ),
      },
      {
        id: 4,
        type: 'text',
        label: 'Autonomous system number',
        name: 'asn',
        placeholder: 'e.g. 37903',
        validation: Yup.string()
          .required('Source Id cannot be empty')
          .matches(
            /^(?:[1-9][0-9]{0,3}|[1-3][0-9]{4,6}|4[0-2][0-9]{7}|429496729[0-5])$/, // ASN validation
            'Invalid ASN format.<br />It must be an integer from 1 to 4294967295.',
          ),
      },
      {
        id: 5,
        type: 'text',
        label: 'Country of Bot Location',
        name: 'country',
        placeholder: 'e.g. CH',
        validation: Yup.string().required('Country cannot be empty'),
      },
      {
        id: 6,
        type: 'dropdown',
        validation: Yup.string().required(
          'Computer operating system cannot be empty',
        ),
        label: 'Computer Operating System',
        name: 'os',
        list: [
          { id: 1, label: 'Windows 10', value: 'windows 10' },
          { id: 2, label: 'CSV', value: 'windows 10' },
          { id: 3, label: 'SQL', value: 'windows 10' },
        ],
        placeholder: 'e.g. SQL',
      },
      {
        id: 7,
        type: 'daterange',
        label: 'Date Range',
        name: 'createdAt',
        placeholder: 'e.g. 2024-10-20',
      },
    ],
    tip: null,
    tip2: 'A user can input multiple items separated by comma like text1, text2.',
    validation: Yup.string().required('Search term cannot be empty'),
  },
  { id: 10, label: 'Data Brokers', value: 'Data Brokers' },
  {
    id: 11,
    label: 'Threat Actor Publications',
    value: 'Threat Actor Publications',
  },
  { id: 12, label: 'SSL Logs', value: 'SSL Logs' },
];
