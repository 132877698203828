import React from "react";

const Image = () => {
  return (
    <div>
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.5604 16.2733L9.05752 10.7761C9.38753 10.4461 9.55254 10.2811 9.74281 10.2193C9.91018 10.1649 10.0905 10.1649 10.2578 10.2193C10.4481 10.2811 10.6131 10.4461 10.9431 10.7761L16.4036 16.2366M11.667 11.5L14.0575 9.10948C14.3875 8.77946 14.5525 8.61445 14.7428 8.55263C14.9102 8.49825 15.0905 8.49825 15.2578 8.55263C15.4481 8.61445 15.6131 8.77946 15.9431 9.10947L18.3337 11.5M8.33366 6.5C8.33366 7.42047 7.58747 8.16667 6.66699 8.16667C5.74652 8.16667 5.00033 7.42047 5.00033 6.5C5.00033 5.57953 5.74652 4.83333 6.66699 4.83333C7.58747 4.83333 8.33366 5.57953 8.33366 6.5ZM5.66699 16.5H14.3337C15.7338 16.5 16.4339 16.5 16.9686 16.2275C17.439 15.9878 17.8215 15.6054 18.0612 15.135C18.3337 14.6002 18.3337 13.9001 18.3337 12.5V5.5C18.3337 4.09987 18.3337 3.3998 18.0612 2.86502C17.8215 2.39462 17.439 2.01217 16.9686 1.77248C16.4339 1.5 15.7338 1.5 14.3337 1.5H5.66699C4.26686 1.5 3.5668 1.5 3.03202 1.77248C2.56161 2.01217 2.17916 2.39462 1.93948 2.86502C1.66699 3.3998 1.66699 4.09987 1.66699 5.5V12.5C1.66699 13.9001 1.66699 14.6002 1.93948 15.135C2.17916 15.6054 2.56161 15.9878 3.03202 16.2275C3.5668 16.5 4.26686 16.5 5.66699 16.5Z"
          stroke="white"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Image;
