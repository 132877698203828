import React, { useState } from "react";
import Search from "../assets/svgs/Search";
import FeedbackIcon from "../assets/svgs/Feedback";
// import { InputGroup } from "../../common/components";
import Table from "../common/table/table.component";
import StatusBall from "../assets/svgs/StatusBall";
import Ratings from "../assets/svgs/Ratings";
import AddTicket from "./components/AddTicket";
import InputGroup from "../../common/components/InputGroup/InputGroup";
import Feedback from "./components/Feedback";
import { useForm } from "react-hook-form";
import Rating from "./components/Rating";

const Support = () => {
  const [isAddTicketModalOpen, setIsAddTicketModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);

  const columns = [
    { Header: "Ticket ID", accessor: "ticketID" },
    { Header: "Create Date", accessor: "createDate" },
    {
      Header: "Status",
      accessor: "status",
      render: (_, data) => {
        return (
          <span
            className={`tw-flex tw-items-center tw-gap-[7.38px] tw-font-medium tw-text-xs ${
              data.status === "Closed" ? "tw-text-red-800" : "tw-text-[#067647]"
            }`}
          >
            <StatusBall
              color={data.status === "Closed" ? "#F03A3A" : "#067647"}
            />
            <span> {data.status}</span>
          </span>
        );
      },
    },
    { Header: "Department", accessor: "department" },
  ];

  const data = [
    {
      ticketID: "55123",
      createDate: "2023-07-10",
      status: "Closed",
      department: "IT Support",
    },
    {
      ticketID: "55124",
      createDate: "2023-07-11",
      status: "Open",
      department: "HR",
    },
    {
      ticketID: "55125",
      createDate: "2023-07-12",
      status: "Closed",
      department: "Finance",
    },
    {
      ticketID: "55126",
      createDate: "2023-07-13",
      status: "Open",
      department: "Sales",
    },
    {
      ticketID: "55127",
      createDate: "2023-07-14",
      status: "Open",
      department: "Customer Service",
    },
    {
      ticketID: "55128",
      createDate: "2023-07-15",
      status: "Open",
      department: "IT Support",
    },
    {
      ticketID: "55129",
      createDate: "2023-07-16",
      status: "Closed",
      department: "HR",
    },
    {
      ticketID: "55130",
      createDate: "2023-07-17",
      status: "Closed",
      department: "Finance",
    },
    {
      ticketID: "55131",
      createDate: "2023-07-18",
      status: "Open",
      department: "Sales",
    },
    {
      ticketID: "55132",
      createDate: "2023-07-19",
      status: "Open",
      department: "Customer Service",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    onClose();
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-6">
        {/* WIKI DIV */}
        <div className="tw-flex tw-flex-col tw-gap-4">
          <p className="tw-text-[18px] tw-font-bold">Support</p>
          <div className="tw-flex tw-items-center tw-flex-col tw-gap-3 md:tw-gap-0 md:tw-flex-row tw-justify-between tw-bg-white tw-rounded-lg tw-p-4">
            <div className="">
              <div className="tw-flex tw-gap-[5px] tw-justify-center md:tw-justify-start tw-items-center tw-mb-1">
                <Search color={"#2B2F38"} />
                <p className="tw-font-bold">WIKI</p>
              </div>
              <p className="tw-text-sm md:tw-text-base ">
                Need Detailed Technical help? Visit our WIKI, which includes a
                search feature to find answers to your questions.{" "}
                <a href="#" className="tw-font-bold">
                  Click here
                </a>{" "}
                to access the WIKI
              </p>
            </div>
            <button className="tw-px-3 tw-py-2 tw-rounded-lg tw-text-sm tw-font-semibold tw-w-full md:tw-w-max md:tw-h-max tw-border tw-border-primary">
              Access WIKI
            </button>
          </div>
        </div>
        {/* FEEDBACK DIV */}
        <div className=" tw-bg-white tw-rounded-lg tw-p-4 ">
          <div className="tw-flex tw-items-center tw-flex-col md:tw-flex-row tw-gap-3 md:tw-gap-0 tw-justify-between tw-mb-6">
            <div className="">
              <div className="tw-flex tw-justify-center md:tw-justify-start tw-gap-[5px] tw-items-center tw-mb-1">
                <FeedbackIcon />
                <p className="tw-font-bold">Tips & Feedback</p>
              </div>
              <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-2 md:tw-gap-0 tw-justify-between lg:tw-w-[676px]">
                <p className="tw-text-sm md:tw-text-base ">
                  Share your feedback to help us improve your experience
                </p>{" "}
                <div className="tw-flex tw-gap-[10px] tw-items-center">
                  <input type="checkbox" name="asAnon" id="asAnon" />{" "}
                  <p className="tw-text-sm md:tw-text-base ">
                    Send Form Anonymous
                  </p>
                </div>
              </div>
            </div>
            <button
              className="tw-px-3 tw-py-2 tw-rounded-lg tw-text-sm tw-font-semibold tw-w-full md:tw-w-max lg:tw-h-max tw-border tw-border-primary"
              onClick={() => setIsFeedbackModalOpen(true)}
            >
              Submit feedback
            </button>
          </div>
          <div>
            <InputGroup
              inputType="text"
              placeholder="Share your feeback here"
              inputName="feedback"
              register={register}
              errors={errors}
              focusOnType={true}
              className="tw-bg-[#F5F6F8]"
            />
          </div>
        </div>

        {/* Table DIV */}
        <div className="tw-bg-white tw-rounded-lg">
          <div className="tw-p-4 tw-relative">
            <div className="tw-flex tw-justify-between tw-items-center tw-pb-4">
              <h1 className="tw-text-lg tw-font-bold">Ticket List</h1>
              <button
                className="tw-bg-primary tw-text-white tw-px-3 tw-py-2 tw-rounded-lg tw-text-sm tw-font-semibold lg:tw-h-[40px] tw-borde"
                onClick={() => setIsAddTicketModalOpen(true)}
              >
                Add Ticket
              </button>
            </div>
            <Table columns={columns} data={data} selectable={true} />
            <div
              className="tw-bg-secondary tw-py-3 tw-px-4 tw-rounded-lg tw-flex tw-items-center tw-justify-between tw-gap-[10px] tw-w-max lg:tw-w-[165px] tw-absolute tw-bottom-[100px] tw-right-0 hover:tw-cursor-pointer"
              onClick={() => setIsRatingModalOpen(true)}
            >
              <Ratings />
              <p className="tw-font-medium">Share Rating</p>
            </div>
          </div>
        </div>
        <AddTicket
          isOpen={isAddTicketModalOpen}
          onClose={() => setIsAddTicketModalOpen(false)}
          title={"Add Ticket"}
        />
        <Feedback
          isOpen={isFeedbackModalOpen}
          onClose={() => setIsFeedbackModalOpen(false)}
          title={"Feedback Form"}
        />
        <Rating
          isOpen={isRatingModalOpen}
          onClose={() => setIsRatingModalOpen(false)}
          title={"Share Rating"}
        />
      </div>
    </>
  );
};

export default Support;
