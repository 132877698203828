import React from "react";

const MailIcon = () => {
  return (
    <div>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.66232 1.75576L13.681 5.0179C13.8583 5.13317 13.947 5.1908 14.0112 5.26768C14.0681 5.33574 14.1108 5.41445 14.1369 5.49922C14.1663 5.59498 14.1663 5.70073 14.1663 5.91224V10.8C14.1663 11.9201 14.1663 12.4802 13.9484 12.908C13.7566 13.2843 13.4506 13.5903 13.0743 13.7821C12.6465 14 12.0864 14 10.9663 14H4.03301C2.9129 14 2.35285 14 1.92503 13.7821C1.5487 13.5903 1.24274 13.2843 1.05099 12.908C0.833008 12.4802 0.833008 11.9201 0.833008 10.8V5.91224C0.833008 5.70073 0.833008 5.59498 0.862455 5.49922C0.888521 5.41445 0.931239 5.33574 0.988106 5.26768C1.05235 5.1908 1.14102 5.13317 1.31835 5.0179L6.33703 1.75576M8.66232 1.75576C8.24149 1.48222 8.03107 1.34545 7.80436 1.29224C7.60396 1.2452 7.39539 1.2452 7.19499 1.29224C6.96828 1.34545 6.75786 1.48222 6.33703 1.75576M8.66232 1.75576L13.4784 4.88621C13.7077 5.03524 13.8223 5.10976 13.862 5.20426C13.8967 5.28684 13.8967 5.37991 13.862 5.4625C13.8223 5.557 13.7077 5.63151 13.4784 5.78055L8.66232 8.91099C8.24149 9.18453 8.03107 9.3213 7.80436 9.37452C7.60396 9.42155 7.39539 9.42155 7.19499 9.37452C6.96828 9.3213 6.75786 9.18453 6.33703 8.91099L1.52096 5.78055C1.29168 5.63151 1.17704 5.557 1.13733 5.4625C1.10263 5.37991 1.10263 5.28684 1.13733 5.20426C1.17704 5.10976 1.29168 5.03524 1.52096 4.88621L6.33703 1.75576"
          stroke="#71747C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default MailIcon;
