import React from 'react';
import Loader from '../Loader/Loader';

const CustomButton = ({
  width = 'tw-w-fit',
  bgColor = 'tw-bg-primary',
  textColor = 'tw-text-white',
  border = 'tw-border-none',
  borderRadius = 'tw-rounded-lg',
  padding = 'tw-px-4 tw-py-3',
  fontSize = 'tw-text-base',
  fontWeight = 'tw-font-medium',
  hoverBgColor = 'secondary',
  hoverTextColor = 'white',
  focusRingColor = 'tw-ring-2 tw-ring-blue-300',
  disabled = false,
  onClick,
  text = '',
  type = 'button',
  icon,
  isLoading = false,
  iconPosition = 'before',
  className = '',
  customStyles = {},
  children,
  ...props
}) => {
  const renderContent = () => {
    return (
      <>
        {iconPosition === 'before' && (isLoading ? null : icon)}
        {isLoading ? (
          <Loader className="tw-text-white !tw-w-5 !tw-h-5 tw-mr-1" />
        ) : null}
        {text}
        {iconPosition === 'after' && (isLoading ? null : icon)}
      </>
    );
  };
  return (
    <button
      type={type}
      className={`tw-flex tw-justify-center tw-items-center focus:tw-outline-none focus:!tw-ring-0 tw-leading-none tw-transition-all tw-duration-300 ${
        icon && 'tw-gap-2 tw-shadow-sm'
      } ${width} ${bgColor} ${textColor} ${border} ${borderRadius} 
      ${padding} ${fontSize} ${fontWeight} hover:tw-bg-${hoverBgColor} hover:tw-text-${hoverTextColor} 
      focus:${focusRingColor} disabled:tw-bg-neutral-300 ${
        disabled
          ? '!tw-bg-neutral-300 !hover:tw-bg-neutral-300 !tw-cursor-not-allowed'
          : ''
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
      {...props}
      style={customStyles}
    >
      {renderContent()}
      {children}
    </button>
  );
};

export default CustomButton;

// Example usage:
// <DynamicButton
//   onClick={() => console.log('Button clicked')}
//   text="Click me"
//   disabled={true} // This button will be disabled
//   iconPosition="after" // Icon will be displayed after the text
// />
