import * as React from "react";

function ChartButton({ selected }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.25"
        width="39.5"
        height="39.5"
        rx="19.75"
        fill={selected ? "#ECC551" : "#F5F5F6"}
      />
      <rect
        x="0.25"
        y="0.25"
        width="39.5"
        height="39.5"
        rx="19.75"
        stroke={selected ? "#ECC551" : "#979BA5"}
        strokeWidth="0.5"
      />
      <path
        d="M25.6196 17.62H20.3696C19.9596 17.62 19.6196 17.28 19.6196 16.87C19.6196 16.46 19.9596 16.12 20.3696 16.12H25.6196C25.8185 16.12 26.0092 16.199 26.1499 16.3397C26.2906 16.4803 26.3696 16.6711 26.3696 16.87C26.3696 17.0689 26.2906 17.2597 26.1499 17.4003C26.0092 17.541 25.8185 17.62 25.6196 17.62ZM15.1196 18.38C14.9296 18.38 14.7396 18.31 14.5896 18.16L13.8396 17.41C13.5496 17.12 13.5496 16.64 13.8396 16.35C14.1296 16.06 14.6096 16.06 14.8996 16.35L15.1196 16.57L16.8396 14.85C17.1296 14.56 17.6096 14.56 17.8996 14.85C18.1896 15.14 18.1896 15.62 17.8996 15.91L15.6496 18.16C15.5091 18.3007 15.3184 18.3798 15.1196 18.38ZM25.6196 24.62H20.3696C19.9596 24.62 19.6196 24.28 19.6196 23.87C19.6196 23.46 19.9596 23.12 20.3696 23.12H25.6196C25.8185 23.12 26.0092 23.199 26.1499 23.3397C26.2906 23.4803 26.3696 23.6711 26.3696 23.87C26.3696 24.0689 26.2906 24.2597 26.1499 24.4003C26.0092 24.541 25.8185 24.62 25.6196 24.62ZM15.1196 25.38C14.9296 25.38 14.7396 25.31 14.5896 25.16L13.8396 24.41C13.5496 24.12 13.5496 23.64 13.8396 23.35C14.1296 23.06 14.6096 23.06 14.8996 23.35L15.1196 23.57L16.8396 21.85C17.1296 21.56 17.6096 21.56 17.8996 21.85C18.1896 22.14 18.1896 22.62 17.8996 22.91L15.6496 25.16C15.5091 25.3007 15.3184 25.3798 15.1196 25.38Z"
        fill= "#5B5D63"
      />
      <path
        d="M23 30.75H17C11.57 30.75 9.25 28.43 9.25 23V17C9.25 11.57 11.57 9.25 17 9.25H23C28.43 9.25 30.75 11.57 30.75 17V23C30.75 28.43 28.43 30.75 23 30.75ZM17 10.75C12.39 10.75 10.75 12.39 10.75 17V23C10.75 27.61 12.39 29.25 17 29.25H23C27.61 29.25 29.25 27.61 29.25 23V17C29.25 12.39 27.61 10.75 23 10.75H17Z"
        fill= "#5B5D63"
      />
    </svg>
  );
}

export default ChartButton;
