import React, { useState } from "react";

export default function QuicksTabs({ children, tabList, onTabClick }) {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <>
      <div className="tw-overflow-auto md:tw-overflow-visible tw-w-full tw-px-4">
        <div
          className={`${
            tabList
              ? "tab-list2 tw-flex tw-justify-between tw-flex-nowrap md:tw-flex-wrap"
              : "tab-list tw-flex tw-justify-between tw-flex-nowrap md:tw-flex-wrap"
          } tw-gap-4 tw-mb-4`}
        >
          {children.map((child, i) => (
            <Tab
              key={i.toString()}
              label={child.props.label}
              onClick={() => {
                setActiveIndex(i);
                onTabClick && onTabClick(i);
              }}
              isActive={i === activeIndex}
              tabList={tabList}
            />
          ))}
        </div>
      </div>

      {children?.length && (
        <div className="tab-content tw-mt-4">
          {children.map((child, i) => (i === activeIndex ? child : null))}
        </div>
      )}
    </>
  );
}

const Tab = ({ label, onClick, isActive, tabList }) => {
  return (
    <>
      {tabList ? (
        <button
          className={`tab ${
            isActive
              ? "tw-font-semibold tw-border-b-3 tw-border-blue-500 tw-pb-3 tw-py-2 tabActiveList tw-whitespace-nowrap tw-text-sm"
              : "tw-text-gray-400 tw-py-2 tw-font-medium tw-pb-3 tw-whitespace-nowrap tw-text-sm"
          }`}
          onClick={onClick}
        >
          <span className="tw-px-4">{label}</span>
        </button>
      ) : (
        <button
          className={`tab ${
            isActive
              ? "tw-font-semibold tw-border-b-2 tw-border-blue-500 tw-pb-3 tw-py-2 tabActive tw-whitespace-nowrap tw-text-sm md:tw-text-base"
              : "tw-text-gray-400 tw-py-2 tw-font-medium tw-pb-3 defaultTab tw-whitespace-nowrap tw-text-sm md:tw-text-base"
          }`}
          onClick={onClick}
        >
          <span className="tw-px-4">{label}</span>
        </button>
      )}
    </>
  );
};
