import { combineReducers } from '@reduxjs/toolkit';

import mainReducer from './main';
import databaseReducer from './database';
import liveReducer from './live';
import manualReducer from './manual';
import crawlersReducer from './crawlers';

export default combineReducers({
  main: mainReducer,
  database: databaseReducer,
  live: liveReducer,
  manual: manualReducer,
  crawlers: crawlersReducer,
});
