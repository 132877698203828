import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdSettings, MdHelpOutline, MdLogout } from 'react-icons/md';

import { logout } from 'store/actions';

import Bell from '../../assets/svgs/Bell';
import userIcon from '../../assets/images/ico.png';
import Dropdrown from '../../assets/svgs/Dropdrown';
import Notifications from '../notifications/Notifications';
import { ArrowDown } from '../../../common/Icons';

const Header = () => {
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.user);
  let profileName = `${profile.first_name ?? ''} ${profile.last_name ?? ''}`;
  if (profileName.trim() === '') profileName = 'No named';

  const [isDropdown, setIsdropdown] = useState(false);
  const navigate = useNavigate();

  const handleDropdown = () => {
    setIsdropdown(!isDropdown);
  };

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsdropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRefhere = useRef(null); // Reference to the dropdown container

  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle clicking outside of the dropdown to close it
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown if click was outside the ref element
      }
    }

    // Add when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    // Cleanup on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="tw-flex tw-justify-between tw-px-2 md:tw-px-6 tw-items-center	tw-h-[80px]">
        <div className="tw-bg-white tw-flex tw-gap-0">
          <span className="tw-text-primary tw-text-[20px] md:tw-text-[30px]">
            KAD
          </span>
          <span className="tw-text-secondary tw-text-[20px] md:tw-text-[30px]">
            UU
          </span>
        </div>
        <div className="tw-flex tw-gap-3 md:tw-gap-6 tw-items-center">
          <div
            ref={dropdownRefhere}
            className="tw-relative hover:tw-cursor-pointer"
            onClick={toggleDropdown}
          >
            <Bell />
            <div className="tw-h-3 tw-w-3 tw-rounded-[21px] tw-bg-red-800 tw-absolute -tw-top-1 tw-right-[-2px]">
              <p className="tw-text-white tw-text-[8px] tw-flex tw-justify-center tw-items-center">
                3
              </p>
            </div>
            {isOpen && (
              <div className="tw-absolute tw-right-0 tw-w-[500px] tw-mt-2 tw-bg-white tw-rounded-md tw-shadow-lg  tw-z-50">
                <Notifications />
              </div>
            )}
          </div>
          <div
            className=" tw-relative hover:tw-cursor-pointer"
            onClick={handleDropdown}
            ref={dropdownRef}
          >
            <div className="tw-bg-[#F5F6F8] tw-flex tw-items-center tw-gap-2 tw-px-2 tw-p-2 tw-my-4  tw-rounded-[100px]">
              <div className="tw-h-[22px] tw-w-[22px] md:tw-h-[32px] md:tw-w-[32px]">
                <img
                  className=" tw-rounded-[50%]"
                  src={profile.profile_img ? profile.profile_img : userIcon}
                  alt=""
                />
              </div>
              <div className="tw-flex tw-items-center tw-gap-2 tw-flex-row">
                <p className="tw-text-sm md:tw-text-base ">{profileName}</p>
                <p>
                  <ArrowDown />
                </p>
                <Dropdrown />
              </div>
            </div>
            {isDropdown && (
              <div className=" tw-mt-12  tw-top-[35px] tw-bg-white  tw-text-[#344054] tw-absolute tw-min-w-[220px] tw-w-max tw-right-0 tw-bottom-[-100px] tw-rounded-xl tw-shadow-lg tw-z-50 ">
                <div className="tw-flex tw-flex-col tw-px-4 tw-py-3 tw-bg-[#fff] tw-border tw-rounded-md">
                  {/* User Info Section */}
                  <div className="tw-flex tw-items-center tw-gap-3 tw-border-b tw-border-gray-200 tw-pb-3">
                    <img
                      src={profile.profile_img ? profile.profile_img : userIcon}
                      alt="User Avatar"
                      className="tw-rounded-full tw-w-10 tw-h-10 tw-border"
                    />
                    <div>
                      <p className="tw-font-semibold tw-text-base">
                        {profileName}
                      </p>
                      <p className="tw-text-sm tw-text-gray-500">
                        {profile.email ?? ''}
                      </p>
                    </div>
                  </div>

                  {/* Menu Options */}
                  <div
                    className="tw-flex tw-items-center tw-gap-2 tw-py-2 tw-cursor-pointer tw-hover:bg-gray-100"
                    onClick={() => navigate('/settings')}
                  >
                    <MdSettings className="tw-text-xl" />
                    <p className="tw-text-sm">Settings</p>
                  </div>
                  <div
                    className="tw-flex tw-items-center tw-gap-2 tw-py-2 tw-cursor-pointer tw-hover:bg-gray-100"
                    onClick={() => navigate('/support')}
                  >
                    <MdHelpOutline className="tw-text-lg" />
                    <p className="tw-text-sm">Support</p>
                  </div>
                  <div
                    className="tw-flex tw-items-center tw-gap-2 tw-py-2 tw-cursor-pointer tw-hover:bg-gray-100"
                    onClick={() => {
                      dispatch(logout());
                    }}
                  >
                    <MdLogout className="tw-text-lg" />
                    <p className="tw-text-sm">Logout</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
