import React, { useState } from 'react';
import ProgressBar from '../../../common/components/ProgressBar/ProgressBar';
import CustomButton from '../../../common/components/Button/Button';
import EyeYellow from '../../../common/Icons/EyeYellow';
import ArrowBack from '../../../common/Icons/ArrowBack';
import InfrastructureExposureResults from './InfrastructureExposureResults.js';

const results = [
  {
    progress: 100,
  },
  {
    progress: 100,
  },
  {
    progress: 100,
  },
  {
    progress: 100,
  },
  {
    progress: 20,
  },
  {
    progress: 100,
  },
  {
    progress: 100,
  },
];

const activity = [
  { title: 'Find Servers in Shodan' },
  { title: 'Find Servers in Zoomeye' },
  { title: 'Find Servers linked in Webpage' },
  { title: 'Find Servers in DNS Dumpsters' },
  { title: 'Find Servers via Shared Hosting' },
  { title: 'Find Servers in SSL Transparency Logs' },
  { title: 'Find Servers in IP Ranges' },
];

const ExposureInfrastructureCheck = ({
  setSearch,
  setIsInfrastructureView,
}) => {
  const [infrastructureResultView, setInfrastructureResultView] =
    useState(false);
  return (
    <>
      {infrastructureResultView ? (
        <InfrastructureExposureResults
          setInfrastructureResultView={setInfrastructureResultView}
        />
      ) : (
        <>
          <div className="tw-flex tw-flex-col tw-bg-[#ffffff] tw-rounded-lg tw-p-6 tw-gap-4">
            <div className="tw-flex tw-items-center tw-gap-2">
              <div
                onClick={() => {
                  if (setSearch) {
                    setSearch(false);
                  } else {
                    setIsInfrastructureView(false);
                  }
                }}
                className="tw-cursor-pointer"
              >
                <ArrowBack />
              </div>
              <div className="tw-text-lg tw-font-bold tw-leading-[30px] tw-text-[#263238]">
                Exposure Infrastructure Check
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-4 tw-p-6 tw-border tw-border-solid tw-border-[#EFF0F2]">
              <div className="tw-flex tw-flex-col tw-gap-1">
                <div className="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]">
                  Selected Domain
                </div>
                <div className="tw-text-sm tw-font-medium tw-leading-[16.41px] tw-text-[#0B0B0B]">
                  Example.com
                </div>
              </div>
              <div className="tw-w-full tw-flex tw-justify-between tw-gap-8">
                {' '}
                <div className="tw-w-full tw-flex tw-flex-col tw-gap-3">
                  <div className="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]">
                    Result
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-[14px]">
                    {results.map((item, index) => (
                      <div className="tw-flex tw-flex-col" key={index}>
                        <ProgressBar
                          height="tw-h-[6px]"
                          inlineProgress={true}
                          current={item.progress}
                          backgroundColor="#ECC551"
                          trackColor="tw-bg-[#EFF0F2]"
                          className="tw-text-sm tw-font-medium tw-leading-[16.41px] tw-text-[#0B0B0B]"
                          subClassName="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]"
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="tw-w-full tw-max-w-[241px]">
                  <div className="tw-mb-3 tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]">
                    Activity
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-3">
                    {activity?.map((item, index) => (
                      <div
                        key={index}
                        className="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#0B0B0B]"
                      >
                        {item.title}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-gap-4">
              <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-bg-[#FCF6E5]">
                <div className="tw-text-base tw-font-bold tw-leading-[26px] tw-text-[#0B0B0B]">
                  What is passive infrastructure monitoring?
                </div>
                <div className="tw-text-sm tw-font-normal tw-leading-5 tw-tracking-[0.02em] tw-text-[#455A64]">
                  Typosquatting involves buying domain names that closely mimic
                  legitimate websites by adding hyphens, using different
                  spellings, or substituting characters (e.g., zeros for "O" or
                  ones for "I").
                </div>
              </div>
              <div className="tw-w-full tw-max-w-[544px] tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-bg-[#FCF6E5]">
                <div className="tw-text-base tw-font-bold tw-leading-[26px] tw-text-[#0B0B0B]">
                  What do we aim to achieve with passive infrastructure
                  monitoring?
                </div>
                <div className="tw-text-sm tw-font-normal tw-leading-5 tw-tracking-[0.02em] tw-text-[#455A64]">
                  <ul className="tw-px-4 tw-list-disc">
                    <li className="">
                      Discover shadow IT by identifying infrastructure not
                      within known IP ranges.
                    </li>
                    <li>
                      Understand the attack surface from a hacker's perspective
                    </li>
                    <li>
                      Identify vulnerabilities and exposed services without
                      disrupting system operations
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-justify-end tw-items-center tw-w-full">
              <div
                onClick={() => {
                  setInfrastructureResultView(true);
                }}
              >
                <CustomButton
                  text="See Results"
                  className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border  tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ExposureInfrastructureCheck;
