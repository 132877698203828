import React from "react";

const StatusBall = ({ color }) => {
  return (
    <div>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.92773 9.75391C4.06836 9.75391 3.28356 9.54439 2.57333 9.12535C1.8631 8.69922 1.29492 8.13104 0.868786 7.42081C0.449752 6.71058 0.240234 5.92578 0.240234 5.06641C0.240234 4.19993 0.449752 3.41513 0.868786 2.712C1.29492 2.00178 1.8631 1.43714 2.57333 1.01811C3.28356 0.591974 4.06836 0.378906 4.92773 0.378906C5.79421 0.378906 6.57901 0.591974 7.28214 1.01811C7.99237 1.43714 8.557 2.00178 8.97603 2.712C9.40217 3.41513 9.61523 4.19993 9.61523 5.06641C9.61523 5.92578 9.40217 6.71058 8.97603 7.42081C8.557 8.13104 7.99237 8.69922 7.28214 9.12535C6.57901 9.54439 5.79421 9.75391 4.92773 9.75391Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default StatusBall;
