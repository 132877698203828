import React from "react";

const Danger = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99963 6.50019V9.83353M9.99963 13.1669H10.008M8.84573 2.24329L1.99166 14.0821C1.61149 14.7388 1.4214 15.0671 1.4495 15.3366C1.474 15.5716 1.59714 15.7852 1.78828 15.9242C2.00741 16.0835 2.38679 16.0835 3.14556 16.0835H16.8537C17.6125 16.0835 17.9919 16.0835 18.211 15.9242C18.4021 15.7852 18.5253 15.5716 18.5498 15.3366C18.5779 15.0671 18.3878 14.7388 18.0076 14.0821L11.1535 2.24329C10.7747 1.58899 10.5853 1.26184 10.3382 1.15196C10.1227 1.05612 9.8766 1.05612 9.66105 1.15196C9.41394 1.26184 9.22454 1.58899 8.84573 2.24329Z"
        stroke="#ECC551"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Danger;
