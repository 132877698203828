import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  DarknetSearchOptions,
  forums,
} from 'common/components/SearchComponent/data';

import ProgressBar from 'common/components/ProgressBar/ProgressBar';
import SearchComponent from './SearchComponent';
import SearchResult from './SearchResult';

import { searchActions } from 'store/actions';

import { searchRequest as searchSocialNetwork } from 'store/slices/search/live/socialNetwork';
import {
  searchRequest as searchExternalSource,
  searchRequestSimple,
} from 'store/slices/search/live/externalSource';
import { searchRequest as searchDataPaste } from 'store/slices/search/live/dataPaste';

const DarknetLiveSearch = () => {
  const dispatch = useDispatch();
  const licenseInfo = useSelector((state) => state.user.license);
  const live = useSelector((state) => state.search.live);

  const features = [
    {
      id: 2,
      premium: true,
      label: 'Hacker Forum Search',
      subLabel:
        'Locate specific keywords within hacker forums using the integrated search function',
      licenseTitle: 'Live Hacker Forum Search',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          (selectedOption.id == this.id || selectedOption.id == 1)
        );
      },
      store: null,
      searchFunc: null,
    },
    {
      id: 3,
      premium: true,
      label: 'Social Media Search',
      subLabel: 'Search for your keyword on public social media forums',
      licenseTitle: 'Social Media Spoofing Detection',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          (selectedOption.id == this.id || selectedOption.id == 1)
        );
      },
      store: live.socialNetwork,
      searchFunc: searchSocialNetwork,
    },
    {
      id: 4,
      premium: true,
      label: 'Tor and I2P Search',
      subLabel: 'Discover if your keyword appears on any Tor or I2P sites',
      errorLabel:
        "Please enter a valid domain or a person's name (last name or first name).",
      licenseTitle: 'Dark Web Live Search Engine',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          (selectedOption.id == this.id || selectedOption.id == 1)
        );
      },
      store: live.externalSource,
      get searchFunc() {
        return selectedOption.id == 1
          ? searchExternalSource
          : searchRequestSimple;
      },
    },
    {
      id: 5,
      premium: true,
      label: 'Paste Search',
      subLabel: 'Utilize Google to find indexed data on paste sites',
      errorLabel: 'Please enter a valid app name.',
      licenseTitle: 'Paste Site Monitoring',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          (selectedOption.id == this.id || selectedOption.id == 1)
        );
      },
      store: live.dataPaste,
      searchFunc: searchDataPaste,
    },
    {
      id: 6,
      premium: true,
      label: 'Telegram Search',
      subLabel: 'Look for your keyword in hacker-related Telegram channels',
      licenseTitle: 'Telegram & Discord Channel Monitoring',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          (selectedOption.id == this.id || selectedOption.id == 1)
        );
      },
      store: null,
      searchFunc: null,
    },
    {
      id: 7,
      premium: true,
      label: 'Discord Search',
      subLabel: 'Search for your keyword in Discord channels',
      licenseTitle: 'Telegram & Discord Channel Monitoring',
      get active() {
        return (
          licenseInfo.active_features.includes(this.licenseTitle) &&
          selectedOption &&
          (selectedOption.id == this.id || selectedOption.id == 1)
        );
      },
      store: null,
      searchFunc: null,
    },
  ];

  const [autoSearch, setAutoSearch] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [appliedForums, setAppliedForums] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const [showResult, setShowResult] = useState(false);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    // Parse the params
    const type = searchParams.get('type');
    const query = searchParams.get('query');
    const _appliedForums = searchParams.get('forums');

    if (type && query) {
      setSelectedOption(
        DarknetSearchOptions.find((option) => option.id == type),
      );
      setSearchTerm(query);

      if (_appliedForums)
        setAppliedForums(
          forums.filter((forum) =>
            _appliedForums.split(',').includes(forum.id.toString()),
          ),
        );

      setAutoSearch(true);
    }

    dispatch(searchActions.resetProgress());
    setShowResult(false);
  }, []);

  useEffect(() => {
    if (autoSearch) {
      handleLiveSearch({
        option: selectedOption,
        searchTerm: searchTerm,
        selectedForums: appliedForums,
      });
    }
  }, [autoSearch]);

  useEffect(() => {
    if (searching) {
      let totalResult = 0;
      let finished = true;

      features.forEach((item) => {
        if (item.active && item.store) {
          totalResult += item.store.totalElements;
          finished &= !item.store.loading;
        }
      });

      setTotalResult(totalResult);
      if (finished) {
        setSearching(false);
        setShowResult(true);
      }
    }
  }, [live]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleLiveSearch = ({ option, searchTerm, selectedForums }) => {
    setSearchTerm(searchTerm);

    setTotalResult(0);
    setSearching(true);

    features.forEach((feature) => {
      let modifiedQuery = searchTerm;

      if (feature.active) {
        if (feature.searchFunc) {
          // Search
          dispatch(feature.searchFunc({ page: 0, query: modifiedQuery }));
        }
      }
    });
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-6 tw-p-2">
      <h1 className="tw-text-xl tw-font-bold tw-leading-8 tw-text-left">
        Live Search in the deep and dark web
      </h1>

      {showResult ? (
        <SearchResult
          setSearch={setShowResult}
          selectedOption={selectedOption}
          searchTerm={searchTerm}
        />
      ) : (
        <>
          <SearchComponent
            darknet={true}
            searchTerm={searchTerm}
            onSearch={handleLiveSearch}
            onOptionChange={handleOptionChange}
            defaultOption={selectedOption}
            defaultForums={appliedForums}
            loading={searching}
          />
          <div className="tw-flex lg:tw-flex-row tw-flex-col tw-gap-6">
            <div className="tw-w-full tw-flex tw-flex-col tw-gap-4">
              <div className="tw-flex tw-justify-between tw-items-center">
                <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-left">
                  Only Premium
                </div>
                <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-left tw-hidden"></div>
              </div>
              <div className="tw-w-full tw-rounded-xl tw-bg-[#ffffff] tw-px-6 tw-pt-6">
                {features.map((item, index) => (
                  <div className="tw-flex tw-flex-col tw-mb-6" key={index}>
                    <ProgressBar
                      premium={item.premium}
                      label={item.label}
                      disabled={!item.active}
                      height="tw-h-[6px]"
                      subLabel={item.subLabel}
                      current={item.store ? item.store.progress : 0}
                      backgroundColor={
                        item.store
                          ? item.store.error
                            ? '#dc2626'
                            : '#ECC551'
                          : '#ECC551'
                      }
                      trackColor="tw-bg-[#EFF0F2]"
                      className="tw-text-sm tw-font-medium tw-leading-[16.41px] tw-text-[#0B0B0B]"
                      subClassName="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-[#5B5D63]"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DarknetLiveSearch;
