import React from "react";
import useWidth from "../../../../hooks/use-width";

export default function Results({ data }) {
  const columnWidth = 96 / data.length;
  const { windowWidth } = useWidth();
  return (
    <div className="tw-bg-white tw-rounded-lg tw-border tw-justify-between tw-border-[#EFF0F2] tw-flex-col sm:tw-flex-row tw-flex tw-py-4 tw-px-6 tw-gap-3">
      {data.map((item, i) => {
        return (
          <div
            key={i.toString()}
            className="tw-flex tw-flex-col sm:tw-flex-row md:tw-text-[unset] md:tw-items-[unset] tw-items-center md:tw-text-left tw-text-center tw-gap-4 tw-border-r-0 md:tw-border-r tw-border-[#EFF0F2] last:tw-border-r-0"
            style={{ width: windowWidth > 767 ? `${columnWidth}%` : "100%" }}
          >
            <div>
              <img src={item.icon} alt="" />
            </div>
            <div>
              <h6 className="tw-text-2xl tw-font-bold">{item.result}</h6>
              <p className="tw-text-[#5B5D63] tw-text-sm">{item.title}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
