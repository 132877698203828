import React, { useState, useRef, useEffect } from "react";
import { InfoIcon, Play, CrossBlackIcon } from "../../../common/Icons";
import CustomButton from "../../../common/components/Button/Button";
import { Cross } from "@phosphor-icons/react";

function TooltipComponent() {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const iconRef = useRef(null);

  // Function to open the first modal
  const openFirstModal = () => {
    if (iconRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      setModalPosition({
        top: rect.bottom + window.scrollY, // Position below the icon
        left: rect.left + window.scrollX, // Align horizontally with the icon
      });
    }
    setIsFirstModalOpen(true);
  };

  // Function to close the first modal
  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  // Function to open the second modal
  const openSecondModal = () => {
    setIsFirstModalOpen(false); // Close the first modal when opening second
    setIsSecondModalOpen(true);
  };

  // Function to close the second modal
  const closeSecondModal = () => {
    setIsSecondModalOpen(false);
  };

  return (
    <>
      <div className="tw-pt-4 tw-flex tw-justify-start tw-gap-1 tw-items-center">
        <p className="tw-text-base tw-font-semibold">DARK And DEEPWEB Search</p>

        {/* Click on InfoIcon to open first modal */}
        <div
          ref={iconRef}
          onClick={openFirstModal}
          className="tw-cursor-pointer"
        >
          <InfoIcon />
        </div>
      </div>

      {/* First Modal with text and Play button */}
      {isFirstModalOpen && (
        <div
          className="tw-absolute tw-bg-white tw-shadow-lg tw-rounded-lg tw-w-[673px]  tw-px-4 tw-py-2 tw-z-50 tw-mb-2"
          style={{
            top: `${modalPosition.top}px`,
            left: `${modalPosition.left}px`,
          }}
        >
          <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
            <p className="tw-font-semibold tw-mt-2 tw-text-sm">
              Database Search
            </p>
            <button
              className="tw-text-xl tw-font-semibold"
              onClick={closeFirstModal}
            >
              <CrossBlackIcon />
            </button>
          </div>
          <div>
            <p className="tw-text-sm tw-text-gray-600">
              Access indexed data updated daily through automatic crawlers.
              These crawlers collect data from structured marketplaces and
              forums. For forums that can't be indexed automatically, our
              analyst team manually gathers and adds this data to our database.
            </p>
            <p className="tw-font-semibold tw-mt-2 tw-text-sm">Live Search</p>
            <p className="tw-text-sm tw-text-gray-600">
              Conduct in-depth, real-time searches. This is limited to prevent
              account bans and IP blocking by systems like Cloudflare. Use it
              alongside database searches to find specific, current information.
            </p>
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-mt-4">
              <div>
                <p className="tw-font-semibold tw-mt-2 tw-text-sm">
                  Learn how to use the search
                </p>
                <p className="tw-text-sm tw-text-gray-600">
                  Watch this 3-minute video to learn
                </p>
              </div>
              <CustomButton
                icon={<Play />}
                className="tw-text-base tw-font-medium tw-rounded-lg tw-leading-6 tw-w-full tw-max-w-36 tw-h-10 tw-bg-black tw-text-white tw-flex tw-items-center tw-justify-center"
                onClick={openSecondModal}
              >
                Watch Demo
              </CustomButton>
            </div>
          </div>
        </div>
      )}

      {/* Second Modal with YouTube video */}
      {isSecondModalOpen && (
        <div className="tw-fixed tw-inset-0 tw-bg-black/50 tw-flex tw-items-center tw-justify-center tw-z-50">
          <div className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-w-full tw-max-w-lg tw-p-4">
            <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
              <div className="tw-bg-white tw-flex tw-gap-0">
                <span className="tw-text-primary tw-text-[20px] md:tw-text-[30px]">
                  KAD
                </span>
                <span className="tw-text-secondary tw-text-[20px] md:tw-text-[30px]">
                  UU
                </span>
              </div>{" "}
              <button
                className="tw-text-lg tw-font-semibold"
                onClick={closeSecondModal}
              >
                <CrossBlackIcon />
              </button>
            </div>
            <div className="tw-aspect-w-16 tw-aspect-h-9">
              <iframe
                width="480px"
                height="500px"
                src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TooltipComponent;
