import React from 'react';
import { MagnifyingGlass } from 'react-loader-spinner';

const Loading = ({ label }) => {
  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-1 tw-py-[180px] tw-bg-white tw-rounded-lg">
      <div>
        <MagnifyingGlass
          visible={true}
          height="80"
          width="80"
          glassColor="#c0efff"
          color="#333"
        />
      </div>
      <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-[#263238]">
        {label}
      </div>
    </div>
  );
};

export default Loading;
