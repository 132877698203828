import React from "react";
import { InfoIcon } from "../../../common/Icons";
import BenchmarkChart from "../../charts/bench_marks/bench_marks";
import GroupPercentChart from "../../charts/attack_percent/attack_percent";
import DarkNet from "../DarkNet/dark-net.component";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

function BookMark() {
  return (
    <div className="tw-p-4 tw-bg-white tw-rounded-lg tw-border">
      <div className="tw-pt-4 tw-flex tw-justify-start tw-gap-1 tw-items-center">
        <p className="tw-text-lg tw-font-semibold">Benchmark and Risk Score</p>
        <div className="my-anchor-elements">
          <InfoIcon />
        </div>
        <Tooltip
          anchorSelect=".my-anchor-elements"
          place="top-end"
          effect="solid"
          style={{
            backgroundColor: "rgba(255, 255, 255, 1)",
            color: "black",
            width: "800px",
            zIndex: "999999",
            opacity: "1",
          }}
        >
          <p>
            Create benchmarks for your organization based on industry type,
            <br></br> country, or specific competitors.<br></br>For benchmarks
            based on industry or country.we compare your <br></br> organization
            to others in our database of similar size <br></br>within the same
            country or industry.<br></br>When selecting competitors. You can
            directly compare your metrics <br></br>against a specific
            competitor's domain
          </p>
        </Tooltip>
      </div>
      <p className=" tw-flex tw-justify-start tw-text-base tw-font-normal tw-mt-1 ">
        Discover how your organization measures up against similar ones.{" "}
      </p>{" "}
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-12 tw-gap-3 tw-mt-2">
        <div className="md:tw-col-span-7">
          <BenchmarkChart />
        </div>
        <div className="md:tw-col-span-5">
          <DarkNet />
        </div>
      </div>
    </div>
  );
}

export default BookMark;
