import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import CustomDropdown from '../DropDown/CustomDropdown';
import CustomButton from '../Button/Button';
import MultiSelect from '../MultiSelect/MultiSelect'; // Import the MultiSelect component
import { ArrowDownLight, SearchIcon } from '../../Icons';
import { DatabaseSearchOptions } from './data';

const filterConfig = {
  'Credit Cards': {
    filters: [
      {
        name: 'asset',
        placeholder: 'Add Assets',
        type: 'multiSelect',
        options: ['Asset 1', 'Asset 2', 'Asset 3'],
      },
      {
        name: 'operator',
        placeholder: 'Add Operators',
        type: 'dropdown',
        options: ['AND', 'OR'],
      },
    ],
  },
  'URL Shorteners': {
    filters: [
      {
        name: 'extension',
        placeholder: 'URL Extension (xlsx, docs, pdf)',
        type: 'text',
      },
    ],
  },
  'Exposed Infrastructure': {
    filters: [],
  },
  'Manual Expert Search': {
    filters: [
      { name: 'filter1', placeholder: 'IP Address', type: 'text' },
      {
        name: 'operator',
        placeholder: 'AND/OR',
        type: 'dropdown',
        options: ['AND', 'OR'],
      },
      { name: 'filter2', placeholder: 'IP Address', type: 'text' },
    ],
  },
  Leaks: {
    filters: [
      {
        name: 'asset',
        placeholder: 'Add Assets',
        type: 'multiSelect',
        options: ['Asset 1', 'Asset 2', 'Asset 3'],
      },
      {
        name: 'operator',
        placeholder: 'Add Operators',
        type: 'dropdown',
        options: ['AND', 'OR'],
      },
    ],
  },
};

const GenericSearch = ({ options = [], redirectUrl }) => {
  const [selectedOption, setSelectedOption] = useState(
    options.length > 0 ? options[0] : null,
  );
  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedOption(options.length > 0 ? options[0] : null);
  }, [options]);

  const handleDropdownChange = (option) => {
    setSelectedOption(option);
    setErrors((prevErrors) => ({ ...prevErrors, selectedOption: '' }));
    // Reset filter values when a new option is selected
    setFilterValues({});
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, inputValue: '' }));
  };

  const handleFilterChange = (filterName, value) => {
    setFilterValues((prev) => ({
      ...prev,
      [filterName]: value,
    }));
  };

  const handleSearchClick = () => {
    const onSearch = () => {
      const queryParams = new URLSearchParams({
        type: selectedOption.id,
        query: inputValue,
        ...filterValues,
      });

      navigate(`${redirectUrl}?${queryParams.toString()}`);
    };

    Yup.object()
      .shape({
        selectedOption: Yup.object()
          .nullable()
          .required('Please select a search option.'),
        inputValue: selectedOption.validation,
      })
      .validate({ selectedOption, inputValue }, { abortEarly: false })
      .then(() => {
        setErrors({});
        onSearch();
      })
      .catch((validationErrors) => {
        const formattedErrors = {};
        validationErrors.inner.forEach((error) => {
          formattedErrors[error.path] = error.message;
        });
        setErrors(formattedErrors);
      });
  };

  // Get filters based on selectedOption
  const filters = selectedOption
    ? filterConfig[selectedOption.label]?.filters
    : [];

  return (
    <div className="graph-card tw-flex tw-bg-white tw-rounded-xl tw-py-4 tw-px-6 tw-relative">
      <div className="tw-flex tw-w-full tw-relative">
        <div className="tw-w-fit-content tw-min-w-44 !tw-bg-gray-100 tw-border tw-border-[#D9D9D9] tw-py-2 tw-rounded-tl-md tw-rounded-bl-md">
          <CustomDropdown
            placeholder="Select"
            options={options}
            onChange={handleDropdownChange}
            className="tw-w-full tw-whitespace-nowrap"
            defaultOption={selectedOption}
          />
        </div>
        <div className="tw-flex tw-w-full !tw-bg-gray-100 tw-border tw-border-[#D9D9D9] tw-rounded-br-md tw-rounded-tr-md">
          {selectedOption.label === 'Hacker Marketplaces' && (
            <div className="tw-w-full tw-max-w-40 tw-ml-2">
              <MultiSelect
                placeholder="Select Forum"
                options={DatabaseSearchOptions}
                name="host"
                background={true}
                border={true}
                dropdownArrow={<ArrowDownLight />}
                className="tw-whitespace-nowrap tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#888C95] tw-h-[28px] tw-my-2 tw-rounded tw-w-full tw-px-2 tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm"
                onChange={(option) => {
                  console.log({ FORUM: option });
                }}
              />
            </div>
          )}
          <div className="tw-flex-1 tw-gap-1 !tw-bg-gray-100 tw-flex tw-w-full tw-rounded-br-md tw-rounded-tr-md tw-overflow-hidden">
            <input
              name="search"
              type="text"
              placeholder={`Enter your ${selectedOption?.label || ''}: e.g. ${
                selectedOption?.value || ''
              }`}
              value={inputValue}
              onChange={handleInputChange}
              className="!tw-bg-gray-100 focus:tw-ring-0 focus:tw-outline-none tw-w-full tw-h-full tw-pl-3 tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-left tw-px-4 tw-py-2 !tw-border-none"
            />
          </div>
        </div>

        {filters?.length > 0 && (
          <div className="tw-flex tw-gap-4 tw-ml-3 tw-flex-1">
            {filters.map((filter) => {
              if (filter.type === 'text') {
                return (
                  <input
                    key={filter.name}
                    type="text"
                    placeholder={filter.placeholder}
                    value={filterValues[filter.name] || ''}
                    onChange={(e) =>
                      handleFilterChange(filter.name, e.target.value)
                    }
                    className="!tw-bg-gray-100 focus:tw-ring-0  tw-rounded-md tw-overflow-hidden tw-border tw-border-[#D9D9D9] focus:tw-outline-none tw-w-full tw-h-full tw-pl-3 tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-left tw-px-4 tw-py-2"
                  />
                );
              } else if (filter.type === 'dropdown') {
                return (
                  <CustomDropdown
                    key={filter.name}
                    placeholder={filter.placeholder}
                    options={filter.options.map((option) => ({
                      id: option,
                      label: option,
                      value: option,
                    }))}
                    onChange={(option) =>
                      handleFilterChange(filter.name, option.value)
                    }
                    className="tw-w-full tw-whitespace-nowrap tw-py-2 tw-border tw-rounded-md"
                  />
                );
              } else if (filter.type === 'multiSelect') {
                return (
                  <MultiSelect
                    key={filter.name}
                    options={filter.options}
                    placeholder={filter.placeholder}
                    onChange={(selectedValues) =>
                      handleFilterChange(filter.name, selectedValues)
                    }
                    value={filterValues[filter.name] || []}
                    className="tw-w-full tw-border tw-rounded-md tw-py-2"
                  />
                );
              }
              return null;
            })}
          </div>
        )}

        <CustomButton
          text="Search"
          onClick={handleSearchClick}
          icon={<SearchIcon fill="#ffffff" />}
          className="tw-py-[10px] tw-ml-3 tw-rounded-md tw-px-3 tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-left"
        />
      </div>

      {/* Error messages for dropdown and input field */}
      {(errors.selectedOption || errors.inputValue) && (
        <div className="tw-absolute tw-top-16 tw-left-56 tw-bg-red-100 tw-w-fit tw-flex tw-flex-col tw-gap-2 tw-px-2 tw-py-1 tw-rounded-md">
          {errors.selectedOption && (
            <div className="tw-text-red-500 tw-text-sm tw-font-medium">
              {errors.selectedOption}
            </div>
          )}
          {errors.inputValue && (
            <div className="tw-text-red-500 tw-text-sm tw-font-medium">
              {errors.inputValue}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GenericSearch;
