import React, { useState } from "react";
import QuicksTabs from "../../../../pages/quick-tabs";
import QuickComponent from "./quickComponent";
import { set } from "react-hook-form";

function CreateReport() {
  const quickComData = [
    {
      label:"QUICK I (3-5 mins)",
      creationTime: "2 - 3 mins",
      pageNum: 2,
      content: "Leak Analysis"
    },
    {
      label:"QUICK II (4-8 mins)",
      creationTime: "3 - 10 mins",
      pageNum: 8,
      content: "Leak Analysis"
    }, 
    {
      label: "QUICK III (10-20 mins)",
      creationTime: "20 - 45 mins",
      pageNum: 10,
      content: "Leak Analysis, Free domain sale, Call Representative, Exposed"
    }
  ];
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className="tabs-sec">
      <div className="tw-p-3">
        <p className=" tw-font-bold tw-text-lg tw-text-[#263238] ">
          Create Darknet Report
        </p>
        <p className="tw-text-[#455A64]">
          Create a darknet report within minutes by providing us domain and date
          range
        </p>
      </div>

      <QuicksTabs tabList={true} onTabClick={(i) => setTabIndex(i)} className="tw-w-full">
        {quickComData.map((data, index) => {
          return (
            <div label={data.label} key={index}>
              <QuickComponent creationTime={data.creationTime} pageNum={data.pageNum} content={data.content} tabIndex={index}/>
            </div>
          )
        } )}
        
      </QuicksTabs>
    </div>
  );
}

export default CreateReport;
