import React from "react";
import InputGroup from "../../../../common/components/InputGroup/InputGroup";
import { useForm } from "react-hook-form";
import SelectGroup from "../../../../common/components/SelectDropdown/SelectDropdown";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ProfileSettings = () => {
  const schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    organization: yup.string().required("Organization is required"),
    phone: yup.string().required("Phone is required"),
    country: yup.string().required("Country is required"),
    city: yup.string().required("City is required"),
    zipCode: yup.string().required("Zip Code is required"),
  });

  const {
    register,
    handleSubmit,
    setValue, // <-- use setValue to update form data
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Handle country change
  const handleCountryChange = (e) => {
    const { value } = e.target;
    setValue("country", value); // <-- Set country value in the form
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <>
      <div className="tw-w-[100%] tw-flex tw-flex-col tw-gap-6">
        <div className="">
          <p className="tw-text-[18px] tw-font-bold">Profile Settings</p>
        </div>
        <div className="tw-grid md:tw-grid-cols-2 tw-gap-4">
          <div className="">
            <InputGroup
              labelText="First Name"
              inputType="text"
              placeholder="First Name"
              inputName="firstName"
              register={register}
              errors={errors}
              focusOnType={true}
            />
          </div>
          <div className="">
            {" "}
            <InputGroup
              labelText="Last Name"
              inputType="text"
              placeholder="Last Name"
              inputName="lastName"
              register={register}
              errors={errors}
              focusOnType={true}
            />
          </div>
          <div className="">
            {" "}
            <InputGroup
              labelText="Email"
              inputType="email"
              placeholder="Email"
              inputName="email"
              register={register}
              errors={errors}
              focusOnType={true}
            />
          </div>
          <div className="">
            {" "}
            <InputGroup
              labelText="Organization"
              inputType="text"
              placeholder="Organization"
              inputName="organization"
              register={register}
              errors={errors}
              focusOnType={true}
            />
          </div>
          <div className="">
            {" "}
            <InputGroup
              labelText="Phone"
              inputType="text"
              placeholder="Phone"
              inputName="phone"
              register={register}
              errors={errors}
              focusOnType={true}
            />
          </div>
          <div className="">
            {" "}
            <SelectGroup
              htmlfor="Country"
              labelText="Country"
              inputName="country"
              register={register}
              errors={errors}
              options={[
                {
                  value: "AF",
                  label: "Afghanistan (+93)",
                  flag: "/CountrySix.png",
                },
                {
                  value: "AL",
                  label: "Albania (+355)",
                  flag: "/CountryFive.png",
                },
                {
                  value: "DZ",
                  label: "Algeria (+213)",
                  flag: "/CountryFour.png",
                },
                {
                  value: "AS",
                  label: "American Samoa (+1-684)",
                  flag: "/CountrySeven.png",
                },
                // Add more countries here
              ]}
              onChange={handleCountryChange} // <-- Use the handler
            />
          </div>
          <div className="">
            {" "}
            <SelectGroup
              htmlfor="City"
              labelText="City"
              inputName="city"
              register={register}
              errors={errors}
              options={[
                { value: "", label: "Select City" },
                { value: "COFFEE_SHOP", label: "Dubai" },
                { value: "RESTAURANT", label: "USA" },
                { value: "RETAIL_SHOP", label: "Bangkok" },
              ]}
              onChange={() => {}} // Optional placeholder
              placeholder="Select"
              className="tw-w-full"
              isLoading={false}
            />
          </div>
          <div className="">
            <InputGroup
              labelText="Zip Code"
              inputType="text"
              placeholder="Zip Code"
              inputName="zipCode"
              register={register}
              errors={errors}
              focusOnType={true}
            />
          </div>
        </div>
        <div className="tw-flex md:tw-justify-end ">
          <button
            className="tw-bg-primary tw-text-white tw-p-3 tw-rounded-lg tw-w-full md:tw-w-max"
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
};

export default ProfileSettings;
