import { createSlice } from '@reduxjs/toolkit';

export const SEARCH_MODE = {
  DATABASE: 'database',
  LIVE: 'live',
  MANUAL: 'manual',
};

const mainSlice = createSlice({
  name: 'main',
  initialState: {
    searchMode: SEARCH_MODE.DATABASE,
    selectedOptionId: 0,
    lastSearchOptionId: -1,
    query: '',
  },
  reducers: {
    setSearchMode: (state, action) => {
      state.searchMode = action.payload;
    },
    setSelectedOptionId: (state, action) => {
      state.selectedOptionId = action.payload;
    },
    setLastSearchOptionId: (state, action) => {
      state.lastSearchOptionId = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
  },
});

export const { setSearchMode, setSelectedOptionId, setLastSearchOptionId, setQuery } = mainSlice.actions;

export default mainSlice.reducer;
