import React from "react";

const Upload = () => {
  return (
    <div>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5833 3.50065H9.1C7.13982 3.50065 6.15972 3.50065 5.41103 3.88213C4.75247 4.21768 4.21703 4.75312 3.88148 5.41168C3.5 6.16038 3.5 7.14047 3.5 9.10065V18.9007C3.5 20.8608 3.5 21.8409 3.88148 22.5896C4.21703 23.2482 4.75247 23.7836 5.41103 24.1192C6.15972 24.5007 7.13982 24.5007 9.1 24.5007H19.8333C20.9183 24.5007 21.4608 24.5007 21.9059 24.3814C23.1137 24.0578 24.0571 23.1143 24.3807 21.9065C24.5 21.4614 24.5 20.919 24.5 19.834M22.1667 9.33398V2.33398M18.6667 5.83398H25.6667M12.25 9.91732C12.25 11.206 11.2053 12.2507 9.91667 12.2507C8.628 12.2507 7.58333 11.206 7.58333 9.91732C7.58333 8.62865 8.628 7.58398 9.91667 7.58398C11.2053 7.58398 12.25 8.62865 12.25 9.91732ZM17.4884 13.9052L7.61967 22.8767C7.06459 23.3813 6.78705 23.6336 6.7625 23.8522C6.74122 24.0417 6.81386 24.2296 6.95705 24.3554C7.12224 24.5007 7.49733 24.5007 8.2475 24.5007H19.1986C20.8777 24.5007 21.7172 24.5007 22.3766 24.2186C23.2043 23.8645 23.8638 23.205 24.2179 22.3772C24.5 21.7178 24.5 20.8783 24.5 19.1993C24.5 18.6344 24.5 18.3519 24.4382 18.0888C24.3606 17.7582 24.2118 17.4486 24.0021 17.1814C23.8353 16.9689 23.6147 16.7924 23.1736 16.4395L19.9101 13.8288C19.4686 13.4755 19.2479 13.2989 19.0048 13.2366C18.7905 13.1817 18.565 13.1888 18.3546 13.2571C18.1159 13.3346 17.9067 13.5248 17.4884 13.9052Z"
          stroke="#2B2F38"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Upload;
