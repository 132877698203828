export const REPORT_OVERVIEW = [
  {
    title: "QUICK I",
    creation: "2-3mins",
    length: "2 pages",
    Content: "Leaks Analysis",
  },
  {
    title: "QUICK II",
    creation: "3-10mins",
    length: "8 pages",
    Content: "Leaks Analysis",
  },
  {
    title: "Extended Report",
    creation: "20-45mins",
    length: "15 pages",
    Content:
      "Leak Analysis Free Botnet Logs Botnet Logs on Sale, Similar Domains Exposed Infrastruc",
  },
  {
    title: "Export Report",
    Content: "Recomended only for expert users with security background.",
  },
];

export const RECENT_EXPORT = [
  {
    exportId: "1001",
    exportType: "Domain Analysis",
    date: "2024-05-16 12:34",
    status: "Completed",
  },
  {
    exportId: "1002",
    exportType: "IP Analysis",
    date: "2024-05-16 12:34",
    status: "In Progress",
  },
  {
    exportId: "1003",
    exportType: "IP Analysis",
    date: "2024-05-16 12:34",
    status: "Completed",
  },
  {
    exportId: "1004",
    exportType: "Domain Analysis",
    date: "2024-05-16 12:34",
    status: "In Progress",
  },
  {
    exportId: "1003",
    exportType: "IP Analysis",
    date: "2024-05-16 12:34",
    status: "Completed",
  },
  {
    exportId: "1004",
    exportType: "Domain Analysis",
    date: "2024-05-16 12:34",
    status: "In Progress",
  },
];

export const RECENT_DRKNET = [
  {
    exportId: "1001",
    reportType: "Domain Analysis",
    domain:"Example.com",
    date: "2024-05-16 12:34",
    status: "Completed",
  },
  {
    exportId: "1002",
    reportType: "IP Analysis",
    domain:"Example.com",
    date: "2024-05-16 12:34",
    status: "In Progress",
  },
  {
    exportId: "1003",
    reportType: "IP Analysis",
    domain:"Example.com",
    date: "2024-05-16 12:34",
    status: "Completed",
  },
  {
    exportId: "1004",
    reportType: "Domain Analysis",
    domain:"Example.com",
    date: "2024-05-16 12:34",
    status: "In Progress",
  },
  {
    exportId: "1005",
    reportType: "Domain Analysis",
    domain:"Example.com",
    date: "2024-05-16 12:34",
    status: "Completed",
  },
  {
    exportId: "1006",
    reportType: "Domain Analysis",
    domain:"Example.com",
    date: "2024-05-16 12:34",
    status: "In Progress",
  },
];
