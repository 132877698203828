// src/GenericModel.js
import React from "react";

const CustomModel = ({ children, isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay tw-fixed tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-bg-[#0000007a] tw-z-[999]">
      <div className="modal tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-h-fit  tw-bg-white tw-px-7 tw-py-4 tw-rounded-lg md:tw-w-[650px] tw-w-[unset] md:tw-m-auto tw-m-2">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default CustomModel;
