import React from "react";
import CustomTabs from "../tabs";
import ActiveAlerts from "../../components/alerts/ActiveAlerts";
import AlertingRules from "../../components/alerts/AlertingRules";
import BasicAlertingSettings from "../../components/alerts/BasicAlertingSettings";
import Heading from "../../common/components/heading/Heading";

export default function AlertsPage() {
  return (
    <>
      <Heading size="h2" text="Alerts" />
      <div className="tabs-sec">
        <CustomTabs>
          <div label="Active Alerts">
            <ActiveAlerts />
          </div>
          <div label="Alerting Rules">
            <AlertingRules />
          </div>
          <div label="Basic Alerting Settings">
            <BasicAlertingSettings />
          </div>
        </CustomTabs>
      </div>
    </>
  );
}
