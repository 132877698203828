import React from "react";

const EyeClosed = () => {
  return (
    <div>
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66196 2.39488C6.9329 2.35482 7.2124 2.33333 7.50028 2.33333C10.9036 2.33333 13.1369 5.33656 13.8871 6.52455C13.9779 6.66833 14.0233 6.74023 14.0488 6.85112C14.0678 6.93439 14.0678 7.06578 14.0487 7.14905C14.0233 7.25993 13.9776 7.3323 13.8861 7.47705C13.6862 7.79343 13.3814 8.23807 12.9777 8.7203M3.98288 3.47669C2.5415 4.45447 1.56297 5.81292 1.11407 6.52352C1.02286 6.66791 0.97725 6.74011 0.951826 6.85099C0.932731 6.93426 0.932724 7.06563 0.951809 7.14891C0.97722 7.25979 1.02262 7.33168 1.11342 7.47545C1.86369 8.66344 4.09694 11.6667 7.50028 11.6667C8.87255 11.6667 10.0546 11.1784 11.0259 10.5177M1.50028 1L13.5003 13M6.08606 5.58579C5.72413 5.94772 5.50028 6.44772 5.50028 7C5.50028 8.10457 6.39571 9 7.50028 9C8.05256 9 8.55256 8.77614 8.91449 8.41421"
          stroke="#71747C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default EyeClosed;
