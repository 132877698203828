import React from "react";

const Email = ({ props }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6127_29937)">
        <path
          d="M11.5535 2.19382L17.8268 6.27149C18.0485 6.41558 18.1593 6.48762 18.2396 6.58372C18.3107 6.66879 18.3641 6.76718 18.3967 6.87314C18.4335 6.99284 18.4335 7.12503 18.4335 7.38942V13.4992C18.4335 14.8993 18.4335 15.5994 18.161 16.1341C17.9213 16.6046 17.5389 16.987 17.0685 17.2267C16.5337 17.4992 15.8336 17.4992 14.4335 17.4992H5.76685C4.36671 17.4992 3.66665 17.4992 3.13187 17.2267C2.66146 16.987 2.27901 16.6046 2.03933 16.1341C1.76685 15.5994 1.76685 14.8993 1.76685 13.4992V7.38942C1.76685 7.12503 1.76685 6.99284 1.80365 6.87314C1.83624 6.76718 1.88963 6.66879 1.96072 6.58372C2.04102 6.48762 2.15186 6.41558 2.37353 6.27149L8.64688 2.19382M11.5535 2.19382C11.0274 1.85189 10.7644 1.68093 10.481 1.61441C10.2305 1.55561 9.96982 1.55561 9.71932 1.61441C9.43593 1.68093 9.17291 1.85189 8.64688 2.19382M11.5535 2.19382L16.7136 5.54791C17.2868 5.92049 17.5734 6.10679 17.6727 6.34303C17.7594 6.54949 17.7594 6.78218 17.6727 6.98864C17.5734 7.22488 17.2868 7.41118 16.7136 7.78376L11.5535 11.1379C11.0275 11.4798 10.7644 11.6507 10.481 11.7173C10.2305 11.7761 9.96982 11.7761 9.71932 11.7173C9.43593 11.6507 9.17291 11.4798 8.64688 11.1379L3.48673 7.78376C2.91352 7.41118 2.62692 7.22488 2.52766 6.98864C2.44091 6.78218 2.44091 6.54949 2.52766 6.34303C2.62692 6.10679 2.91352 5.9205 3.48673 5.54791L8.64688 2.19382M18.0168 15.8325L12.4812 10.8325M7.7192 10.8325L2.18351 15.8325"
          stroke="#44464A"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6127_29937">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.100098)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Email;
