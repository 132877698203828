import React from "react";

const irectionSidebarIcon = () => {
  return (
    <div>
      <svg
        width="10"
        height="99"
        viewBox="0 0 10 99"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.17081 49.771L1.17081 1L9.14013 1M1.17081 49.771C1.17081 49.771 -0.867697 49.771 9.14013 49.7709M1.17081 49.771L1.17081 98L9.14013 98"
          stroke="white"
        />
      </svg>
    </div>
  );
};

export default irectionSidebarIcon;
