import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExportButton from 'common/components/Export/ExportButton';
import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import Loading from 'common/components/Loading/Loading';

import { search as searchPhishingAttack } from 'store/slices/search/database/phishingAttack';

const PhishingAttack = ({ selectedOptionLabel, searchTerm }) => {
  const dispatch = useDispatch();

  const { query, list, currentPage, totalPages, totalElements, loading } = useSelector(
    (state) => state.search.database.phishingAttack,
  );

  const [isPageSearch, setIsPageSearch] = useState(false);

  const setCurrentPage = (page) => {
    setIsPageSearch(true);

    dispatch(
      searchPhishingAttack({
        page: page - 1,
        progress: false,
      }),
    );
  };

  const columns = [
    {
      Header: 'Created',
      accessor: 'created_at',
      render: (value) => {
        return (
          <span className="tw-whitespace-nowrap tw-text-[#44464A]">
            {moment(value).format('YYYY-MM-DD')}
          </span>
        );
      },
    },
    {
      Header: 'Domain',
      accessor: 'domain',
      render: (value) => <span className=" tw-text-[#44464A]">{value}</span>,
    },
    {
      Header: 'URL',
      accessor: 'url',
      render: (value) => {
        return <span className="tw-break-all tw-text-[#00B6FF]">{value}</span>;
      },
    },
    {
      Header: 'Source',
      accessor: 'db',
      render: (value) => (
        <span className="tw-whitespace-nowrap tw-text-[#44464A]">{value}</span>
      ),
    },
  ];

  return (
    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
      {!(loading && !isPageSearch) ? (
        <>
          <div className="tw-flex tw-justify-between tw-items-center">
            <p className="tw-font-bold tw-text-lg tw-text-[#263238]">
              Search Result for {selectedOptionLabel} Search
            </p>
            <ExportButton />
          </div>
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
            <div className="tw-flex tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">
                Search results
              </span>
              <span className="tw-text-sm tw-font-medium">{totalElements}</span>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">Search term</span>
              <span className="tw-text-sm tw-font-medium">{query}</span>
            </div>
          </div>

          <div className="tw-relative">
            <Table
              data={list}
              columns={columns}
              selectable={true}
              // onRowSelection={handleRowSelection}
            />
            {totalPages > 1 && (
              <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
            {loading && (
              <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
            )}
          </div>
        </>
      ) : (
        <Loading label="The search is still running – Please wait a moment..." />
      )}
    </div>
  );
};

export default PhishingAttack;
