import { MagnifyingGlass, Plus } from "@phosphor-icons/react";
import React, { useState, useEffect } from "react";
import CustomButton from "../../../common/components/Button/Button";
import api from 'store/api';
 
const DomainDropdown = ({selectedDomain, setSelectedDomain, selectedDomains}) => {
  const [domains, setDomains] = useState([]);
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  //const [selectedDomain, setSelectedDomain] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await api.get('/settings/list_companies');
        const domainData = response.data.map(item => ({
          id: item.id,
          domain: item.domain}));
        setDomains(domainData);
      } catch (error) {
        console.error('Error fetching domain data:', error);
      }
    };
    fetchDomains();
    const results = domains.filter(item => 
      item.domain.toLowerCase().includes(searchTerm.toLowerCase()) &&
      selectedDomains.includes(item.domain)
    );
    setFilteredDomains(results);
  }, [searchTerm, domains]);

  const handleChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedDomain(selectedValue);
    
    try {
      // Make your API post request here using selectedValue
      const response = await api.post('/service/set_default_company/', { id: selectedValue });
      console.log("POST response:", response.data);
    } catch (error) {
      console.error("Error in POST request:", error);
    }
  };

  return (
    <div className="tw-max-w-sm tw-bg-white tw-rounded-lg tw-shadow-md tw-p-4">
      <h2 className="tw-text-lg tw-font-medium tw-mb-4">Select Domain</h2>

      {/* Search Input */}
      <div className="tw-relative tw-mb-4">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          className="tw-w-full tw-pl-10 tw-pr-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-yellow-500 focus:tw-border-yellow-500"
        />
        <span className="tw-absolute tw-left-3 tw-top-2 tw-text-gray-400">
          <MagnifyingGlass size={24} />
        </span>
      </div>
      {/* Recent Added List */}
      <h3 className="tw-text-sm tw-font-medium tw-mb-2">Recent Selected</h3>
      <div className="tw-h-40 tw-overflow-y-auto">
        {filteredDomains.map((item, index) => (
          <label key={index} className="tw-flex tw-items-center tw-mb-2 tw-py-1.5">
            <input
              type="radio"
              name="domain"
              value={item.id}
              checked={selectedDomain == item.id}
              onChange={handleChange}
              className="tw-mr-2"
            />
            <span className="tw-text-sm tw-text-gray-700">{item.domain}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default DomainDropdown;
