import * as React from "react";

function ChartLine({selected }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill= {selected ? "#ECC551" : "#F9F9F9"}/>
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke={selected ? "#ECC551" : "#F9F9F9"}/>
      <path
        d="M29 29H12.6C12.0399 29 11.7599 29 11.546 28.891C11.3578 28.7951 11.2049 28.6422 11.109 28.454C11 28.2401 11 27.9601 11 27.4V11M28 16L24.0811 20.1827C23.9326 20.3412 23.8584 20.4204 23.7688 20.4614C23.6897 20.4976 23.6026 20.5125 23.516 20.5047C23.4179 20.4958 23.3215 20.4458 23.1287 20.3457L19.8713 18.6543C19.6785 18.5542 19.5821 18.5042 19.484 18.4953C19.3974 18.4875 19.3103 18.5024 19.2312 18.5386C19.1416 18.5796 19.0674 18.6588 18.9189 18.8173L15 23"
        stroke="#979BA5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill= {selected ? "#ECC551" : "#F9F9F9"}
      />
    </svg>
  );
}

export default ChartLine;
