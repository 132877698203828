// src/components/AddTicket.js
import React, { useState } from "react";
import InputGroup from "../../../common/components/InputGroup/InputGroup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";
import Modal from "../../../common/components/Modal/Modal";

const AddTicket = ({ isOpen, onClose, title }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="tw-p-4 md:tw-w-[550px]"
      >
        <div className="tw-mb-4">
          {" "}
          <InputGroup
            labelText="Full name"
            inputType="text"
            placeholder="Full name"
            inputName="fullName"
            register={register}
            errors={errors}
            focusOnType={true}
            // readOnly
          />
        </div>
        <div className="tw-mb-4">
          <InputGroup
            labelText="Email address"
            inputType="email"
            placeholder="Email address"
            inputName="email"
            register={register}
            errors={errors}
            focusOnType={true}
            // readOnly
          />
        </div>
        <div className="tw-mb-4">
          {" "}
          <SelectGroup
            htmlfor="Subject"
            labelText="Subject"
            inputName="subject"
            register={register}
            errors={errors}
            options={[
              { value: "", label: "Select" },
              {
                value: "Hard_to find_sound_settings",
                label: "Hard to find sound settings",
              },
              { value: "RESTAURANT", label: "Device does not power up" },
              {
                value: "RETAIL_SHOP",
                label: "Sound is all fuzzy using bluetooth",
              },
            ]}
            onChange={() => {}}
            placeholder="Select Subject"
            className="tw-w-full"
            isLoading={false}
          />
        </div>
        <div className="tw-mb-4">
          {" "}
          <SelectGroup
            htmlfor="Department"
            labelText="Department"
            inputName="department"
            register={register}
            errors={errors}
            options={[
              { value: "", label: "Select Department" },
              { value: "Mobility", label: "Mobility" },
              { value: "Portability", label: "Portability" },
              { value: "Customer_Retention", label: "Customer Retention" },
              { value: "After_Sales_Support", label: "After Sales Support" },
              { value: "L3_Escalation", label: "L3 Escalation" },
              { value: "Technical_team", label: "Technical Team" },
              { value: "L2_Escalation", label: "L2 Escalation" },
            ]}
            onChange={() => {}}
            placeholder="Select Department"
            className="tw-w-full"
            isLoading={false}
          />
        </div>
        <div className="tw-mb-4">
          {" "}
          <InputGroup
            labelText="Description"
            inputType="textarea"
            placeholder="Enter a description..."
            inputName="description"
            register={register}
            errors={errors}
            focusOnType={true}
          />
        </div>
        <div className="tw-flex tw-justify-start tw-gap-4">
          <button
            type="submit"
            className="tw-bg-blue-500 tw-bg-primary tw-text-white tw-px-4 tw-py-2 tw-rounded-lg"
          >
            Create
          </button>
          <button
            type="button"
            onClick={onClose}
            className="tw-border tw-px-4 tw-py-2 tw-rounded-lg"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddTicket;
