import React from 'react';

const SearchWizard = ({ steps, selectedIndex }) => {
  
  return (
    <ol className="tw-flex tw-items-center tw-pb-8">
      {steps.map((step, index) => (
        <li
          key={index}
          className={
            'tw-relative tw-w-40 tw-flex tw-items-center tw-justify-center ' +
            `before:tw-content-[''] before:tw-w-20 before:tw-h-1 before:tw-border-b before:tw-border-${index == 0 ? 'transparent' : 'secondary'} before:tw-border-4 before:tw-inline-block` +
            `after:tw-content-[''] after:tw-w-20 after:tw-h-1 after:tw-border-b after:tw-border-${index == steps.length - 1 ? 'transparent' : 'secondary'} after:tw-border-4 after:tw-inline-block`
          }
        >
          <span
            className={
              'tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-border tw-border-secondary tw-rounded-full tw-shrink-0 ' +
              (selectedIndex == index ? '' : 'tw-bg-secondary')
            }
          >
            {step.number}
          </span>

          <span className="tw-absolute tw-top-10 tw-text-center tw-text-xs">
            {step.text}
          </span>
        </li>
      ))}
    </ol>
  );
};

export default SearchWizard;
