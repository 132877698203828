import { MagnifyingGlass } from "@phosphor-icons/react";
import React, { useState, useEffect } from "react";
import api from 'store/api';

const CountryDropdown = ({ selectedCountry, setSelectedCountry, activeTab }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeOptions, setActiveOptions] = useState([]);
  const [activePlaceholder, setActivePlaceholder] = useState("");
  const [industries, setIndustries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [competitors, setCompetitors] = useState([]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await api.get('/settings/country_list');
        setCountries(response.data.results);
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };

    const fetchIndustries = async () => {
      try {
        const response = await api.get('/settings/industry_type_list');
        setIndustries(response.data);
      } catch (error) {
        console.error('Error fetching industry data:', error);
      }
    };

    const fetchCompetitors = async () => {
      try {
        const response = await api.get('/settings/competitors');
        setCompetitors(response.data.competitors);
      } catch (error) {
        console.error('Error fetching competitor data:', error);
      }
    };

    switch (activeTab) {
      case 'country':
        fetchCountries();
        setActiveOptions(countries);
        setActivePlaceholder('Select Country');
        break;
      case 'industry':
        fetchIndustries();
        setActiveOptions(industries);
        setActivePlaceholder('Select Industry');
        break;
      case 'competitor':
        fetchCompetitors();
        setActiveOptions(competitors);
        setActivePlaceholder('Select Competitor');
        break;
      default:
        setActiveOptions([]);
        setActivePlaceholder('Select an option');
    }
  }, [activeTab, countries, industries, competitors]);

  const filteredOptions = activeOptions.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="tw-max-w-sm tw-bg-white tw-rounded-lg tw-shadow-md tw-p-4">
      <h2 className="tw-text-lg tw-font-medium tw-mb-4">{activePlaceholder}</h2>

      {/* Search Input */}
      <div className="tw-relative tw-mb-4">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          className="tw-w-full tw-pl-10 tw-pr-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-yellow-500 focus:tw-border-yellow-500"
        />
        <span className="tw-absolute tw-left-3 tw-top-2 tw-text-gray-400">
          <MagnifyingGlass size={24} />
        </span>
      </div>

      {/* Options List */}
      <div className="tw-h-40 tw-overflow-y-auto">
      {filteredOptions.length > 0 ? (
          filteredOptions.map((option, index) => (
            <label key={index} className="tw-flex tw-items-center tw-mb-2 tw-py-1.5">
              <input
                type="radio"
                name="option"
                value={option.id}
                checked={selectedCountry === option.id}
                onChange={() => setSelectedCountry(option.id)}
                className="tw-mr-2"
              />
              <span className="tw-text-sm tw-text-gray-700">{option.name}</span>
            </label>
          ))
        ) : (
          <div className="tw-text-sm tw-text-center tw-text-gray-700">No data available</div>
        )}
      </div>
    </div>
  );
};

export default CountryDropdown;