import React from "react";

const InstaIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0009 14.88C13.5915 14.88 14.8809 13.5905 14.8809 12C14.8809 10.4094 13.5915 9.11996 12.0009 9.11996C10.4104 9.11996 9.12094 10.4094 9.12094 12C9.12094 13.5905 10.4104 14.88 12.0009 14.88ZM12.0009 13.92C13.0613 13.92 13.9209 13.0603 13.9209 12C13.9209 10.9396 13.0613 10.08 12.0009 10.08C10.9406 10.08 10.0809 10.9396 10.0809 12C10.0809 13.0603 10.9406 13.92 12.0009 13.92Z"
          fill="#455A64"
        />
        <path
          d="M15.6009 7.91996C15.3358 7.91996 15.1209 8.13486 15.1209 8.39996C15.1209 8.66506 15.3358 8.87996 15.6009 8.87996C15.866 8.87996 16.0809 8.66506 16.0809 8.39996C16.0809 8.13486 15.866 7.91996 15.6009 7.91996Z"
          fill="#455A64"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.28094 9.11996C5.28094 6.99919 7.00016 5.27996 9.12094 5.27996H14.8809C17.0017 5.27996 18.7209 6.99919 18.7209 9.11996V14.88C18.7209 17.0007 17.0017 18.72 14.8809 18.72H9.12094C7.00016 18.72 5.28094 17.0007 5.28094 14.88V9.11996ZM6.24094 9.11996C6.24094 7.52938 7.53036 6.23996 9.12094 6.23996H14.8809C16.4715 6.23996 17.7609 7.52938 17.7609 9.11996V14.88C17.7609 16.4705 16.4715 17.76 14.8809 17.76H9.12094C7.53036 17.76 6.24094 16.4705 6.24094 14.88V9.11996Z"
          fill="#455A64"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.0409 12C23.0409 18.0972 18.0982 23.04 12.0009 23.04C5.90371 23.04 0.960938 18.0972 0.960938 12C0.960938 5.90274 5.90371 0.959961 12.0009 0.959961C18.0982 0.959961 23.0409 5.90274 23.0409 12ZM22.0809 12C22.0809 17.567 17.568 22.08 12.0009 22.08C6.43391 22.08 1.92094 17.567 1.92094 12C1.92094 6.43293 6.43391 1.91996 12.0009 1.91996C17.568 1.91996 22.0809 6.43293 22.0809 12Z"
          fill="#455A64"
        />
      </svg>
    </div>
  );
};

export default InstaIcon;
