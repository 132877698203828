import React from "react";
import { TbArrowBackUp } from "react-icons/tb";
import Chip from "../../../../common/components/chip/chip";
import view from "../../../assets/svgs/view.svg";
import Quotation from "./components/quotation.component";
import Heading from "../../../../common/components/heading/Heading";

export const CyberDetection = ({ setIsShow }) => {
  const detectionData = [
    {
      size: "90%",
      value: "2480",
    },
    {
      size: "80%",
      value: "952",
    },
    {
      size: "40%",
      value: "2570",
    },
    {
      size: "60%",
      value: "9501",
    },
    {
      size: "60%",
      value: "248",
    },
    {
      size: "40%",
      value: "248",
    },
    {
      size: "80%",
      value: "248",
    },
  ];
  const activity = [
    "Find Servers in Shodan",
    "Find Servers in Zoomeye",
    "Find Servers linked in Webpage",
    "Find Servers in DNS Dumpsters",
    "Find Servers via Shared Hosting",
    "Find Servers in SSL Transparency Logs",
    "Find Servers in IP Ranges",
  ];
  return (
    <div className="tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 tw-mt-4 ">
      <div
        className="tw-flex tw-gap-3 tw-items-center tw-cursor-pointer"
        onClick={() => setIsShow((show) => !show)}
      >
        <TbArrowBackUp className="tw-mb-4 tw-text-xl" />
        <Heading size="h2" text="Exposure Infrastructure Check" />
      </div>

      <div className="tw-border tw-border-[#EFF0F2] tw-rounded tw-py-5 tw-px-7">
        <p className="tw-text-primaryGrey tw-text-sm tw-pb-1">
          Selected Domain
        </p>
        <Heading size="h6" text="Example.com" />

        <div className="tw-flex tw-gap-3 tw-flex-col sm:tw-flex-row">
          <div className="md:tw-w-[65%] tw-w-full">
            <p className="tw-my-3 tw-text-primaryGrey">Results</p>
            {detectionData.map((item, i) => {
              return (
                <div
                  className="tw-flex tw-gap-3 tw-items-center tw-mb-4"
                  key={i.toString()}
                >
                  <div className="tw-w-full tw-bg-[#80808014] tw-rounded-full tw-h-2.5">
                    <div
                      className="tw-bg-secondary tw-h-2.5 tw-rounded-full"
                      style={{ width: item.size }}
                    ></div>
                  </div>
                  <span className="tw-text-[#797C84] tw-text-xs tw-w-[30px] tw-text-right">
                    {item.value}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="md:tw-w-[34%] tw-w-full">
            <p className="tw-my-3 tw-text-primaryGrey">Activity</p>
            {activity.map((item, i) => {
              return (
                <div key={i.toString()} className="tw-mb-3">
                  <Heading size="h6" text={item} />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="tw-grid md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-4 tw-mt-3">
        <Quotation
          title="What is passive infrastructure monitoring?"
          description={`Passive infrastructure monitoring identifies information like ports, vulnerabilities, host names, services, and technology without actively interacting with the system or network. It involves querying deep web databases like Shodan, ZoomEye, and other threat intelligence sources. This method doesn't disrupt system operations or alert the target, though it may miss some vulnerabilities only detectable through active methods.`}
        />
        <Quotation
          title="What do we aim to achieve with passive infrastructure monitoring?"
          description={
            <>
              <ul className="tw-list-disc tw-pl-6">
                <li>
                  Discover shadow IT by identifying infrastructure not within
                  known IP ranges.
                </li>
                <li>
                  Understand the attack surface from a hacker's perspective
                </li>
                <li>
                  Identify vulnerabilities and exposed services without
                  disrupting system operations
                </li>
              </ul>
            </>
          }
        />
      </div>
    </div>
  );
};
