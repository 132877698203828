import React, { useState, useEffect } from "react";
import api from 'store/api';

const DomainsIncluded = ({ selectedDomains, setSelectedDomains }) => {
  // const [domains, setDomains] = useState([
  //   { name: "investor.thyrocare.com", checked: true },
  //   { name: "Cliso.thyrocare.com", checked: true },
  //   { name: "LIS.thyrocare.cloud", checked: true },
  //   { name: "Matrnity.com", checked: true },
  //   { name: "Live.thyrocare.com", checked: true },
  //   { name: "Staff.thyrocare.cloud", checked: true },
  //   { name: "mis.thyrocare.com", checked: true },
  // ]);

  const [domains, setDomains] = useState([]);
  const handleCheckboxChange = (index) => {
    const newDomains = [...domains];
    newDomains[index].checked = !newDomains[index].checked;
    setDomains(newDomains);

    const updatedSelectedDomains = newDomains
      .filter(domain => domain.checked)
      .map(domain => domain.name);
    setSelectedDomains(updatedSelectedDomains);
  };

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await api.get('/settings/list_companies');
        const domainData = response.data.map(item => ({
          name: item.domain,
          checked: selectedDomains.includes(item.domain),
        }));
        setDomains(domainData);
      } catch (error) {
        console.error('Error fetching domain data:', error);
      }
    };
    fetchDomains();
  }, [selectedDomains]);

  return (
    <div className="tw-max-w-xs tw-bg-white tw-rounded-lg tw-shadow-md tw-p-4">
      <h2 className="tw-text-lg tw-font-medium tw-mb-2">Domains Included</h2>
      <div className="tw-h-70 tw-overflow-y-auto tw-flex tw-flex-col tw-gap-4">
        {domains.map((domain, index) => (
          <label key={index} className="tw-flex tw-items-center tw-mb-2">
            <input
              type="checkbox"
              checked={domain.checked}
              onChange={() => handleCheckboxChange(index)}
              className="tw-mr-2 tw-h-5 tw-w-5 tw-text-black tw-accent-black"
            />
            <span className="tw-text-sm tw-text-gray-700">{domain.name}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default DomainsIncluded;
