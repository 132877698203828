import React from 'react';

const CornerRadius = () => {
  return (
    <>
      <div className="tw-hidden md:tw-block tw-bg-[#111] tw-absolute -tw-top-[25px] tw-right-0 tw-w-[25px] tw-h-[25px] tw-rounded-br-[100px] tw-shadow-top-custom-light" />
      <div className="tw-hidden md:tw-block tw-bg-[#111]  tw-absolute -tw-bottom-[25px] tw-right-0 tw-w-[25px] tw-h-[25px] tw-rounded-tr-[100px] tw-shadow-bottom-custom-light" />
    </>
  );
};

export default CornerRadius;
