import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as Yup from 'yup';

import CustomButton from 'common/components/Button/Button';
import CustomDropdown from 'common/components/DropDown/CustomDropdown';
import InputGroup from 'common/components/InputGroup/InputGroup';
import { DatabaseSearchOptions } from 'common/components/SearchComponent/data';
import { SearchIcon } from 'common/Icons';
import CrossIcon from 'common/Icons/CrossIcon';

import useFocus from 'hooks/use-focus';

const SearchComponent = ({
  defaultOption = {},
  searchTerm = '',
  onOptionChange,
  onSearchTermChange,
  onSearch,
  loading,
}) => {
  const searchConfig = useSelector((state) => state.search.main);

  const [option, setOption] = useState({});
  const [searchInputValue, setSearchInputValue] = useState(searchTerm);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setOption(defaultOption);
  }, [defaultOption]);

  useEffect(() => {
    setSearchInputValue(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (onSearchTermChange) onSearchTermChange(searchInputValue);
  }, [searchInputValue]);

  const classifyInput = (input) => {
    input = input.trim();

    // Define regular expressions for each type of input
    const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/i;
    const ipRegex = /^\d{1,3}(\.\d{1,3}(\.\d{1,3}(\.\d{1,3})?)?)?$/;
    const creditCardRegex = /^\d{5,}$/;
    const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    const brandNameRegex = /^[A-Za-z]+$/;

    // Check for IP address (with wildcard support)
    if (ipRegex.test(input)) {
      return 4;
    }

    // Check for domain (also removes subdomains like www)
    if (domainRegex.test(input)) {
      const matches = input.match(domainRegex);
      let domain = matches[1];
      if (domain.indexOf('.') !== -1) {
        domain = domain.split('.').slice(-2).join('.');
      }

      return 1;
    }

    // Check for credit card numbers
    if (creditCardRegex.test(input)) {
      return 5;
    }

    // Check for email
    if (emailRegex.test(input)) {
      return 2;
    }

    // Check for brand name
    if (brandNameRegex.test(input)) {
      return 6;
    }

    // If no type is matched, return undefined type
    return -1;
  };

  const handleSearch = async () => {
    Yup.object()
      .shape({
        searchInputValue: option.validation,
      })
      .validate({ searchInputValue })
      .then(() => {
        setErrorMessage('');

        if (
          searchConfig.selectedOptionId == 2 &&
          searchInputValue.split(' ').length == 1
        ) {
          // If search option is Name and input is first/last name only...
          openModal();
          return;
        }

        onSearch(searchInputValue);
      })
      .catch((err) => {
        console.error(err.errors);
        setErrorMessage(err.errors);
      });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputRef, setInputFocus] = useFocus();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setInputFocus();
  };

  const confirmModal = () => {
    setIsModalOpen(false);
    onSearch(searchInputValue);
  };

  return (
    <div className="tw-p-4 tw-rounded-xl tw-bg-[#ffffff]">
      <div className="tw-flex lg:tw-flex-row tw-flex-col tw-rounded-lg tw-gap-3">
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-gap-1 md:tw-gap-0 tw-bg-[#F5F6F8] tw-rounded-[8px]">
          <div className="tw-w-full md:tw-max-w-[220px]">
            <CustomDropdown
              defaultOption={defaultOption}
              placeholder="Select"
              options={DatabaseSearchOptions}
              name="searchOption"
              background={true}
              border={true}
              className="tw-w-full tw-h-[45px] tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm tw-rounded-[8px_0px_0px_8px]"
              onChange={(option) => {
                setOption(option);
                {
                  onOptionChange && onOptionChange(option);
                }
              }}
            />
          </div>

          <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-px-4 tw-gap-3 md:tw-gap-[10px] tw-border-[0.5px] tw-rounded-[0px_8px_8px_0px]">
            <div className="tw-w-full tw-flex tw-items-center tw-relative">
              <InputGroup
                ref={inputRef}
                placeholder={`${
                  _.isEmpty(option)
                    ? 'Keyword...'
                    : `Enter your ${option.label}: e.g. ${option.placeholder}`
                }`}
                value={searchInputValue}
                onChange={(e) => setSearchInputValue(e.target.value)}
                className="!tw-bg-gray-100 tw-w-full tw-h-full tw-pl-3 tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-left tw-px-4 tw-py-2 !tw-border-none"
              />
              {errorMessage && (
                <div className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-red-500 tw-text-white tw-text-xs tw-rounded tw-px-2 tw-py-1 tw-shadow-md">
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <CustomButton
            text="Search"
            disabled={_.isEmpty(option) || loading}
            onClick={handleSearch}
            isLoading={loading}
            icon={<SearchIcon fill="#ffffff" />}
            className="tw-w-full tw-py-[10px] tw-rounded-md tw-px-3 tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-h-full tw-text-sm tw-font-semibold tw-leading-6 tw-text-left"
          />
        </div>
      </div>
      <WarningModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={confirmModal}
      />
    </div>
  );
};

export default SearchComponent;

// Modal Component
const WarningModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-gray-800 tw-bg-opacity-75 tw-flex tw-items-center tw-justify-center tw-z-[100]">
      <div className="tw-bg-white tw-rounded-lg tw-p-6 tw-shadow-xl tw-max-w-[700px] tw-w-full tw-relative">
        {/* Cross Icon for closing */}
        <button
          className="tw-absolute tw-top-3 tw-right-3 tw-text-gray-400 hover:tw-text-gray-600"
          onClick={onClose}
        >
          <CrossIcon />
        </button>

        <h2 className="tw-font-bold tw-text-lg">Tip</h2>
        <p className="tw-text-gray-700 tw-mt-2 tw-py-1">
          To avoid getting too many <strong>Leaks</strong> search results, we
          recommend entering your full name or make sure your first or last name
          is at least 10 characters long.
        </p>
        <div className="tw-flex tw-justify-end tw-space-x-4 tw-mt-6">
          <CustomButton text="Continue anyway" onClick={onConfirm} />
          <CustomButton
            text="Edit the keyword"
            bgColor="tw-bg-secondary"
            textColor="tw-text-primary"
            hoverTextColor="white"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};
