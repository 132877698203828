import React from "react";

import { AlertGray, ArrowGray, ColorArrow } from "../../../common/Icons";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

function WebSheet() {
  const navigate = useNavigate();

  return (
    <div className="tw-p-4 tw-rounded-lg tw-overflow-auto">
      <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-md ">
        <div className="tw-flex tw-justify-between tw-items-center ">
          <h2 className="tw-text-base tw-font-bold ">
            Setup Dark Web Monitoring
          </h2>
          <button className="tw-text-gray-400 tw-hover:text-gray-500">
            <div className="my-anchor-elementsa12">
              <AlertGray />{" "}
            </div>
            <Tooltip
              anchorSelect=".my-anchor-elementsa12"
              place="top"
              style={{
                backgroundColor: "white",
                color: "black",
                width: "600px",
              }}
            >
              The Leak Trend Analysis provides a real-time overview of the most
              recent data leaks discovered and documented by our analysts. You
              can access this information through a graphical view for visual
              insights or a list view for detailed data representation.
            </Tooltip>
          </button>
        </div>
        <p className="tw-text-sm tw-text-[#263238] tw-mb-1">
          Get alerted when your assets show up<br></br> in the dark or deep web
        </p>
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-py-[6px] tw-w-full">
          <button
            onClick={() =>
              navigate("/settings", { state: { activeItem: "ApiSetup" } })
            }
            className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-py-[6px] tw-px-1 tw-text-sm tw-font-[400] hover:tw-font-medium tw-bg-[#FCF6E5] tw-text-[#D4B149] tw-rounded-xl tw-border-[0.5px] hover:tw-border-[1px] tw-border-[#D4B149] tw-w-full"
          >
            Setup API Integration <ColorArrow />
          </button>
          <button
            onClick={() =>
              navigate("/AlertingRules", { state: { isNewAlertingOpen: true } })
            }
            className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-px-1 tw-py-[6px] tw-text-sm  tw-font-[400] hover:tw-font-medium tw-bg-[#F9F9F9] tw-text-gray-500 tw-rounded-xl tw-border-[0.5px] hover:tw-border-[1px] tw-border-gray-500 tw-w-full"
          >
            Setup Email Alerts <ArrowGray />
          </button>
        </div>
      </div>
    </div>
  );
}

export default WebSheet;
