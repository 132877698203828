import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { CiCalendarDate } from 'react-icons/ci';
import CustomButton from 'common/components/Button/Button';
import CustomDropdown from 'common/components/DropDown/CustomDropdown';
import InputGroup from 'common/components/InputGroup/InputGroup';
import { ArrowDownLight, InfoIcon, PlusIcon } from 'common/Icons';
import { useIsMount } from 'hooks/useMount';

const Step4 = ({ searchType, onApply, setSearchButton }) => {
  const isMount = useIsMount();

  const [availableFields, setAvailableFields] = useState(
    searchType ? searchType.optionals : [],
  );
  const [appliedFilters, setAppliedFilters] = useState([]);

  const [field, setField] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateError, setDateError] = useState('');
  const [listOption, setListOption] = useState(null);

  useEffect(() => {
    const fields = searchType.optionals.filter(
      (optional) =>
        !appliedFilters.map((filter) => filter.field).includes(optional),
    );
    setAvailableFields(fields);

    setField(fields.length > 0 ? fields[0] : null);
  }, [appliedFilters]);

  useEffect(() => {
    if (field) {
      if (field.type == 'dropdown') {
        setListOption(field.list[0]);
      } else if (field.type == 'daterange') {
        setStartDate(null);
        setEndDate(null);
        setDateError('');
      } else {
        setInputValue('');
      }
    }
  }, [field]);

  const makeNewFilter = async () => {
    let value;

    if (field.type === 'dropdown') {
      return {
        field,
        option: listOption,
        value: listOption.value,
      };
    } else if (field.type === 'daterange') {
      if (!startDate && !endDate) {
        setDateError('You must select date range');
        return {};
      } else {
        if (startDate && endDate && startDate > endDate) {
          setDateError('The start date cannot be later than the end date');
          return {};
        } else {
          setDateError('');
          value =
            (startDate ? moment(startDate).format('YYYY-MM-DD') : '*') +
            ' TO ' +
            (endDate ? moment(endDate).format('YYYY-MM-DD') : '*');
        }
      }
      return {
        field,
        startDate,
        endDate,
        value,
      };
    } else {
      try {
        const valid = await Yup.object()
          .shape({
            inputValue: field.validation,
          })
          .validate({ inputValue });

        setErrorMessage('');
        return {
          field,
          value: inputValue,
        };
      } catch (err) {
        setErrorMessage(err.errors);
        return {};
      }
    }
  };

  const handleAddOptionalFilter = async () => {
    const newFilter = await makeNewFilter();

    if (!_.isEmpty(newFilter)) {
      setAppliedFilters([...appliedFilters, newFilter]);
    }
  };

  const handleApply = async () => {
    let additionalFilters = appliedFilters;

    if (
      field &&
      !appliedFilters.map((filter) => filter.field).includes(field)
    ) {
      const newFilter = await makeNewFilter();

      if (!_.isEmpty(newFilter)) {
        additionalFilters = [...additionalFilters, newFilter];
      }
    }

    onApply &&
      onApply(
        additionalFilters.map((filter) => {
          return {
            filterMode: 'AND',
            searchType,
            field: filter.field,
            term: filter.value,
          };
        }),
      );

    setSearchButton && setSearchButton(true);
  };

  const handleSkip = () => {
    onApply && onApply([]);

    setSearchButton && setSearchButton(true);
  };

  return (
    <div className="tw-grid tw-gap-1 tw-px-4 tw-py-2 tw-rounded-xl tw-bg-[#ffffff]">
      {appliedFilters.map((filter, index) => (
        <div
          key={index}
          className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 md:tw-gap-x-6 tw-items-center"
        >
          <h3
            className={
              'tw-text-base tw-font-bold tw-text-nowrap' +
              (index > 0 ? ' tw-invisible' : '')
            }
          >
            Step 4
          </h3>
          <div
            className={
              'tw-text-sm tw-text-nowrap' + (index > 0 ? ' tw-invisible' : '')
            }
          >
            Additional Search Filters
          </div>

          <div className="tw-relative tw-w-52 tw-flex-shrink-0">
            <CustomDropdown
              defaultOption={filter.field}
              options={searchType.optionals}
              name="field"
              background={true}
              border={true}
              dropdownArrow={<ArrowDownLight />}
              className="tw-w-full tw-h-10 tw-whitespace-nowrap tw-text-sm tw-border tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm tw-rounded-lg"
              disabled={true}
            />
          </div>

          {filter.field.type === 'daterange' ? (
            <div className="tw-flex tw-items-center tw-relative">
              <div className="tw-relative tw-flex">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={filter.startDate}
                  placeholderText="Start Date"
                  className="tw-border tw-rounded-lg tw-me-2"
                  showIcon
                  icon={
                    <CiCalendarDate
                      style={{ fontSize: '19px', color: '#71747C' }}
                    />
                  }
                  disabled={true}
                />
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={filter.endDate}
                  placeholderText="End Date"
                  className="tw-border tw-rounded-lg"
                  showIcon
                  icon={
                    <CiCalendarDate
                      style={{ fontSize: '19px', color: '#71747C' }}
                    />
                  }
                  disabled={true}
                />
              </div>
            </div>
          ) : filter.field.type === 'dropdown' ? (
            <CustomDropdown
              defaultOption={filter.option}
              placeholder="Select a option"
              options={filter.field.list}
              name="dropdown"
              background={true}
              border={true}
              dropdownArrow={<ArrowDownLight />}
              className="tw-w-full tw-h-10 tw-whitespace-nowrap tw-text-sm tw-border tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm tw-rounded-lg"
              disabled={true}
            />
          ) : (
            <div className="tw-w-full tw-flex tw-items-center tw-relative">
              <InputGroup
                value={filter.value}
                className="tw-w-full tw-h-full !tw-p-3 tw-text-left tw-leading-[16.41px] tw-tracking-[0.02em]"
                disabled={true}
              />
            </div>
          )}

          <div className="tw-p-1 tw-border tw-rounded-md hover:tw-bg-[#DFE0E3] tw-cursor-pointer tw-invisible">
            <PlusIcon color="#000" />
          </div>

          <CustomButton
            className={index > 0 ? 'tw-invisible' : 'tw-ms-auto'}
            text="Apply"
            onClick={handleApply}
          />
          <CustomButton
            text="Skip this step"
            bgColor="tw-bg-secondary"
            textColor="tw-text-primary"
            hoverTextColor="white"
            className={
              'tw-whitespace-nowrap' + (index > 0 ? ' tw-invisible' : '')
            }
            onClick={handleSkip}
          />
        </div>
      ))}

      {availableFields.length > 0 && (
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 md:tw-gap-x-6 tw-items-center">
          <h3
            className={
              'tw-text-base tw-font-bold tw-text-nowrap' +
              (appliedFilters.length > 0 ? ' tw-invisible' : '')
            }
          >
            Step 4
          </h3>
          <div
            className={
              'tw-text-sm tw-text-nowrap' +
              (appliedFilters.length > 0 ? ' tw-invisible' : '')
            }
          >
            Additional Search Filters
          </div>

          <div className="tw-relative tw-w-52 tw-flex-shrink-0">
            <CustomDropdown
              defaultOption={field}
              labelOfList="Select Optional Field"
              placeholder="Select Field"
              options={availableFields}
              name="field"
              background={true}
              border={true}
              dropdownArrow={<ArrowDownLight />}
              className="tw-w-full tw-h-10 tw-whitespace-nowrap tw-text-sm tw-border tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm tw-rounded-lg"
              onChange={(field) => {
                setField(field);
              }}
            />
          </div>

          {field?.type === 'daterange' ? (
            <div className="tw-flex tw-items-center tw-relative">
              <div className="tw-relative tw-flex">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setDateError(false);
                  }}
                  placeholderText="Start Date"
                  className="tw-border tw-rounded-lg tw-me-2"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  isClearable={true}
                  clearButtonClassName="tw-me-8 after:!tw-bg-secondary"
                  showIcon
                  icon={
                    <CiCalendarDate
                      style={{ fontSize: '19px', color: '#71747C' }}
                    />
                  }
                />
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={endDate}
                  className="tw-border tw-rounded-lg"
                  onChange={(date) => {
                    setEndDate(date);
                    setDateError('');
                  }}
                  placeholderText="End Date"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  isClearable={true}
                  clearButtonClassName="tw-me-8 after:!tw-bg-secondary"
                  showIcon
                  icon={
                    <CiCalendarDate
                      style={{ fontSize: '19px', color: '#71747C' }}
                    />
                  }
                />
                {dateError && (
                  <div className="tw-absolute tw-whitespace-nowrap tw-top-full tw-left-0 tw-mt-1 tw-bg-red-500 tw-text-white tw-text-xs tw-rounded tw-px-2 tw-py-1 tw-shadow-md">
                    {dateError}
                  </div>
                )}
              </div>
            </div>
          ) : field?.type === 'dropdown' ? (
            <CustomDropdown
              defaultOption={listOption}
              placeholder="Select a option"
              options={field.list}
              name="dropdown"
              background={true}
              border={true}
              dropdownArrow={<ArrowDownLight />}
              className="tw-w-full tw-h-10 tw-whitespace-nowrap tw-text-sm tw-border tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm tw-rounded-lg"
              onChange={(option) => {
                setListOption(option);
              }}
            />
          ) : (
            <div className="tw-w-full tw-flex tw-items-center tw-relative">
              <InputGroup
                placeholder={field ? field.placeholder : searchType.placeholder}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="tw-w-full tw-h-full !tw-p-3 tw-text-left tw-leading-[16.41px] tw-tracking-[0.02em]"
              />
              {errorMessage && (
                <div className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-red-500 tw-text-white tw-text-xs tw-rounded tw-px-2 tw-py-1 tw-shadow-md">
                  {errorMessage}
                </div>
              )}
            </div>
          )}

          <div
            className="tw-p-1 tw-border tw-rounded-md hover:tw-bg-[#DFE0E3] tw-cursor-pointer"
            onClick={handleAddOptionalFilter}
          >
            <PlusIcon color="#000" />
          </div>

          <CustomButton
            className={
              appliedFilters.length > 0 ? 'tw-invisible' : 'tw-ms-auto'
            }
            text="Apply"
            onClick={handleApply}
          />
          <CustomButton
            text="Skip this step"
            bgColor="tw-bg-secondary"
            textColor="tw-text-primary"
            hoverTextColor="white"
            className={
              'tw-whitespace-nowrap' +
              (appliedFilters.length > 0 ? ' tw-invisible' : '')
            }
            onClick={handleSkip}
          />
        </div>
      )}
    </div>
  );
};

export default Step4;
