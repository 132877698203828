import _ from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const search = createAsyncThunk(
  'search/database/ransomware/search',
  async (
    { page, size, sort, query, progress = true },
    { getState, dispatch, rejectWithValue },
  ) => {
    const state = getState().search.database.ransomware;

    if (_.isNil(page)) page = state.currentPage - 1;
    if (_.isNil(size)) size = state.size;
    if (_.isNil(sort)) sort = state.sort;
    if (_.isNil(query)) query = state.query;

    let progressValue = 0,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (!isError) {
        if (step === 0 && progressValue < 75) {
          progressValue++;
          dispatch(setProgress(progressValue));

          await delay(50);
          requestAnimationFrame(mockProgress);
        }
      }
    };

    let originalQuery = query;
    try {
      if (progress) {
        requestAnimationFrame(mockProgress);
        query = query.split('.')[0];

        dispatch(setParams({ query, size, sort, page }));
        const response = await api.get('service/ransomware_victim_details/', {
          params: {
            page,
            size,
            sort,
            company: query,
          },
        });
        step = 1;

        if (response.data.count <= 5) {
          return response.data;
        }
      }

      dispatch(setParams({ query: originalQuery, size, sort, page }));
      const response = await api.get('service/ransomware_victim_details/', {
        params: {
          page,
          size,
          sort,
          company: originalQuery,
        },
      });
      step = 1;

      return response.data;
    } catch (error) {
      isError = true;
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

const ransomwareSlice = createSlice({
  name: 'ransomware',
  initialState: {
    query: '',
    list: [],
    sort: 'DATE,DESC',
    size: 10,
    currentPage: 1,
    totalElements: 0,
    totalPages: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setParams: (state, action) => {
      state.query = action.payload.query;
      state.size = action.payload.size;
      state.sort = action.payload.sort;
      state.currentPage = action.payload.page + 1;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload.rows;
        state.totalElements = action.payload.count;
        state.totalPages = Math.ceil(state.totalElements / state.size);        
        
        state.loading = false;
        state.progress = 100;
      })
      .addCase(search.rejected, (state, action) => {
        state.totalElements = state.totalPages = 0;
        state.list = [];
        state.loading = false;
        // state.progress = 0;
        state.error = action.payload;
      })
      .addCase(searchActions.resetProgress, (state) => {
        state.progress = 0;
      });
  },
});

const { setQuery, setParams, setProgress } = ransomwareSlice.actions;
export default ransomwareSlice.reducer;
