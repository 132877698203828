import _ from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const searchRequest = createAsyncThunk(
  'search/live/socialNetwork/searchRequest',
  async ({ query }, { getState, dispatch, rejectWithValue }) => {
    let progressValue = 0,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (!isError) {
        if (step === 0 && progressValue < 10) {
          progressValue++;
          dispatch(setProgress(progressValue));

          await delay(50);
          requestAnimationFrame(mockProgress);
        } else if (step === 1 && progressValue < 90) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(600);
          requestAnimationFrame(mockProgress);
        } else if (step === 2 && progressValue < 95) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(500);
          requestAnimationFrame(mockProgress);
        }
      }
    };

    try {
      requestAnimationFrame(mockProgress);
      const requestResponse = await api.post('service/social_network_search/', {
        query: query,
      });

      progressValue = 10;
      step = 1;
      requestAnimationFrame(mockProgress);
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const statusResponse = await api.get(
          `service/social_network_search_status/${requestResponse.data.id}`,
        );

        if (statusResponse.data.status === 'FINISHED') break;
        await delay(2500);
      }

      progressValue = 90;
      step = 2;
      requestAnimationFrame(mockProgress);

      const state = getState();

      const resultResponse = await api.get(
        `service/social_network_search_results/${requestResponse.data.id}`,
        {
          params: {
            page: 0,
            size: state.search.live.socialNetwork.size,
            sort: state.search.live.socialNetwork.sort,
          },
        },
      );
      step = 3;

      return { searchId: requestResponse.data.id, data: resultResponse.data };
    } catch (error) {
      isError = true;
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

export const search = createAsyncThunk(
  'search/live/socialNetwork/search',
  async ({ page, size, sort }, { getState, dispatch, rejectWithValue }) => {
    const state = getState();

    if (_.isNil(page)) page = state.search.live.socialNetwork.currentPage - 1;
    if (_.isNil(size)) size = state.search.live.socialNetwork.size;
    if (_.isNil(sort)) sort = state.search.live.socialNetwork.sort;

    try {
      dispatch(setParams({ size, sort }));

      const resultResponse = await api.get(
        `service/social_network_search_results/${state.search.live.socialNetwork.searchId}`,
        {
          params: {
            page,
            size,
            sort,
          },
        },
      );

      return resultResponse.data;
    } catch (error) {
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

const socialNetworkSlice = createSlice({
  name: 'socialNetwork',
  initialState: {
    searchId: null,
    list: [],
    sort: 'createdAt,desc',
    size: 10,
    currentPage: 1,
    totalElements: 0,
    totalPages: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setParams: (state, action) => {
      state.size = action.payload.size;
      state.sort = action.payload.sort;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchRequest.fulfilled, (state, action) => {
        state.searchId = action.payload.searchId;

        const { hasContent, totalElements, totalPages } = action.payload.data;
        state.list = hasContent ? action.payload.data.content : [];
        state.totalElements = totalElements;
        state.totalPages = totalPages;
        state.currentPage = action.payload.data.number + 1;

        state.loading = false;
        state.progress = 100;
      })
      .addCase(searchRequest.rejected, (state, action) => {
        state.totalElements = state.totalPages = 0;
        state.list = [];
        state.loading = false;
        // state.progress = 0;
        state.error = action.payload;
      })
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        const { hasContent, totalElements, totalPages } = action.payload;
        state.list = hasContent ? action.payload.content : [];
        state.totalElements = totalElements;
        state.totalPages = totalPages;
        state.currentPage = action.payload.number + 1;

        state.loading = false;
      })
      .addCase(search.rejected, (state, action) => {
        state.totalElements = state.totalPages = 0;
        state.list = [];
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(searchActions.resetProgress, (state) => {
        state.progress = 0;
      });
  },
});

const { setParams, setProgress } = socialNetworkSlice.actions;
export default socialNetworkSlice.reducer;
