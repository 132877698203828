import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import CustomButton from 'common/components/Button/Button';
import FunnelFilter from 'common/Icons/FunnelFilter';
import ExportButton from 'common/components/Export/ExportButton';
import GenericDropdown from 'common/components/GenericDropdown/GenericDropdown';

import Table from 'components/common/table/table.component';
import Loading from 'common/components/Loading/Loading';

const CreditCards = ({ selectedOptionLabel, searchTerm }) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  // Function to open modal
  const openFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  // Function to close modal
  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const FilterFormModal = ({ showModal, closeModal }) => {
    if (!showModal) return null; // If the modal is not open, return nothing

    return (
      <div className="tw-fixed tw-top-0 tw-right-0 tw-p-4 tw-mt-24 tw-mr-4 tw-bg-white tw-w-80 tw-shadow-lg tw-rounded-lg tw-z-50">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <h2 className="tw-text-lg tw-font-semibold">Filter</h2>
          <button onClick={closeModal} className="tw-text-gray-500">
            &times;
          </button>
        </div>

        {/* Buttons */}
        <div className="tw-flex tw-justify-around tw-mt-4">
          <button
            type="button"
            className="tw-bg-yellow-500 tw-text-white tw-px-6 tw-py-2 tw-rounded-md hover:tw-bg-yellow-600"
          >
            Apply Filter
          </button>
          <button
            type="button"
            className="tw-border tw-text-gray-700 tw-px-6 tw-py-2 tw-rounded-md hover:tw-bg-gray-400"
            onClick={closeModal}
          >
            Clear Filter
          </button>
        </div>
      </div>
    );
  };

  const { list, totalElements, loading } = useSelector(
    (state) => state.search.database.creditCard,
  );

  const size = 10;

  const defaultRenderer = (value) => (
    <span className=" tw-text-[#44464A]">{value ?? '—'}</span>
  );
  const columns = [
    {
      Header: 'Created',
      accessor: 'createdAt',
      render: defaultRenderer,
    },
    {
      Header: 'Number',
      accessor: 'number',
      render: (value) => {
        return <span className="tw-text-[#00B6FF]">{value ?? '—'}</span>;
      },
    },
    {
      Header: 'Expire',
      accessor: 'expire',
      render: defaultRenderer,
    },
    {
      Header: 'CVV',
      accessor: 'cvv',
      render: defaultRenderer,
    },
    {
      Header: 'Owner',
      accessor: 'owner',
      render: defaultRenderer,
    },
    {
      Header: 'Bank',
      accessor: 'bank',
      render: (value) => {
        return (
          <span className="tw-text-[#44464A] tw-uppercase">{value ?? '—'}</span>
        );
      },
    },
    {
      Header: 'Leak',
      accessor: 'leak',
      render: (_, record) => {
        return (
          <div>
            <p className="tw-text-[#00B6FF]">{record.leakName}</p>
            <p className="tw-text-[#44464A]">{record.leakDate}</p>
          </div>
        );
      },
    },
    {
      Header: 'Price',
      accessor: 'price',
      render: defaultRenderer,
    },
  ];

  return (
    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
      {!loading ? (
        <>
          <span className="tw-font-bold tw-text-lg tw-text-[#263238]">
            Search Result for {selectedOptionLabel} Search
          </span>
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
            <div className="tw-flex tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">
                Search results
              </span>
              <span className="tw-text-sm tw-font-medium">{totalElements}</span>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">Search term</span>
              <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
            </div>
          </div>
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
            <div></div>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 md:tw-gap-4">
              <CustomButton
                icon={<FunnelFilter />}
                text="Filters"
                onClick={openFilterModal}
                className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border tw-border-[#DFE0E3] tw-rounded-md tw-bg-[#ffffff] tw-text-[#0B0B0B] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
              />

              <ExportButton />
            </div>
          </div>

          <Table
            data={list}
            columns={columns}
            selectable={true}
            paginatorLabel="credit cards"
            rowsPerPage={size}
          />

          <FilterFormModal
            showModal={isFilterModalOpen}
            closeModal={closeFilterModal}
          />
        </>
      ) : (
        <Loading label="The search is still running – Please wait a moment..." />
      )}
    </div>
  );
};

export default CreditCards;
