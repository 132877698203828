import React from 'react'

function Quotation({title, description}){
  return (
    <div className='tw-bg-primary_danger tw-rounded-xl tw-py-4 tw-px-6'>
        <h4 className='tw-text-base tw-text-dark tw-font-bold tw-pb-4'>{title}</h4>
        <p className='tw-text-sm tw-text-[#455A64]'>{description}</p>
    </div>
  )
}
export default Quotation;
