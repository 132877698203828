import { combineReducers } from '@reduxjs/toolkit';

import exportReducer from './export';
import riskReducer from './risk';
import affectedSystemReducer from './affectedSystem';
import leakedCredentialReducer from './leakedCredential';

export default combineReducers({
  export: exportReducer,
  risk: riskReducer,
  affectedSystem: affectedSystemReducer,
  leakedCredential: leakedCredentialReducer,
});
