import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Modal from "../../../../common/components/Modal/Modal";

const EmailTemplate = () => {
  const [isEditorOpen, setIsEditorOpen] = useState(true);
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  return (
    <>
      {isEditorOpen && (
        <Modal
          isOpen={true}
          title={"WYSIWYG Email Editor"}
          onClose={() => {
            setIsEditorOpen(false);
          }}
        >
          <div className="tw-px-3 tw-flex tw-flex-col tw-gap-4">
            <Editor
              apiKey="cktgkb5fo4d3hzvny71eyei4qmqywwi982j99mptx55qm9q8"
              onInit={(_evt, editor) => (editorRef.current = editor)}
              initialValue="<div><h2>I’m a standard template</h2>
              <p>Lorem ipsum dolor sit amet consectetur. Duis metus ac malesuada at. Aenean aliquam vitae viverra ut pellentesque feugiat. Netus metus sagittis tincidunt dolor ipsum ultrices ut leo sagittis. Dictum maecenas sem dui sed mattis</p>
              <div/> "
              init={{
                height: 500,
                menubar: "file insert view  format table tools",
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks fontfamily fontsizeinput  | bold italic underline strikethrough  | forecolor | link image",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
            <div className="tw-flex tw-gap-2 tw-w-full tw-justify-end">
              <button
                className="tw-text-primary tw-bg-white tw-border tw-p-2 tw-rounded-lg"
                onClick={() => {}}
              >
                Switch to HTML
              </button>
              <button
                className="tw-bg-primary tw-text-white tw-p-2 tw-rounded-lg "
                onClick={() => {}}
              >
                Save Changes
              </button>
            </div>
          </div>
        </Modal>
      )}

      <button
        onClick={() => setIsEditorOpen(true)}
        className="tw-rounded-lg tw-p-2 tw-bg-primary tw-text-white"
      >
        Show editor modal
      </button>
    </>
  );
};

export default EmailTemplate;
