import React, { useState, useEffect } from "react";
import CustomTable from "../common/table/table.component";
import view from "../assets/svgs/view.svg";
import Heading from "../../common/components/heading/Heading";
import { TbArrowBackUp } from "react-icons/tb";
import { CyberDetection } from "../cyber-detecion/cyber-detection.component";
import CustomButton from "../../common/components/Button/Button";
import { VscListFilter } from "react-icons/vsc";
import InputGroup from "../../common/components/InputGroup/InputGroup";
import InputLabel from "../../common/components/InputLabel/InputLabel";
import NoResult from "../../common/components/NoResult/NoResult";
import SelectGroup from "../../common/components/SelectDropdown/SelectDropdown";
import { SearchIcon } from "../../common/Icons";
import GenericDropdown from "../../common/components/GenericDropdown/GenericDropdown";

export default function DomainSpoofing(searchDomainTerm) {
  console.log("search domain term---> ", searchDomainTerm);
  const initialTerm = searchDomainTerm?.searchDomainTerm || '';
  const [inputValue, setInputValue] = useState(initialTerm);

  useEffect(() => {
    if (searchDomainTerm?.searchDomainTerm !== inputValue) {
      setInputValue(searchDomainTerm.searchDomainTerm);
    }
  }, [searchDomainTerm]);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const [isShow, setIsShow] = useState();
  const [isInvestigating, setIsInvestigating] = useState(false);

  const handleInvestigate = () => {
    setIsInvestigating(true);
  };
  const handleNewRunInvestigate = () => {
    setIsInvestigating(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const handleMonitorModel = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);

  const openFilterModal = () => {
    setFilterModalOpen(true);
  };

  const closeFilterModal = () => {
    setFilterModalOpen(false);
  };

  const FilterFormModal = ({ showModal, closeModal }) => {
    if (!showModal) return null; // If the modal is not open, return nothing

    return (
      <div className="tw-fixed tw-top-0 tw-right-0 tw-p-4 tw-mt-24 tw-mr-4 tw-bg-white tw-w-80 tw-shadow-lg tw-rounded-lg tw-z-50">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <h2 className="tw-text-lg tw-font-semibold">Filter</h2>
          <button onClick={closeModal} className="tw-text-gray-500">
            &times;
          </button>
        </div>

        {/* Form Starts Here */}
        <form>
          {/* Start Date */}
          <div className="tw-mb-4">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
              From Date
            </label>
            <input
              type="text"
              className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm"
              placeholder="mm/dd/yyyy"
            />
          </div>

          {/* End Date */}
          <div className="tw-mb-4">
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
              End Date
            </label>
            <input
              type="text"
              className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm"
              placeholder="mm/dd/yyyy"
            />
          </div>

          {/* Enumeration Method */}
          <div className="tw-mb-4">
            <SelectGroup
              htmlfor="Enumeration Method"
              labelText="Enumeration Method"
              inputName="country"
              options={[
                { value: "", label: "Select an option" },
                {
                  value: "Method 1",
                  label: "Method 1",
                },
                {
                  value: "Method 2",
                  label: "Method 2",
                },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
            />
          </div>

          {/* Domain Condition */}
          <div className="tw-mb-4">
            <SelectGroup
              htmlfor=" Domain has a"
              labelText="Domain has a"
              inputName="country"
              options={[
                { value: "", label: "Select an option" },
                {
                  value: "Has",
                  label: "Has",
                },
                {
                  value: "Does not have",
                  label: "Does not have",
                },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
            />
          </div>

          {/* Categories */}
          <div className="tw-mb-4">
            <SelectGroup
              htmlfor="Categories"
              labelText="Categories"
              inputName="country"
              options={[
                { value: "", label: "Select an option" },
                {
                  value: "Category 1",
                  label: "Category 1",
                },
                {
                  value: "Category 2",
                  label: "Category 2",
                },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
            />
          </div>

          {/* Risk */}
          <div className="tw-mb-4">
            <SelectGroup
              htmlfor="Risk"
              labelText="Conditions"
              inputName="country"
              options={[
                { value: "", label: "Select an option" },
                {
                  value: "Low",
                  label: "Low",
                },
                {
                  value: "Medium",
                  label: "Medium",
                },
                {
                  value: "High",
                  label: "High",
                },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
            />
          </div>

          {/* Buttons */}
          <div className="tw-flex tw-justify-around tw-mt-4">
            <button
              type="button"
              className="tw-bg-yellow-500 tw-text-white tw-px-6 tw-py-2 tw-rounded-md hover:tw-bg-yellow-600"
            >
              Apply Filter
            </button>
            <button
              type="button"
              className="tw-border tw-text-gray-700 tw-px-6 tw-py-2 tw-rounded-md hover:tw-bg-gray-400"
              onClick={closeModal}
            >
              Clear Filter
            </button>
          </div>
        </form>
      </div>
    );
  };

  const Modal = ({ isOpen, handleClose }) => {
    if (!isOpen) return null; // Don't render the modal if it's not open

    return (
      <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center">
        <div className="tw-bg-white tw-rounded-lg tw-p-8 tw-w-[600px] tw-relative">
          <h2 className="tw-text-lg tw-font-bold tw-mb-4">Alert Setup</h2>

          {/* Domain Input */}
          <div className="tw-mb-4">
            <label className="tw-block tw-mb-1 tw-text-sm tw-font-medium">
              Domain
            </label>
            <InputGroup
              placeholder="Enter full name"
              className="tw-w-full tw-bg-[#F5F6F8] tw-p-2 tw-rounded-md"
            />
          </div>

          <div className="tw-mb-4">
            <GenericDropdown
              htmlfor="Conditions"
              labelText="Conditions"
              inputName="country"
              options={[
                { value: "", label: "Select" },
                {
                  value: "COFFEE_SHOP",
                  label:
                    "Alert me if a new similar domain appears that has a NS Record",
                },
                {
                  value: "RESTAURANT",
                  label:
                    "Alert me if a similar domain changes the domain status (New a record)",
                },
                {
                  value: "RETAIL_SHOP",
                  label:
                    "Alert me if a similar domain changes the webservice (http or https appers)",
                },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
            />
          </div>

          {/* Action Buttons */}
          <div className="tw-flex tw-gap-2">
            <CustomButton
              text="Save"
              className="tw-bg-black tw-text-white tw-py-2 tw-px-6 tw-rounded-lg"
              onClick={handleClose} // You can replace this with actual save logic
            />
            <CustomButton
              text="Cancel"
              className="tw-bg-white tw-text-black tw-border tw-py-2 tw-px-6 tw-rounded-lg"
              onClick={handleClose} // Close the modal when cancel is clicked
            />
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    { Header: "Created", accessor: "created" },
    { Header: "Brand", accessor: "brand" },
    { Header: "Date", accessor: "date" },
    { Header: "MX", accessor: "mx" },
    { Header: "NX", accessor: "nx" },
    { Header: "WHOIS", accessor: "whois" },
    { Header: "WEB", accessor: "web" },
    { Header: "Category", accessor: "category" },
    { Header: "Spoofing", accessor: "spoofing" },
    { Header: "Risk", accessor: "risk" },
    {
      Header: "Screenshot",
      accessor: "screenshot",
      render: (_, record) => {
        return <img src={record.screenshot} alt="" className="tw-m-auto" />;
      },
    },
  ];

  const data = [
    {
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },
    {
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },{
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },{
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },{
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },{
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },{
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },{
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },{
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },{
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },{
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },{
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },
    {
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },
    {
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },
    {
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },
    {
      created: "Examplewebsite.com",
      brand: "True",
      date: "2024-06-17",
      mx: "True",
      nx: "True",
      whois: "Anonymous",
      web: "True",
      category: "Parked",
      spoofing: "Lorerm Ipsum",
      risk: "34",
      screenshot: view,
    },
  ];
  return (
    <div>
      {!isInvestigating ? (
        <>
          <div className="tw-flex tw-gap-2  tw-mt-1 tw-pb-6">
            <InputGroup
              className="tw-border tw-border-[#D9D9D9]  tw-text-base tw-px-2 tw-pb-2 tw-rounded-lg  tw-w-full"
              placeholder="Keyword separated by comma, no empty spaces (example: brand1,namex)"
              value={inputValue}
              onChange={handleInputChange}
            />
            <CustomButton
              type="button"
              text="Search"
              textColor="tw-text-[#44464A]"
              className="tw-text-white tw-py-2 tw-px-[14px] tw-bg-dark"
              borderRadius="tw-rounded-lg"
              fontWeight="tw-font-medium"
              fontSize="tw-text-sm"
              onClick={handleInvestigate}
              icon={<SearchIcon fill="#ffffff" />}

            />
          </div>
          <div className="tw-mt-2">
            <NoResult />
          </div>
        </>
      ) : (
        <div>
          <div className="tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 tw-mt-4">
            <Heading size="h3" text="Investigate Potential Domain Spoofing" />
            <p className="tw-text-[#979BA5] tw-text-sm tw-my-1">
              Find out if the domains that run a website are potentially
              malicious or contains your brand/organization name
            </p>
            <div className="tw-mt-4">
              <InputLabel text="Define your Brand/Name" />
              <div className="tw-flex tw-gap-2 brandSearch tw-mt-1">
                <InputGroup
                  className="tw-border tw-border-[#D9D9D9] !tw-bg-gray-100 tw-text-base tw-px-2 tw-py-2 tw-rounded-lg   tw-w-full"
                  placeholder="Keyword separated by comma, no empty spaces (example: brand1,namex"
                />
                <CustomButton
                  type="button"
                  text="Investigate"
                  textColor="tw-text-[#44464A]"
                  className="tw-text-white tw-py-2 tw-px-[14px] tw-bg-dark"
                  borderRadius="tw-rounded-lg"
                  fontWeight="tw-font-medium"
                  fontSize="tw-text-sm"
                />
              </div>
            </div>
          </div>
          <div className="tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 tw-mt-4">
            {!isShow ? (
              <>
                <div className="tw-flex tw-items-center tw-justify-between tw-flex-col sm:tw-flex-row">
                  <div
                    className="tw-flex tw-gap-3 tw-items-center tw-cursor-pointer"
                    onClick={() => setIsShow((show) => !show)}
                  >
                    <TbArrowBackUp className="tw-mb-4 tw-text-xl" />
                    <Heading size="h2" text="Spoofing domain" />
                  </div>
                  <div className="tw-flex tw-mb-4 tw-gap-3 tw-flex-col sm:tw-flex-row">
                    <CustomButton
                      type="button"
                      text="Filter"
                      className="tw-border-[#DFE0E3] tw-gap-2 tw-border tw-flex tw-items-center tw-py-2 tw-px-[14px]"
                      textColor="tw-text-[#44464A]"
                      borderRadius="tw-rounded-lg"
                      fontSize="tw-text-sm"
                      fontWeight="tw-font-medium"
                      icon={<VscListFilter />}
                      onClick={openFilterModal}
                    />

                    <CustomButton
                      type="button"
                      text="Monitor Typosquatted Domains"
                      textColor="tw-text-[#44464A]"
                      className="tw-border-dark tw-border tw-py-2 tw-px-[14px]"
                      borderRadius="tw-rounded-lg"
                      fontWeight="tw-font-medium"
                      fontSize="tw-text-sm"
                      onClick={handleMonitorModel}
                    />

                    <CustomButton
                      type="button"
                      text="Run a New Search"
                      textColor="tw-text-white"
                      bgColor="tw-bg-dark"
                      fontSize="tw-text-sm"
                      fontWeight="tw-font-medium"
                      className="tw-border-dark tw-border tw-py-2 tw-px-[14px]"
                      borderRadius="tw-rounded-lg"
                      onClick={handleNewRunInvestigate}
                    />
                  </div>
                </div>
                <CustomTable data={data} columns={columns} selectable={true} />
                <Modal isOpen={isModalOpen} handleClose={handleCloseModal} />
                <FilterFormModal
                  showModal={isFilterModalOpen}
                  closeModal={closeFilterModal}
                />
              </>
            ) : (
              <CyberDetection setIsShow={setIsShow} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
