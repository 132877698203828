import React, { useState } from 'react';
import Modal from '../../../../../common/components/Modal/Modal';
import ReadMore from '../../../../../common/components/ReadMore/ReadMore';
import BellYellow from '../../../../../common/Icons/BellYellow';
import CustomButton from '../../../../../common/components/Button/Button';
import Feedback from '../../../../../common/Icons/Feedback';
import Heading from '../../../../../common/components/heading/Heading';
import DbUpload from '../../../../../common/Icons/DbUpload';
import DropdownList from '../../../../../common/Icons/DropdownList';
import Table from '../../../../common/table/table.component';
import EyeIcon from '../../../../../common/Icons/EyeIcon';
import Delete from '../../../../../components/assets/svgs/Delete';
import DownloadIcon from '../../../../assets/svgs/DownloadIcon';
import ExportButton from '../../../../../common/components/Export/ExportButton';
const TelegramDetail = ({ setSearch, selectedOptionLabel, searchTerm }) => {
  console.log({ setSearch });
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDetailView, setIsDetailView] = useState(false);
  const [isDetailViewModal, setIsDetailViewModal] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openSearchTips, setOpenSearchTips] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  const columns = [
    {
      Header: 'Channel Name',
      accessor: 'channelName',
      render: (_, record) => {
        return <span className=" tw-text-[#44464A]">{record.channelName}</span>;
      },
    },
    {
      Header: 'Search Status',
      accessor: 'searchStatus',
      render: (_, record) => {
        return <span className="tw-text-[#44464A]">{record.searchStatus}</span>;
      },
    },
    {
      Header: 'Details',
      accessor: 'details',
      render: (_, record) => {
        return <span className="tw-text-[#44464A]">{record.details}</span>;
      },
    },

    {
      Header: 'Actions',
      accessor: 'action',
      render: (_, item) => {
        return (
          <button
            className="tw-font-medium"
            onClick={() => {
              setIsDetailViewModal(true);
            }}
          >
            View All
          </button>
        );
      },
    },

    // {
    //   Header: "Actions",
    //   accessor: "action",
    //   render: (_, item) => {
    //     return (
    //       <div className="tw-flex tw-gap-2">
    //         <button>
    //           <EyeIcon color="#71747C" />
    //         </button>
    //         <button>
    //           <Delete color="#71747C" />
    //         </button>
    //       </div>
    //     );
    //   },
    // },
  ];
  const data = [
    {
      channelName: '4/21/12',
      searchStatus: 'example.com',
      details: 'N/A',
    },
  ];

  const sideData = [
    {
      site: 'Example.com',
      status: 'Offline',
    },
    {
      site: 'Example.com',
      status: 'Online',
    },
    {
      site: 'Example.com',
      status: 'Offline',
    },
    {
      site: 'Example.com',
      status: 'Online',
    },
    {
      site: 'Example.com',
      status: 'Offline',
    },
    {
      site: 'Example.com',
      status: 'Online',
    },
    {
      site: 'Example.com',
      status: 'Offline',
    },
    {
      site: 'Example.com',
      status: 'Online',
    },
    {
      site: 'Example.com',
      status: 'Offline',
    },
  ];

  return (
    <>
      {/* FEEDBACK IF OPEN */}
      {openFeedback && (
        <Modal
          isOpen={openFeedback}
          title="Feedback Form"
          onClose={() => setOpenFeedback(false)}
          className="tw-w-full tw-max-w-[416px]"
        >
          <div className="tw-flex tw-flex-col tw-gap-2 tw-border-b tw-border-solid tw-border-[#EFF0F2] tw-p-4">
            <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#35363A]">
              Are we missing a leak? Do you know a site we should include a
              crawl? Noticed a bug?
            </div>
            <CustomTextArea
              className="tw-w-full"
              textAreaClassName="tw-border tw-border-solid tw-border-[#DFE0E3] tw-rounded-lg"
            />
            <div className="tw-flex tw-items-center">
              <input type="checkbox" className="tw-mr-2 custom-checkbox" />
              <span className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#35363A]">
                Send Anonymous
              </span>
            </div>
          </div>
          <div className="tw-flex tw-gap-2 tw-mt-2 tw-px-4">
            <CustomButton className="tw-text-sm tw-font-semibold tw-leading-6 tw-w-full tw-max-w-[73px] tw-h-[42px] tw-mt-2 tw-bg-black tw-text-white tw-rounded">
              Submit
            </CustomButton>
            <CustomButton
              onClick={() => setOpenFeedback(false)}
              className="tw-text-sm tw-font-semibold tw-leading-6 tw-w-full tw-max-w-[73px] tw-h-[42px] tw-mt-2 tw-border tw-border-solid tw-border-[#DFE0E3] tw-text-black tw-rounded"
            >
              Cancel
            </CustomButton>
          </div>
        </Modal>
      )}
      {/* MODALS IF OPEN */}
      <Modal
        title="Search Tips"
        isOpen={openSearchTips}
        onClose={() => setOpenSearchTips(false)}
        className="tw-w-full tw-max-w-[700px]"
      >
        <div className="tw-p-4">
          <ReadMore
            size={446}
            text='For the search in hacker forums it should be noted that we transmit all search terms live to the search functions of the hacker forums and the administrators thus have access to such search logs. It may well be that hackers share information about specific leaks and organizations, which is why entering a domain or company name may well show results. In most cases, however, hackers do not share data with such a precise specification. They are often referred to as combo lists, leaks, accounts, etc. from a particular geographic region, industry type or service. The details of the data are usually not disclosed. This must be taken into account in the search and be creative in the query. The more generic your search term is, but still has associations with the target, the more likely you are to find what you are looking for. For example, if an Austrian bank wants to know whether logins have been lost, you could search for "bank", "login" and the country "Austria".'
          />
        </div>
      </Modal>
      {selectedOptionLabel !== 'Search Everywhere' && (
        <div className="tw-flex tw-justify-between lg:tw-items-center tw-items-start lg:tw-flex-row tw-flex-col tw-bg-[#ffffff] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px]">
          <div className="tw-flex tw-items-center tw-gap-[9.45px]">
            <BellYellow />
            <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
              If you didn't find what you were looking for, try refining your
              search or click 'New search' to start again.
            </div>
          </div>

          <CustomButton
            text="New Search"
            onClick={() => setSearch(false)}
            className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
          />
        </div>
      )}

      {/*DETAIL VIEW TABLE */}
      <div className="tw-grid tw-grid-cols-4 tw-gap-3 tw-mt-6">
        <div
          className={
            'tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4  lg:tw-col-span-3 tw-col-span-4'
          }
        >
          <div className="tw-flex tw-items-center tw-justify-between">
            <div>
              <Heading
                size="h2"
                text={`${selectedOptionLabel} Search Details`}
              />
              <span>{searchTerm}</span>
            </div>

            <ExportButton />
          </div>

          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
            <div className="tw-flex tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">
                Search results
              </span>
              <span className="tw-text-sm tw-font-medium">{data.length}</span>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">Search term</span>
              <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
            </div>
          </div>
          <Table data={data} columns={columns} />
        </div>

        <div className="lg:tw-col-span-1 tw-col-span-4 tw-bg-white tw-rounded-lg tw-p-4 tw-overflow-auto">
          <Heading size="h3" text="Site Status" />

          <div>
            <div className="tw-bg-[#F4F7FC] tw-text-sm tw-w-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-font-medium tw-justify-between">
              <span>Site</span>
              <span className="tw-pr-[14px]">Status</span>
            </div>
            <div className="tw-overflow-y-auto tw-h-[80vh]">
              {sideData.map((data, index) => {
                return (
                  <div
                    className="tw-w-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-justify-between"
                    key={index}
                  >
                    <span className="tw-text-[#5B5D63] tw-text-sm">
                      {data.site}
                    </span>
                    <span
                      className={
                        data.status === 'Offline'
                          ? 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]'
                          : 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]'
                      }
                    >
                      {data.status}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TelegramDetail;
