import * as React from "react";

function Play(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3499_17869)">
        <path
          d="M10.0003 18.3333C14.6027 18.3333 18.3337 14.6024 18.3337 9.99999C18.3337 5.39762 14.6027 1.66666 10.0003 1.66666C5.39795 1.66666 1.66699 5.39762 1.66699 9.99999C1.66699 14.6024 5.39795 18.3333 10.0003 18.3333Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.91699 7.4711C7.91699 7.07336 7.91699 6.87449 8.00011 6.76347C8.07254 6.66672 8.18342 6.60619 8.30397 6.59758C8.44231 6.5877 8.6096 6.69524 8.94416 6.91031L12.878 9.4392C13.1683 9.62584 13.3135 9.71916 13.3636 9.83782C13.4074 9.94149 13.4074 10.0585 13.3636 10.1622C13.3135 10.2808 13.1683 10.3741 12.878 10.5608L8.94416 13.0897C8.6096 13.3047 8.44231 13.4123 8.30397 13.4024C8.18342 13.3938 8.07254 13.3333 8.00011 13.2365C7.91699 13.1255 7.91699 12.9266 7.91699 12.5289V7.4711Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3499_17869">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Play;
