import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const search = createAsyncThunk(
  'search/database/cloudStorage/search',
  async ({ query }, { getState, dispatch, rejectWithValue }) => {
    let progressValue = 0,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (!isError) {
        if (step === 0 && progressValue < 10) {
          progressValue++;
          dispatch(setProgress(progressValue));

          await delay(50);
          requestAnimationFrame(mockProgress);
        } else if (step === 1 && progressValue < 75) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(600);
          requestAnimationFrame(mockProgress);
        } else if (step === 2 && progressValue < 95) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(500);
          requestAnimationFrame(mockProgress);
        } else if (step === 3 && progressValue < 100) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(50);
          requestAnimationFrame(mockProgress);
        }
      }
    };

    if (getState().search.main.selectedOptionId === 0) {
      // Domain option
      const firstQuery = query.split('.')[0];
      try {
        requestAnimationFrame(mockProgress);
        dispatch(setQuery(firstQuery));
        let requestResponse = await api.post(
          'service/create_bucket_search_request/',
          {
            search_term: firstQuery,
          },
        );

        progressValue = 10;
        step = 1;
        requestAnimationFrame(mockProgress);
        let resultOverflow = false;
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const statusResponse = await api.get(
            `service/get_bucket_search_request/${requestResponse.data.id}/`,
          );

          if (statusResponse.data.status === 'finished') {
            resultOverflow = statusResponse.data.has_more_result_than_limit;
            break;
          }
          await delay(2500);
        }

        // if results is more than 5000...
        if (resultOverflow) {
          progressValue = 0;
          step = 0;
          requestAnimationFrame(mockProgress);
          dispatch(setQuery(query));
          requestResponse = await api.post(
            'service/create_bucket_search_request/',
            {
              search_term: query,
            },
          );

          progressValue = 10;
          step = 1;
          requestAnimationFrame(mockProgress);
          // eslint-disable-next-line no-constant-condition
          while (true) {
            const statusResponse = await api.get(
              `service/get_bucket_search_request/${requestResponse.data.id}/`,
            );

            if (statusResponse.data.status === 'finished') break;
            await delay(2500);
          }

          progressValue = 75;
          step = 2;
          requestAnimationFrame(mockProgress);
          const resultResponse = await api.get(
            'service/list_bucket_search_result/',
            {
              params: { searchrequest: requestResponse.data.id },
            },
          );

          progressValue = 95;
          step = 3;
          requestAnimationFrame(mockProgress);
          await api.delete(
            `service/delete_bucket_search_request/${requestResponse.data.id}/`,
          );

          step = 4;

          return resultResponse.data;
        } else {
          progressValue = 75;
          step = 2;
          requestAnimationFrame(mockProgress);
          const resultResponse = await api.get(
            'service/list_bucket_search_result/',
            {
              params: { searchrequest: requestResponse.data.id },
            },
          );

          progressValue = 95;
          step = 3;
          requestAnimationFrame(mockProgress);
          await api.delete(
            `service/delete_bucket_search_request/${requestResponse.data.id}/`,
          );

          step = 4;

          return resultResponse.data;
        }
      } catch (error) {
        isError = true;
        return rejectWithValue(error.response.data || 'Search data failed');
      }
    } else {
      try {
        requestAnimationFrame(mockProgress);
        dispatch(setQuery(query));
        const requestResponse = await api.post(
          'service/create_bucket_search_request/',
          {
            search_term: query,
          },
        );

        progressValue = 10;
        step = 1;
        requestAnimationFrame(mockProgress);
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const statusResponse = await api.get(
            `service/get_bucket_search_request/${requestResponse.data.id}/`,
          );

          if (statusResponse.data.status === 'finished') break;
          await delay(2500);
        }

        progressValue = 75;
        step = 2;
        requestAnimationFrame(mockProgress);
        const resultResponse = await api.get(
          'service/list_bucket_search_result/',
          {
            params: { searchrequest: requestResponse.data.id },
          },
        );

        progressValue = 95;
        step = 3;
        requestAnimationFrame(mockProgress);
        await api.delete(
          `service/delete_bucket_search_request/${requestResponse.data.id}/`,
        );

        step = 4;

        return resultResponse.data;
      } catch (error) {
        isError = true;
        return rejectWithValue(error.response.data || 'Search data failed');
      }
    }
  },
);

const cloudStorageSlice = createSlice({
  name: 'cloudStorage',
  initialState: {
    query: '',
    list: [],
    totalElements: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload;
        state.totalElements = state.list.length;

        state.loading = false;
        state.progress = 100;
      })
      .addCase(search.rejected, (state, action) => {
        state.list = [];
        state.totalElements = 0;
        state.loading = false;
        // state.progress = 0;
        state.error = action.payload;
      })
      .addCase(searchActions.resetProgress, (state) => {
        state.progress = 0;
      });
  },
});

const { setQuery, setProgress } = cloudStorageSlice.actions;
export default cloudStorageSlice.reducer;
