import React from "react";
import Chart from "react-apexcharts";

const GroupAttacksChart = () => {
  const values = [263, 216, 198, 226, 78, 65, 35];

  const data = {
    series: values,
    options: {
      chart: {
        type: "donut",
        height: 450,
      },
      labels: [
        "Lockbit 3",
        "Lockbit2",
        "Alphv",
        "Play",
        "Bianlian",
        "Clop",
        "Blackbasta",
      ],
      colors: [
        "#FF6384",
        "#4BC0C0",
        "#36A2EB",
        "#FF9F40",
        "#FFCE56",
        "#FF9F80",
        "#FFCDD2",
      ],
      legend: {
        position: "bottom",
      },
      // title: {
      //   text: "Group Attacks (No Wise)",
      //   align: "left",
      //   style: {
      //     fontSize: "18px",
      //     fontWeight: "bold",
      //     color: "#333",
      //     marginBottom: "2px",
      //   },
      // },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        },
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          colors: ["#FFFFFF"],
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div className="tw-bg-white tw-rounded-lg tw-px-2 tw-pt-4">
      <p className="tw-font-medium tw-text-lg tw-px-6 ">
        Group Attacks (No Wise)
      </p>

      <Chart
        options={data.options}
        series={data.series}
        type="donut"
        height={460}
      />
    </div>
  );
};

export default GroupAttacksChart;
