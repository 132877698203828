import cart from "../assets/svgs/case-1.svg";
import cart2 from "../assets/svgs/case-2.svg";
import cart3 from "../assets/svgs/case-3.svg";
import cart4 from "../assets/svgs/case-4.svg";

export const RANSOM_WARE = [
  {
    id: 1,
    title: "Ransomware Cases Last 24H",
    result: "7456",
    icon: cart,
  },
  {
    id: 2,
    title: "Ransomware Cases Overall",
    result: "198250",
    icon: cart2,
  },
  {
    id: 3,
    title: "Targeted Countries",
    result: "68",
    icon: cart3,
  },
  {
    id: 4,
    title: "Targeted Regions",
    result: "110",
    icon: cart4,
  },
];
