import React, { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { List } from '@phosphor-icons/react';

import Header from 'components/common/header/Header';
import Sidebar from 'components/common/sidebar/Sidebar';
import CrossIcon from 'common/Icons/CrossIcon';
import FacebookIcon from 'common/Icons/FacebookIcon';
import InstaIcon from 'common/Icons/InstaIcon';
import TwitterIcon from 'common/Icons/TwitterIcon';

import { getUserInfo } from 'store/slices/user';

function DashboardLayout() {
  const sideRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  const toggleSide = () => {
    sideRef.current.classList.toggle('show_side');
  };
  const hideSide = () => {
    sideRef.current.classList.remove('show_side');
  };
  return (
    <div className="tw-w-full">
      <div className="tw-flex tw-items-center tw-gap-1 ">
        <div
          className="md:tw-hidden tw-z-4 tw-text-primary tw-pl-1"
          onClick={toggleSide}
        >
          <List />
        </div>
        <div className="tw-w-full">
          <Header />
        </div>
      </div>

      <div className="tw-flex">
        <div
          ref={sideRef}
          className="tw-hidden md:tw-block md:tw-static tw-bg-[#111] tw-text-white tw-py-4 md:tw-pl-4 tw-w-[240px] tw-absolute tw-z-10 tw-left-0 tw-min-h-[100vh] tw-overflow-hidden"
          onClick={hideSide}
        >
          <div
            className="md:tw-hidden tw-flex tw-justify-end tw-mr-2"
            onClick={hideSide}
          >
            <CrossIcon stroke={'#FFFFFFF'} />
          </div>
          <Sidebar />
        </div>

        <div className="tw-w-full md:tw-w-[calc(100%-240px)] tw-flex tw-flex-col tw-justify-between tw-overflow-hidden">
          <div className="tw-flex-1 tw-bg-gray-100 tw-p-4">
            <Outlet />
          </div>
          <div className="tw-bg-[#FFFFFF] tw-h-[56px] tw-flex tw-justify-between tw-items-center tw-py-4 tw-px-6">
            <div className="tw-text-xs tw-text-[#455A64]">
              © {new Date().getFullYear()} Copyright Darknetsearch. All Rights
              Reserved.
            </div>
            <div className="tw-flex tw-gap-3 tw-items-center">
              <div className="tw-cursor-pointer">
                <FacebookIcon />
              </div>
              <div className="tw-cursor-pointer">
                <InstaIcon />
              </div>
              <div className="tw-cursor-pointer">
                <TwitterIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
