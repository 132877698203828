import React from "react";

const CrossBlackIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3332 4.66699L4.6665 11.3337M4.6665 4.66699L11.3332 11.3337"
        stroke="#0B0B0B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossBlackIcon;
