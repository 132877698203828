import React, { useState } from "react";
import InputGroup from "../../../common/components/InputGroup/InputGroup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";
import Modal from "../../../common/components/Modal/Modal";

const Feedback = ({ isOpen, onClose, title }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-py-4 tw-p-4">
          <div className="tw-mb-4 tw-flex tw-flex-col tw-gap-2">
            <p>
              Are we missing a leak? Do you know a site we should include a
              crawl? Noticed a bug?
            </p>
            <InputGroup
              //   labelText="Description"
              inputType="textarea"
              placeholder="Enter your feedback"
              inputName="description"
              register={register}
              errors={errors}
              focusOnType={true}
            />
          </div>
          <div className="tw-flex tw-gap-[10px] tw-items-center">
            <input type="checkbox" name="asAnon" id="asAnon" />{" "}
            <p>Send Anonymous</p>
          </div>
        </div>
        <div className="tw-flex tw-justify-start tw-gap-4 tw-border-t tw-p-4">
          <button
            type="submit"
            className="tw-bg-blue-500 tw-bg-primary tw-text-white tw-px-4 tw-py-2 tw-rounded-lg"
          >
            Submit
          </button>
          <button
            type="button"
            onClick={onClose}
            className="tw-bg-gray-300 tw-px-4 tw-py-2 tw-rounded-lg"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default Feedback;
