import React, { useRef, useState } from "react";
import Heading from "../../../common/components/heading/Heading";
import CustomButton from "../../../common/components/Button/Button";
import InputLabel from "../../../common/components/InputLabel/InputLabel";
import InputGroup from "../../../common/components/InputGroup/InputGroup";
import DatePicker from "react-datepicker";
import { CiCalendarDate } from "react-icons/ci";
import { AiOutlineClose } from "react-icons/ai"; // Importing close icon from React Icons

const ReportCreate = ({ isShow, setModalOpen }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const endDateRef = useRef(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date) {
      setTimeout(() => {
        endDateRef.current.setFocus();
      }, 100);
    }
  };

  return (
    <div>
      <div className="tw-flex tw-justify-between tw-items-center">
        {/* Heading on the left and Close icon on the right */}
        <Heading size="h2" text={isShow} />
        <button onClick={() => setModalOpen(false)} className="tw-text-gray-600">
          <AiOutlineClose className="tw-text-xl tw-cursor-pointer" />
        </button>
      </div>
      
      <div className="tw-mt-2">
        <p className="tw-text-dark tw-font-medium tw-mb-2">Research period</p>
        <div className="tw-flex tw-gap-4 tw-mt-4">
          <span className="tw-flex tw-gap-2">
            <input type="radio" id="html" name="time" value="HTML" />
            <label for="html" className="tw-text-[#44464A] tw-text-sm">
              Past 24 month
            </label>
          </span>
          <span className="tw-flex tw-gap-2">
            <input type="radio" id="css" name="time" value="CSS" />
            <label for="css" className="tw-text-[#44464A] tw-text-sm">
              Custom time
            </label>
          </span>
        </div>

        <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-3 tw-mt-4">
          <div className="md:tw-w-3/6 tw-h-full">
            <InputLabel
              text="From date"
              className="tw-text-[#263238] tw-text-sm"
            />
            <div className="tw-border tw-border-[#DFE0E3] tw-bg-[#F5F6F8] tw-text-base tw-rounded-lg tw-py-1 tw-pr-4 tw-relative custom__picker tw-w-full">
              <DatePicker
                showIcon
                selected={startDate}
                onChange={handleStartDateChange}
                placeholderText="Start Date"
                className="tw-bg-[#F5F6F8]"
                icon={
                  <CiCalendarDate
                    style={{ fontSize: "19px", color: "#71747C" }}
                  />
                }
              />
            </div>
          </div>
          <div className="md:tw-w-3/6 tw-h-full">
            <InputLabel
              text="To date"
              className="tw-text-[#263238] tw-text-sm"
            />
            <div className="tw-border tw-border-[#DFE0E3] tw-bg-[#F5F6F8] tw-text-base tw-rounded-lg tw-py-1 tw-pr-4 tw-relative custom__picker tw-w-full">
              <DatePicker
                ref={endDateRef}
                showIcon
                selected={endDate}
                className="tw-bg-[#F5F6F8]"
                onChange={(date) => setEndDate(date)}
                placeholderText="End Date"
                icon={
                  <CiCalendarDate
                    style={{ fontSize: "19px", color: "#71747C" }}
                  />
                }
              />
            </div>
          </div>
        </div>

        <div className="tw-mt-4">
          <InputLabel text="Domain" className="tw-text-[#263238] tw-text-sm" />
          <InputGroup
            className=" tw-bg-[#F5F6F8] tw-text-base tw-px-4 tw-py-[10px] tw-rounded-lg input investigate tw-w-full"
            placeholder="Enter your domain example.com (do not use the subdomain or http, https)"
          />
        </div>
      </div>

      <div className="tw-flex tw-gap-3 tw-mt-5">
        <CustomButton
          type="button"
          text="Create Report"
          textColor="tw-text-[#44464A]"
          className="tw-text-white tw-py-2 tw-px-[14px] tw-bg-dark"
          borderRadius="tw-rounded-lg"
          fontWeight="tw-font-medium"
          fontSize="tw-text-sm"
        />
        <CustomButton
          type="button"
          text="Cancel"
          textColor="tw-text-[#44464A]"
          className="tw-text-[#344054] tw-border tw-border-[#DFE0E3] tw-py-2 tw-px-[14px] tw-bg-white"
          borderRadius="tw-rounded-lg"
          fontWeight="tw-font-medium"
          fontSize="tw-text-sm"
          onClick={() => setModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default ReportCreate;
