import React from "react";
import Chart from "react-apexcharts";

const GroupPercentChart = () => {
  const data = {
    series: [263, 216, 198, 226, 78, 65, 35],
    options: {
      chart: {
        type: "donut",
        height: 350,
      },
      labels: [
        "Lockbit 3",
        "Lockbit2",
        "Alphv",
        "Play",
        "Bianlian",
        "Clop",
        "Blackbasta",
      ],
      colors: [
        "#FF6384",
        "#4BC0C0",
        "#36A2EB",
        "#FF9F40",
        "#FFCE56",
        "#FF9F80",
        "#FFCDD2",
      ],
      legend: {
        position: "bottom",
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div className="tw-bg-white tw-shadow-lg tw-rounded-lg tw-pt-4">
      <p className="tw-font-medium tw-text-lg tw-px-6 ">
        Group Attacks (%) Wise
      </p>

      <Chart
        options={data.options}
        series={data.series}
        type="donut"
        height={460}
      />
    </div>
  );
};

export default GroupPercentChart;
