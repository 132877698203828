import _ from 'lodash';
import React, { useState, useRef } from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { CiCalendarDate } from 'react-icons/ci';
import CrossBlackIcon from 'common/Icons/CrossBlackIcon';
import CustomButton from 'common/components/Button/Button';
import InputGroup from 'common/components/InputGroup/InputGroup';

const AdvanceFilter = ({ open, close }) => {
  const [range, setRange] = useState([476, 5842]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const endDateRef = useRef(null);

  const handleRangeChange = (index, value) => {
    if (_.isNaN(value)) {
      value = 0;
    }

    const newRange = [...range];
    newRange[index] = value;
    setRange(newRange);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date) {
      setTimeout(() => {
        endDateRef.current.setFocus();
      }, 100);
    }
  };

  return (
    <div>
      {open && (
        <div className="tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-w-[540px] tw-bg-white tw-z-50 tw-overflow-y-auto tw-shadow-lg">
          <div className="tw-flex tw-flex-col tw-gap-6 tw-p-6">
            <div className="tw-h-full tw-flex tw-justify-between">
              <p className="tw-text-lg tw-font-bold tw-leading-[30px]">
                Advance Filters
              </p>
              <div
                className="tw-flex tw-justify-end tw-h-5 tw-w-5"
                onClick={() => close(false)}
              >
                <CrossBlackIcon />
              </div>
            </div>
            <div className="tw-flex tw-flex-col">
              <div className="tw-flex tw-flex-col tw-mt-3">
                <div className="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#263238]">
                  File size range
                </div>
                <div className="tw-w-full tw-flex tw-justify-between tw-items-center tw-mt-5">
                  <InputGroup
                    type="number"
                    value={range[0]}
                    onChange={(e) =>
                      handleRangeChange(0, parseInt(e.target.value))
                    }
                    className="tw-w-full tw-mr-2 tw-px-4 tw-py-2 tw-border tw-rounded tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#0B0B0B] tw-bg-[#F5F6F8]"
                    placeholder="File size start range (bytes)"
                  />
                  <InputGroup
                    type="number"
                    value={range[1]}
                    onChange={(e) =>
                      handleRangeChange(1, parseInt(e.target.value))
                    }
                    className="tw-w-full tw-ml-2 tw-px-4 tw-py-2 tw-border tw-rounded tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#0B0B0B] tw-bg-[#F5F6F8]"
                    placeholder="File size end range (bytes)"
                  />
                </div>

                <div className="tw-w-full tw-flex tw-justify-between tw-items-center tw-my-5">
                  <RangeSlider
                    id="range-slider-yellow"
                    max={10000}
                    value={range}
                    onInput={setRange}
                  />
                </div>
                <div></div>
              </div>
              <div className="tw-flex tw-flex-col tw-mt-3">
                <div className="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#263238]">
                  Last modify range
                </div>
                <div className="tw-w-full tw-flex tw-justify-between tw-items-center tw-gap-4 tw-mt-5">
                  <div className="tw-w-full tw-relative tw-border tw-border-[#DFE0E3] tw-bg-[#F5F6F8] tw-text-base tw-rounded-lg tw-py-1 custom__picker">
                    <DatePicker
                      showIcon
                      selected={startDate}
                      onChange={handleStartDateChange}
                      placeholderText="Start Date"
                      className="tw-bg-[#F5F6F8]"
                      icon={
                        <CiCalendarDate
                          style={{ fontSize: '19px', color: '#71747C' }}
                        />
                      }
                    />
                  </div>
                  <div className="tw-w-full tw-relative tw-border tw-border-[#DFE0E3] tw-bg-[#F5F6F8] tw-text-base tw-rounded-lg tw-py-1 custom__picker">
                    <DatePicker
                      ref={endDateRef}
                      showIcon
                      selected={endDate}
                      className="tw-bg-[#F5F6F8]"
                      onChange={(date) => setEndDate(date)}
                      placeholderText="End Date"
                      icon={
                        <CiCalendarDate
                          style={{ fontSize: '19px', color: '#71747C' }}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="tw-flex tw-gap-4 tw-mt-6">
                <CustomButton
                  text="Apply"
                  className="tw-text-sm tw-font-semibold tw-leading-6 tw-rounded-xl tw-bg-[#0B0B0B] tw-text-white tw-px-[14px] tw-py-[6px]"
                />
                <CustomButton
                  text="Cancel"
                  className="tw-text-sm tw-font-semibold tw-leading-6 tw-rounded-xl tw-bg-[#ffffff] tw-text-black tw-px-[14px] tw-py-[6px] tw-border tw-border-solid tw-border-[#DFE0E3]"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvanceFilter;
