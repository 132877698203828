import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import CustomButton from 'common/components/Button/Button';
import Heading from 'common/components/heading/Heading';
import FunnelFilter from 'common/Icons/FunnelFilter';
import BellYellow from 'common/Icons/BellYellow';
import { useOutsideClick } from 'hooks/useOutsideClick';
import ExportButton from 'common/components/Export/ExportButton';

import Table from 'components/common/table/table.component';

const sideData = [
  {
    site: 'Example.com',
    status: 'Offline',
  },
  {
    site: 'Example.com',
    status: 'Online',
  },
  {
    site: 'Example.com',
    status: 'Offline',
  },
  {
    site: 'Example.com',
    status: 'Online',
  },
  {
    site: 'Example.com',
    status: 'Offline',
  },
  {
    site: 'Example.com',
    status: 'Online',
  },
  {
    site: 'Example.com',
    status: 'Offline',
  },
  {
    site: 'Example.com',
    status: 'Online',
  },
  {
    site: 'Example.com',
    status: 'Offline',
  },
];

const DataPastes = ({ setSearch, selectedOptionLabel, searchTerm }) => {
  const { list, totalElements } = useSelector(
    (state) => state.search.live.dataPaste,
  );

  const size = 10;

  const columns = [
    {
      Header: <div className="tw-whitespace-nowrap">Search Term</div>,
      accessor: 'search_term',
      render: (_) => <span className=" tw-text-[#44464A]">{searchTerm}</span>,
    },
    {
      Header: 'Link',
      accessor: 'link',
      render: (value) => (
        <a href={value} target="_blank">
          <span className="tw-text-[#00B6FF] tw-block tw-break-all">
            {value}
          </span>
        </a>
      ),
    },
    {
      Header: 'Date',
      accessor: 'appeared_date',
      render: (value) => <span className=" tw-text-[#44464A]">{value}</span>,
    },
    {
      Header: 'Source',
      accessor: 'source',
      render: (value) => <span className="tw-capitalize tw-text-[#44464A]">{value}</span>,
    },
  ];

  const [exportDropdown, setExportDropdown] = useState(false);
  const exportDropdownRef = useRef(null);
  useOutsideClick(exportDropdownRef, () => setExportDropdown(false));
  const toggleExport = (event) => {
    event.stopPropagation(); // Prevent the click from triggering the outside click handler
    setExportDropdown((prev) => !prev); // Toggle the dropdown open state
  };
  return (
    <>
      {selectedOptionLabel !== 'Search Everywhere' && (
        <div className="tw-flex tw-justify-between lg:tw-items-center tw-items-start lg:tw-flex-row tw-flex-col tw-bg-[#ffffff] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px]">
          <div className="tw-flex tw-items-center tw-gap-[9.45px]">
            <BellYellow />
            <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
              If you didn't find what you were looking for, try refining your
              search or click 'New search' to start again.
            </div>
          </div>

          <CustomButton
            text="New Search"
            onClick={() => setSearch(false)}
            className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
          />
        </div>
      )}

      <div>
        <div className="tw-grid tw-grid-cols-4 tw-gap-3 tw-mt-6">
          <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4 lg:tw-col-span-3 tw-col-span-4">
            <Heading size="h2" text={`${selectedOptionLabel} Search Result`} />

            <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">
                  Search results
                </span>
                <span className="tw-text-sm tw-font-medium">
                  {totalElements}
                </span>
              </div>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">
                  Search term
                </span>
                <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
              </div>
            </div>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
              <div>{/* <p>Showing Data Pastes: 1 — 5 of 349</p> */}</div>
              <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 md:tw-gap-4">
                <CustomButton
                  icon={<FunnelFilter />}
                  text="Filters"
                  // onClick={() => setSearch(false)}
                  className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border tw-border-[#DFE0E3] tw-rounded-md tw-bg-[#ffffff] tw-text-[#0B0B0B] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
                />

                <ExportButton />
              </div>
            </div>

            <Table
              data={list}
              columns={columns}
              paginatorLabel="pastes"
              rowsPerPage={size}
            />
          </div>

          <div className="lg:tw-col-span-1 tw-col-span-4 tw-bg-white tw-rounded-lg tw-p-4 tw-overflow-auto">
            <Heading size="h3" text="Site Status" />

            <div>
              <div className="tw-bg-[#F4F7FC] tw-text-sm tw-w-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-font-medium tw-justify-between">
                <span>Site</span>
                <span className="tw-pr-[14px]">Status</span>
              </div>
              <div className="tw-overflow-y-auto tw-h-[80vh]">
                {sideData.map((data, index) => {
                  return (
                    <div
                      className="tw-w-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-justify-between"
                      key={index}
                    >
                      <span className="tw-text-[#5B5D63] tw-text-sm">
                        {data.site}
                      </span>
                      <span
                        className={
                          data.status === 'Offline'
                            ? 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]'
                            : 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]'
                        }
                      >
                        {data.status}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataPastes;
