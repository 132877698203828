import React, { useState } from "react";
import InputGroup from "../../../common/components/InputGroup/InputGroup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";
import AngryFace from "../../assets/svgs/AngryFace";
import WorriedFace from "../../assets/svgs/WorriedFace";
import ExpressionLessFace from "../../assets/svgs/ExpressionLessFace";
import SmileyFace from "../../assets/svgs/SmileyFace";
import BlowingKissFace from "../../assets/svgs/BlowingKissFace";
import Modal from "../../../common/components/Modal/Modal";

const Rating = ({ isOpen, onClose, title }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(selectedRating);
    alert(`You rated: ${selectedRating}`);
    onClose();
  };

  const [selectedRating, setSelectedRating] = useState(null);
  const ratings = [
    { id: 1, emoji: <AngryFace /> },
    { id: 2, emoji: <WorriedFace /> },
    { id: 3, emoji: <ExpressionLessFace /> },
    { id: 4, emoji: <ExpressionLessFace /> },
    // { id: 4, emoji: <SmileyFace /> },
    { id: 2, emoji: <WorriedFace /> },
    { id: 5, emoji: <BlowingKissFace /> },
  ];

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-py-4 tw-p-8">
          <div>
            <p className="tw-font-medium tw-text-lg tw-mb-2">
              How would you rate your experience?
            </p>
            <div className="tw-flex tw-gap-2">
              {ratings.map((rating) => (
                <button
                  key={rating.id}
                  className={`tw-rounded-full ${
                    selectedRating === rating.id
                      ? "tw-bg-secondary tw-border-2"
                      : ""
                  }`}
                  onClick={() => setSelectedRating(rating.id)}
                >
                  {rating.emoji}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="tw-flex tw-justify-start tw-gap-4 tw-border-t tw-px-8 tw-py-4">
          <button
            type="submit"
            className="tw-bg-blue-500 tw-bg-primary tw-text-white tw-px-6 tw-py-3 tw-rounded-lg"
          >
            Submit
          </button>
          <button
            type="button"
            onClick={onClose}
            className="tw-bg-gray-300 tw-px-6 tw-py-3 tw-rounded-lg"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default Rating;
