import React from "react";

const Loader = React.memo(({ className = "", center = false}) => {
  const Icon = () => (
    <svg
      className={`tw-w-7 tw-h-7 tw-animate-spin tw-text-surface-primary ${className}`}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
    >
      <circle
        className="tw-opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="tw-opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );

  if (center) {
    return (
      <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
        <Icon />
      </div>
    );
  }

  return <Icon />;
});

export default Loader;
