import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import api from 'store/api';
import { useSnackbar, useErrorbar } from 'utils/snackbar';

import Heading from 'common/components/heading/Heading';
import CustomButton from 'common/components/Button/Button';
import NewWindow from '../assets/svgs/NewWindow';
import CustomTable from '../common/table/table.component';
import Delete from '../assets/svgs/Delete';
import Edit from '../assets/svgs/Edit';
import { FaCircle } from 'react-icons/fa';
import CustomModel from 'common/components/Model/Model';
import ReportCreate from './components/report-create.component';
import Loader from 'common/components/Loader/Loader';

import {
  data,
  RECENT_DRKNET,
  RECENT_EXPORT,
  REPORT_OVERVIEW,
} from './report.constant';

import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';

import { getList as getExportList } from 'store/slices/report/export';

export const Reportpage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Scroll to the top of the page on render
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getExportList({}));
  }, [dispatch]);

  const { list, size, currentPage, totalElements, totalPages, loading } =
    useSelector((state) => state.report.export);

  const setCurrentPage = (page) => {
    dispatch(
      getExportList({
        page: page - 1,
      }),
    );
  };

  const handleExportDelete = async (item) => {};

  const [exportDownloading, setExportDownloading] = useState(null);
  const handleExportDownload = async (item) => {
    try {
      setExportDownloading(item.id);

      const downloadResponse = await api.get(
        `service/download_export_file/${item.id}`,
        { responseType: 'blob' },
      );

      const contentType = downloadResponse.headers['content-type'];
      const fileBlob = new Blob([downloadResponse.data], { contentType });
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(fileBlob);
      downloadLink.download = `${item.id}.${item.type.toLowerCase()}`;

      document.body.appendChild(downloadLink);
      downloadLink.click();

      window.URL.revokeObjectURL(downloadLink.href);
      document.body.removeChild(downloadLink);

      useSnackbar('Export downloaded successfully.');
    } catch (error) {
      console.log(error.response);

      useErrorbar('Failed to download.');
    } finally {
      setExportDownloading(null);
    }
  };

  const columns = [
    {
      Header: 'Export ID',
      accessor: 'id',
      render: (value) => <span className=" tw-text-[#44464A]">{value}</span>,
    },
    {
      Header: 'Source',
      accessor: 'sources',
      render: (value) => {
        const sources = value.map((v) => v.replace('_', ' ')).join(', ');
        return (
          <span className="tw-capitalize tw-text-[#44464A]">
            {sources.toLowerCase()}
          </span>
        );
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
      render: (value) => {
        return <span className="tw-text-[#44464A]">{value}</span>;
      },
    },
    {
      Header: 'Date & Time',
      accessor: 'createdAt',
      render: (value) => <span className=" tw-text-[#44464A]">{value}</span>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      render: (_, item) => {
        const status = {
          FINISHED: 'Completed',
          NEW: 'In Progress',
          ERROR: 'Error',
        };
        return (
          <span
            className={`${
              item.status === 'FINISHED'
                ? 'tw-text-[#067647]'
                : item.status === 'NEW'
                  ? 'tw-text-secondary'
                  : item.status === 'ERROR'
                    ? 'tw-text-danger'
                    : ''
            } tw-font-medium tw-flex tw-items-center tw-gap-2`}
          >
            <FaCircle
              className={`${
                item.status === 'FINISHED'
                  ? 'tw-text-[#067647]'
                  : item.status === 'NEW'
                    ? 'tw-text-secondary'
                    : item.status === 'ERROR'
                      ? 'tw-text-danger'
                      : ''
              } tw-text-[10px]`}
            />
            {status[item.status]}
          </span>
        );
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      render: (_, item) => {
        return (
          <div className="tw-flex tw-gap-2">
            <button onClick={() => handleExportDelete(item)}>
              <Delete color="#71747C" />
            </button>
            {item.status != 'ERROR' &&
              (exportDownloading == item.id ? (
                <Loader className="tw-text-[#71747C] !tw-w-5 !tw-h-5" />
              ) : (
                <button
                  onClick={() => handleExportDownload(item)}
                  disabled={exportDownloading != null}
                >
                  <Edit color="#71747C" />
                </button>
              ))}
          </div>
        );
      },
    },
  ];

  const darketColumns = [
    {
      Header: 'Export ID',
      accessor: 'exportId',
      render: (_, date) => {
        return <span className="tw-whitespace-nowrap">{date.date}</span>;
      },
    },
    {
      Header: 'Report Type',
      accessor: 'reportType',
    },
    {
      Header: 'Domain',
      accessor: 'domain',
    },
    {
      Header: 'Date & Time',
      accessor: 'date',
    },
    {
      Header: 'Status',
      accessor: 'status',
      render: (_, item) => {
        return (
          <span
            className={`${
              item.status === 'Completed'
                ? 'tw-text-[#067647]'
                : item.status === 'In Progress'
                  ? 'tw-text-secondary'
                  : ''
            } tw-font-medium tw-flex tw-items-center tw-gap-2`}
          >
            <FaCircle
              className={`${
                item.status === 'Completed'
                  ? 'tw-text-[#067647]'
                  : item.status === 'In Progress'
                    ? 'tw-text-secondary'
                    : ''
              } tw-text-[10px]`}
            />
            {item.status}
          </span>
        );
      },
    },
    {
      Header: 'Actions',
      accessor: 'action',
      render: (_, item) => {
        return (
          <div className="tw-flex tw-gap-2">
            <button>
              <Delete color="#71747C" />
            </button>
            <button>
              <Edit color="#71747C" />
            </button>
          </div>
        );
      },
    },
  ];
  const [isModalOpen, setModalOpen] = useState(false);
  const [isShow, setIsShow] = useState('');

  useEffect(() => {}, [isShow]);

  return (
    <div>
      <Heading size="h2" text="Reports Overview" />
      <div className="tw-flex tw-gap-4 tw-flex-col sm:tw-flex-row">
        {REPORT_OVERVIEW.map((item, i) => {
          return (
            <div
              className={`${
                !item.creation && 'tw-border-secondary report-bg'
              } tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] sm:tw-w-[24%] tw-w-full tw-py-5 tw-px-5 tw-relative`}
              key={i.toString()}
            >
              <div className="tw-mb-4">
                <Heading size="h6" fontWeight="medium" text={item.title} />
              </div>
              {item.creation ? (
                <>
                  <div className="tw-flex tw-justify-between tw-mb-[10px]">
                    <Heading
                      size="h5"
                      fontWeight="semibold"
                      text="Creation time"
                    />
                    <Heading
                      size="h6"
                      fontWeight="normal"
                      text={item.creation}
                    />
                  </div>
                  <div className="tw-flex tw-justify-between tw-mb-[10px]">
                    <Heading size="h5" fontWeight="semibold" text="Length" />
                    <Heading size="h6" fontWeight="normal" text={item.length} />
                  </div>
                </>
              ) : (
                <button className="tw-flex tw-items-center tw-gap-2 tw-font-semibold tw-text-sm sm:tw-absolute tw-static tw-bottom-[17px] tw-transition tw-ease-in-out tw-duration-500 hover:tw-text-secondary">
                  <NewWindow />
                  Open a new browser window
                </button>
              )}
              <div>
                <Heading
                  size="h5"
                  fontWeight="semibold"
                  text={item.creation ? 'Content' : ''}
                />
                <div className="sm:tw-h-[75px] h-[unset] tw-pt-1">
                  <Heading size="h6" fontWeight="normal" text={item.Content} />
                </div>
              </div>
              {item.creation && (
                <div className="tw-mt-2 sm:tw-mt-0">
                  <CustomButton
                    type="button"
                    text="Create Report"
                    textColor="tw-text-white"
                    bgColor="tw-bg-dark"
                    fontSize="tw-text-sm"
                    fontWeight="tw-font-medium"
                    className="tw-border-dark tw-border tw-py-2 tw-px-[14px] tw-transition tw-ease-in-out tw-duration-500 hover:tw-bg-secondary hover:tw-border-secondary"
                    borderRadius="tw-rounded-lg"
                    onClick={() => {
                      setModalOpen(true);
                      setIsShow(item.title);
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className="tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 tw-mt-4 ">
        <Heading size="h2" text="Recent Exports" />
        <div className="tw-relative">
          <Table data={list} columns={columns} selectable={false} />

          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between tw-items-center">
            <div className={loading ? 'tw-invisible' : 'tw-visible'}>
              <p className="tw-my-4">
                Showing Exports:{' '}
                {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
                {currentPage * size > totalElements
                  ? totalElements
                  : currentPage * size}{' '}
                of {totalElements}
              </p>
            </div>
            {totalPages > 1 && (
              <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
          </div>

          {loading && (
            <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
          )}
        </div>
      </div>

      <div className="tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 tw-mt-4">
        <Heading size="h2" text="Recent Darknet Reports" />
        <p className="tw-pb-2 tw-text-[#44464A]">
          Please download or delete reports using the corresponding icons in the
          action column on the right.
        </p>
        <CustomTable
          data={RECENT_DRKNET}
          columns={darketColumns}
          selectable={true}
        />
      </div>
      <CustomModel
        title="Sample Modal"
        children={<ReportCreate isShow={isShow} setModalOpen={setModalOpen} />}
        isOpen={isModalOpen}
      />
    </div>
  );
};
