import React, { useState } from 'react';

import api from 'store/api';
import { useSnackbar, useErrorbar } from 'utils/snackbar';

const useExport = ({
  source,
  sort = 'createdAt,desc',
  size = 10,
  length = 500,
  searchTerm = '',
  mode = 'select',
}) => {
  // mode : select | all

  const [canExport, setCanExport] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [includeIds, setIncludeIds] = useState([]);
  const [excludeIds, setExcludeIds] = useState([]);

  const handleRowSelection = (selectAll, includes, excludes) => {
    setSelectAll(selectAll);
    setIncludeIds(includes);
    setExcludeIds(excludes);

    setCanExport(includes.length > 0);
  };

  const [exporting, setExporting] = useState(false);
  const handleExportOptionChange = async (option) => {
    try {
      setExporting(true);

      const sortOpt = sort.split(',');
      if (sortOpt.length === 1) sortOpt.push('desc');

      const query = {
        size,
        highlight: false,
        query: searchTerm,
      };

      if (mode === 'select') {
        await api.post(`service/export_request/`, {
          type: option,
          sources: [source.toUpperCase()],
          query: JSON.stringify(query),
          includeIdStrategy: selectAll ? 'EXCLUDE' : 'INCLUDE',
          queryIds: selectAll ? excludeIds : includeIds,
          sortField: sortOpt[0],
          sortDirection: sortOpt[1],
        });
      } else {
        await api.post(`service/export_request/`, {
          type: option,
          sources: [source.toUpperCase()],
          query: JSON.stringify(query),
          includeIdStrategy: 'EXCLUDE',
          queryIds: [],
          sortField: sortOpt[0],
          sortDirection: sortOpt[1],
        });
      }

      useSnackbar('Export started, please check Reports & Exports page.');
    } catch (error) {
      console.log(error.response);

      useErrorbar('Failed to export.');
    } finally {
      setExporting(false);
    }
  };

  return {
    canExport,
    handleRowSelection,
    exporting,
    handleExportOptionChange,
  };
};

export default useExport;
