export const SettingsNavBarData = [
  {
    label: "Profile Settings",
    link: "ProfileSettings",
  },
  {
    label: "License Info",
    link: "LicenseInfo",
  },
  {
    label: "API Setup",
    link: "ApiSetup",
  },
  {
    label: "Whitelabeling",
    link: "WhiteLabeling",
  },
  {
    label: "Security",
    link: "Security",
  },
];
