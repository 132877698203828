import React, { useRef, useState, useEffect } from "react";
import { Setting } from "../../../common/Icons";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";
import CustomBubbleChart from "../../charts/bubble_chart/bubble_chart";
import CustomSelect from "../../../common/components/Select/select";
import CompanySettings from "./CompanySettings";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { useNavigate } from "react-router-dom";
import GenericDropdown from "../../../common/components/DropDown/GenericDrop";
import ReactApexChart from "react-apexcharts";
import api from 'store/api';

function Alerts() {
  const [selectedOption, setSelectedOption] = useState("Last Week");
  const [areaChartData, setAreaChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [domains, setDomains] = useState([
    { name: "Show all", checked: false },
    { name: "Blog.thyrocare.com", checked: false },
    { name: "Cliso.thyrocare.com", checked: false },
  ]);
  const handleCheckboxChange = (index) => {
    const newDomains = [...domains];

    // If "Show all" is selected or deselected
    if (newDomains[index].name === "Show all") {
      const isChecked = !newDomains[index].checked;
      newDomains.forEach((domain, idx) => {
        newDomains[idx].checked = isChecked;
      });
    } else {
      newDomains[index].checked = !newDomains[index].checked;
  
      // If any other box is unchecked, uncheck "Show all"
      if (!newDomains[index].checked) {
        newDomains[0].checked = false;
      }
  
      // If all boxes except "Show all" are checked, check "Show all"
      const allChecked = newDomains.slice(1).every(domain => domain.checked);
      if (allChecked) {
        newDomains[0].checked = true;
      }
    }
  
    setDomains(newDomains);
    };
  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const navigate = useNavigate();

  const [isDropdownOpen, setDropdownOpen] = useState(false); 
  const settingsDropdownRef = useRef(null);

  useOutsideClick(settingsDropdownRef, () => setDropdownOpen(false));

  const toggleDropdown = (event) => {
    event.stopPropagation(); 
    setDropdownOpen((prev) => !prev);
  };

  const getPeriodValue = (option) => {
    const index = options.indexOf(option);
    return option_values[index];
  };

  const getAlertHistory = async () => {
    try {
      const periodvalue = getPeriodValue(selectedOption);
      const result = await api.get("service/alert_system_statistic_get_alert_history", {
        params: {
          period: periodvalue,
        },
      });
      setAreaChartData(result.data);
    } catch (error) {
      console.error("Error fetching bubble chart stats:", error);
      throw error;
    }
  }

  useEffect(() => {
    getAlertHistory();
  }, [selectedOption]);

  useEffect(() => {
    if (areaChartData.length > 0) {
      const dates = areaChartData.map(item => item.date);
      const scores = areaChartData.map(item => item.count);

      setChartOptions({
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: dates,
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        colors: ["#ECC551"],
        grid: {
          borderColor: "#555",
          clipMarkers: false,
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        tooltip: {
          enabled: true,
        },
      });

      setChartSeries([
        {
          name: "Alert Count",
          data: scores,
        },
      ]);
    } 
  },  [areaChartData]);

  return (
    <div className="tw-p-4 tw-bg-white tw-shadow-md tw-rounded-lg tw-h-full"> 
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center ">
        <div className="tw-flex  tw-items-center tw-gap-1 md:tw-gap-4">
          {/* Legend section */}
        </div>
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-gap-4 ">
          {/* Buttons and dropdown */}
          <button
            className="tw-bg-black tw-text-white tw-px-4 tw-py-2 tw-rounded"
            onClick={() =>
              navigate("/AlertingRules", { state: { isNewAlertingOpen: true } })
            }
          >
            Setup Alerts
          </button>
          <div className="tw-relative">
            <div onClick={toggleDropdown} className="tw-cursor-pointer">
              <Setting />
            </div>
            {isDropdownOpen && (
              <div
                ref={settingsDropdownRef}
                className="tw-absolute tw-mt-2 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
              >
                <div className="tw-max-h-40 tw-overflow-y-auto">
                  {domains.map((domain, index) => (
                    <label key={index} className="tw-flex tw-items-center tw-mb-2 tw-p-2">
                      <input
                        type="checkbox"
                        checked={domain.checked}
                        onChange={() => handleCheckboxChange(index)}
                        className="tw-mr-2 tw-h-5 tw-w-5 tw-form-checkbox tw-text-black tw-accent-black"
                      />
                      <span className="tw-text-sm tw-text-gray-700">
                        {domain.name}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            )}
          </div>

          <GenericDropdown
            options={options}
            defaultText={selectedOption}
            onSelect={handleSelect}
            staticFirstOption={true}
          />
        </div>
      </div>
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="area"
        height={330}
      />
    </div>
  );
}

export default Alerts;
const options = ["Last Week", "Last Month", "Last Year", "All Time"];
const option_values = ['last_week', 'last_month', 'last_year', 'all_time'];
