import React from "react";

const Map = () => {
  return (
    <div>
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99967 18.3327C8.83301 14.166 14.6663 13.6812 14.6663 8.33268C14.6663 4.65078 11.6816 1.66602 7.99967 1.66602C4.31778 1.66602 1.33301 4.65078 1.33301 8.33268C1.33301 13.6812 7.16634 14.166 7.99967 18.3327Z"
          stroke="#2B2F38"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99967 10.8327C9.38039 10.8327 10.4997 9.71339 10.4997 8.33268C10.4997 6.95197 9.38039 5.83268 7.99967 5.83268C6.61896 5.83268 5.49967 6.95197 5.49967 8.33268C5.49967 9.71339 6.61896 10.8327 7.99967 10.8327Z"
          stroke="#2B2F38"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Map;
