import _ from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const search = createAsyncThunk(
  'search/database/phishingAttack/search',
  async (
    { page, size, query, progress = true },
    { getState, dispatch, rejectWithValue },
  ) => {
    const state = getState().search.database.phishingAttack;

    if (_.isNil(query)) query = state.query;
    if (_.isNil(page)) page = state.currentPage - 1;
    if (_.isNil(size)) size = state.size;

    let progressValue = 0,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (!isError) {
        if (step === 0 && progressValue < 85) {
          progressValue++;
          dispatch(setProgress(progressValue));

          await delay(50);
          requestAnimationFrame(mockProgress);
        }
      }
    };

    try {
      if (progress) {
        requestAnimationFrame(mockProgress);

        if (getState().search.main.selectedOptionId === 0) {
          // Domain option
          query = query.split('.')[0];
        } else if (getState().search.main.selectedOptionId === 5) {
          // Brand option
          const brand = query.toLowerCase().split(' ');
          if (brand.length > 1) {
            query = `${brand[0]}${brand[1]} AND ${brand[0]}-${brand[1]}`;
          }
        }

        dispatch(setQuery(query));
      }

      dispatch(setParams({ size, page }));
      const response = await api.get('service/phishing_search/', {
        params: {
          page: page + 1,
          page_size: size,
          domain: query,
        },
      });
      step = 1;

      return response.data;
    } catch (error) {
      isError = true;
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

const phishingAttackSlice = createSlice({
  name: 'phishingAttack',
  initialState: {
    query: '',
    list: [],
    size: 10,
    currentPage: 1,
    totalElements: 0,
    totalPages: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setParams: (state, action) => {
      state.size = action.payload.size;
      state.currentPage = action.payload.page + 1;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        const { count, results } = action.payload;

        state.list = results;
        state.totalElements = count;
        state.totalPages = Math.ceil(state.totalElements / state.size);

        state.loading = false;
        state.progress = 100;
      })
      .addCase(search.rejected, (state, action) => {
        state.totalElements = state.totalPages = 0;
        state.list = [];
        state.loading = false;
        // state.progress = 0;
        state.error = action.payload;
      })
      .addCase(searchActions.resetProgress, (state) => {
        state.progress = 0;
      });
  },
});

const { setQuery, setProgress, setParams } = phishingAttackSlice.actions;

export default phishingAttackSlice.reducer;
