import React from "react";

const NewWindow = () => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.3333 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V5.66667M8.58333 9.41667L13.1667 4.83333M13.1667 4.83333H9M13.1667 4.83333V9M4.16667 16.5H6.33333C7.26675 16.5 7.73346 16.5 8.08998 16.3183C8.40359 16.1586 8.65855 15.9036 8.81834 15.59C9 15.2335 9 14.7668 9 13.8333V11.6667C9 10.7332 9 10.2665 8.81834 9.91002C8.65855 9.59641 8.40359 9.34144 8.08998 9.18166C7.73346 9 7.26675 9 6.33333 9H4.16667C3.23325 9 2.76654 9 2.41002 9.18166C2.09641 9.34144 1.84144 9.59641 1.68166 9.91002C1.5 10.2665 1.5 10.7332 1.5 11.6667V13.8333C1.5 14.7668 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1586 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5Z"
          stroke="#0B0B0B"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default NewWindow;
