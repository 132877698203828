import React from "react";

const BellYellow = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6665 17.4998H7.33315M13.9998 6.6665C13.9998 5.34042 13.473 4.06865 12.5353 3.13097C11.5977 2.19329 10.3259 1.6665 8.99981 1.6665C7.67373 1.6665 6.40196 2.19329 5.46428 3.13097C4.5266 4.06865 3.99981 5.34042 3.99981 6.6665C3.99981 9.24166 3.3502 11.0048 2.62453 12.171C2.01242 13.1547 1.70636 13.6466 1.71758 13.7838C1.73001 13.9357 1.7622 13.9937 1.88463 14.0845C1.99519 14.1665 2.49364 14.1665 3.49052 14.1665H14.5091C15.506 14.1665 16.0044 14.1665 16.115 14.0845C16.2374 13.9937 16.2696 13.9357 16.282 13.7838C16.2933 13.6466 15.9872 13.1547 15.3751 12.171C14.6494 11.0048 13.9998 9.24166 13.9998 6.6665Z"
        stroke="#ECC551"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BellYellow;
