import ProtectedRoute from './ProtectedRoute';

import ChartsSection from '../components/charts/charts';
import Tabs from '../components/common/tabs/tabs.component';
import DarknetLiveSearch from '../components/search/DarknetLiveSearch/DarknetLiveSearch';
import DatabaseSearch from '../components/search/DatabaseSearch/DatabaseSearch';
import ManualExpertSearch from '../components/search/ManualExpertSearch/ManualExpertSearch';
import DashboardLayout from '../layout/dashboard-layout';
import Dashboard from '../pages/table';
import NotFound from '../pages/NotFound';
import CustomTabs from '../pages/tabs';
import Settings from '../pages/settings/Settings';
import AdminDashboard from '../components/dashboard/dashboard';
import RansomwareFeedPage from '../pages/ransomware-feed';

import Support from '../components/support/Support';
import DomainSpoofing from '../components/domain-spoofing-detection/domainSpofing.component';
import ExposeAttackComponent from '../components/expose-attack-surface/expose-attack.component';

import Reports from '../pages/reports';
import AlertsPage from '../pages/alerts';
import AlertingRules from '../components/alerts/AlertingRules';
import BasicAlertingSettings from '../components/alerts/BasicAlertingSettings';
import Login from '../pages/auth/Login';
import ForgotPassword from '../pages/auth/ForgotPassword';

export const routes = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: 'register',
    element: <div>register</div>,
  },
  {
    path: 'reset-password',
    element: <div>reset password</div>,
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <AdminDashboard />,
      },
      {
        path: 'search-all-databases',
        element: <DatabaseSearch />,
      },
      {
        path: 'darknet-search',
        element: <DarknetLiveSearch />,
      },
      {
        path: 'manual-expert-search',
        element: <ManualExpertSearch />,
      },
      {
        path: 'alerts',
        element: <div>alerts</div>,
      },
      {
        path: 'docs',
        element: <div>docs</div>,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: 'support',
        element: <Support />,
      },
      {
        path: 'expert-search',
        element: <div>expert-search</div>,
      },
      {
        path: 'charts',
        element: <ChartsSection />,
      },

      {
        path: 'ransomware-feed',
        element: <RansomwareFeedPage />,
      },
      {
        path: 'ransomware-feed-spoofing',
        element: <DomainSpoofing />,
      },
      {
        path: 'ransomware-feed-surface',
        element: <ExposeAttackComponent />,
      },
      {
        path: 'ActiveAlerts',
        element: <AlertsPage />,
      },
      {
        path: 'AlertingRules',
        element: <AlertingRules />,
      },
      {
        path: 'basic-alerting-settings',
        element: <BasicAlertingSettings />,
      },
      {
        path: 'reports',
        element: <Reports />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];
