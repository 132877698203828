import React from "react";

const BlowingKissFace = ({ color }) => {
  return (
    <div>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.35487 11.0062C6.16186 14.4735 4.50293 19.3328 4.50293 24.8382C4.50293 30.3436 6.16186 35.2028 9.35487 38.6701C12.5292 42.1171 17.3551 44.3365 24.0013 44.3365C28.8401 44.3365 32.714 43.1601 35.6882 41.1899C37.372 40.9957 39.8803 40.4491 42.0979 39.2074C41.7096 39.7248 41.2952 40.2237 40.8544 40.7023C37.0301 44.8552 31.3568 47.3365 24.0013 47.3365C16.6457 47.3365 10.9724 44.8552 7.14806 40.7023C3.34233 36.5697 1.50293 30.9298 1.50293 24.8382C1.50293 18.7465 3.34233 13.1066 7.14806 8.97399C10.9724 4.82113 16.6457 2.33984 24.0013 2.33984C31.3568 2.33984 37.0301 4.82113 40.8544 8.97399C44.6602 13.1066 46.4996 18.7465 46.4996 24.8382C46.4996 27.0491 46.2573 29.2005 45.7666 31.2419C45.2529 30.1456 44.3487 29.2321 43.2046 28.7452C43.4006 27.4886 43.4996 26.1827 43.4996 24.8382C43.4996 19.3328 41.8407 14.4735 38.6476 11.0062C35.4733 7.55919 30.6474 5.33984 24.0013 5.33984C17.3551 5.33984 12.5292 7.55919 9.35487 11.0062Z"
          fill="#212121"
        />
        <path
          d="M24.8379 29.9049C24.8379 29.0765 25.5095 28.4049 26.3379 28.4049C28.4263 28.4049 30.1179 30.0965 30.1179 32.1849C30.1179 33.0414 29.8333 33.8312 29.3536 34.4649C29.8333 35.0986 30.1179 35.8884 30.1179 36.745C30.1179 38.8334 28.4263 40.525 26.3379 40.525C25.5095 40.525 24.8379 39.8534 24.8379 39.025C24.8379 38.1965 25.5095 37.525 26.3379 37.525C26.7695 37.525 27.1179 37.1765 27.1179 36.745C27.1179 36.3134 26.7695 35.965 26.3379 35.965C25.5095 35.965 24.8379 35.2934 24.8379 34.465C24.8379 33.6365 25.5095 32.9649 26.3379 32.9649C26.7695 32.9649 27.1179 32.6165 27.1179 32.1849C27.1179 31.7533 26.7695 31.4049 26.3379 31.4049C25.5095 31.4049 24.8379 30.7333 24.8379 29.9049Z"
          fill="#212121"
        />
        <path
          d="M34.2309 39.3983C34.407 39.6448 34.6983 39.7735 35.0003 39.75C37.0352 39.5916 40.8155 38.7999 43.203 36.5789C46.4929 33.7728 44.0699 29.146 40.4982 29.9617C40.4057 29.9828 40.3081 30.0038 40.2082 30.0252C39.9057 30.0902 39.583 30.1595 39.3241 30.25C39.1683 30.3044 38.976 30.2265 38.9131 30.074C38.737 29.6468 38.4245 29.0578 38.2135 28.7013C36.7034 26.1497 31.7278 26.2202 31.4535 31.2606C31.1881 33.4558 32.9241 37.5687 34.2309 39.3983Z"
          fill="#212121"
        />
        <path
          d="M37.7129 20.4297L37.7067 20.401C37.7004 20.3732 37.6898 20.3281 37.6738 20.2682C37.6418 20.1482 37.589 19.9709 37.5091 19.7572C37.3481 19.3264 37.0839 18.7671 36.6727 18.2335C35.8831 17.2087 34.5121 16.2113 32.0182 16.5077C31.504 16.5689 31.0377 16.2016 30.9766 15.6875C30.9155 15.1733 31.2827 14.707 31.7969 14.6459C35.0217 14.2626 37.014 15.6045 38.1579 17.089C38.7136 17.8101 39.0588 18.5479 39.2654 19.1006C39.3693 19.3786 39.44 19.6146 39.4855 19.785C39.5082 19.8703 39.5248 19.9397 39.5361 19.9901L39.5432 20.0222L39.5493 20.0513L39.5532 20.0707L39.5545 20.0774L39.555 20.0801L39.5554 20.0822C39.651 20.5911 39.3162 21.0812 38.8074 21.1769C38.2989 21.2725 37.8092 20.9379 37.7129 20.4297Z"
          fill="#212121"
        />
        <path
          d="M16.6214 9.8061C16.6619 10.324 16.2769 10.767 15.7616 10.7955C13.1498 10.94 11.9797 12.1757 11.409 13.3129C11.1107 13.9074 10.9657 14.5015 10.8966 14.9517C10.8624 15.1751 10.8476 15.358 10.8413 15.4809C10.8381 15.5422 10.8371 15.5881 10.8368 15.6161L10.8368 15.6442L10.8368 15.6471C10.8483 16.1641 10.4411 16.5838 9.9257 16.5853C9.40903 16.5868 8.97922 16.1676 8.96569 15.6489L8.96564 15.6466L8.96557 15.6439L8.96544 15.6368L8.96518 15.6167C8.96504 15.6007 8.96501 15.5795 8.96527 15.5534C8.9658 15.5013 8.96752 15.4296 8.97207 15.3409C8.98114 15.1639 9.00154 14.9176 9.04659 14.624C9.13614 14.0401 9.32616 13.2487 9.7334 12.4371C10.5739 10.7622 12.2975 9.10361 15.6153 8.91999C16.1306 8.89147 16.581 9.2882 16.6214 9.8061Z"
          fill="#212121"
        />
        <path
          d="M29.0747 24.0225C29.1523 23.776 29.3189 23.4344 29.6447 23.141C29.9542 22.8623 30.4851 22.5558 31.4243 22.4734C32.0981 22.4143 32.6611 22.6701 33.1125 23.0837C33.6015 23.5317 33.8331 24.0512 33.8698 24.2512C34.012 25.0253 34.7547 25.5375 35.5288 25.3953C36.3029 25.2531 36.8151 24.5104 36.6729 23.7363C36.5064 22.8298 35.9065 21.7782 35.0377 20.9823C34.1314 20.1519 32.8102 19.491 31.1754 19.6343C29.6664 19.7666 28.5405 20.3002 27.7377 21.023C26.9511 21.7313 26.5496 22.5526 26.3563 23.1666C26.1199 23.9172 26.5369 24.7174 27.2875 24.9537C28.0382 25.1901 28.8384 24.7731 29.0747 24.0225Z"
          fill="#212121"
        />
        <path
          d="M19.4634 20.8719C19.7535 22.482 18.6834 24.0225 17.0733 24.3126C15.4632 24.6027 13.9228 23.5326 13.6327 21.9225C13.3426 20.3124 14.4127 18.772 16.0228 18.4819C17.6329 18.1918 19.1733 19.2619 19.4634 20.8719Z"
          fill="#212121"
        />
        <path
          d="M14.3767 15.5297C18.2341 14.8347 21.9246 17.3983 22.6196 21.2557C23.0331 23.5506 22.2925 25.7866 20.815 27.3661C20.5742 27.6235 20.1866 27.6785 19.8837 27.4982C18.5553 26.7077 16.8142 26.2172 14.8899 26.2172C13.4007 26.2172 12.0189 26.5111 10.8529 27.0123C10.535 27.149 10.1649 27.0504 9.95709 26.7738C9.31249 25.9158 8.85363 24.8983 8.6508 23.7726C7.95576 19.9152 10.5194 16.2248 14.3767 15.5297ZM21.1434 21.5217C20.5953 18.4796 17.6848 16.4578 14.6427 17.006C11.6006 17.5541 9.57889 20.4645 10.127 23.5066C10.2518 24.1992 10.4985 24.8378 10.8408 25.4051C12.0645 24.9631 13.4406 24.7172 14.8899 24.7172C16.8117 24.7172 18.6077 25.1497 20.0908 25.9014C21.0067 24.696 21.4327 23.1272 21.1434 21.5217Z"
          fill="#212121"
        />
      </svg>
    </div>
  );
};

export default BlowingKissFace;
