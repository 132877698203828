import React, { useEffect, useState } from "react";
import ArrowDownLight from "../../Icons/ArrowDownLight";
import CrossBlackIcon from "../../Icons/CrossBlackIcon";
import QuestionBlackIcon from "../../Icons/QuestionBlackIcon";
import SearchIcon from "../../Icons/SearchIcon";
import CustomButton from "../Button/Button";
import CustomDropdown from "../DropDown/CustomDropdown";
import InputGroup from "../InputGroup/InputGroup";
import MultiSelect from "../MultiSelect/MultiSelect";
import Checkbox from "../Checkbox/Checkbox";
import FilterIcon from "../../Icons/FilterIcon";
import {
  Assets,
  DarknetSearchOptions,
  DatabaseSearchOptions,
  ManualSearchOptions,
  Operators,
} from "./data";
import * as Yup from "yup";

const CustomComponent = ({
  search,
  darknet = false,
  manualExpert = false,
  setOpenAdvanceFilter = false,
  handleOptionLabelChange,
  handleOptionForumChange,
  handleSearchTerm,
  defaultOption,
}) => {
  const [option, setOption] = useState("");
  const [operatorsOption, setOperatorsOption] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const searchValidationSchema = Yup.object().shape({
    searchInputValue: Yup.string().required("Search input cannot be empty"),
  });
  const handleSearch = () => {
    searchValidationSchema
      .validate({ searchInputValue })
      .then(() => {
        console.log("Search input:", searchInputValue);
        setErrorMessage("");
        search(true);
        handleSearchTerm();
      })
      .catch((err) => {
        console.error(err.errors);
        setErrorMessage(err.errors); //we can show toast here
      });
  };

  // useEffect(() => {
  //   console.log("Search Input Value:", searchInputValue);
  //   handleSearchTerm(searchInputValue);
  // }, [searchInputValue]);

  useEffect(() => {
    DatabaseSearchOptions.length && setOption(DatabaseSearchOptions[0]);
  }, [DatabaseSearchOptions]);

  return (
    <div className="tw-p-4 tw-rounded-xl tw-bg-[#ffffff]">
      <div className="tw-flex lg:tw-flex-row tw-flex-col tw-rounded-lg tw-gap-3">
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-gap-1 md:tw-gap-0 tw-bg-[#F5F6F8] ">
          <div className="tw-w-full md:tw-max-w-[220px]">
            <CustomDropdown
              defaultOption={defaultOption}
              placeholder="Select"
              options={
                darknet
                  ? DarknetSearchOptions
                  : manualExpert
                  ? ManualSearchOptions
                  : DatabaseSearchOptions
              }
              name="host"
              background={true}
              border={true}
              className="tw-w-full tw-h-[45px] tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm tw-rounded-[8px_0px_0px_8px]"
              onChange={(option) => {
                setOption(option);
                {
                  handleOptionLabelChange &&
                    handleOptionLabelChange(option.label);
                }
              }}
            />
          </div>

          <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-px-4 tw-gap-3 md:tw-gap-[10px] tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9]">
            {darknet && option.label === "Hacker Marketplaces" && (
              <div className="tw-w-full tw-max-w-[152px]">
                <MultiSelect
                  placeholder="Select Forum"
                  options={DatabaseSearchOptions}
                  name="host"
                  background={true}
                  border={true}
                  dropdownArrow={<ArrowDownLight />}
                  className="tw-whitespace-nowrap tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#888C95] tw-h-[28px] tw-my-2 tw-rounded tw-w-full tw-px-2 tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm"
                  onChange={(option) => {
                    setOption(option);
                    console.log({ FORUM: option });

                    {
                      handleOptionForumChange &&
                        handleOptionForumChange(option);
                    }
                  }}
                />
              </div>
            )}
            {manualExpert &&
            option.label !== "Spoofed Domains" &&
            manualExpert &&
            option.label !== "Exposed Infrastructure" &&
            manualExpert &&
            option.label !== "Credit Cards" &&
            manualExpert &&
            option.label !== "URL Shorteners" &&
            manualExpert &&
            option.label !== "Open Cloud Storage" ? (
              <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-[10px]">
                <div className="tw-w-[172px]">
                  <CustomDropdown
                    placeholder="Add Assets"
                    options={Assets}
                    name="host"
                    background={true}
                    border={true}
                    dropdownArrow={<ArrowDownLight />}
                    className="tw-whitespace-nowrap tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#888C95] tw-h-[28px] tw-my-2 tw-rounded tw-w-full tw-px-2 tw-py-2 tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm"
                    onChange={(option) => setOption(option)}
                  />
                </div>
                <div className="tw-w-[172px]">
                  <CustomDropdown
                    placeholder="Add Operators"
                    options={Operators}
                    name="host"
                    background={true}
                    border={true}
                    dropdownArrow={<ArrowDownLight />}
                    className="tw-whitespace-nowrap tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#888C95] tw-h-[28px] tw-my-2 tw-rounded tw-w-full tw-px-2 tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm"
                    onChange={(option) => setOperatorsOption(option)}
                  />
                </div>
              </div>
            ) : null}

            {manualExpert && manualExpert && option.label === "Credit Cards" ? (
              <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-[10px]">
                <div className="tw-w-[112px]">
                  <CustomDropdown
                    placeholder="Card Type"
                    options={Assets}
                    name="host"
                    background={true}
                    border={true}
                    dropdownArrow={<ArrowDownLight />}
                    className="tw-whitespace-nowrap tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#888C95] tw-h-[28px] tw-my-2 tw-rounded tw-w-full tw-px-2 tw-py-2 tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm"
                    onChange={(option) => setOption(option)}
                  />
                </div>
                <div className="tw-w-[92px]">
                  <CustomDropdown
                    placeholder="Source"
                    options={Assets}
                    name="host"
                    background={true}
                    border={true}
                    dropdownArrow={<ArrowDownLight />}
                    className="tw-whitespace-nowrap tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#888C95] tw-h-[28px] tw-my-2 tw-rounded tw-w-full tw-px-2 tw-py-2 tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm"
                    onChange={(option) => setOption(option)}
                  />
                </div>
                <div className="tw-w-[142px]">
                  <CustomDropdown
                    placeholder="Card Number"
                    options={Operators}
                    name="host"
                    background={true}
                    border={true}
                    dropdownArrow={<ArrowDownLight />}
                    className="tw-whitespace-nowrap tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#888C95] tw-h-[28px] tw-my-2 tw-rounded tw-w-full tw-px-2 tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm"
                    onChange={(option) => setOperatorsOption(option)}
                  />
                </div>
                <div className="tw-w-[102px]">
                  <CustomDropdown
                    placeholder="Country"
                    options={Assets}
                    name="host"
                    background={true}
                    border={true}
                    dropdownArrow={<ArrowDownLight />}
                    className="tw-whitespace-nowrap tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#888C95] tw-h-[28px] tw-my-2 tw-rounded tw-w-full tw-px-2 tw-py-2 tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm"
                    onChange={(option) => setOption(option)}
                  />
                </div><div className="tw-w-[82px]">
                  <CustomDropdown
                    placeholder="Bank"
                    options={Assets}
                    name="host"
                    background={true}
                    border={true}
                    dropdownArrow={<ArrowDownLight />}
                    className="tw-whitespace-nowrap tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#888C95] tw-h-[28px] tw-my-2 tw-rounded tw-w-full tw-px-2 tw-py-2 tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm"
                    onChange={(option) => setOption(option)}
                  />
                </div>
                <div className="tw-w-[122px]">
                  <CustomDropdown
                    placeholder="First Name"
                    options={Assets}
                    name="host"
                    background={true}
                    border={true}
                    dropdownArrow={<ArrowDownLight />}
                    className="tw-whitespace-nowrap tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#888C95] tw-h-[28px] tw-my-2 tw-rounded tw-w-full tw-px-2 tw-py-2 tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm"
                    onChange={(option) => setOption(option)}
                  />
                </div>
                <div className="tw-w-[92px]">
                  <CustomDropdown
                    placeholder="AND"
                    options={Operators}
                    name="host"
                    background={true}
                    border={true}
                    dropdownArrow={<ArrowDownLight />}
                    className="tw-whitespace-nowrap tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-[#888C95] tw-h-[28px] tw-my-2 tw-rounded tw-w-full tw-px-2 tw-border-[0.5px] tw-border-solid tw-border-[#D9D9D9] tw-shadow-sm"
                    onChange={(option) => setOperatorsOption(option)}
                  />
                </div>
              </div>
            ) : null}
            <div className="tw-w-full tw-flex tw-items-center tw-relative">
              <InputGroup
                placeholder={`${
                  darknet || manualExpert
                    ? option && option.value
                    : `Enter your ${option && option.label}: e.g. ${
                        option && option.value
                      }`
                }`}
                value={searchInputValue}
                onChange={(e) => setSearchInputValue(e.target.value)}
                className="tw-bg-inherit tw-w-full tw-h-full tw-pl-3 tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-left tw-px-4 tw-py-2 tw-rounded-[0px_18px_8px_0px]"
              />
              {errorMessage && (
                <div className="tw-absolute tw-top-full tw-left-0 tw-mt-1 tw-bg-red-500 tw-text-white tw-text-xs tw-rounded tw-px-2 tw-py-1 tw-shadow-md">
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
        {(manualExpert && option.label === "URL Shorteners") ||
        (manualExpert && option.label === "Open Cloud Storage") ? (
          <div className="tw-w-full tw-max-w-[281px] tw-h-[44px]">
            <InputGroup
              placeholder="URL Extension (xlsx, docs, pdf)"
              className="tw-bg-[#F5F6F8] tw-border  tw-h-[44px] tw-border-solid tw-border-[#D9D9D9] tw-pl-3 tw-text-sm tw-font-normal tw-leading-[16.41px] tw-tracking-[0.02em] tw-text-left tw-px-4 tw-py-2 tw-rounded-[0px_18px_8px_0px]"
            />
          </div>
        ) : null}
        {manualExpert && option.label === "Open Cloud Storage" ? (
          <CustomButton
            text="Advance Filters"
            icon={<FilterIcon />}
            onClick={() => setOpenAdvanceFilter(true)}
            className="tw-w-full tw-max-w-[149px] tw-whitespace-nowrap tw-py-[10px] tw-px-3 tw-rounded-lg tw-border tw-border-solid tw-border-[#979BA5] tw-text-sm tw-font-medium tw-leading-6 tw-text-[#979BA5]"
          />
        ) : null}
        <div>
          <CustomButton
            text="Search"
            onClick={handleSearch}
            icon={<SearchIcon fill="#ffffff" />}
            className="tw-w-full tw-py-[10px] tw-rounded-md tw-px-3 tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-h-full tw-text-sm tw-font-semibold tw-leading-6 tw-text-left"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomComponent;
