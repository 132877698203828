import React, { useEffect, useState } from "react";
import ProfileSettings from "./components/profileSettings/ProfileSettings";
import SideCard from "./SideCard";
import { SettingsNavBarData } from "./setting-form-data";
import LicenseInfo from "./components/licenseInfo/LicenseInfo";
import ApiSetup from "./components/apiSetup/ApiSetup";
import WhiteLabeling from "./components/whiteLabeling/WhiteLabeling";
import Security from "./components/security/Security";
import { useLocation } from "react-router-dom";

const Setting = () => {
  const [activeItem, setActiveItem] = useState("ProfileSettings");
  useEffect(() => {
    // Scroll to the top of the page on render
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const { activeItem: dashboardState } = location.state || {};
  useEffect(() => {
    if (dashboardState) {
      setActiveItem(dashboardState);
    }
  }, []);

  const renderTabContent = (tab) => {
    switch (tab) {
      case "ProfileSettings":
        return <ProfileSettings />;
      case "ApiSetup":
        return <ApiSetup />;
      case "LicenseInfo":
        return <LicenseInfo />;
      case "WhiteLabeling":
        return <WhiteLabeling />;
      case "Security":
        return <Security />;
      default:
        return <div>Select a tab</div>;
    }
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-6">
        <div>
          <p className="tw-text-[18px] tw-font-bold tw-mb-4">Settings</p>
          <div className="tw-rounded-lg tw-w-[100%] ">
            {/* tw-sticky tw-top-[24px] tw-z-[1] */}
            <div className="tw-flex tw-overflow-x-scroll lg:tw-overflow-hidden tw-bg-[#FFFFFF] tw-gap-3 tw-py-[14px] tw-px-[25px] tw-rounded-lg">
              {SettingsNavBarData?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setActiveItem(item.link);
                  }}
                  className={
                    activeItem?.includes(item.link)
                      ? "tw-flex tw-py-[10px] tw-px-[85px] tw-justify-center tw-items-center tw-rounded-lg tw-gap-2 tw-bg-secondary hover:tw-cursor-pointer tw-font-semibold md:tw-max  tw-border-2 tw-border-secondary "
                      : "tw-flex tw-py-[10px] tw-px-[85px] tw-justify-center tw-items-center tw-rounded-lg tw-gap-2 tw-bg-[#FFFFFF] hover:tw-cursor-pointer md:tw-w-max tw-border-2 tw-border-secondary"
                  }
                >
                  {<p className="tw-text-[16px] tw-w-max">{item.label}</p>}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-w-[100%] tw-gap-6">
          <SideCard />
          <div className="tw-w-[100%] tw-bg-white tw-p-6 tw-rounded-lg">
            {renderTabContent(activeItem)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
