import React from "react";
import { TbArrowBackUp } from "react-icons/tb";
import Heading from "../../common/components/heading/Heading";
import Chip from "../../common/components/chip/chip";
import view from "../assets/svgs/view.svg";
import Quotation from "./components/quotation.component";

export const CyberDetection = ({ setIsShow }) => {
  const detectionData = [
    {
      size: "100%",
      value: "2480",
    },
    {
      size: "100%",
      value: "952",
    },
    {
      size: "100%",
      value: "2570",
    },
    {
      size: "100%",
      value: "9501",
    },
    {
      size: "100%",
      value: "248",
    },
  ];
  const activity = [
    "Generating Type-Squtted Domains",
    "Find Domains that contain your search term",
    "Find domains with 2 typos",
    "Find domains with 1 typos",
    "Find domain in SSK transperancy logs",
  ];
  return (
    <>
      <div
        className="tw-flex tw-gap-3 tw-items-center tw-cursor-pointer tw-flex-col sm:tw-flex-row"
        onClick={() => setIsShow((show) => !show)}
      >
        <TbArrowBackUp className="tw-mb-4 tw-text-xl" />
        <Heading size="h2" text="Domain Spoofing Detection" />
      </div>

      <div className="tw-border tw-border-[#EFF0F2] tw-rounded tw-py-5 tw-px-7">
        <p className="tw-text-primaryGrey tw-text-sm tw-pb-1">
          Selected Domain
        </p>
        <Heading size="h6" text="Example.com" />

        <div className="tw-flex tw-gap-3 tw-flex-col sm:tw-flex-row">
          <div className="md:tw-w-[40%]  tw-w-[100%]">
            <p className="tw-my-3 tw-text-primaryGrey">Results</p>
            {detectionData.map((item, i) => {
              return (
                <div
                  className="tw-flex tw-gap-3 tw-items-center tw-mb-4"
                  key={i.toString()}
                >
                  <div className="tw-w-full tw-bg-[#80808014] tw-rounded-full tw-h-2.5">
                    <div
                      className="tw-bg-secondary tw-h-2.5 tw-rounded-full"
                      style={{ width: item.size }}
                    ></div>
                  </div>
                  <span className="tw-text-[#797C84] tw-text-xs tw-w-[30px] tw-text-right">
                    {item.value}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="md:tw-w-[31%] tw-w-[100%]">
            <p className="tw-my-3 tw-text-primaryGrey">Activity</p>
            {activity.map((item, i) => {
              return (
                <div key={i.toString()} className="tw-mb-3">
                  <Heading size="h6" text={item} />
                </div>
              );
            })}
          </div>
          <div className="tw-flex md:tw-w-[28%] tw-w-[100%] tw-gap-3 tw-flex-col sm:tw-flex-row">
            <div>
              <p className="tw-my-3 tw-text-primaryGrey">Alert</p>
              <Chip className="tw-bg-[#FEF3F2] tw-text-danger tw-border tw-border-[#FECDCA] tw-font-medium">{`Warning: > {Nr} Results`}</Chip>
            </div>
            <div>
              <p className="tw-my-3 tw-text-primaryGrey">Exclude</p>
              <button className="tw-text-secondary tw-text-sm tw-font-semibold tw-flex tw-items-center tw-gap-2">
                <img src={view} alt="" />
                View Details
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 tw-mt-3 ">
        <Quotation
          title="What is Typosquatting?"
          description={`Typosquatting involves buying domain names that closely mimic legitimate websites by adding hyphens, using different spellings, or substituting characters (e.g., zeros for "O" or ones for "I").`}
        />
        <Quotation
          title="How We Monitor Domains?"
          description={`We combine monitoring new domain registration feeds with actively creating and monitoring typo variations. This involves checking publicly available databases for new domains and generating thousands of typo-squatted variations of your domain.`}
        />
      </div>
    </>
  );
};
