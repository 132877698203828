import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ArrowBack from 'common/Icons/ArrowBack';

import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import Loading from 'common/components/Loading/Loading';

import api from 'store/api';

const stealerLogsDetailColumns = [
  {
    Header: 'Created',
    accessor: 'createdAt',
    render: (value) => {
      return (
        <span className=" tw-text-[#44464A]">
          {' '}
          {moment(value).format('YYYY-MM-DD')}
        </span>
      );
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    render: (value) => {
      return <span className=" tw-text-[#44464A]">{value ?? ''}</span>;
    },
  },
  {
    Header: 'Data',
    accessor: 'data',
    render: (_, record) => {
      return (
        <>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-justify-between">
            <div className="tw-flex tw-gap-2">
              <span className="tw-text-[#888C95]">URL</span>
              <span className="tw-text-[#00A4E6]">{record.url}</span>
            </div>
            <div className="tw-flex tw-gap-2">
              <span className="tw-text-[#888C95]">Name</span>
              <span>{record.name}</span>
            </div>
            <div className="tw-flex tw-gap-2">
              <span className="tw-text-[#888C95]">Value</span>
              <span className="tw-break-all">{record.value}</span>
            </div>
            <div className="tw-flex tw-gap-2">
              <span className="tw-text-[#888C95]">Time</span>
              <span>{record.time}</span>
            </div>
          </div>
        </>
      );
    },
  },
];

const StealerLogDetails = ({ stealerLog, setIsStealerLogDetails }) => {
  const [records, setRecords] = useState([]);
  const [sort, setSort] = useState('createdAt,desc');
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchRecords = async (botId, page = 1) => {
    setLoading(true);

    try {
      const response = await api.get(`service/botnet_record_search/`, {
        params: {
          page: page - 1,
          sort,
          size,
          query: `botId:${botId}`,
        },
      });

      setCurrentPage(page);
      setTotalElements(response.data.totalElements);
      setTotalPages(response.data.totalPages);
      setRecords(response.data.content ?? []);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!_.isNil(stealerLog)) {
      fetchRecords(stealerLog.botId);
    }
  }, [stealerLog, sort, size]);

  const handlePageChange = (page) => {
    if (!_.isNil(stealerLog)) {
      fetchRecords(stealerLog.botId, page);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-p-6 tw-gap-6">
      <div className="tw-flex tw-gap-2">
        <div
          onClick={() => setIsStealerLogDetails(false)}
          className="tw-cursor-pointer"
        >
          <ArrowBack />
        </div>
        <span className="tw-font-semibold">Stealer Logs Details</span>
      </div>

      <div className="tw-p-6 tw-border tw-border-[#DFE0E3] tw-rounded-lg tw-flex tw-flex-col tw-gap-3">
        <span className="tw-font-semibold tw-text-lg">{stealerLog.ip}</span>
        <div className="tw-flex tw-flex-col">
          <span className="tw-text-[#44464A] tw-text-sm">ID</span>
          <span className="tw-text-[#00B6FF] tw-text-sm">
            {stealerLog.botId}
          </span>
        </div>

        <div className="tw-grid tw-grid-cols-4 tw-gap-4">
          <div>
            <p className="tw-text-[#44464A] tw-text-sm">Time Created</p>
            <p className="tw-text-[#0B0B0B] tw-text-sm">
              {stealerLog.createdAt ?? '—'}
            </p>
          </div>
          <div>
            <p className="tw-text-[#44464A] tw-text-sm">Time Collected</p>
            <p className="tw-text-[#0B0B0B] tw-text-sm">
              {stealerLog.collectedAt ?? 'Invalid date'}
            </p>
          </div>
          <div>
            <p className="tw-text-[#44464A] tw-text-sm">Bot</p>
            <p className="tw-text-[#0B0B0B] tw-text-sm">
              {stealerLog.botName ?? '—'}
            </p>
          </div>
          <div>
            <p className="tw-text-[#44464A] tw-text-sm">IP</p>
            <p className="tw-text-[#0B0B0B] tw-text-sm">
              {stealerLog.ip ?? '—'}
            </p>
          </div>
          <div>
            <p className="tw-text-[#44464A] tw-text-sm">Computer Name</p>
            <p className="tw-text-[#0B0B0B] tw-text-sm">
              {stealerLog.computerName ?? '—'}
            </p>
          </div>
          <div>
            <p className="tw-text-[#44464A] tw-text-sm">ASN</p>
            <p className="tw-text-[#0B0B0B] tw-text-sm">
              {stealerLog.asn ?? '—'}
            </p>
          </div>
          <div>
            <p className="tw-text-[#44464A] tw-text-sm">Country</p>
            <p className="tw-text-[#0B0B0B] tw-text-sm">
              {stealerLog.country ?? '—'}
            </p>
          </div>
          <div>
            <p className="tw-text-[#44464A] tw-text-sm">User Name</p>
            <p className="tw-text-[#0B0B0B] tw-text-sm">
              {stealerLog.userName ?? '—'}
            </p>
          </div>
          <div>
            <p className="tw-text-[#44464A] tw-text-sm">OS</p>
            <p className="tw-text-[#0B0B0B] tw-text-sm">
              {stealerLog.os ?? '—'}
            </p>
          </div>
          <div>
            <p className="tw-text-[#44464A] tw-text-sm">Records</p>
            <p className="tw-text-[#0B0B0B] tw-text-sm">{totalElements}</p>
          </div>
        </div>
        <div className="tw-grid tw-grid-cols-2">
          <div>
            <p className="tw-text-[#44464A] tw-text-sm">Timezone</p>
            <p className="tw-text-[#0B0B0B] tw-text-sm">
              {stealerLog.timeZone ?? '—'}
            </p>
          </div>
          <div>
            <p className="tw-text-[#44464A] tw-text-sm">Path</p>
            <p className="tw-text-[#0B0B0B] tw-text-sm">
              {stealerLog.botPath ?? '—'}
            </p>
          </div>
        </div>
      </div>

      <div className="tw-relative">
        <Table
          data={records}
          columns={stealerLogsDetailColumns}
          selectable={false}
        />

        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between tw-items-center">
          <div className={loading ? 'tw-invisible' : 'tw-visible'}>
            <p className="tw-my-4">
              Showing records: {(currentPage - 1) * size + 1} —{' '}
              {currentPage * size > totalElements
                ? totalElements
                : currentPage * size}{' '}
              of {totalElements}
            </p>
          </div>
          {totalPages > 1 && (
            <Paginator
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>

        {loading && (
          <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
        )}
      </div>
    </div>
  );
};

export default StealerLogDetails;
