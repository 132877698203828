import React, { useEffect, useRef, useState } from 'react';
import CustomButton from '../Button/Button';
import Characters from '../../Icons/Characters';

const CreationDropdown = ({ defaultValue = 0, onChange }) => {
  const [value, setValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  // Toggle the dropdown's open state
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle clicking outside the component to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (value) => {
    setValue(value);
    if (onChange) onChange(value);
    setIsOpen(false);
  };

  return (
    <div ref={ref} className="tw-relative">
      <CustomButton
        bgColor="tw-bg-white"
        textColor="tw-text-primary"
        icon={<Characters />}
        text={`${value} Characters`}
        onClick={toggleDropdown}
        className="tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-border !tw-border-solid tw-border-[#DFE0E3] tw-rounded-md tw-text-sm tw-font-semibold tw-leading-6 tw-text-center hover:tw-bg-[#F5F6F8]"
      />
      {isOpen && (
        <div className="tw-absolute tw-w-56 tw-bg-white card-shadow tw-rounded-lg tw-z-50 py-1 ">
          <ul className="">
            <li className="tw-px-4 tw-py-2 tw-text-black tw-text-sm tw-font-medium">
              Leak content extract size
            </li>
            <li
              className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
              onClick={() => handleOptionClick(100)}
            >
              100 characters
            </li>
            <li
              className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
              onClick={() => handleOptionClick(500)}
            >
              500 characters
            </li>
            <li
              className="tw-px-4 tw-py-2 tw-text-[#44464A] tw-text-sm hover:tw-bg-[#FCF6E5] tw-cursor-pointer"
              onClick={() => handleOptionClick(1000)}
            >
              1000 characters
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default CreationDropdown;
