import React, { useState } from "react";
import NoResultIcon from "../../Icons/NoResultIcon";
import CustomButton from "../Button/Button";
import QuestionYellowIcon from "../../Icons/QuestionYellowIcon";
import CrossIcon from "../../Icons/CrossIcon";

// Modal Component
const AssistanceModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-flex tw-items-center tw-justify-center">
      <div className="tw-bg-white tw-rounded-lg tw-p-6 tw-shadow-xl tw-max-w-[700px] tw-w-full tw-relative">
        {/* Cross Icon for closing */}
        <button
          className="tw-absolute tw-top-3 tw-right-3 tw-text-gray-400 hover:tw-text-gray-600"
          onClick={onClose}
        >
          <CrossIcon/>
        </button>

        <h2 className="tw-font-bold tw-text-2xl">Get Assistance</h2>
        <p className="tw-text-lg tw-text-gray-700 tw-mt-2 tw-py-1">
          The Darknet Risk Score quantifies the security threat to your organization with a score
          between 1 and 100, where 100 represents the highest risk. This score is derived from
          several key factors: the number of data leaks associated with your company's domain,
          the types and severity of these leaks—ranging from generic collections to those specifically
          targeting your infrastructure, and the presence of your organization's data in hacker forums.
        </p>
        <p className="tw-text-lg tw-text-gray-700 ">
          Additionally, we conduct an infrastructure risk analysis that includes checks for open ports
          and known vulnerabilities (CVEs), and assess the security of passwords that have been exposed in data leaks.
        </p>
       
      </div>
    </div>
  );
};

const NoResult = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-1 tw-py-[180px] tw-bg-white tw-rounded-lg">
      <div>
        <NoResultIcon />
      </div>
      <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-[#263238]">
        No Results
      </div>
      <div className="tw-text-[#44464A] tw-text-sm tw-font-normal tw-leading-6 tw-text-center">
        Currently, there are no results available. If you need assistance with
        your search, please click on the help button below.
      </div>
      <CustomButton
        text="Get Assistance"
        icon={<QuestionYellowIcon />}
        bgColor="tw-bg-white"
        textColor="tw-text-[#D4B149]"
        hoverBgColor="[#FCF6E5]"
        hoverTextColor="[#D4B149]"
        className="tw-mt-3 !tw-border !tw-border-solid !tw-border-[#F9EDC9] tw-px-[14px] tw-py-2 tw-rounded-lg tw-text-sm tw-font-semibold tw-leading-6"
        onClick={openModal}
      />

      {/* Modal Component */}
      <AssistanceModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default NoResult;
