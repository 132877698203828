import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CyberSheet = ({ setIsInfrastructureView, setIsDomainSpoofingView }) => {
  const navigate = useNavigate();
  const [domain1, setDomain1] = useState("");
  const [domain2, setDomain2] = useState("");

  const handleDomainChange = (e, setter) => setter(e.target.value);

  const handleSubmit = (e, domain, searchHandler) => {
    e.preventDefault();
    searchHandler(domain);
    // Add API call or logic here
  };
  const handleDomainSpoofingSearch = (domain) => {
    navigate('/ransomware-feed', { state: { spoofDomain : domain, activeTab: 1 } });
  };
  const handleInfraViewSearch = (domain) => {
    navigate('/ransomware-feed', { state: { infraDomain : domain, activeTab: 2 } });
  };

  return (
    <div className="tw-px-4 tw-py-4 tw-rounded-lg  tw-font-sans">
      <h2 className="tw-text-base tw-font-bold tw-mb-6 ">Cyber Threat Intel</h2>{" "}
      <div className="tw-flex tw-flex-col tw-gap-2">
        <form
          onSubmit={(e) => handleSubmit(e, domain1, handleInfraViewSearch)}
          className="tw-grid tw-grid-cols-12 tw-gap-2"
        >
          <label className="tw-text-sm tw-text-[#263238] md:tw-col-span-4 tw-col-span-12">
            How does your infrastructure appear from a hacker's view?
          </label>
          <div className="tw-flex tw-flex-col md:tw-col-span-8 tw-col-span-12 tw-w-full md:tw-flex-row tw-items-start sm:tw-items-center tw-gap-2">
            <input
              type="text"
              className="tw-w-full tw-flex-1 tw-block tw-px-3 tw-py-2 tw-rounded-lg tw-bg-[#F9F9F9] tw-text-black "
              placeholder="Enter your domain: example.com"
              value={domain1}
              onChange={(e) => handleDomainChange(e, setDomain1)}
            />
            <button
              type="submit"
              className="tw-px-4 tw-py-2 tw-rounded-lg tw-bg-black tw-text-white tw-w-full md:tw-w-max"
            >
              Go
            </button>
          </div>
        </form>
        <form
          onSubmit={(e) => handleSubmit(e, domain2, handleDomainSpoofingSearch)}
          className="tw-grid tw-grid-cols-12 tw-gap-2"
        >
          <label className="tw-text-sm tw-text-[#263238] md:tw-col-span-4 tw-col-span-12">
            Is anyone spoofing your domain?
          </label>
          <div className="tw-flex tw-flex-col md:tw-col-span-8 tw-col-span-12 md:tw-flex-row tw-items-start sm:tw-items-center tw-gap-2">
            <input
              type="text"
              className="tw-w-full tw-flex-1 tw-px-3 tw-py-2 tw-rounded-lg tw-bg-[#F9F9F9] tw-text-black "
              placeholder="Enter your domain: example.com"
              value={domain2}
              onChange={(e) => handleDomainChange(e, setDomain2)}
            />
            <button
              type="submit"
              className="tw-px-4 tw-py-2 tw-rounded-lg tw-bg-black tw-text-white tw-w-full md:tw-w-max"
            >
              Go
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CyberSheet;
