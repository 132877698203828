import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { CiCalendarDate } from "react-icons/ci";

import api from 'store/api';
import { useSnackbar, useErrorbar } from 'utils/snackbar';

import { InfoIcon } from "../../../../common/Icons";
import InputGroup from "../../../../common/components/InputGroup/InputGroup";
import CustomButton from "../../../../common/components/Button/Button";
import InputLabel from "../../../../common/components/InputLabel/InputLabel";

function QuickComponent({creationTime, pageNum, content, tabIndex}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [domain, setDomain] = useState("");
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const endDateRef = useRef(null);
  const reportTypes = ["sprsimple", "simple", "extended"];

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date) {
      setTimeout(() => {
        endDateRef.current.setFocus();
      }, 100);
    }
  };

  const onButtonClicked = async () => {
    if (domain === "") {
        useErrorbar("Please enter domain name!")
        return;
    } else if (!startDate) {
        useErrorbar("Please select start date!")
        return;
    } else if (!endDate) {
        useErrorbar("Please select end date!")
        return;
    }
    setIsLoadingButton(true);
    const result = await api.post("/service/generate_report_request/", {
        type: reportTypes[tabIndex],
        domain,
        period_type: "period",
        started_at: moment(startDate).format("DD-MM-YYYY"),
        ended_at: moment(endDate).format("DD-MM-YYYY")
    })
    setIsLoadingButton(false)
    if (result.status === 201) {
        setDomain("");
        setStartDate(null);
        setEndDate(null);
        useSnackbar('Created Report Successful.')
        navigate("/reports");
    } else {
        useErrorbar('Create Report Failed!')
    }
}

  const navigate = useNavigate();

  return (
    <div className="tw-p-4 -tw-mt-4">
      <div className="tw-border tw-rounded-lg tw-p-2 tw-mb-2">
        <div className="tw-flex tw-items-center tw-pb-2">
          <InfoIcon />
          <span className="tw-pl-2 tw-font-bold">QUICK I Report Info</span>
        </div>
        <p className="tw-pb-2">
          Creation Time: {creationTime} &nbsp;{" "}
          <span className="tw-text-[#DFE0E3]">|</span> &nbsp; Length: {pageNum} pages
          &nbsp; <span className="tw-text-[#DFE0E3]">|</span> &nbsp; Content:
          {" "}{content}
        </p>
      </div>
      <InputGroup
        labelText="Domain"
        inputType="text"
        placeholder="Enter your domain example.com"
        inputName="domain"
        bgColor="!tw-bg-[#F5F6F8]"
        className="tw-bg-gray-200 tw-p-2 tw-rounded-lg"
        value={domain}
        onChange={(input) => setDomain(input.target.value) }
      />
      <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-3 tw-mt-4">
        <div className="md:tw-w-3/6 tw-h-full">
          <InputLabel
            text="From date"
            className="tw-text-[#263238] tw-text-sm tw-mb-1"
          />
          <div className="tw-border tw-border-[#DFE0E3] tw-bg-[#F5F6F8] tw-text-base tw-rounded-lg tw-py-1 tw-pr-4 tw-relative custom__picker tw-w-full">
            <DatePicker
              showIcon
              selected={startDate}
              onChange={handleStartDateChange}
              placeholderText="Start Date"
              className="tw-bg-[#F5F6F8]"
              icon={
                <CiCalendarDate
                  style={{ fontSize: "19px", color: "#71747C" }}
                />
              }
            />
          </div>
        </div>
        <div className="md:tw-w-3/6 tw-h-full">
          <InputLabel
            text="To date"
            className="tw-text-[#263238] tw-text-sm tw-mb-1"
          />
          <div className="tw-border tw-border-[#DFE0E3] tw-bg-[#F5F6F8] tw-text-base tw-rounded-lg tw-py-1 tw-pr-4 tw-relative custom__picker tw-w-full">
            <DatePicker
              ref={endDateRef}
              showIcon
              selected={endDate}
              className="tw-bg-[#F5F6F8]"
              onChange={(date) => setEndDate(date)}
              placeholderText="End Date"
              icon={
                <CiCalendarDate
                  style={{ fontSize: "19px", color: "#71747C" }}
                />
              }
            />
          </div>
        </div>
      </div>
      <CustomButton
        text="Create report"
        isLoading={isLoadingButton}
        disabled={isLoadingButton}
        iconPosition="after"
        className="tw-bg-black tw-p-2 tw-mt-4 tw-text-white tw-rounded-lg tw-w-full md:tw-w-max"
        onClick={onButtonClicked}
      />
    </div>
  );
}

export default QuickComponent;
