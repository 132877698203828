import React from "react";

const Template = ({ props }) => {
  return (
    <div>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.2835 9.16667H11.1169M15.2835 12.5H11.1169M15.2835 5.83333H11.1169M8.2002 2.5L8.2002 17.5M7.2002 2.5H14.2002C15.6003 2.5 16.3004 2.5 16.8352 2.77248C17.3056 3.01217 17.688 3.39462 17.9277 3.86502C18.2002 4.3998 18.2002 5.09987 18.2002 6.5V13.5C18.2002 14.9001 18.2002 15.6002 17.9277 16.135C17.688 16.6054 17.3056 16.9878 16.8352 17.2275C16.3004 17.5 15.6003 17.5 14.2002 17.5H7.20019C5.80006 17.5 5.1 17.5 4.56522 17.2275C4.09481 16.9878 3.71236 16.6054 3.47268 16.135C3.2002 15.6002 3.2002 14.9001 3.2002 13.5V6.5C3.2002 5.09987 3.2002 4.3998 3.47268 3.86502C3.71236 3.39462 4.09481 3.01217 4.56522 2.77248C5.1 2.5 5.80006 2.5 7.2002 2.5Z"
          stroke="#44464A"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"

        />
      </svg>
    </div>
  );
};

export default Template;
