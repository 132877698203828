export const delay = (millis) =>
  new Promise((resolve, reject) => {
    setTimeout(() => resolve(), millis);
  });

export const kaduuHighlightTags = (string) => {
  const regex = /<kaduu:h>(.*?)<\/kaduu:h>/g;

  return string.replace(regex, '<span class="tw-bg-secondary">$1</span>');
};

export const formatFileSize = (bytes) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let unitIndex = 0;
  while (bytes >= 1024 && unitIndex < units.length - 1) {
    bytes /= 1024;
    unitIndex++;
  }

  return `${bytes.toFixed(unitIndex === 0 ? 0 : 2)} ${units[unitIndex]}`;
};

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

export const getCountryNameFromCode = (code) => {
  return regionNames.of(code.toUpperCase());
};

export const parseURL = (url) => {
  try {
    const parsedUrl = new URL(url);
    return {
      protocol: parsedUrl.protocol,
      hostname: parsedUrl.hostname,
    };
  } catch (error) {
    console.error('Invalid URL:', error);
    return {
      protocol: '',
      hostname: '',
    };
  }
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const truncateString = (str, maxLength = 160) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  }

  return str;
};

export const convertToWildcardIP = (ip) => {
  const cleanIP = ip.split('/')[0];

  const segments = cleanIP.split('.');
  segments[3] = '*';

  return segments.join('.');
};

export const removeLastIP = (ip) => {
  const cleanIP = ip.split('/')[0];

  const segments = cleanIP.split('.');
  segments.pop();

  return segments.join('.');
};
