import React from "react";

const Test = ({ props }) => {
  return (
    <div>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2333 5.83333H4.73332C3.7999 5.83333 3.33319 5.83333 2.97667 6.01499C2.66306 6.17478 2.4081 6.42975 2.24831 6.74335C2.06665 7.09987 2.06665 7.56658 2.06665 8.5V11.5C2.06665 12.4334 2.06665 12.9001 2.24831 13.2567C2.4081 13.5703 2.66306 13.8252 2.97667 13.985C3.33319 14.1667 3.7999 14.1667 4.73332 14.1667H11.2333M14.5667 5.83333H16.0667C17.0001 5.83333 17.4668 5.83333 17.8233 6.01499C18.1369 6.17478 18.3919 6.42975 18.5517 6.74335C18.7333 7.09987 18.7333 7.56658 18.7333 8.5V11.5C18.7333 12.4334 18.7333 12.9001 18.5517 13.2567C18.3919 13.5703 18.1369 13.8252 17.8233 13.985C17.4668 14.1667 17.0001 14.1667 16.0667 14.1667H14.5667M14.5667 17.5L14.5667 2.5M16.65 2.50001L12.4833 2.5M16.65 17.5L12.4833 17.5"
          stroke="#44464A"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Test;
