import React, { useState, useEffect, useRef } from "react";
import api from 'store/api';
import {
  ArrowDown,
  ArrowDownLight,
  ChartButton,
  ChartLine,
  InfoIcon,
  Setting,
} from "../../../common/Icons";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";
import ReactApexChart from "react-apexcharts";
import CustomTable from "../../common/table/table.component";
import { Tooltip } from "react-tooltip";
import GenericDropdown from "../../../common/components/DropDown/GenericDrop";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

function Leak() {
  const [view, setView] = useState("chart");
  const [selectedOption, setSelectedOption] = useState("Last Week");
  const [lineChartData, setLineChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [domains, setDomains] = useState([
    { name: "Show all", checked: false },
    { name: "Blog.thyrocare.com", checked: false },
    { name: "Cliso.thyrocare.com", checked: false },
  ]);
  const handleCheckboxChange = (index) => {
    const newDomains = [...domains];

    // If "Show all" is selected or deselected
    if (newDomains[index].name === "Show all") {
      const isChecked = !newDomains[index].checked;
      newDomains.forEach((domain, idx) => {
        newDomains[idx].checked = isChecked;
      });
    } else {
      newDomains[index].checked = !newDomains[index].checked;
  
      // If any other box is unchecked, uncheck "Show all"
      if (!newDomains[index].checked) {
        newDomains[0].checked = false;
      }
  
      // If all boxes except "Show all" are checked, check "Show all"
      const allChecked = newDomains.slice(1).every(domain => domain.checked);
      if (allChecked) {
        newDomains[0].checked = true;
      }
    }
  
    setDomains(newDomains);
    };

  const handleSelect = (option) => {
      setSelectedOption(option);
    };
  const [isDropdownOpen, setDropdownOpen] = useState(false); 
  const settingsDropdownRef = useRef(null);
  useOutsideClick(settingsDropdownRef, () => setDropdownOpen(false));
  const toggleDropdown = (event) => {
    event.stopPropagation(); 
    setDropdownOpen((prev) => !prev);
  };

  const getPeriodValue = (option) => {
      const index = options.indexOf(option);
      return option_values[index];
    };
  const getAlertHistory = async () => {
      try {
        const periodvalue = getPeriodValue(selectedOption);
        const result = await api.get("service/alert_system_statistic_get_alert_overview", {
          params: {
            period: periodvalue,
          },
        });
        setLineChartData(result.data);
      } catch (error) {
        console.error("Error fetching bubble chart stats:", error);
        throw error;
      }
    }
  
    useEffect(() => {
      getAlertHistory();
    }, [selectedOption]);
  
    useEffect(() => {
      if (lineChartData.length > 0) {
        const dates = lineChartData.map(item => item.service_name);
        const scores = lineChartData.map(item => item.count);
  
        setChartOptions({
          chart: {
            height: 350,
            type: "line",
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            categories: dates,
          },
          stroke: {
            curve: "smooth",
            width: 4,
          },
          fill: {
            type: "solid",
            colors: ["#ECC551"],
          },
          colors: ["#ECC551"],
          grid: {
            show: false,
          },
          tooltip: {
            enabled: true,
          },
        });
  
        setChartSeries([
          {
            name: "Risk Score",
            data: scores,
          },
        ]);
      } 
    },  [lineChartData]);

  const columns = [
    { Header: "Created", accessor: "created" },
    { Header: "Name", accessor: "name" },
    { Header: "Size", accessor: "size" },
    { Header: "Records", accessor: "records" },
  ];

  const data = [
    {
      name: "Johnny",
      created: "12-01-2024",
      size: "12",
      records: "200",
    },
    {
      name: "Johnny",
      created: "12-01-2024",
      size: "12",
      records: "200",
    },
    {
      name: "Johnny",
      created: "12-01-2024",
      size: "12",
      records: "200",
    },
    {
      name: "Johnny",
      created: "12-01-2024",
      size: "12",
      records: "200",
    },
  ];

  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-lg">
      <div className="tw-flex  tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-center tw-gap-1">
          <h2 className="tw-text-sm lg:tw-text-base tw-font-bold tw-whitespace-nowrap">
            {view === "chart" ? "Leak Trend Statistics" : "Latest Leaks"}
          </h2>
          {/* <div className="my-anchor-elementsa23">
            <InfoIcon />
          </div> */}
          {/* <Tooltip
            anchorSelect=".my-anchor-elementsa23"
            place="top"
            style={{ backgroundColor: "white", color: "black", width: "550px" }}
          >
            The Leak Trend Analysis provides a real-time overview of the most
            recent data leaks discovered and documented by our analysts. You can
            access this information through a graphical view for visual insights
            or a list view for detailed data representation, allowing you to
            monitor emerging threats and assess their relevance to your
            organization
          </Tooltip> */}
          <div className="tw-relative">
            <div onClick={toggleDropdown} className="tw-cursor-pointer">
              <Setting />
            </div>
            {isDropdownOpen && (
              <div
                ref={settingsDropdownRef}
                className="tw-absolute tw-mt-2 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-w-72 tw-z-10"
              >
                <div className="tw-max-h-40 tw-overflow-y-auto">
                  {domains.map((domain, index) => (
                    <label key={index} className="tw-flex tw-items-center tw-mb-2 tw-p-2">
                      <input
                        type="checkbox"
                        checked={domain.checked}
                        onChange={() => handleCheckboxChange(index)}
                        className="tw-mr-2 tw-h-5 tw-w-5 tw-form-checkbox tw-text-black tw-accent-black"
                      />
                      <span className="tw-text-sm tw-text-gray-700">
                        {domain.name}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="tw-flex tw-w-full tw-justify-end tw-items-end tw-gap-2">
          <div onClick={() => setView("chart")} className="tw-cursor-pointer">
            {/* Render ChartLine with condition based on `view` */}
            <ChartLine selected={view === "chart"} />
          </div>
          <div onClick={() => setView("table")} className="tw-cursor-pointer">
            {/* Render ChartButton with condition based on `view` */}
            <ChartButton selected={view === "table"} />
          </div>
        </div>
      </div>
      {view === "chart" && (
        <>
          <GenericDropdown
            options={options}
            defaultText={selectedOption}
            onSelect={handleSelect}
            staticFirstOption={true}
          />

          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="line"
            height={330}
          />
        </>
      )}
      {view === "table" && (
        <div className="tw-mt-12">
          <CustomTable data={data} columns={columns} selectable={false} />
        </div>
      )}
    </div>
  );
}

export default Leak;
const options = ["Last Week", "Last Month", "Last Year", "All Time"];
const option_values = ['last_week', 'last_month', 'last_year', 'all_time'];
