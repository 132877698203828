import React, { useEffect, useState } from "react";
import Heading from "../../common/components/heading/Heading";

import CustomTable from "../common/table/table.component";
import InputGroup from "../../common/components/InputGroup/InputGroup";
import CustomButton from "../../common/components/Button/Button";
import "react-datepicker/dist/react-datepicker.css";
import { IoIosArrowDown } from "react-icons/io";

import SelectGroup from "../../common/components/SelectDropdown/SelectDropdown";
import { useForm, useFieldArray } from "react-hook-form";
import Modal from "../../common/components/Modal/Modal.js";
import GoBack from "../assets/svgs/GoBack.js";
import Cross from "../../common/Icons/Cross.js";
import PencilIcon from "../../common/Icons/PencilIcon.js";
import DeleteIcon from "../../common/Icons/DeleteIcon.js";
import PlusIcon from "../../common/Icons/PlusIcon.js";
import SettingsIcon from "../assets/svgs/Setting.js";
import Delete from "../assets/svgs/Delete.js";
import { useLocation, useParams } from "react-router-dom";

export default function AlertingRules() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isNewAlertnigOpen, setIsNewAlertnigOpen] = useState(false);
  const [isNewExpertRuleOpen, setIsNewExpertRuleOpen] = useState(false);
  const [step, setStep] = useState(1);
  const columns = [
    {
      Header: "Rule Name",
      accessor: "date",
      render: (_, date) => {
        return <span className="tw-whitespace-nowrap">{date.date}</span>;
      },
    },
    {
      Header: "Keywords",
      accessor: "keywords",
    },
    { Header: "Types", accessor: "types" },

    {
      Header: "Actions",
      accessor: "action",
      render: (_, item) => {
        return (
          <div className="tw-flex tw-gap-2">
            <button>
              <SettingsIcon color="#71747C" />
            </button>
            <button>
              <Delete color="#71747C" />
            </button>
          </div>
        );
      },
    },
  ];

  const data = [
    {
      date: "2024-05-21",
      alertName: "Unusual login attempt",
      keywords: "spoofguard, domain.com",
      types: "leak",
      status: "In Progress",
      alertExtract:
        "peter@domain.com:passwd23; https://login.ebanding.com/changepwd:User...",
    },
    {
      date: "2024-06-12",
      alertName: "Password reset",
      keywords: "phishing, domain.com",
      types: "attack",
      status: "Solved",
      alertExtract:
        "john@domain.com:resetme123; https://reset.ebanding.com/resetpwd:Admin...",
    },
    {
      date: "2024-06-12",
      alertName: "Password reset",
      keywords: "phishing, domain.com",
      types: "attack",
      status: "Solved",
      alertExtract:
        "john@domain.com:resetme123; https://reset.ebanding.com/resetpwd:Admin...",
    },{
      date: "2024-06-12",
      alertName: "Password reset",
      keywords: "phishing, domain.com",
      types: "attack",
      status: "Solved",
      alertExtract:
        "john@domain.com:resetme123; https://reset.ebanding.com/resetpwd:Admin...",
    },{
      date: "2024-06-12",
      alertName: "Password reset",
      keywords: "phishing, domain.com",
      types: "attack",
      status: "Solved",
      alertExtract:
        "john@domain.com:resetme123; https://reset.ebanding.com/resetpwd:Admin...",
    },
    {
      date: "2024-07-15",
      alertName: "Phishing attempt",
      keywords: "phishing, finance",
      types: "attack",
      status: "In Progress",
      alertExtract:
        "bob@domain.com:letmein456; https://phish.ebanding.com/verify:Finance...",
    },
    {
      date: "2024-07-20",
      alertName: "Unauthorized access",
      keywords: "access, internal",
      types: "security",
      status: "Solved",
      alertExtract:
        "eve@domain.com:password1; https://access.ebanding.com/login:Security...",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      alertKeywords: [{ domainNameIp: "", condition: "" }],
      filters: [{ leakTag: "", excludeAccounts: "" }],
    },
  });

  const onSubmit = (data) => {
    setIsNewExpertRuleOpen(false)
    console.log(data);
  };

  const location = useLocation();

  const { isNewAlertingOpen: dashboardALertState } = location.state || {};

  useEffect(() => {
    if (dashboardALertState) {
      setIsNewAlertnigOpen(dashboardALertState);
    }
  }, []);

  const {
    fields: alertKeywordsFields,
    append: appendAlertKeyword,
    remove: removeAlertKeyword,
  } = useFieldArray({
    name: "alertKeywords",
    control,
  });
  const {
    fields: filtersFields,
    append: appendFiltersFields,
    remove: removeFiltersFields,
  } = useFieldArray({
    name: "filters",
    control,
  });

  return (
    <>
      {isNewAlertnigOpen ? (
        <div>
          <div
            className="tw-flex tw-gap-2 tw-items-center hover:tw-cursor-pointer tw-mb-3"
            onClick={() => setIsNewAlertnigOpen(false)}
          >
            <GoBack />
            <div className="tw-flex tw-gap-1">
              <Heading size="h3" text="New Alerting Rule" />
              <p className="tw-text-[#71747C]"> (Simple Setup)</p>
            </div>
          </div>
          <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-w-full">
            <div className="tw-flex tw-items-center tw-gap-2 tw-w-full tw-border-b tw-border-[#DFE0E3] tw-pb-4 tw-flex-col md:tw-flex-row">
              <span className="tw-whitespace-nowrap">Notify me, when the</span>
              <InputGroup
                // labelText="Phone"
                inputType="text"
                placeholder="Bug Type"
                inputName="bug"
                register={register}
                errors={errors}
                focusOnType={true}
              />
              <span className="tw-whitespace-nowrap">appears in</span>
              <SelectGroup
                htmlfor="attackType"
                // labelText="Country"
                inputName="attackType"
                register={register}
                errors={errors}
                options={[
                  { value: "", label: "Leak" },
                  { value: "Breach", label: "Breach" },
                  { value: "Hack", label: "Hack" },
                ]}
                onChange={() => {}}
                isLoading={false}
                className="md:tw-w-[200px] tw-w-[215px]"
              />
              <span className="tw-whitespace-nowrap">
                and send the notification
              </span>
              <InputGroup
                // labelText="Phone"
                inputType="email"
                placeholder="ali@example.com"
                inputName="user"
                register={register}
                errors={errors}
                focusOnType={true}
              />
              <button
                className="tw-bg-primary tw-text-white tw-p-2 tw-rounded-lg md:tw-w-[60px] tw-h-[40px] tw-w-[215px]"
                onClick={() => setIsNewAlertnigOpen(false)}
              >
                Save
              </button>
            </div>

            <div className="tw-pt-4 tw-flex tw-items-center tw-justify-between tw-flex-col md:tw-flex-row tw-gap-4 md:tw-gap-0">
              <span>Need more sophisticated alerting Rules?</span>
              <button
                className="tw-flex tw-items-center tw-gap-[4px] tw-border tw-border-primary tw-bg-white tw-text-primary  tw-p-2 tw-rounded-lg  md:tw-w-max tw-font-semibold "
                onClick={() => setIsNewExpertRuleOpen(true)}
              >
                New Expert Rule Setup
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="tw-mt-4 tw-flex md:tw-flex-row tw-justify-between tw-items-center tw-px-6 tw-flex-col">
            <Heading size="h2" text="Alerting Rules" />
            <button
              className="tw-bg-primary tw-w-full tw-text-white tw-p-2 tw-rounded-lg md:tw-max-w-[150px] md:tw-font-semibold"
              onClick={() => setIsNewAlertnigOpen(true)}
            >
              New Alerting Rule
            </button>
          </div>

          <div className="tw-py-4 md:tw-px-6 ">
            <div className="tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 ">
              <div className="">
                <CustomTable data={data} columns={columns} selectable={true} />
              </div>
            </div>
          </div>
        </>
      )}
      {isNewExpertRuleOpen && (
        <>
          <div className="tw-flex tw-flex-col tw-gap-6 md:tw-w-[540px] tw-bg-white tw-absolute tw-top-0 tw-right-0 tw-p-6 tw-overflow-y-auto tw-h-[100%] tw-shadow-lg">
            <div className="tw-flex tw-items-center tw-justify-between">
              <span className="tw-text-lg tw-font-bold">
                Expert Alerting Rule Setup
              </span>
              <button onClick={() => setIsNewExpertRuleOpen(false)}>
                <Cross />
              </button>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-2">
              <span className="tw-text-[#35363A] tw-text-lg tw-font-semibold">
                Step 1 : New Alerting Rule (Expert Setup)
              </span>
              <div className="tw-border tw-border-[#DFE0E3] tw-p-4 tw-rounded-lg">
                <div className="tw-flex tw-items-end tw-gap-3">
                  <InputGroup
                    labelText="Rule Name"
                    inputType="text"
                    placeholder="Enter rule name"
                    inputName="ruleName"
                    register={register}
                    errors={errors}
                    focusOnType={true}
                    className=""
                  />
                  <button>
                    <Cross />
                  </button>
                </div>

                {step === 1 && (
                  <button
                    className="tw-bg-primary tw-text-white tw-py-2 tw-px-3 tw-rounded-lg tw-mt-[10px]"
                    onClick={() => setStep(2)}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            {step >= 2 && (
              <div className="tw-flex tw-flex-col tw-gap-2">
                <span className="tw-text-[#35363A] tw-text-lg tw-font-semibold">
                  Step 2 : Define Rule Alert Type
                </span>
                <div className="tw-border tw-border-[#DFE0E3] tw-p-4 tw-rounded-lg">
                  <div className="tw-flex tw-items-end tw-gap-3">
                    <SelectGroup
                      htmlfor="ruleAlert"
                      labelText="Rule Alert"
                      inputName="ruleAlert"
                      register={register}
                      errors={errors}
                      options={[
                        { value: "", label: "Leak" },
                        { value: "Breach", label: "Breach" },
                        { value: "Hack", label: "Hack" },
                      ]}
                      onChange={() => {}}
                      isLoading={false}
                      className="tw-w-full"
                    />
                    <button>
                      <Cross />
                    </button>
                  </div>
                  {step === 2 && (
                    <button
                      className="tw-bg-primary tw-text-white tw-py-2 tw-px-3 tw-rounded-lg tw-mt-[10px]"
                      onClick={() => setStep(3)}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            )}
            {step >= 3 && (
              <div className="tw-flex tw-flex-col tw-gap-2">
                <span className="tw-text-[#35363A] tw-text-lg tw-font-semibold">
                  Step 3 : Define your Alert Keyword
                </span>

                <div className="tw-border tw-border-[#DFE0E3] tw-p-4 tw-rounded-lg">
                  <InputGroup
                    labelText="Alert Keyword"
                    inputType="text"
                    placeholder="One keyword rule a Domain, IP or email domain"
                    inputName="alertKeyword"
                    register={register}
                    errors={errors}
                    focusOnType={true}
                    className=""
                  />

                  <div className="tw-grid tw-grid-cols-4 tw-items-end tw-gap-[10px] tw-mt-3">
                    <div className="tw-col-span-1">
                      <SelectGroup
                        htmlfor="ruleCondition"
                        labelText="Add Condition"
                        inputName="ruleCondition"
                        register={register}
                        errors={errors}
                        options={[
                          { value: "", label: "AND" },
                          { value: "OR", label: "OR" },
                          { value: "AND", label: "AND" },
                        ]}
                        onChange={() => {}}
                        isLoading={false}
                        className="tw-w-full"
                      />
                    </div>
                    <div className="tw-col-span-3">
                      {" "}
                      <InputGroup
                        // labelText="a"
                        inputType="text"
                        placeholder="Keyword"
                        inputName="alertKeyword"
                        register={register}
                        errors={errors}
                        focusOnType={true}
                        className=""
                      />
                    </div>
                  </div>

                  {step === 3 && (
                    <button
                      className="tw-bg-primary tw-text-white tw-py-2 tw-px-3 tw-rounded-lg tw-mt-[10px]"
                      onClick={() => setStep(4)}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            )}

            {step >= 4 && (
              <div className="tw-flex tw-flex-col tw-gap-2">
                <span className="tw-text-[#35363A] tw-text-lg tw-font-semibold">
                  Step 4 : Add Filters (Only for Leaks from database Query)
                </span>
                <div className="tw-border tw-border-[#DFE0E3] tw-p-4 tw-rounded-lg">
                  <div className="tw-flex tw-items-end tw-gap-3">
                    <SelectGroup
                      htmlfor="ruleAlert"
                      labelText="Include only results with the following Leaks Tag(s)"
                      inputName="ruleAlert"
                      register={register}
                      errors={errors}
                      options={[
                        { value: "", label: "Must Contain “Password,hash”" },
                        { value: "Breach", label: "Breach" },
                        { value: "Hack", label: "Hack" },
                      ]}
                      onChange={() => {}}
                      isLoading={false}
                      className="tw-w-full"
                    />
                    <button>
                      <Cross />
                    </button>
                  </div>
                  <div className="tw-flex tw-items-end tw-gap-3">
                    <SelectGroup
                      htmlfor="ruleAlert"
                      labelText="Add Special Filters"
                      inputName="ruleAlert"
                      register={register}
                      errors={errors}
                      options={[
                        {
                          value: "",
                          label:
                            "Exclude duplicate usernames / passwords in combo",
                        },
                        { value: "Breach", label: "Breach" },
                        { value: "Hack", label: "Hack" },
                      ]}
                      onChange={() => {}}
                      isLoading={false}
                      className="tw-w-full"
                    />
                    <button>
                      <Cross />
                    </button>
                  </div>
                  {step === 4 && (
                    <button
                      className="tw-bg-primary tw-text-white tw-py-2 tw-px-3 tw-rounded-lg tw-mt-[10px]"
                      onClick={() => setStep(5)}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            )}

            {step >= 5 && (
              <div className="tw-flex tw-flex-col tw-gap-2">
                <span className="tw-text-[#35363A] tw-text-lg tw-font-semibold">
                  Step 5 : Define Email Alerting Settings
                </span>
                <div className="tw-border tw-border-[#DFE0E3] tw-p-4 tw-rounded-lg">
                  <div className="tw-grid md:tw-grid-cols-2 tw-gap-4">
                    <SelectGroup
                      htmlfor="Recipient"
                      labelText="Recipient"
                      inputName="recipient"
                      register={register}
                      errors={errors}
                      options={[
                        { value: "", label: "Recipient" },
                        { value: "ali", label: "Ali" },
                        { value: "maaz", label: "maaz" },
                        { value: "waqas", label: "waqas" },
                      ]}
                      onChange={() => {}}
                      placeholder="Select"
                      className="tw-w-full"
                      isLoading={false}
                    />
                    <SelectGroup
                      htmlfor="Sender"
                      labelText="Sender"
                      inputName="Sender"
                      register={register}
                      errors={errors}
                      options={[
                        { value: "", label: "Sender" },
                        { value: "ali", label: "Ali" },
                        { value: "maaz", label: "maaz" },
                        { value: "waqas", label: "waqas" },
                      ]}
                      onChange={() => {}}
                      placeholder="Select"
                      className="tw-w-full"
                      isLoading={false}
                    />
                    <SelectGroup
                      htmlfor="Email Template"
                      labelText="Email Template"
                      inputName="Email Template"
                      register={register}
                      errors={errors}
                      options={[
                        { value: "", label: "Email Template" },
                        { value: "ali@gmail.com", label: "Ali@gmail.com" },
                        { value: "maaz@gmail.com", label: "maaz@gmail.com" },
                        { value: "waqas@gmail.com", label: "waqas@gmail.com" },
                      ]}
                      onChange={() => {}}
                      placeholder="Select"
                      className="tw-w-full"
                      isLoading={false}
                    />
                  </div>
                </div>
              </div>
            )}

            {step >= 5 && (
              <div className="tw-flex tw-gap-4 tw-items-center tw-mt-6">
                <button
                  className="tw-bg-primary tw-text-white tw-p-3 tw-rounded-lg"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </button>
                <button
                  className="tw-text-primary tw-bg-white tw-border tw-p-3 tw-rounded-lg"
                  onClick={() => setIsNewExpertRuleOpen(false)}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
