import { combineReducers } from '@reduxjs/toolkit';

import dataLeaksReducer from './dataLeaks';
import stealerLogsReducer from './stealerLogs';
import urlShortenerReducer from './urlShortener';
import cloudStorageReducer from './cloudStorage';
import dataPastesReducer from './dataPastes';
import ransomwareReducer from './ransomware';
import emailReferencesReducer from './emailReferences';
import dataBrokersReducer from './dataBrokers';
import phishingAttackReducer from './phishingAttack';
import fakeAppsReducer from './fakeApps';
import sslLogsReducer from './sslLogs';
import creditCardReducer from './creditCard';
import creditCardLeaksReducer from './creditCardLeaks';

export default combineReducers({
  dataLeaks: dataLeaksReducer,
  stealerLogs: stealerLogsReducer,
  urlShortener: urlShortenerReducer,
  cloudStorage: cloudStorageReducer,
  dataPastes: dataPastesReducer,
  ransomware: ransomwareReducer,
  emailReferences: emailReferencesReducer,
  dataBrokers: dataBrokersReducer,
  phishingAttack: phishingAttackReducer,
  fakeApps: fakeAppsReducer,
  sslLogs: sslLogsReducer,
  creditCard: creditCardReducer,
  creditCardLeaks: creditCardLeaksReducer,
});
