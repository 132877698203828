import React from "react";
import Chart from "react-apexcharts";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";
import Heading from "../../../common/components/heading/Heading";
import CustomSelect from "../../../common/components/Select/select";
import GenericDropdown from "../../../common/components/DropDown/GenericDrop";

const AlertsOverviewChart = () => {
  const data = {
    series: [
      {
        data: [450, 300, 200, 350, 50, 150, 200, 100],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "70%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          "External Resources",
          "Discord",
          "Telegram",
          "Hacker Forum",
          "Ransomware",
          "Credit card",
          "Paste",
          "Leaks",
        ],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 1,
          gradientToColors: ["#ECC551"],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
          colorStops: [
            {
              offset: 0,
              color: "#F9EDC9",
              opacity: 1,
            },
            {
              offset: 100,
              color: "#ECC551",
              opacity: 1,
            },
          ],
        },
      },
      
    },
  };

  const handleSelect = (option) => {
    console.log("", option);
  };

  return (
    <div className="tw-bg-white tw-rounded-lg tw-px-4 tw-pt-4">
      <div className="tw-flex tw-justify-between tw-items-center">
        <Heading size="h3" text="Alerts Overview" />

        <GenericDropdown
            options={options}
            // onSelect={handleSelect}
            defaultText="Last 30 Days"
            staticFirstOption={true}
          />
      </div>

      <Chart
        options={data.options}
        series={data.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default AlertsOverviewChart;
const options = ["Date Range", "Last Year", "Last 6 Month", "All Time"];
