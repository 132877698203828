import React from "react";

const DbUpload = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3332 9.99984L9.99984 6.6665M9.99984 6.6665L6.6665 9.99984M9.99984 6.6665V14.3332C9.99984 15.4921 9.99984 16.0716 10.4586 16.7203C10.7634 17.1514 11.641 17.6834 12.1642 17.7543C12.9516 17.861 13.2506 17.705 13.8486 17.3931C16.5137 16.0028 18.3332 13.2138 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 13.0843 3.34233 15.7774 5.83317 17.2183"
          stroke="#44464A"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default DbUpload;
