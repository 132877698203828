import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import Heading from 'common/components/heading/Heading';
import ExportButton from 'common/components/Export/ExportButton';
import Table from 'components/common/table/table.component';
import Loading from 'common/components/Loading/Loading';

import { crawlerSearch } from 'store/slices/search/database/dataBrokers';
import { capitalizeFirstLetter, truncateString } from 'utils/global';

const DataBrokers = ({ selectedOptionLabel, searchTerm }) => {
  const dispatch = useDispatch();
  const { query, list, totalElements, loading, error, crawlers } = useSelector(
    (state) => state.search.database.dataBrokers,
  );

  useEffect(() => {
    dispatch(crawlerSearch());
  }, []);

  const size = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      Header: 'Forum | Seller',
      accessor: 'source',
      render: (value) => <div className="tw-text-[#44464A]"><p>{value}</p></div>,
    },
    // {
    //   Header: 'Content Extract',
    //   accessor: 'content',
    //   render: (value, _, rowIndex) => {
    //     const short = truncateString(value);

    //     return (
    //       <>
    //         <span className="tw-text-[#44464A]" id={`row-${rowIndex}`}>
    //           {short}
    //         </span>
    //         {short.length < value.length && (
    //           <Tooltip
    //             anchorSelect={`#row-${rowIndex}`}
    //             place="top-end"
    //             border="1px solid #eee"
    //             opacity={1}
    //             style={{
    //               backgroundColor: '#ECC551',
    //               color: 'black',
    //               borderRadius: 10,
    //               padding: 20,
    //               width: 450,
    //               boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
    //             }}
    //           >
    //             {value}
    //           </Tooltip>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      Header: 'Scraped Date',
      accessor: 'date_scrapped',
      render: (value) => <span className=" tw-text-[#44464A]">{value}</span>,
    },
    {
      Header: 'Price',
      render: (_, record) => {
        return (
          <span className=" tw-text-[#44464A]">
            {extractPrice(record.content)}
          </span>
        );
      },
    },
    {
      Header: 'URL',
      render: (_, record) => {
        return (
          <span className="tw-break-all tw-text-[#44464A]">
            {extractURL(record.content)}
          </span>
        );
      },
    },
    {
      Header: 'Seller',
      render: (_, record) => {
        return (
          <span className=" tw-text-[#44464A]">
            {extractSeller(record.content)}
          </span>
        );
      },
    },
    {
      Header: 'Data Types',
      accessor: 'meta',
      render: (value) => (
        <span className=" tw-text-[#44464A]">
          {capitalizeFirstLetter(value.replace('section: ', ''))}
        </span>
      ),
    },
  ];

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);

  // Function to match the heights of child divs
  const matchHeights = () => {
    const section1 = section1Ref.current;
    const section2 = section2Ref.current;

    const height = section1.offsetHeight;
    section2.setAttribute('style', `height:${height}px !important`);
  };

  useEffect(() => {
    matchHeights();
    window.addEventListener('resize', matchHeights);

    return () => window.removeEventListener('resize', matchHeights);
  }, []);

  return (
    <div className="tw-grid tw-grid-rows-1 tw-grid-cols-5 tw-gap-4">
      <div
        className="tw-col-span-5 lg:tw-col-span-4 tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4"
        ref={section1Ref}
      >
        {!loading ? (
          <>
            <div className="tw-flex tw-justify-between tw-items-center">
              <p className="tw-font-bold tw-text-lg tw-text-[#263238]">
                Search Result for {selectedOptionLabel} Search
              </p>

              <ExportButton disabled={true} />
            </div>
            <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">
                  Search results
                </span>
                <span className="tw-text-sm tw-font-medium">
                  {totalElements}
                </span>
              </div>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-sm tw-text-[#344054]">
                  Search term
                </span>
                <span className="tw-text-sm tw-font-medium">{query}</span>
              </div>
            </div>

            <Table
              data={list}
              columns={columns}
              rowsPerPage={size}
              paginatorLabel="data"
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <Loading label="The search is still running – Please wait a moment..." />
        )}
      </div>
      <div
        className="tw-col-span-5 lg:tw-col-span-1 tw-bg-white tw-p-4 tw-rounded-lg tw-overflow-auto !tw-h-0"
        ref={section2Ref}
      >
        <Heading size="h3" text="Site Status" />

        <div className="tw-mt-4 tw-relative">
          <div className="tw-bg-[#F4F7FC] tw-text-sm tw-w-full tw-h-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-font-medium tw-justify-between">
            <span>Site</span>
            <span className="tw-pr-[14px]">Status</span>
          </div>
          <div className="tw-overflow-y-auto">
            {crawlers.map((crawler, index) => {
              return (
                <div
                  className="tw-w-full tw-flex tw-items-center tw-py-3 tw-px-3 tw-justify-between"
                  key={index}
                >
                  <span className="tw-text-[#5B5D63] tw-text-sm">
                    {crawler.forum_name}
                  </span>
                  <span
                    className={
                      crawler.status === 'FAILED'
                        ? 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]'
                        : 'tw-whitespace-nowrap tw-text-xs tw-font-medium tw-bg-[#ECFDF3] tw-border tw-border-[#ABEFC6] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#067647]'
                    }
                  >
                    {crawler.status}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataBrokers;

const extractSeller = (str) => {
  // Regular expression to match both formats: seller: xxx or "seller" : "xxx"
  const regex = /seller\s*:\s*"?([\w\s]+)"?/;

  const match = str.match(regex);
  return match ? match[1] : '—';
};

const extractPrice = (str) => {
  // Regular expression to match both formats: price: xxx or "price" : "xxx"
  const regex = /price:\s*\$?\s*(\d+(?:\.\d{1,2})?)\s*\$?/; // /price\s*:\s*(\d+(\.\d+)?|"(\d+(\.\d+)?)")/;

  const match = str.match(regex);
  if (match) {
    const priceString = match[3] ? match[3] : match[1];
    return '$' + parseFloat(priceString).toFixed(2);
  } else {
    return '—';
  }
};

const extractURL = (str) => {
  // Regular expression to match both formats: url: xxx or "url" : "xxx"
  const regex = /url\s*:\s*"?([\w:/?.&%=-]+)"?/;

  const match = str.match(regex);
  return match ? match[1] : '—';
};
