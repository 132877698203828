import React from "react";

const WorriedFace = ({ color }) => {
  return (
    <div>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.3733 9.0102C16.965 8.94215 16.5788 9.21778 16.5103 9.62593L16.5057 9.64953C16.5009 9.67276 16.4926 9.71078 16.4799 9.76146C16.4545 9.863 16.4119 10.0142 16.3454 10.1984C16.2117 10.5686 15.9857 11.0608 15.6188 11.55C14.9049 12.5018 13.62 13.5 11.25 13.5C10.8358 13.5 10.5 13.8358 10.5 14.25C10.5 14.6642 10.8358 15 11.25 15C14.13 15 15.8451 13.7482 16.8187 12.45C17.2956 11.8142 17.5852 11.1814 17.7562 10.7079C17.842 10.4702 17.899 10.2698 17.9351 10.1253C17.9532 10.0529 17.9662 9.99423 17.9749 9.95155C17.9793 9.9302 17.9827 9.91282 17.9851 9.89969L17.9881 9.88321L17.9891 9.87742L17.9895 9.87515L17.9898 9.8733C18.0579 9.46472 17.7819 9.0783 17.3733 9.0102Z"
          fill="#212121"
        />
        <path
          d="M31.4943 9.64953L31.4897 9.62613C31.4213 9.21798 31.035 8.94215 30.6267 9.0102C30.2181 9.0783 29.9421 9.46472 30.0102 9.8733L30.0105 9.87515L30.0109 9.87742L30.0119 9.88321L30.0149 9.89969C30.0173 9.91282 30.0207 9.9302 30.0251 9.95155C30.0338 9.99423 30.0468 10.0529 30.0649 10.1253C30.101 10.2698 30.158 10.4702 30.2438 10.7079C30.4148 11.1814 30.7044 11.8142 31.1813 12.45C32.1549 13.7482 33.87 15 36.75 15C37.1642 15 37.5 14.6642 37.5 14.25C37.5 13.8358 37.1642 13.5 36.75 13.5C34.38 13.5 33.0951 12.5018 32.3812 11.55C32.0143 11.0608 31.7883 10.5686 31.6546 10.1984C31.5881 10.0142 31.5455 9.863 31.5201 9.76146C31.5074 9.71078 31.4991 9.67276 31.4943 9.64953Z"
          fill="#212121"
        />
        <path
          d="M19.3416 38.1708C19.5989 37.6563 20.9931 36 24 36C27.0069 36 28.4011 37.6563 28.6584 38.1708C29.0288 38.9118 29.9299 39.2121 30.6708 38.8416C31.4118 38.4712 31.7121 37.5701 31.3416 36.8292C30.5989 35.3437 28.1931 33 24 33C19.8069 33 17.4011 35.3437 16.6584 36.8292C16.2879 37.5701 16.5882 38.4712 17.3292 38.8416C18.0701 39.2121 18.9712 38.9118 19.3416 38.1708Z"
          fill="#212121"
        />
        <path
          d="M30.2774 13.7374C26.6736 14.6216 24 17.8736 24 21.75C24 17.8694 21.3206 14.6145 17.7109 13.7345C17.3183 14.1941 16.8497 14.6338 16.2973 15.0219C19.7693 15.3005 22.5 18.2064 22.5 21.75C22.5 25.478 19.4779 28.5 15.75 28.5C12.0221 28.5 9 25.478 9 21.75C9 19.6279 9.97934 17.7344 11.5107 16.497C11.4246 16.499 11.3377 16.5 11.25 16.5C10.7138 16.5 10.2201 16.322 9.83206 16.0019C8.38873 17.4877 7.5 19.5151 7.5 21.75C7.5 26.3064 11.1937 30 15.75 30C20.3063 30 24 26.3064 24 21.75C24 26.3064 27.6936 30 32.25 30C36.8063 30 40.5 26.3064 40.5 21.75C40.5 19.5118 39.6087 17.4817 38.1616 15.9954C37.7724 16.3196 37.2756 16.5 36.7358 16.5C36.653 16.5 36.571 16.4991 36.4897 16.4974C38.0208 17.7348 39 19.6281 39 21.75C39 25.478 35.9779 28.5 32.25 28.5C28.5221 28.5 25.5 25.478 25.5 21.75C25.5 18.2107 28.2241 15.3076 31.69 15.0229C31.1381 14.6353 30.6698 14.1963 30.2774 13.7374Z"
          fill="#212121"
        />
        <path
          d="M21 22.4999C21 24.1568 19.6569 25.4999 18 25.4999C16.3431 25.4999 15 24.1568 15 22.4999C15 20.8431 16.3431 19.4999 18 19.4999C19.6569 19.4999 21 20.8431 21 22.4999Z"
          fill="#212121"
        />
        <path
          d="M33 22.4999C33 24.1568 31.6569 25.4999 30 25.4999C28.3431 25.4999 27 24.1568 27 22.4999C27 20.8431 28.3431 19.4999 30 19.4999C31.6569 19.4999 33 20.8431 33 22.4999Z"
          fill="#212121"
        />
        <path
          d="M7.14513 8.13414C10.9695 3.98129 16.6428 1.5 23.9983 1.5C31.3538 1.5 37.0272 3.98129 40.8515 8.13414C44.6573 12.2668 46.4967 17.9067 46.4967 23.9983C46.4967 30.09 44.6573 35.7299 40.8515 39.8625C37.0272 44.0154 31.3538 46.4967 23.9983 46.4967C16.6428 46.4967 10.9695 44.0154 7.14513 39.8625C3.3394 35.7299 1.5 30.09 1.5 23.9983C1.5 17.9067 3.3394 12.2668 7.14513 8.13414ZM9.35194 10.1664C6.15893 13.6337 4.5 18.4929 4.5 23.9983C4.5 29.5037 6.15893 34.363 9.35194 37.8303C12.5263 41.2773 17.3522 43.4967 23.9983 43.4967C30.6445 43.4967 35.4703 41.2773 38.6447 37.8303C41.8377 34.363 43.4967 29.5037 43.4967 23.9983C43.4967 18.4929 41.8377 13.6337 38.6447 10.1664C35.4703 6.71935 30.6445 4.5 23.9983 4.5C17.3522 4.5 12.5263 6.71935 9.35194 10.1664Z"
          fill="#212121"
        />
      </svg>
    </div>
  );
};

export default WorriedFace;
