import * as React from "react";

function FacebookIcon(props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9995 0.440186C5.173 0.440186 0.439453 5.17374 0.439453 11.0002C0.439453 16.2894 4.34067 20.6666 9.42164 21.4299L9.97289 21.5133V13.1921H7.4782V11.5027H9.97289V9.25925C9.97289 7.87777 10.3044 6.96791 10.8532 6.39706C11.402 5.82622 12.2129 5.53831 13.3413 5.53831C14.2436 5.53831 14.5947 5.59298 14.9285 5.63394V7.01675H13.7538C13.0873 7.01675 12.5405 7.38862 12.2566 7.88394C11.9728 8.37925 11.8845 8.97066 11.8845 9.57331V11.5017H14.8235L14.562 13.1911H11.8845V21.5264L12.4291 21.4524C17.5823 20.7535 21.5595 16.3411 21.5595 11.0002C21.5595 5.17374 16.8259 0.440186 10.9995 0.440186ZM10.9995 1.40019C16.3071 1.40019 20.5995 5.69256 20.5995 11.0002C20.5995 15.6705 17.2566 19.5114 12.8445 20.3761V14.1511H15.3851L15.9429 10.5417H12.8445V9.57331C12.8445 9.07532 12.9356 8.62913 13.0891 8.36112C13.2427 8.09312 13.3907 7.97675 13.7538 7.97675H15.8885V4.76487L15.4723 4.70862C15.1844 4.66967 14.5271 4.57831 13.3413 4.57831C12.0438 4.57831 10.9301 4.93176 10.1613 5.73144C9.39253 6.53111 9.01289 7.72185 9.01289 9.25925V10.5427H6.5182V14.1521H9.01289V20.3461C4.67161 19.4265 1.39945 15.6199 1.39945 11.0002C1.39945 5.69256 5.69182 1.40019 10.9995 1.40019Z"
        fill="#455A64"
      />
    </svg>
  );
}

export default FacebookIcon;
