import React from "react";

const AngryFace = ({ color }) => {
  return (
    <div>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.4348 18.7516C22.0553 18.7846 22.5314 19.3144 22.4983 19.9349C22.4653 20.5553 21.9355 21.0315 21.3151 20.9984C19.7894 20.9171 18.332 20.543 17.0899 19.678C15.8369 18.8055 14.8996 17.5007 14.308 15.7318C14.111 15.1426 14.4289 14.5051 15.0181 14.3081C15.6074 14.111 16.2448 14.4289 16.4418 15.0182C16.9073 16.4098 17.5852 17.2811 18.3757 17.8316C19.1769 18.3896 20.1911 18.6853 21.4348 18.7516Z"
          fill="#212121"
        />
        <path
          d="M26.5651 18.7516C25.9446 18.7846 25.4685 19.3144 25.5015 19.9349C25.5346 20.5553 26.0644 21.0315 26.6848 20.9984C28.2105 20.9171 29.6679 20.543 30.91 19.678C32.1629 18.8055 33.1003 17.5007 33.6918 15.7318C33.8889 15.1426 33.571 14.5051 32.9818 14.3081C32.3925 14.111 31.7551 14.4289 31.558 15.0182C31.0926 16.4098 30.4147 17.2811 29.6242 17.8316C28.823 18.3896 27.8088 18.6853 26.5651 18.7516Z"
          fill="#212121"
        />
        <path
          d="M19.3416 38.1708C19.5988 37.6563 20.993 36 23.9999 36C27.0069 36 28.401 37.6563 28.6583 38.1708C29.0288 38.9118 29.9298 39.2121 30.6708 38.8416C31.4117 38.4711 31.7121 37.5701 31.3416 36.8292C30.5988 35.3437 28.193 33 23.9999 33C19.8069 33 17.401 35.3437 16.6583 36.8292C16.2878 37.5701 16.5882 38.4711 17.3291 38.8416C18.0701 39.2121 18.9711 38.9118 19.3416 38.1708Z"
          fill="#212121"
        />
        <path
          d="M26.6864 22.4995C26.1543 22.5124 25.6534 22.365 25.2315 22.1005C24.9203 22.9243 24.75 23.8174 24.75 24.7502C24.75 28.8923 28.1079 32.2502 32.25 32.2502C36.3921 32.2502 39.75 28.8923 39.75 24.7502C39.75 21.403 37.5573 18.5679 34.53 17.603C34.2885 18.0759 34.0133 18.5182 33.7072 18.9284C36.3167 19.5794 38.25 21.939 38.25 24.7502C38.25 28.0639 35.5637 30.7502 32.25 30.7502C28.9363 30.7502 26.25 28.0639 26.25 24.7502C26.25 23.9542 26.405 23.1945 26.6864 22.4995Z"
          fill="#212121"
        />
        <path
          d="M21.75 24.7502C21.75 23.9542 21.595 23.1945 21.3136 22.4995C21.8457 22.5122 22.3466 22.3647 22.7684 22.1001C23.0797 22.9241 23.25 23.8172 23.25 24.7502C23.25 28.8923 19.8921 32.2502 15.75 32.2502C11.6079 32.2502 8.25 28.8923 8.25 24.7502C8.25 21.4032 10.4424 18.5683 13.4694 17.6032C13.7109 18.0761 13.9861 18.5184 14.2922 18.9285C11.683 19.5798 9.75 21.9393 9.75 24.7502C9.75 28.0639 12.4363 30.7502 15.75 30.7502C19.0637 30.7502 21.75 28.0639 21.75 24.7502Z"
          fill="#212121"
        />
        <path
          d="M21 25.5C21 27.1569 19.6569 28.5 18 28.5C16.3431 28.5 15 27.1569 15 25.5C15 23.8431 16.3431 22.5 18 22.5C19.6569 22.5 21 23.8431 21 25.5Z"
          fill="#212121"
        />
        <path
          d="M33 25.5C33 27.1569 31.6569 28.5 30 28.5C28.3431 28.5 27 27.1569 27 25.5C27 23.8431 28.3431 22.5 30 22.5C31.6569 22.5 33 23.8431 33 25.5Z"
          fill="#212121"
        />
        <path
          d="M44.9967 23.9983C44.9967 35.5954 38 44.9967 23.9983 44.9967C9.99665 44.9967 3 35.5954 3 23.9983C3 12.4013 9.99665 3 23.9983 3C38 3 44.9967 12.4013 44.9967 23.9983ZM37.5413 36.8141C40.428 33.6795 41.9967 29.2106 41.9967 23.9983C41.9967 18.7861 40.428 14.3171 37.5413 11.1825C34.6919 8.08837 30.2898 6 23.9983 6C17.7069 6 13.3047 8.08837 10.4553 11.1825C7.56869 14.3171 6 18.7861 6 23.9983C6 29.2106 7.56869 33.6795 10.4553 36.8141C13.3047 39.9083 17.7069 41.9967 23.9983 41.9967C30.2898 41.9967 34.6919 39.9083 37.5413 36.8141Z"
          fill="#212121"
        />
      </svg>
    </div>
  );
};

export default AngryFace;
