import React, { useState, useEffect } from "react";
import api from 'store/api';

const CompanySettings = ({onCancel}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [countries, setCountries] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [formData, setFormData] = useState({
    domain: "",
    size: "",
    country: "",
    industry: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("form Data ---> ", formData);
    try {
      const requestResponse = await api.post(
        `settings/create_company/`,
        {
          country: formData.country,
          industry_type: formData.industry,
          domain: formData.domain,
          size: formData.size
        },
      );
      console.log("company_settings :: ", requestResponse);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await api.get('/settings/country_list');
        setCountries(response.data.results);
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };

    const fetchIndustries = async () => {
      try {
        const response = await api.get('/settings/industry_type_list');
        setIndustries(response.data);
      } catch (error) {
        console.error('Error fetching industry data:', error);
      }
    };
    fetchCountries();
    fetchIndustries();
  }, []);

  const handleCountryChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleIndustryChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  if (!isOpen) return null;

  return (
    <div className="tw-max-w-sm tw-mx-auto tw-p-4 tw-bg-white tw-rounded-lg tw-shadow-md">
      <div className="tw-text-base tw-font-bold tw-text-gray-900 tw-mb-2">
        Company Settings
      </div>
      <form onSubmit={handleSubmit}>
        <div className="tw-mb-4">
          <label
            htmlFor="domain"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Company domain
          </label>
          <input
            type="text"
            name="domain"
            id="domain"
            value={formData.domain}
            onChange={handleChange}
            className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-focus:tw-outline-none tw-focus:tw-ring-yellow-500 tw-focus:tw-border-yellow-500 sm:tw-text-sm"
            placeholder="Company domain"
          />
        </div>

        <div className="tw-mb-4">
          <label
            htmlFor="size"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Company size
          </label>
          <input
            type="text"
            name="size"
            id="size"
            value={formData.size}
            onChange={handleChange}
            className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-focus:tw-outline-none tw-focus:tw-ring-yellow-500 tw-focus:tw-border-yellow-500 sm:tw-text-sm"
            placeholder="Company size"
          />
        </div>

        <div className="tw-mb-4">
          <label
            htmlFor="country"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Country
          </label>
          <select
            name="country"
            id="country"
            value={formData.country}
            onChange={handleCountryChange}
            className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-white tw-rounded-md tw-shadow-sm tw-focus:tw-outline-none tw-focus:tw-ring-yellow-500 tw-focus:tw-border-yellow-500 sm:tw-text-sm"
          >
            <option value="" disabled>
              Select
            </option>
            {countries.map((country) => (
              <option key={country.id} value={country.name}>
                {country.name}
              </option>
            ))}
            {/* Add more country options as needed */}
          </select>
        </div>

        <div className="tw-mb-4">
          <label
            htmlFor="industry"
            className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
          >
            Industry Type
          </label>
          <select
            name="industry"
            id="industry"
            value={formData.industry}
            onChange={handleIndustryChange}
            className="tw-mt-1 tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-white tw-rounded-md tw-shadow-sm tw-focus:tw-outline-none tw-focus:tw-ring-yellow-500 tw-focus:tw-border-yellow-500 sm:tw-text-sm"
          >
            <option value="" disabled>
              Select
            </option>
            {industries.map((industry) => (
              <option key={industry.id} value={industry.name}>
                {industry.name}
              </option>
            ))}
            {/* Add more industry options as needed */}
          </select>
        </div>

        <div className="tw-flex tw-justify-end">
          <button
            type="submit"
            className="tw-bg-yellow-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-md tw-shadow-sm hover:tw-bg-yellow-600 tw-focus:tw-outline-none tw-focus:tw-ring-2 tw-focus:tw-ring-offset-2 tw-focus:tw-ring-yellow-500"
          >
            Apply
          </button>
          <button
            type="button"
            className="tw-ml-2 tw-border tw-font-medium tw-text-gray-700 tw-px-4 tw-py-2 tw-rounded-md tw-shadow-sm hover:tw-bg-gray-400 tw-focus:tw-outline-none tw-focus:tw-ring-2 tw-focus:tw-ring-offset-2 tw-focus:tw-ring-gray-300"
            onClick={() =>
              {setFormData({ domain: "", size: "", country: "", industry: "" });
              onCancel();}
            }
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompanySettings;
