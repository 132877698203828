import moment from 'moment';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const search = createAsyncThunk(
  'search/database/creditCard/search',
  async ({ query }, { getState, dispatch, rejectWithValue }) => {
    let resultList = [];

    let clQuery, dlQuery, dlSearchTerm;
    
    if (getState().search.main.selectedOptionId === 2) {
      // Name option
      const names = query.toLowerCase().split(' ');

      clQuery = names.map((seg) => `owner:${seg}`).join(' AND ');
      dlQuery =
        names.length === 1
          ? { first_name: names[0] }
          : { first_name: names[0], last_name: names[1] };
      dlSearchTerm =
        names.length === 1
          ? `first_name=${names[0]}`
          : `first_name=${names[0]},last_name=${names[1]}`;
    } else if (getState().search.main.selectedOptionId === 4) {
      // Credit card option
      clQuery = `*${query}*`;
      dlQuery = { bins: query };
      dlSearchTerm = `bins=['${query}']`;
    }

    let progressValue = 0,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (!isError) {
        if (step === 0 && progressValue < 10) {
          progressValue++;
          dispatch(setProgress(progressValue));

          await delay(50);
          requestAnimationFrame(mockProgress);
        } else if (step === 1 && progressValue < 50) {
          //
        } else if (step === 2 && progressValue < 60) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(500);
          requestAnimationFrame(mockProgress);
        } else if (step === 3 && progressValue < 85) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(500);
          requestAnimationFrame(mockProgress);
        } else if (step === 4 && progressValue < 98) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(750);
          requestAnimationFrame(mockProgress);
        } else if (step === 5 && progressValue < 100) {
          progressValue++;
          dispatch(setProgress(progressValue));
          await delay(50);
          requestAnimationFrame(mockProgress);
        }
      }
    };

    try {
      // control.leak.center
      let page = 0,
        totalPages = 1;
      requestAnimationFrame(mockProgress);

      while (page < totalPages) {
        let clResponse = await api.get('/service/cc_leaks_search/', {
          params: {
            page: page++,
            size: 100,
            query: clQuery,
          },
        });

        totalPages = clResponse.data.totalPages;
        resultList = resultList.concat(
          clResponse.data.content.map((item) => ({
            id: item.id,
            createdAt: item.createdAt
              ? moment(item.createdAt).format('YYYY/MM/DD')
              : '',
            number: item.number,
            expire: item.expireDate
              ? moment(item.expireDate).format('MM/YY')
              : '',
            cvv: item.cvv,
            owner: item.owner,
            bank: item.bank,
            leakName: item.leakName,
            leakDate: item.leakDiscoverDate,
            price: 'Free',
          })),
        );

        step = 1;
        progressValue =
          10 + Math.ceil(((50 - 10) / clResponse.data.totalPages) * page);
        dispatch(setProgress(progressValue));
      }

      // deepweb.leak.center
      progressValue = 50;
      step = 2;
      requestAnimationFrame(mockProgress);

      await api.post('service/cc_search_request/', dlQuery);

      progressValue = 60;
      step = 3;
      requestAnimationFrame(mockProgress);

      let taskId = null,
        currentTask;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const statusResponse = await api.get(`service/cc_search_results/`);

        if (!taskId) {
          currentTask = statusResponse.data.tasks.find(
            (task) => task.search_term === dlSearchTerm,
          );
          taskId = currentTask.id;
        } else {
          currentTask = statusResponse.data.tasks.find(
            (task) => task.id === taskId,
          );
        }

        if (currentTask.status === 'Completed') break;
        await delay(2000);
      }

      progressValue = 85;
      step = 4;
      requestAnimationFrame(mockProgress);

      const resultResponse = await api.post('service/download_results_file/', {
        output_format: 'json',
        served_link: currentTask.served_link,
      });

      // progressValue = 98;
      step = 5;
      // requestAnimationFrame(mockProgress);
      // await api.delete(
      //   `service/delete_shortener_search_request/${requestResponse.data.id}/`,
      // );

      resultList = resultList.concat(
        resultResponse.data.map((item) => ({
          id: item.id,
          createdAt: item.scrapped_date,
          number: item.bin,
          expire: item.expires,
          cvv: item.card_code,
          owner: item.firstname,
          bank: item.bank_fullname,
          leakName: item.source,
          leakDate: item.scrapped_date,
          price: item.offer_final_price_usd
            ? '$' + item.offer_final_price_usd
            : '—',
        })),
      );

      return resultList;
    } catch (error) {
      isError = true;
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

const creditCardSlice = createSlice({
  name: 'creditCard',
  initialState: {
    list: [],
    totalElements: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload;
        state.totalElements = state.list.length;

        state.loading = false;
        state.progress = 100;
      })
      .addCase(search.rejected, (state, action) => {
        state.list = [];
        state.totalElements = 0;
        state.loading = false;
        // state.progress = 0;
        state.error = action.payload;
      })
      .addCase(searchActions.resetProgress, (state) => {
        state.progress = 0;
      });
  },
});

const { setProgress } = creditCardSlice.actions;
export default creditCardSlice.reducer;
