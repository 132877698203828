import React from 'react';
import { useSelector } from 'react-redux';

import InfoYellow from 'common/Icons/InfoYellow';
import ExportButton from 'common/components/Export/ExportButton';

import Table from 'components/common/table/table.component';
import Loading from 'common/components/Loading/Loading';

import { kaduuHighlightTags } from 'utils/global';

const CreditCardLeaks = ({ selectedOptionLabel, searchTerm, onLeakDetail }) => {
  const { list, totalElements, loading } = useSelector(
    (state) => state.search.database.creditCardLeaks,
  );

  const size = 10;
  const columns = [
    {
      Header: 'Leak Name',
      accessor: 'leakName',
      render: (value, record) => {
        return (
          <div className="tw-flex tw-flex-col tw-gap-1">
            <span
              className="tw-whitespace-nowrap tw-text-[#00B6FF] tw-cursor-pointer"
              onClick={() => {
                if (onLeakDetail) onLeakDetail(record.leakId);
              }}
            >
              {value}
            </span>
            <span>{record.createdAt}</span>
          </div>
        );
      },
    },
    {
      Header: 'Leak Content Extract',
      accessor: 'content',
      render: (_, record) => {
        return (
          <>
            <div className="tw-flex tw-gap-1 ">
              {record.leakTags.split(',').map((keyword, index) => {
                return (
                  <span
                    className="tw-whitespace-nowrap tw-bg-[#FEF6EE] tw-border tw-border-[#F9DBAF] tw-rounded-full tw-py-[2px] tw-px-2 tw-text-[#B93815]"
                    key={index}
                  >
                    {keyword}
                  </span>
                );
              })}
            </div>
            <p
              className="tw-max-w-[270px] tw-mt-[10px]"
              dangerouslySetInnerHTML={{
                __html: kaduuHighlightTags(record.content),
              }}
            />
            <p className="tw-mt-[10px] tw-text-[#00B6FF]">{record.fileName}</p>
          </>
        );
      },
    },
    {
      Header: 'Leak Date',
      accessor: 'leakDiscoverDate',
      render: (value) => <span className=" tw-text-[#44464A]">{value}</span>,
    },
  ];

  return (
    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
      {!loading ? (
        <>
          <div className="tw-flex tw-justify-between tw-items-center">
            <p className="tw-font-bold tw-text-lg tw-text-[#263238]">
              Search Result for {selectedOptionLabel} Search
            </p>
            <ExportButton />
          </div>
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
            <div className="tw-flex tw-shrink-0 tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">
                Search results
              </span>
              <span className="tw-text-sm tw-font-medium">{totalElements}</span>
            </div>
            <div className="tw-flex tw-shrink-0 tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">Search term</span>
              <span className="tw-text-sm tw-font-medium">{searchTerm}</span>
            </div>
            {totalElements === 10000 && (
              <div className="tw-flex tw-grow tw-items-center tw-justify-center tw-gap-x-4">
                <div>
                  <InfoYellow />
                </div>
                <div className="tw-text-sm tw-font-normal tw-text-[#344054] tw-text-pretty">
                  Your search returned more than 10&apos;000 entries. We show
                  only the first 10&apos;000 entries.
                  <br /> Please adjust your search to get more accurate results.
                </div>
              </div>
            )}
          </div>
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center"></div>

          <Table
            data={list}
            columns={columns}
            selectable={true}
            paginatorLabel="leaks"
            rowsPerPage={size}
          />
        </>
      ) : (
        <Loading label="The search is still running – Please wait a moment..." />
      )}
    </div>
  );
};

export default CreditCardLeaks;
