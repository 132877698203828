import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputGroup from "../../../../common/components/InputGroup/InputGroup";
import SelectGroup from "../../../../common/components/SelectDropdown/SelectDropdown";
import Heading from "../../../../common/components/heading/Heading";
import Danger from "../../../../common/Icons/Danger";
import PlusCircle from "../../../../common/Icons/PlusCircle";
import { AiOutlineClose } from "react-icons/ai";
const EmailSettings = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [selectedServer, setSelectedServer] = useState("Built-in Server");
  const handleServerChange = (e) => {
    setSelectedServer(e.target.value);
  };

  const onSubmit = (data) => {
    console.log("Form Data: ", data);
  };
  const [isDivVisible, setIsDivVisible] = useState(true); // State to manage visibility of the div

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <div className="tw-grid md:tw-grid-cols-2">
        <div className="tw-flex tw-w-full tw-flex-col tw-gap-[6px] ">
          {/* SelectGroup Component */}
          <SelectGroup
            htmlfor="Alert Email-Server"
            labelText="Alert Email-Server"
            inputName="emailServer"
            register={register}
            errors={errors}
            options={[
              { value: "Built-in Server", label: "Built-in Server" },
              { value: "Azure", label: "Azure" },
              { value: "Aws", label: "Aws" },
            ]}
            onChange={handleServerChange}
            placeholder="Select"
            className="tw-w-full"
            isLoading={false}
          />
          <p className="tw-text-sm">
            External Proxy your own email server{" "}
            <span className="tw-text-[#888C95] tw-text-sm">(Recommended)</span>
          </p>
          {/* Conditionally show the divs based on the selected value */}
        </div>
      </div>
      {isDivVisible && selectedServer === "Built-in Server" ? (
        <div className="tw-flex tw-items-center tw-gap-[9.45px] tw-bg-[#fcf6e5] tw-px-8 tw-py-3 tw-rounded-xl tw-h-full tw-min-h-[64px]">
          <Danger />
          <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
            The system will send Alerts through a built-in Email server with the
            sender domain "darknetsearch.com" and the IP address xxxx.<br></br>{" "}
            Please make sure to whitelist this sender address
          </div>
          {/* <button
            className="tw-absolute tw-right-3 tw-top-3 tw-text-[#344054]"
            onClick={() => setIsDivVisible(false)} 
          >
            <Danger />
          </button> */}
        </div>
      ) : (
        <p className="tw-text-sm">
          External Proxy your own email server{" "}
          <span className="tw-text-[#888C95] tw-text-sm">(Recommended)</span>
        </p>
      )}

      {/* Rest of the form... */}
      <div className="tw-border tw-border-[#DFE0E3] tw-p-4 tw-rounded-lg tw-flex tw-flex-col tw-gap-6">
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-2 md:tw-gap-0 tw-justify-between">
          <Heading size="h3" text="Email Server Setup" />
          <div className="tw-flex tw-gap-1 tw-items-center">
            <PlusCircle color={"#0B0B0B"} fill={"none"} />
            <Heading size="h3" text="Add your email server" />
          </div>
        </div>

        <div className="tw-grid md:tw-grid-cols-2 tw-gap-4">
          <div className="tw-flex tw-flex-col tw-mb-4">
            <InputGroup
              labelText="Server hostname (e.g. mx.domain.com)"
              inputType="input"
              defaultValue="example.domain.com"
              inputName="hostname"
              register={register}
              errors={errors}
            />
          </div>
          <div className="tw-flex tw-flex-col tw-mb-2">
            <SelectGroup
              htmlfor="Encryption method"
              labelText="Encryption method"
              inputName="encryptionMethod"
              register={register}
              errors={errors}
              options={[
                { value: "", label: "None" },
                { value: "Sha256", label: "Sha256" },
                { value: "AES", label: "AES" },
                { value: "SEM", label: "SEM" },
              ]}
              onChange={() => {}}
              placeholder="Select"
              className="tw-w-full"
              isLoading={false}
            />
          </div>
          <div className="tw-w-max tw-flex tw-items-center tw-gap-[5px] tw-mb-2">
            <InputGroup
              inputType="checkBox"
              inputName="isAuth"
              register={register}
              errors={errors}
            />
            <span>Authentication</span>
          </div>
        </div>

        <div className="tw-grid md:tw-grid-cols-2 tw-gap-4">
          <div className="tw-flex tw-flex-col tw-gap-[6px] tw-mb-4">
            <InputGroup
              labelText="Port"
              inputType="number"
              inputName="port"
              register={register}
              errors={errors}
            />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-[6px] tw-mb-4">
            <InputGroup
              labelText="Username"
              inputType="text"
              inputName="username"
              register={register}
              errors={errors}
            />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-[6px] tw-mb-4">
            <InputGroup
              labelText="Password"
              inputType="password"
              inputName="password"
              register={register}
              errors={errors}
            />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-[6px] tw-mb-4">
            <InputGroup
              labelText="Test email (enter test email recipient)"
              inputType="email"
              inputName="email"
              register={register}
              errors={errors}
            />
          </div>
        </div>
      </div>

      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-items-center">
        <button
          className="tw-bg-primary tw-w-full md:tw-w-max tw-text-white tw-py-2 tw-px-4 tw-rounded-lg"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </button>
        <button
          className="tw-text-primary tw-w-full md:tw-w-max tw-bg-white tw-border tw-py-2 tw-px-4 tw-rounded-lg"
          onClick={() => {}}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EmailSettings;
