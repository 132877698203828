import React from "react";

const QuestionYellowIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.07533 7.50033C8.27125 6.94338 8.65795 6.47375 9.16695 6.1746C9.67596 5.87546 10.2744 5.76611 10.8563 5.86592C11.4382 5.96573 11.966 6.26826 12.3462 6.71993C12.7264 7.1716 12.9345 7.74326 12.9337 8.33366C12.9337 10.0003 10.4337 10.8337 10.4337 10.8337M10.5003 14.167H10.5087M18.8337 10.0003C18.8337 14.6027 15.1027 18.3337 10.5003 18.3337C5.89795 18.3337 2.16699 14.6027 2.16699 10.0003C2.16699 5.39795 5.89795 1.66699 10.5003 1.66699C15.1027 1.66699 18.8337 5.39795 18.8337 10.0003Z"
        stroke="#D4B149"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default QuestionYellowIcon;
