import React, { useState } from "react";
import Heading from "../../common/components/heading/Heading";

import CustomTable from "../common/table/table.component";
import InputGroup from "../../common/components/InputGroup/InputGroup";
import CustomButton from "../../common/components/Button/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiCalendarDate } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import AlertsOverviewChart from "../charts/alerts_chart/AlertsOverview";
import AlertsHistoryChart from "../charts/alerts_chart/AlertsHistoryChart";
import SelectGroup from "../../common/components/SelectDropdown/SelectDropdown";
import { useForm } from "react-hook-form";
import DownloadIcon from "../assets/svgs/DownloadIcon.js";
import Modal from "../../common/components/Modal/Modal.js";
// import DownloadIcon from "../assets/svgs/DownloadIcon.js";
import Delete from "../assets/svgs/Delete.js";
import EyeIcon from "../../common/Icons/EyeIcon.js";

export default function ActiveAlerts() {
  const [isExportAlertsModalOpen, setIsExportAlertsModalOpen] = useState(false);

  const openExportAlertsModal = () => {
    setIsExportAlertsModalOpen(true);
  };

  const closeopenExportAlertsModal = () => {
    setIsExportAlertsModalOpen(false);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const columns = [
    {
      Header: "Create Date",
      accessor: "date",
      render: (_, date) => {
        return <span className="tw-whitespace-nowrap">{date.date}</span>;
      },
    },
    {
      Header: "Alert Name",
      accessor: "alertName",
      render: (_, record) => {
        return <span className="tw-text-blue">{record.alertName}</span>;
      },
    },
    {
      Header: "Keywords",
      accessor: "keywords",
    },
    { Header: "Types", accessor: "types" },
    {
      Header: "Status",
      accessor: "status",
      render: (_, item) => {
        return (
          <div className="tw-flex tw-gap-2 tw-items-center">
            <span
              className={`tw-font-medium tw-text-xs tw-whitespace-nowrap ${
                item.status === "In Progress"
                  ? "tw-text-red-800"
                  : "tw-text-[#067647]"
              }`}
            >
              {item.status}
            </span>

            <IoIosArrowDown
              className={`tw-font-medium tw-text-xs tw-whitespace-nowrap ${
                item.status === "In Progress"
                  ? "tw-text-red-800"
                  : "tw-text-[#067647]"
              }`}
            />
          </div>
        );
      },
    },
    { Header: "Alert Extract", accessor: "alertExtract" },
    {
      Header: "Actions",
      accessor: "action",
      render: (_, item) => {
        return (
          <div className="tw-flex tw-gap-2">
            <button>
              <EyeIcon color="#71747C" />
            </button>
            <button>
              <Delete color="#71747C" />
            </button>
          </div>
        );
      },
    },
  ];

  const data = [
    {
      date: "2024-05-21",
      alertName: "Unusual login attempt",
      keywords: "spoofguard, domain.com",
      types: "leak",
      status: "In Progress",
      alertExtract:
        "peter@domain.com:passwd23; https://login.ebanding.com/changepwd:User...",
    },
    {
      date: "2024-06-12",
      alertName: "Password reset",
      keywords: "phishing, domain.com",
      types: "attack",
      status: "Solved",
      alertExtract:
        "john@domain.com:resetme123; https://reset.ebanding.com/resetpwd:Admin...",
    },

    {
      date: "2024-07-15",
      alertName: "Phishing attempt",
      keywords: "phishing, finance",
      types: "attack",
      status: "In Progress",
      alertExtract:
        "bob@domain.com:letmein456; https://phish.ebanding.com/verify:Finance...",
    },
    {
      date: "2024-07-15",
      alertName: "Phishing attempt",
      keywords: "phishing, finance",
      types: "attack",
      status: "In Progress",
      alertExtract:
        "bob@domain.com:letmein456; https://phish.ebanding.com/verify:Finance...",
    },
    {
      date: "2024-07-15",
      alertName: "Phishing attempt",
      keywords: "phishing, finance",
      types: "attack",
      status: "In Progress",
      alertExtract:
        "bob@domain.com:letmein456; https://phish.ebanding.com/verify:Finance...",
    },
    {
      date: "2024-07-20",
      alertName: "Unauthorized access",
      keywords: "access, internal",
      types: "security",
      status: "Solved",
      alertExtract:
        "eve@domain.com:password1; https://access.ebanding.com/login:Security...",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  return (
    <>
      <div className="tw-mt-0 md:tw-px-6 ">
        <Heading size="h2" text="Active Alerts " />
      </div>
      <div className="tw-py-2 md:tw-px-2 tw-mt-0">
        <div className="tw-flex tw-justify-center md:tw-justify-between tw-mb-2 tw-flex-col md:tw-flex-row tw-gap-4 md:tw-gap-0">
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-w-[90vw] md:tw-w-[49%]">
            <AlertsOverviewChart />
          </div>
          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-w-[90vw] md:tw-w-[49%]">
            <AlertsHistoryChart />
          </div>
        </div>

        <div className="tw-bg-white tw-rounded-lg tw-border tw-border-[#EFF0F2] tw-py-4 tw-px-6 tw-mt-4">
          <Heading
            size="h3"
            fontSize="18px"
            color="#263238"
            text="Filter Alerts"
          />
          <div className="tw-flex tw-mb-4 tw-gap-3 tw-w-[100%]">
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-3 tw-w-full">
              <div className="tw-w-4/4 md:tw-w-2/4 tw-border tw-border-[#DFE0E3] tw-text-base tw-rounded-lg tw-py-1 tw-pr-4 tw-relative custom__picker">
                <DatePicker
                  showIcon
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="From Date"
                  icon={
                    <CiCalendarDate
                      style={{ fontSize: "19px", color: "#71747C" }}
                    />
                  }
                />
              </div>
              <div className="tw-w-4/4 md:tw-w-2/4 tw-border tw-border-[#DFE0E3] tw-text-base tw-rounded-lg tw-py-1 tw-pr-4 tw-relative custom__picker">
                <DatePicker
                  showIcon
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="To Date"
                  icon={
                    <CiCalendarDate
                      style={{ fontSize: "19px", color: "#71747C" }}
                    />
                  }
                />
              </div>
              <div className="tw-w-4/4 md:tw-w-2/4">
                <SelectGroup
                  htmlfor="Country"
                  // labelText="Country"
                  inputName="country"
                  register={register}
                  errors={errors}
                  options={[
                    { value: "", label: "Select Type" },
                    { value: "COFFEE_SHOP", label: "Leaked" },
                    { value: "RESTAURANT", label: "Paid" },
                    { value: "RETAIL_SHOP", label: "Unpaid" },
                  ]}
                  onChange={() => {}}
                  placeholder="Select"
                  className="tw-w-full"
                  isLoading={false}
                />
              </div>
              <div className="tw-w-4/4 md:tw-w-2/4">
                <SelectGroup
                  htmlfor="Country"
                  // labelText="Country"
                  inputName="country"
                  register={register}
                  errors={errors}
                  options={[
                    { value: "", label: "Select Status" },
                    { value: "COFFEE_SHOP", label: "In Progess" },
                    { value: "RESTAURANT", label: "Complete" },
                  ]}
                  onChange={() => {}}
                  placeholder="Select"
                  className="tw-w-full"
                  isLoading={false}
                />
              </div>
              <button className="tw-bg-primary tw-text-white tw-p-2 tw-rounded-lg tw-w-4/4 md:tw-min-w-[64px]">
                Apply
              </button>
              <div
                className="tw-flex tw-items-center tw-justify-center tw-gap-[4px] tw-border tw-border-primary tw-bg-white tw-text-primary  tw-p-2 tw-rounded-lg tw-w-4/4 md:tw-min-w-[135px] hover:tw-cursor-pointer"
                onClick={openExportAlertsModal}
              >
                <DownloadIcon />
                <button>Export Alerts</button>
              </div>
            </div>
          </div>
          <div className="tw-mt-0 md:tw-px-1 ">
            <Heading size="h3" text="Active Alerts " />
          </div>
          <div className="">
            <CustomTable data={data} columns={columns} selectable={true} />
          </div>
        </div>
        {isExportAlertsModalOpen && (
          <Modal
            isOpen={isExportAlertsModalOpen}
            title={<DownloadIcon />}
            onClose={closeopenExportAlertsModal}
          >
            <div className="tw-px-3 tw-flex tw-flex-col tw-gap-4">
              <div>
                <Heading size="h3" text="Export Alerts" />
                <p className="tw-text-gray-700">
                  You can locate the export file in the Reports/Exports section
                </p>
              </div>

              <button
                className="tw-bg-primary tw-text-white tw-p-2 tw-rounded-lg tw-w-3/4 md:tw-max-w-[64px]"
                onClick={closeopenExportAlertsModal}
              >
                Close
              </button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
