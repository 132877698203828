import React from "react";

const Phone = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2755_8553)">
          <path
            d="M11.7084 4.99935C12.5223 5.15815 13.2704 5.55623 13.8568 6.14263C14.4432 6.72903 14.8412 7.47707 15 8.29102M11.7084 1.66602C13.3994 1.85388 14.9764 2.61116 16.1802 3.81352C17.3841 5.01588 18.1434 6.59186 18.3334 8.28268M8.52253 11.5519C7.52121 10.5506 6.73055 9.41839 6.15056 8.21037C6.10067 8.10647 6.07572 8.05451 6.05656 7.98877C5.98846 7.75514 6.03737 7.46826 6.17905 7.2704C6.21892 7.21472 6.26655 7.16709 6.36181 7.07183C6.65315 6.78049 6.79881 6.63483 6.89405 6.48834C7.25322 5.93593 7.25321 5.22378 6.89405 4.67138C6.79881 4.52489 6.65315 4.37923 6.36181 4.08789L6.19942 3.92549C5.75655 3.48263 5.53511 3.26119 5.2973 3.1409C4.82433 2.90168 4.26577 2.90168 3.79281 3.1409C3.55499 3.26119 3.33355 3.48263 2.89069 3.92549L2.75932 4.05686C2.31797 4.49821 2.09729 4.71889 1.92875 5.01891C1.74174 5.35183 1.60727 5.86891 1.60841 6.25076C1.60943 6.59488 1.67618 6.83007 1.80969 7.30044C2.52716 9.82827 3.88089 12.2136 5.87088 14.2036C7.86086 16.1935 10.2462 17.5473 12.774 18.2647C13.2444 18.3983 13.4795 18.465 13.8237 18.466C14.2055 18.4672 14.7226 18.3327 15.0555 18.1457C15.3555 17.9771 15.5762 17.7565 16.0176 17.3151L16.1489 17.1837C16.5918 16.7409 16.8132 16.5194 16.9335 16.2816C17.1728 15.8087 17.1728 15.2501 16.9335 14.7771C16.8132 14.5393 16.5918 14.3179 16.1489 13.875L15.9865 13.7126C15.6952 13.4213 15.5495 13.2756 15.4031 13.1804C14.8506 12.8212 14.1385 12.8212 13.5861 13.1804C13.4396 13.2756 13.2939 13.4213 13.0026 13.7126C12.9073 13.8079 12.8597 13.8555 12.804 13.8954C12.6062 14.0371 12.3193 14.086 12.0857 14.0179C12.0199 13.9987 11.968 13.9738 11.8641 13.9239C10.656 13.3439 9.52384 12.5532 8.52253 11.5519Z"
            stroke="#2B2F38"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2755_8553">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Phone;
