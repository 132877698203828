import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExportButton from 'common/components/Export/ExportButton';
import Table from 'components/common/table-no-paginator/table.component';
import Paginator from 'components/common/table-no-paginator/paginator.component';
import ReadMore from 'common/components/ReadMore/ReadMore';
import Loading from 'common/components/Loading/Loading';

import useExport from 'hooks/use-export';

import { search as searchRansomware } from 'store/slices/search/database/ransomware';

const ThreatActor = ({ selectedOptionLabel, searchTerm }) => {
  const dispatch = useDispatch();

  const {
    query,
    list: ransomwares,
    size,
    currentPage,
    totalElements,
    totalPages,
    loading,
  } = useSelector((state) => state.search.database.ransomware);

  const [isPageSearch, setIsPageSearch] = useState(false);

  const setCurrentPage = (page) => {
    setIsPageSearch(true);

    dispatch(
      searchRansomware({
        page: page - 1,
        progress: false,
      }),
    );
  };

  const defaultRenderer = (value) => (
    <span className="tw-whitespace-nowrap tw-text-[#44464A]">{value}</span>
  );

  const columns = [
    {
      Header: 'Created',
      accessor: 'created_at',
      render: (value) => (
        <span className="tw-whitespace-nowrap tw-text-[#44464A]">
          {value ? moment(value).format('YYYY-MM-DD') : '—'}
        </span>
      ),
    },
    {
      Header: 'Company',
      accessor: 'company',
      render: (value) => <span className="tw-text-[#00B6FF]">{value}</span>,
    },
    {
      Header: 'Malware Type',
      accessor: 'groupname',
      render: defaultRenderer,
    },
    {
      Header: 'Region',
      accessor: 'region',
      render: defaultRenderer,
    },
    {
      Header: 'Country',
      accessor: 'country',
      render: defaultRenderer,
    },
    {
      Header: 'Industry',
      accessor: 'industry_type',
      render: defaultRenderer,
    },
    {
      Header: 'Details',
      accessor: 'description',
      render: (value) => {
        return <ReadMore text={value} size={200} />;
      },
    },
  ];

  const { canExport, handleRowSelection, exporting, handleExportOptionChange } =
    useExport({ source: 'ransomware', size, searchTerm });

  return (
    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-col tw-gap-4">
      {!(loading && !isPageSearch) ? (
        <>
          <div className="tw-flex tw-justify-between tw-items-center">
            <p className="tw-font-bold tw-text-lg tw-text-[#263238]">
              Search Result for {selectedOptionLabel} Search
            </p>
          </div>

          <div className="tw-border tw-border-[#EFF0F2] tw-rounded-lg tw-py-2 tw-px-4 tw-flex tw-gap-6">
            <div className="tw-flex tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">
                Search results
              </span>
              <span className="tw-text-sm tw-font-medium">{totalElements}</span>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1">
              <span className="tw-text-sm tw-text-[#344054]">Search term</span>
              <span className="tw-text-sm tw-font-medium">{query}</span>
            </div>
          </div>

          <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
            <div className={loading ? 'tw-invisible' : 'tw-visible'}>
              <p>
                Showing ransomwares:{' '}
                {totalElements === 0 ? 0 : (currentPage - 1) * size + 1} —{' '}
                {currentPage * size > totalElements
                  ? totalElements
                  : currentPage * size}{' '}
                of {totalElements}
              </p>
            </div>
            <div className="tw-flex tw-flex-col tw-items-center md:tw-flex-row tw-gap-1 md:tw-gap-4">
              <ExportButton
                onChange={handleExportOptionChange}
                isLoading={exporting}
                disabled={!canExport}
              />
            </div>
          </div>
          <div className="tw-relative">
            <Table
              data={ransomwares}
              columns={columns}
              selectable={true}
              onRowSelection={handleRowSelection}
            />
            {totalPages > 1 && (
              <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
            {loading && (
              <div className="tw-absolute tw-bg-[#F0F0F0C0] tw-inset-0 tw-w-full tw-h-full"></div>
            )}
          </div>
        </>
      ) : (
        <Loading label="The search is still running – Please wait a moment..." />
      )}
    </div>
  );
};

export default ThreatActor;
