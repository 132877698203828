import _ from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';

export const SEARCH_TYPE = {
  ALL: 'all',
  SIMPLE: 'simple',
  EXTENDED: 'extended',
};

export const search = createAsyncThunk(
  'search/manual/dataLeaks/search',
  async (
    { type = SEARCH_TYPE.ALL, page, size, sort, length, query },
    { getState, dispatch, rejectWithValue },
  ) => {
    const state = getState().search.manual.dataLeaks;
    const result = {};

    try {
      if (type == SEARCH_TYPE.ALL || type == SEARCH_TYPE.SIMPLE) {
        if (_.isNil(page)) page = state.simple.currentPage - 1;
        if (_.isNil(size)) size = state.simple.size;
        if (_.isNil(sort)) sort = state.simple.sort;

        dispatch(setParams({ type: SEARCH_TYPE.SIMPLE, size, sort }));
        const responseSimple = await api.get(
          'service/leak_simple_database_search/',
          {
            params: {
              page,
              size,
              sort,
              query,
            },
          },
        );

        result.simple = responseSimple.data;
      }

      if (type == SEARCH_TYPE.ALL || type == SEARCH_TYPE.EXTENDED) {
        if (_.isNil(page)) page = state.extended.currentPage - 1;
        if (_.isNil(size)) size = state.extended.size;
        if (_.isNil(sort)) sort = state.extended.sort;
        if (_.isNil(length)) length = state.extended.length;

        dispatch(setParams({ type: SEARCH_TYPE.EXTENDED, size, sort, length }));
        const responseExtended = await api.get(
          'service/leak_extended_database_search/',
          {
            params: {
              page,
              size,
              sort,
              length,
              highlight: true,
              query,
            },
          },
        );

        result.extended = responseExtended.data;
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

const dataLeaksSlice = createSlice({
  name: 'dataLeaks',
  initialState: {
    simple: {
      list: [],
      sort: 'resultCount,desc',
      size: 10,
      currentPage: 1,
      totalElements: 0,
      totalPages: 0,
      loading: false,
      error: null,
    },
    extended: {
      list: [],
      sort: 'createdAt,desc',
      size: 10,
      length: 500,
      currentPage: 1,
      totalElements: 0,
      totalPages: 0,
      loading: false,
      error: null,
    },
  },
  reducers: {
    setParams: (state, action) => {
      if (action.payload.type == SEARCH_TYPE.SIMPLE) {
        state.simple.size = action.payload.size;
        state.simple.sort = action.payload.sort;
      } else {
        state.extended.size = action.payload.size;
        state.extended.sort = action.payload.sort;
        state.extended.length = action.payload.length;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state, action) => {
        const type = action.meta.arg.type;
        if (type == SEARCH_TYPE.ALL || type == SEARCH_TYPE.SIMPLE) {
          state.simple.loading = true;
          state.simple.error = null;
        }
        if (type == SEARCH_TYPE.ALL || type == SEARCH_TYPE.EXTENDED) {
          state.extended.loading = true;
          state.extended.error = null;
        }
      })
      .addCase(search.fulfilled, (state, action) => {
        if (action.payload.simple) {
          const { hasContent, totalElements, totalPages } =
            action.payload.simple;

          state.simple.list = hasContent ? action.payload.simple.content : [];
          state.simple.totalElements = totalElements;
          state.simple.totalPages = totalPages;
          state.simple.currentPage = action.payload.simple.number + 1;

          state.simple.loading = false;
        }

        if (action.payload.extended) {
          const { hasContent, totalElements, totalPages } =
            action.payload.extended;

          state.extended.list = hasContent
            ? action.payload.extended.content
            : [];
          state.extended.totalElements = totalElements;
          state.extended.totalPages = totalPages;
          state.extended.currentPage = action.payload.extended.number + 1;

          state.extended.loading = false;
        }
      })
      .addCase(search.rejected, (state, action) => {
        const type = action.meta.arg.type;
        if (type == SEARCH_TYPE.ALL || type == SEARCH_TYPE.SIMPLE) {
          state.simple.totalElements = 0;
          state.simple.totalPages = 0;
          state.simple.list = [];
          state.simple.loading = false;
          state.simple.error = action.payload;
        }
        if (type == SEARCH_TYPE.ALL || type == SEARCH_TYPE.EXTENDED) {
          state.extended.totalElements = 0;
          state.extended.totalPages = 0;
          state.extended.list = [];
          state.extended.loading = false;
          state.extended.error = action.payload;
        }
      });
  },
});

const { setParams } = dataLeaksSlice.actions;

export default dataLeaksSlice.reducer;
