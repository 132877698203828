import * as React from "react";

function AuthEyeIcon(props) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.11342 8.47549C2.02262 8.33173 1.97723 8.25985 1.95182 8.14898C1.93273 8.06571 1.93273 7.93437 1.95182 7.8511C1.97723 7.74023 2.02262 7.66835 2.11341 7.52459C2.86369 6.3366 5.09693 3.33337 8.50027 3.33337C11.9036 3.33337 14.1369 6.3366 14.8871 7.52459C14.9779 7.66835 15.0233 7.74023 15.0487 7.8511C15.0678 7.93437 15.0678 8.06571 15.0487 8.14898C15.0233 8.25985 14.9779 8.33173 14.8871 8.47549C14.1369 9.66348 11.9036 12.6667 8.50027 12.6667C5.09693 12.6667 2.86369 9.66348 2.11342 8.47549Z"
        stroke="#71747C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50027 10C9.60484 10 10.5003 9.10461 10.5003 8.00004C10.5003 6.89547 9.60484 6.00004 8.50027 6.00004C7.3957 6.00004 6.50027 6.89547 6.50027 8.00004C6.50027 9.10461 7.3957 10 8.50027 10Z"
        stroke="#71747C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AuthEyeIcon;
