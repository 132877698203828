import React from "react";

const CrossIconLight = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1668 1.83301L1.8335 10.1663M1.8335 1.83301L10.1668 10.1663"
        stroke="#888C95"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossIconLight;
