import React from "react";

const Successful = ({ color }) => {
  return (
    <div>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="44" height="44" rx="22" fill="#E9F9EF" />
        <g clipPath="url(#clip0_6127_49154)">
          <path
            d="M18.2498 21.9993L20.7498 24.4993L25.7498 19.4993M30.3332 21.9993C30.3332 26.6017 26.6022 30.3327 21.9998 30.3327C17.3975 30.3327 13.6665 26.6017 13.6665 21.9993C13.6665 17.397 17.3975 13.666 21.9998 13.666C26.6022 13.666 30.3332 17.397 30.3332 21.9993Z"
            stroke="#1FB155"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_6127_49154">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(12 12)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Successful;
