import axios from 'axios';

import env from '../config';
import store from './index';
import { refreshAccessToken } from './slices/auth';
import { logout } from './actions';

const api = axios.create({
  baseURL: env.API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const accessToken = state.auth.accessToken;

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.code === 'ERR_NETWORK') {
      //
    } else {
      const originalRequest = error.config;

      if (error.response.status === 401 /* && !originalRequest._retry*/) {
        originalRequest._retry = true;
        try {
          const state = store.getState();
          const refreshToken = state.auth.refreshToken;
          if (refreshToken) {
            const newAccessToken = await store
              .dispatch(refreshAccessToken(refreshToken))
              .unwrap();
            axios.defaults.headers.common['Authorization'] =
              `Bearer ${newAccessToken}`;
            return api(originalRequest);
          } else {
            store.dispatch(logout()).unwrap();

            window.location.href = '/login';
          }
        } catch (refreshError) {
          store.dispatch(logout()).unwrap();

          window.location.href = '/login';
        }
      }

      // Handle other common errors (403, 404, 500)
      if (error.response.status === 403) {
        console.error('Forbidden: You do not have the right permissions.');
      } else if (error.response.status === 404) {
        console.error('Not Found: The requested resource could not be found.');
      } else if (error.response.status === 500) {
        console.error('Server Error: Something went wrong on the server.');
      } else if (error.response.status === 502) {
        console.error('Bad Gateway: Something went wrong on the server.');
      }
    }

    return Promise.reject(error);
  },
);

export default api;
