import React from "react";
import ReactApexChart from "react-apexcharts";

function RiskScoreChart() {
  const chartOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    },
    stroke: {
      curve: 'smooth',
      width: 4 
    },
    fill: {
      type: 'solid', 
      colors: ['#ECC551'] 
    },
    colors: ['#ECC551'], 
    grid: {
      show: false, 
    },
    tooltip: {
      enabled: true
    }
  };

  const chartSeries = [{
    name: "Risk Score",
    data: [40, 60,   75, 60, 80, 95,  85]
  }];

  return (
    <div className="tw-p-2 tw-bg-white tw-rounded-lg tw-h-min-screen">
      <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={320} />
    </div>
  );
}

export default RiskScoreChart;
