import _ from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';
import { delay } from 'utils/global';
import { searchActions } from 'store/actions';

export const search = createAsyncThunk(
  'search/database/sslLogs/search',
  async (
    { page, size, sort, length, query, progress = true },
    { getState, dispatch, rejectWithValue },
  ) => {
    const state = getState().search.database.sslLogs;

    if (_.isNil(page)) page = state.currentPage - 1;
    if (_.isNil(size)) size = state.size;
    if (_.isNil(sort)) sort = state.sort;
    if (_.isNil(length)) length = state.length;
    if (_.isNil(query)) query = state.query;

    let progressValue = 0,
      step = 0,
      isError = false;
    const mockProgress = async () => {
      if (!isError) {
        if (step === 0 && progressValue < 75) {
          progressValue++;
          dispatch(setProgress(progressValue));

          await delay(50);
          requestAnimationFrame(mockProgress);
        }
      }
    };

    let originalQuery = query;
    try {
      if (progress) {
        requestAnimationFrame(mockProgress);

        if (getState().search.main.selectedOptionId === 0) {
          // Domain option
          if (query.split('.')[0].length > 6) {
            query = query.split('.')[0];
          }
        } else if (getState().search.main.selectedOptionId === 2) {
          // Name option
          const names = query.split(' ');
          if (names.length > 1) {
            query = names[0];
          }
        } else if (getState().search.main.selectedOptionId === 5) {
          // Brand option
          const brand = query.toLowerCase().split(' ');
          if (brand.length > 1) {
            query = `${brand[0]}${brand[1]} AND ${brand[0]}-${brand[1]}`;
          }
        }
      }

      dispatch(setParams({ query, size, sort, length }));
      let response = await api.get('service/certificate_database_search/', {
        params: {
          page,
          size,
          sort,
          length,
          highlight: true,
          query,
        },
      });
      step = 1;

      if (getState().search.main.selectedOptionId === 0) {
        // Domain option

        if (
          progress &&
          query != originalQuery &&
          response.data.totalElements >= 2000
        ) {
          step = 0;
          progressValue = 0;
          requestAnimationFrame(mockProgress);

          dispatch(setQuery(originalQuery));
          response = await api.get('service/certificate_database_search/', {
            params: {
              page,
              size,
              sort,
              length,
              highlight: true,
              query: originalQuery,
            },
          });

          step = 1;
        }
      }

      return response.data;
    } catch (error) {
      isError = true;
      return rejectWithValue(error.response.data || 'Search data failed');
    }
  },
);

const sslLogsSlice = createSlice({
  name: 'sslLogs',
  initialState: {
    query: '',
    list: [],
    sort: 'createdAt,desc',
    size: 10,
    length: 500,
    currentPage: 1,
    totalElements: 0,
    totalPages: 0,
    loading: false,
    progress: 0,
    error: null,
  },
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setParams: (state, action) => {
      state.query = action.payload.query;
      state.size = action.payload.size;
      state.sort = action.payload.sort;
      state.length = action.payload.length;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(search.fulfilled, (state, action) => {
        const { hasContent, totalElements, totalPages } = action.payload;

        state.list = hasContent ? action.payload.content : [];
        state.totalElements = totalElements;
        state.totalPages = totalPages;
        state.currentPage = action.payload.number + 1;

        state.loading = false;
        state.progress = 100;
      })
      .addCase(search.rejected, (state, action) => {
        state.totalElements = state.totalPages = 0;
        state.list = [];
        state.loading = false;
        // state.progress = 0;
        state.error = action.payload;
      })
      .addCase(searchActions.resetProgress, (state) => {
        state.progress = 0;
      });
  },
});

const { setQuery, setProgress, setParams } = sslLogsSlice.actions;

export default sslLogsSlice.reducer;
