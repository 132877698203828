import React, { useEffect, useRef, useState } from 'react';
import DropdownArrow from '../../Icons/dropdownArrow';
import { TickIcon } from '../../Icons';

const CustomDropdown = ({
  label,
  labelOfList,
  options,
  placeholder = '',
  name = '',
  setValue,
  value,
  onChange,
  disabled = false,
  className = 'tw-w-full',
  dropdownArrow = <DropdownArrow />,
  defaultOption,
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [dropdownPosition, setDropdownPosition] = useState('bottom');

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (setValue) {
      setValue(name || '', selectedOption?.id || null);
    }
  }, [selectedOption, setValue, name]);

  useEffect(() => {
    const dropdownElement = dropdownRef.current;
    if (!dropdownElement) return;

    const { bottom } = dropdownElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // Check if there is enough space below the dropdown, otherwise set position to "top"
    if (bottom + 200 > windowHeight) {
      setDropdownPosition('top');
    } else {
      setDropdownPosition('bottom');
    }
  }, [isOpen]);

  const handleToggleDropdown = () => {
    !disabled && setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange && onChange(option);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (defaultOption) {
      const defaultOptionObject = options.find(
        (option) => defaultOption.id && defaultOption.id === option.id,
      );
      setSelectedOption(defaultOptionObject);
    } else {
      setSelectedOption({});
    }
  }, [defaultOption, options]);

  useEffect(() => {
    if (defaultOption) {
      setSelectedOption(defaultOption);
    }
  }, [defaultOption]);

  return (
    <div
      className={`tw-text-left tw-w-full  ${
        dropdownPosition === 'top' ? 'tw-bottom-0' : ''
      }`}
      ref={dropdownRef}
    >
      <div className="tw-w-full tw-flex tw-items-start tw-gap-2 tw-flex-col">
        {label && (
          <div className="tw-whitespace-nowrap tw-text-sm tw-text-black tw-font-normal tw-leading-[17.5px]">
            {label}
          </div>
        )}
        <button
          onClick={handleToggleDropdown}
          type="button"
          className={`tw-relative tw-flex tw-justify-between tw-px-4 tw-items-center tw-w-full tw-transition tw-ease-in-out tw-duration-150 ${
            disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'
          } ${className}`}
        >
          {value || selectedOption?.label || placeholder || options[0].label}
          &nbsp;{dropdownArrow}
          {isOpen && (
            <div
              className={`tw-origin-top-right tw-absolute tw-z-10 tw-py-2 ${
                dropdownPosition === 'top' ? 'tw-bottom-12' : 'tw-top-[100%]'
              } tw-left-0 tw-mt-2 tw-w-full tw-min-w-60 tw-max-w-auto tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-y-auto tw-max-h-60 dropdown-shadow tw-rounded-lg`}
            >
              {labelOfList && (
                <div className="tw-px-4 tw-py-2 tw-text-left tw-whitespace-nowrap tw-text-sm tw-text-black tw-font-bold">
                  {labelOfList}
                </div>
              )}

              {options.map((option, index) => (
                <div
                  key={index}
                  onClick={() => handleOptionClick(option)}
                  className={`tw-px-4 tw-py-2 tw-text-left tw-text-sm tw-text-black tw-cursor-pointer hover:tw-bg-[#FCF6E5] tw-flex tw-justify-between tw-items-center`}
                >
                  {option.label}
                  {selectedOption?.id === option.id && <TickIcon />}
                </div>
              ))}
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default CustomDropdown;
