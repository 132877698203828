import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Heading from '../../../common/components/heading/Heading';
import CustomTable from '../../common/table-no-paginator/table.component';
import Loader from "../../../common/components/Loader/Loader";
import api from 'store/api';

function CyberNewsTable() {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const columns = [
    { Header: 'Date', accessor: 'discovered' },
    { Header: 'Title', 
      accessor: 'title', 
      render: (_, record) => {
        return <span className="tw-text-[#00B6FF]">{record.title}</span>;
      },},
    { Header: 'Description', accessor: 'description' },
  ];

  const fetchRecords = async () => {
    try {
      setLoading(true);
      const response = await  await axios.get(`https://blog.darknetsearch.com/wp-json/wp/v2/posts`, 
        {
            params: { page: 1, per_page: 10 },
        });
				const transformedData = response.data.map((item, index) => ({
					discovered: item.date.split('T')[0],
					title: item.title.rendered,
					description: stripHtmlTags(item.excerpt.rendered)
				}));
				setNewsData(transformedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

	function stripHtmlTags(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
	}
	useEffect(() => {
		fetchRecords();
	}, []);

  return (
    <div className='tw-h-full tw-flex tw-flex-col'>
      <Heading size="h4" text="Cyber Security News" />
      {loading ? <div className="tw-w-full tw-flex tw-justify-center tw-pt-40"><Loader /></div> : <div className="tw-flex-1 tw-bg-white tw-p-4 tw-rounded-lg tw-shadow tw-whitespace-nowrap tw-text-ellipsis tw-overflow-hidden">
        <CustomTable data={newsData} columns={columns} selectable={false} />
      </div>}
    </div>
  );
}

export default CyberNewsTable;

