import * as React from "react";

function ColorArrow(props) {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66699 7H17.667M17.667 7L11.667 1M17.667 7L11.667 13"
        stroke="#D4B149"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ColorArrow;
