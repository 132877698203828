import React, { useState } from 'react';

import BellYellow from 'common/Icons/BellYellow';
import Feedback from 'common/Icons/Feedback';
import InfoYellow from 'common/Icons/InfoYellow';
import CustomButton from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import CustomTextArea from 'common/components/TextArea/TextArea';
import ReadMore from 'common/components/ReadMore/ReadMore';

import Leaks from './components/Leaks';
import LeakDetails from '../DatabaseSearch/components/leak-detail/LeakDetails';
import CreditCards from './components/CreditCards';

const SearchResult = ({ setSearch, selectedOptionLabel, searchTerm }) => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openSearchTips, setOpenSearchTips] = useState(false);

  const [isLeakDetails, setIsLeakDetails] = useState(false);
  const [selectedLeakId, setLeakId] = useState(null);

  const handleLeakDetail = (leakId) => {
    setLeakId(leakId);
    setIsLeakDetails(true);
  };
  return (
    <div>
      {/* FEEDBACK */}
      {openFeedback && (
        <Modal
          isOpen={openFeedback}
          title="Feedback Form"
          onClose={() => setOpenFeedback(false)}
          className="tw-w-full tw-max-w-[416px]"
        >
          <div className="tw-flex tw-flex-col tw-gap-2 tw-border-b tw-border-solid tw-border-[#EFF0F2] tw-p-4">
            <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#35363A]">
              Are we missing a leak? Do you know a site we should include a
              crawl? Noticed a bug?
            </div>
            <CustomTextArea
              className="tw-w-full"
              textAreaClassName="tw-border tw-border-solid tw-border-[#DFE0E3] tw-rounded-lg"
            />
            <div className="tw-flex tw-items-center">
              <input type="checkbox" className="tw-mr-2 custom-checkbox" />
              <span className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#35363A]">
                Send Anonymous
              </span>
            </div>
          </div>
          <div className="tw-flex tw-gap-2 tw-mt-2 tw-px-4">
            <CustomButton className="tw-text-sm tw-font-semibold tw-leading-6 tw-w-full tw-max-w-[73px] tw-h-[42px] tw-mt-2 tw-bg-black tw-text-white tw-rounded">
              Submit
            </CustomButton>
            <CustomButton
              onClick={() => setOpenFeedback(false)}
              className="tw-text-sm tw-font-semibold tw-leading-6 tw-w-full tw-max-w-[73px] tw-h-[42px] tw-mt-2 tw-border tw-border-solid tw-border-[#DFE0E3] tw-text-black tw-rounded"
            >
              Cancel
            </CustomButton>
          </div>
        </Modal>
      )}
      {/* MODALS IF OPEN */}
      <Modal
        title="Search Tips"
        isOpen={openSearchTips}
        onClose={() => setOpenSearchTips(false)}
        className="tw-w-full tw-max-w-[700px]"
      >
        <div className="tw-p-4">
          <ReadMore
            size={446}
            text='For the search in hacker forums it should be noted that we transmit all search terms live to the search functions of the hacker forums and the administrators thus have access to such search logs. It may well be that hackers share information about specific leaks and organizations, which is why entering a domain or company name may well show results. In most cases, however, hackers do not share data with such a precise specification. They are often referred to as combo lists, leaks, accounts, etc. from a particular geographic region, industry type or service. The details of the data are usually not disclosed. This must be taken into account in the search and be creative in the query. The more generic your search term is, but still has associations with the target, the more likely you are to find what you are looking for. For example, if an Austrian bank wants to know whether logins have been lost, you could search for "bank", "login" and the country "Austria".'
          />
        </div>
      </Modal>

      {/* NEW SEARCH FOR ALL */}
      <div className="tw-flex tw-justify-between lg:tw-items-center tw-items-start lg:tw-flex-row tw-flex-col tw-bg-[#ffffff] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px]">
        <div className="tw-flex tw-items-center tw-gap-[9.45px]">
          <BellYellow />
          <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
            If you didn't find what you were looking for, try refining your
            search or click 'New search' to start again.
          </div>
        </div>

        <CustomButton
          width="tw-w-fit"
          text="New Search"
          onClick={() => setSearch(false)}
          className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-rounded-md tw-bg-[#0B0B0B] tw-text-[#ffffff] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
        />
      </div>

      {/* Feedback only for hacker forum */}
      {selectedOptionLabel === 'Hacker Forum' && (
        <div className="tw-flex tw-justify-between lg:tw-items-center tw-items-start lg:tw-flex-row tw-flex-col tw-bg-[#ffffff] tw-px-8 tw-py-3 tw-rounded-xl twh-full tw-min-h-[64px] tw-mt-6">
          <div className="tw-w-full tw-max-w-[676px]">
            <div className="tw-flex tw-items-center tw-gap-[9.45px]">
              <Feedback />
              <div className="tw-text-base tw-font-bold tw-leading-[28.24px] tw-tracking-[0.02em] tw-text-[#0B0B0B]">
                Tips & Feedback
              </div>
            </div>
            <div className="tw-text-sm tw-font-normal tw-leading-6 tw-text-[#344054]">
              Explore search tips and share your feedback to help us improve
              your experience. Click below to access the feedback form and
              discover valuable search tips.
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-2">
            <CustomButton
              text="Submit feedback"
              onClick={() => setOpenFeedback(true)}
              className="tw-mt-4 lg:tw-mt-0 tw-h-[40px] tw-py-2 tw-px-3 tw-whitespace-nowrap tw-rounded-md tw-border tw-border-solid tw-border-[#0B0B0B] tw-text-[#0B0B0B] tw-text-sm tw-font-semibold tw-leading-6 tw-text-center"
            />
            <div
              className="text-[#0B0B0B] tw-text-sm tw-font-semibold tw-leading-6 tw-cursor-pointer"
              onClick={() => setOpenSearchTips(true)}
            >
              Explore Search Tips
            </div>
          </div>
        </div>
      )}
      {/* INFO for cards and pastes only */}
      {selectedOptionLabel === 'Credit Cards' ||
      selectedOptionLabel === 'Data Pastes' ? (
        <div className="tw-bg-white tw-flex tw-flex-col tw-justify-center md:tw-justify-start md:tw-flex-row tw-gap-3 tw-py-3 tw-px-6 tw-rounded-lg tw-mt-6">
          <div className="tw-flex tw-gap-1 tw-justify-center md:tw-justify-start">
            <InfoYellow />
          </div>
          <p className="tw-w-full tw-text-wrap tw-text-[#344054]">
            Click on certain results to access additional menu options,
            including filters and new search capabilities.
          </p>
        </div>
      ) : null}

      <div className="tw-pt-6">
        {isLeakDetails && (
          <LeakDetails
            leakId={selectedLeakId}
            setIsLeakDetails={setIsLeakDetails}
          />
        )}

        <div className={isLeakDetails ? 'tw-hidden' : ''}>
          {selectedOptionLabel === 'Leaks' ? (
            <Leaks
              selectedOptionLabel={selectedOptionLabel}
              searchTerm={searchTerm}
              onLeakDetail={handleLeakDetail}
            />
          ) : selectedOptionLabel === 'Credit Cards' ? (
            <CreditCards
              selectedOptionLabel={selectedOptionLabel}
              searchTerm={searchTerm}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
