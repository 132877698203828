/**
 * InputLabel component.
 * Renders a label for an input field.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.className - Additional CSS classes for the label.
 * @param {string} props.htmlFor - The HTML for attribute.
 * @param {string} props.text - The text to be displayed as the label.
 * @returns {JSX.Element} JSX code for rendering the InputLabel component.
 */

const InputLabel = ({
  className = '',
  htmlFor = '',
  text = '',
  extraLabel = '',
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className={`tw-block tw-text-sm tw-font-medium tw-text-primary ${className}`}
    >
      {text}
      {extraLabel && (
        <span className="tw-text-neutral-400 tw-text-xs tw-ml-1">
          {extraLabel}
        </span>
      )}
    </label>
  );
};

export default InputLabel;

// Sample Usage:
// import InputLabel from '.path/InputLabel';
// <InputLabel
//   htmlFor="email"
//   text="Email"
// />
