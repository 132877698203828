import React from "react";
import InputGroup from "../../../../common/components/InputGroup/InputGroup";
import { useForm } from "react-hook-form";
import SelectGroup from "../../../../common/components/SelectDropdown/SelectDropdown";
import Info from "../../../assets/svgs/Info";
const ApiSetup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <>
      <div className="tw-w-[100%] tw-flex tw-flex-col tw-gap-6 tw-overflow-x-auto md:tw-overflow-hidden">
        <div className="">
          <p className="tw-text-[18px] tw-font-bold tw-text-wrap tw-break-all">
            How to Authenticate and Use the API ?
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-flex-col tw-gap-1">
            <p className=" tw-font-medium tw-text-base">Authentication</p>
            <p className="tw-text-wrap tw-break-all">
              To use the API, start by obtaining an access token. This token is
              crucial for making authenticated requests across different
              endpoints. Follow these steps to authenticate:
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <p className=" tw-font-medium tw-text-base">
              Endpoint for Authentication:
            </p>
            <p className="tw-text-wrap tw-break-all">
              https://client-api.leak.center/api/token/
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <p className=" tw-font-medium tw-text-base">Required Inputs:</p>
            <div className="tw-flex tw-gap-1">
              <span className="tw-font-medium tw-text-base ">Username:</span>
              <p className="tw-text-wrap tw-break-all">
                Your registered username
              </p>
            </div>

            <div className="tw-flex tw-gap-1">
              <span className="tw-font-medium tw-text-base ">Password:</span>
              <p className="tw-text-wrap tw-break-all">Your Account password</p>
            </div>
            <div className="tw-flex tw-gap-1">
              <span className="tw-font-medium tw-text-base ">Server:</span>
              <p className="tw-text-wrap tw-break-all">
                The server you are connecting to
              </p>
            </div>
          </div>

          <div>
            <p className="tw-text-wrap tw-break-all">
              Use a POST request with your credentials to fetch the access
              token. If authentication is successful, you'll receive a token to
              use in subsequent requests.
            </p>
          </div>

          <div className="tw-flex tw-flex-col tw-gap-1">
            <p className=" tw-font-medium tw-text-base ">Making API Requests</p>
            <p className="tw-text-wrap tw-break-all">
              Once authenticated, you can interact with various service
              endpoints using the obtained access token. Here’s a general
              workflow:
            </p>
          </div>

          <div className="tw-flex tw-flex-col tw-gap-4">
            <div>
              <p className=" tw-font-medium tw-text-base">
                1. Create a Search Request :
              </p>
              <ul className="tw-list-disc tw-pl-5">
                <li className="tw-text-wrap tw-break-all">
                  Send a search term and necessary identifiers (like
                  `backend_client_id`) to the specific service endpoint.{" "}
                </li>
                <li className="tw-text-wrap tw-break-all">
                  Example Endpoint:
                  `https://client-api.leak.center/api/service/create_search_request/`
                </li>
                <li className="tw-text-wrap tw-break-all">
                  Include your `access_token` in the Authorization header.
                </li>
              </ul>
            </div>
            <div>
              <p className=" tw-font-medium tw-text-base">
                2. Check Search Status :
              </p>
              <ul className="tw-list-disc tw-pl-5">
                <li className="tw-text-wrap tw-break-all">
                  Use the search ID returned from your creation request to check
                  the status
                </li>
                <li className="tw-text-wrap tw-break-all">
                  Example Endpoint:
                  `https://client-api.leak.center/api/service/create_search_request/`
                </li>
                <li className="tw-text-wrap tw-break-all">
                  Continue checking until the status is 'finished', 'failed', or
                  'error.
                </li>
              </ul>
            </div>
            <div>
              <p className=" tw-font-medium tw-text-base">3. List Result :</p>
              <ul className="tw-list-disc tw-pl-5">
                <li className="tw-text-wrap tw-break-all">
                  Once the search is finished, list all results.
                </li>
                <li className="tw-text-wrap tw-break-all">
                  Example Endpoint:
                  `https://client-api.leak.center/api/service/list_search_result/`
                </li>
              </ul>
            </div>

            <div>
              <p className=" tw-font-medium tw-text-base">
                4. Retrieve Specific Results :
              </p>
              <ul className="tw-list-disc tw-pl-5">
                <li className="tw-text-wrap tw-break-all">
                  To get more details about a particular result, use its
                  `result_id`.
                </li>
                <li className="tw-text-wrap tw-break-all">
                  Example Endpoint:
                  `https://client-api.leak.center/api/service/get_search_result/
                  result_id/`
                </li>
              </ul>
            </div>
            <div>
              <div className="tw-flex tw-gap-1">
                <Info />
                <p className="-tw-mt-1 tw-text-wrap tw-break-all">
                  This process is applicable to various services offered via the
                  API, ensuring a consistent method of interacting across
                  different endpoints. Remember, each step requires proper
                  authorization using the access_token.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button className="tw-bg-primary tw-text-white tw-px-3 tw-py-2 tw-rounded-lg tw-text-sm tw-font-semibold tw-h-[40px]">
            Download Sample Script
          </button>
        </div>
      </div>
    </>
  );
};

export default ApiSetup;
