import React from 'react';
import Premium from '../../Icons/Premium';
import InfoError from '../../Icons/Info';

function ProgressBar({
  current,
  total = 100,
  label = '',
  subLabel = '',
  backgroundColor,
  className,
  subClassName,
  trackColor = 'tw-bg-gray-200',
  height = 'tw-h-2.5',
  rounded = 'tw-rounded-lg', // updated to 8px equivalent
  disabled = false,
  premium = false,
  inlineProgress = false,
  errorLabel,
}) {
  const progress = disabled ? 0 : (current / total) * 100;

  // Custom gradient style
  const gradientStyle = {
    background: backgroundColor,
    width: `${progress}%`,
  };

  return (
    <div className="tw-flex tw-flex-col">
      <div>
        <div
          className={`tw-flex tw-justify-between tw-items-center ${className}`}
        >
          {label && (
            <div
              className={`tw-flex tw-gap-[10.5px] ${disabled ? 'tw-text-gray-400' : ''}`}
            >
              <span>{label}</span>{' '}
              {premium && <Premium disabled={disabled} />}
            </div>
          )}
          {!inlineProgress && <span className={disabled ? '!tw-text-gray-300' : 'tw-text-base'}>{Math.ceil(progress)}%</span>}
        </div>
        {subLabel && <span className={subClassName + (disabled ? ' !tw-text-gray-300' : '')}>{subLabel}</span>}
      </div>
      <div className="tw-flex tw-gap-3 tw-items-center">
        <div className={`tw-w-full ${trackColor} ${rounded} ${height}`}>
          <div className={`${height} ${rounded}`} style={gradientStyle}></div>
        </div>
        {inlineProgress && (
          <div className="tw-text-xs tw-font-normal tw-leading-[14.06px] tw-text-[#797C84]">
            {Math.ceil(progress)}%
          </div>
        )}
      </div>
      {errorLabel && (
        <div className="tw-flex tw-mt-1 tw-gap-2 tw-items-center">
          <InfoError stroke="#F03A3A" />
          <span className="tw-text-sm tw-font-normal tw-leading-[16.41px] tw-text-red-800">
            {errorLabel}
          </span>
        </div>
      )}
    </div>
  );
}

export default ProgressBar;
