import _ from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'store/api';

export const getList = createAsyncThunk(
  'report/affectedSystem/getList',
  async ({ page, size }, { getState, dispatch, rejectWithValue }) => {
    const state = getState();

    if (_.isNil(page)) page = state.report.affectedSystem.currentPage - 1;
    if (_.isNil(size)) size = state.report.affectedSystem.size;

    try {
      const response = await api.get('service/affected_system_stats/', {
        params: {
          page: page + 1,
          size: 10,
        },
      });
      // Transform the response data
      const transformedData = response.data.results;

      return {
        content: transformedData,
        totalElements: response.data.count,
        totalPages: Math.ceil(response.data.count / size),
        number: page,
      };
    } catch (error) {
      return rejectWithValue(error.response.data || 'Fetch risk list failed');
    }
  },
);

const affectedSystemSlice = createSlice({
  name: 'affectedSystem',
  initialState: {
    list: [],
    size: 10,
    currentPage: 1,
    totalElements: 0,
    totalPages: 0,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getList.fulfilled, (state, action) => {
        const { content, totalElements, totalPages, number } = action.payload;
        state.list = content;
        state.totalElements = totalElements;
        state.totalPages = totalPages;
        state.currentPage = number + 1;
        state.loading = false;
      })
      .addCase(getList.rejected, (state, action) => {
        state.totalElements = state.totalPages = 0;
        state.list = [];
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default affectedSystemSlice.reducer;
